import { Autocomplete, Button, FormControl, FormHelperText, IconButton, InputLabel, MenuItem, Select, Stack, TextField } from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import RefreshIcon from '@mui/icons-material/Refresh';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import dayjs from 'dayjs';
import 'dayjs/locale/it';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
const sanitizeSelectOption = (option) => {
    if (option.value && option.label) {
        return option;
    } else {
        return {
            value: option.toString(),
            label: option.toString()
        }
    }
}

export default function OpinionatedForm({ fields, buttons = [], displayJson = false, formValues, setFormValues, validateOnChange = false }) {




    const [formErrors, setFormErrors] = useState({});

    const [formItems, setFormItems] = useState({})

    const [options, setOptions] = useState({});

    const [formIsValid, setFormIsValid] = useState(false);

    const fieldShouldCompare = (field) => {
        if (typeof field.displayIf !== "undefined") {
            return field.displayIf(formValues);
        }
        return true;
    }

    const handleInputChange = (field, value) => {
        if (field.setter) {
            field.setter(value);
        } else {
            setFormValues((prevValues) => ({
                ...prevValues,
                [field]: value,
            }));
        }
        if (validateOnChange) {
            validateForm({
                ...formValues,
                [field]: value,
            })
        }

        for (let item of fields) {

            if (item.dependencies?.includes(field) && item.getOptions) {
                handleInputChange(item.name, "");
                reloadSelectOptions(item, {
                    ...formValues,
                    [field]: value,
                });
            }
        }
    };

    const validateForm = (overrideValues = null) => {
        let fv = overrideValues || formValues;
        let errors = {};
        for (let item of fields) {
            if (item.required && !fv[item.name]) {
                errors[item.name] = "Required";
            }
            if (item.validator) {
                let err = item.validator(fv[item.name], fv);
                if (err) {
                    errors[item.name] = err;
                }
            }
        }
        setFormErrors(errors);
        let fiv = Object.keys(errors).length === 0;
        setFormIsValid(fiv);
        return fiv;

    }

    const initFieldValue = (item, fv) => {
        fv[item.name] = fv[item.name] || item.initialValue || undefined;
        return fv;
    }

    useEffect(() => {
        let fv = formValues;
        for (let item of fields) {
            initFieldValue(item, fv);
        }
    }, []);

    function reloadSelectOptions(field, fv = null) {
        if (!fv) fv = formValues
        if (field.type === 'select' && !field.getOptions) {
            throw new Error("Field " + field + " should have getOptions");
        }
        if (field.type === 'select' && typeof field.getOptions === 'function') {
            setOptions((prevOptions) => ({
                ...prevOptions,
                [field.name]: 'loading',
            }));
            const getAsyncOptions = async () => {
                const asyncOptions = await field.getOptions(fv);
                setOptions((prevOptions) => ({
                    ...prevOptions,
                    [field.name]: asyncOptions.map(sanitizeSelectOption),
                }));
            };

            if (field.getOptions(fv) instanceof Promise) {
                // Caso asincrono
                getAsyncOptions();
            } else {
                // Caso sincrono
                setOptions((prevOptions) => ({
                    ...prevOptions,
                    [field.name]: field.getOptions(fv).map(sanitizeSelectOption),
                }));
            }
        }
        if (field.type === 'autocomplete' && typeof field.getOptions === 'function') {
            setOptions((prevOptions) => ({
                ...prevOptions,
                [field.name]: 'loading',
            }));
            const getAsyncOptions = async () => {
                const asyncOptions = await field.getOptions(fv);
                setOptions((prevOptions) => ({
                    ...prevOptions,
                    [field.name]: asyncOptions,
                }));
            };

            if (field.getOptions(fv) instanceof Promise) {
                // Caso asincrono
                getAsyncOptions();
            } else {
                // Caso sincrono
                setOptions((prevOptions) => ({
                    ...prevOptions,
                    [field.name]: field.getOptions(fv),
                }));
            }
        }
    }

    useEffect(() => {
        // Carica le opzioni asincronamente quando il componente viene montato
        fields.forEach((field) => {
            reloadSelectOptions(field);
        });

        //effettua una prima validazione
        validateForm();
    }, []);


    return <Stack justifyContent="space-evenly" sx={{ width: "100%", p: 2 }} spacing={2}>
        {displayJson && <pre>{JSON.stringify(formValues, null, 2)}</pre>}
        {
            fields.length ? fields.map(item => (
                fieldShouldCompare(item) ? <Fragment key={item.name}>

                    {item.type === "text" && <TextField
                        label={item.label || "NOLABEL"}
                        variant="outlined"
                        required={item.required}
                        value={formValues[item.name]}
                        onChange={e => handleInputChange(item.name, e.target.value)}
                        fullWidth={true}
                        margin="normal"
                        error={!!formErrors[item.name]}
                        placeholder={item.placeholder || ""}
                        helperText={formErrors[item.name]}
                    />}
                    {item.type === "datepicker" && <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="it">
                        <DateTimePicker
                            size="small"
                            ampm={item.ampm || false}
                            format={item.format || "DD-MM-YYYY hh:mm:ss"}
                            label={item.label || "NOLABEL"}
                            value={formValues[item.name]}
                            onChange={v => handleInputChange(item.name, v)}
                            renderInput={(params) => <TextField size="small" {...params} />}
                        />
                    </LocalizationProvider>}

                    {item.type === "autocomplete" && <FormControl
                        required={item.required}
                        error={!!formErrors[item.name]}
                    >
                        {options[item.name] === 'loading' ? <div>Loading options...</div> : (
                            <Stack direction="row" spacing={2}>
                                <Autocomplete
                                    multiple={item.multiple || false}
                                    freeSolo={item.freeSolo || false}
                                    size="small"
                                    id={item.name}
                                    name={item.name}
                                    fullWidth={true}
                                    value={formValues[item.name]}
                                    onChange={(e, v) => handleInputChange(item.name, v)}
                                    options={(options[item.name] || [])}
                                    renderInput={(params) => <TextField
                                        {...params}
                                        variant="outlined"
                                        label={item.label || "NOLABEL"}
                                        placeholder={item.placeholder || ""}
                                    />}
                                    loading={!options[item.name]?.length}
                                >

                                </Autocomplete>
                                {item.reloadable && <IconButton onClick={() => reloadSelectOptions(item)}><RefreshIcon /></IconButton>}
                            </Stack>
                        )}


                        {!!formErrors[item.name] ? <FormHelperText>{formErrors[item.name]}</FormHelperText> : <></>}
                    </FormControl>}

                    {item.type === "select" && <FormControl
                        error={!!formErrors[item.name]}
                        required={item.required}>
                        <InputLabel id={item.name + "-label"}>{item.label || "NOLABEL"}</InputLabel>
                        {options[item.name] === 'loading' ? <div>Loading options...</div> : (
                            <Stack direction="row" spacing={2}>
                                <Select
                                    required
                                    id={item.name}
                                    labelId={item.name + "-label"}
                                    label={item.label || "NOLABEL"}
                                    fullWidth={true}
                                    value={formValues[item.name]}
                                    onChange={e => handleInputChange(item.name, e.target.value)}
                                >
                                    {options[item.name]?.map((option) => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </Select>
                                {item.reloadable && <IconButton onClick={() => reloadSelectOptions(item)}><RefreshIcon /></IconButton>}
                            </Stack>
                        )}
                        {!!formErrors[item.name] ? <FormHelperText>{formErrors[item.name]}</FormHelperText> : <></>}
                    </FormControl>}
                </Fragment> : <></>)
            ) : <div>No form items</div>
        }
        {
            !!buttons.length && <Stack direction="row" spacing={2} sx={{ width: "100%" }} alignContent="center" justifyContent="space-evenly">

                {buttons.map((button, index) => (
                    <Button
                        key={index}
                        variant="contained"
                        color={button.color || 'default'}
                        onClick={button.onClick}
                        disabled={button.disabled ? button.disabled(formIsValid) : false}
                        style={{ marginLeft: '10px' }}
                    >
                        {button.name}
                    </Button>
                ))}

            </Stack>
        }
    </Stack>

}