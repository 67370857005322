import { Box, FormControlLabel, Grid, Switch } from "@mui/material";



import React from 'react';
import CheckWidget from "../../components/checks/CheckWidget";
import FromToPicker from "../../components/time/fromtoPicker";

import './checks.scss';









export default function ChecksDashboard({ environment }) {


    const [timeSpan,setTimeSpan] = React.useState({});
    const all_customers = ['Carrefour','Stellantis','Gucci','Unicredit'];
    const [toggleCustomers,setToggleCustomer] = React.useState(()=>{
        let tC = {};
        for (let customer of all_customers){
            tC[customer] = true;
        }
        return tC;
    });

    return <React.Fragment>
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Box className="filters" justifyItems="center">
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <FromToPicker initial={timeSpan} onChange={setTimeSpan}></FromToPicker>
                        </Grid>
                        { all_customers.map((customer,customerI)=>(
                        <Grid item xs={12} sm={6} lg={3} key={customerI}>
                           <FormControlLabel control={<Switch size="small" color="background" checked={toggleCustomers[customer]} />} label={customer} />
                        </Grid>
                        ))}
                    </Grid>
                </Box>
            </Grid>
           
            <Grid item xs={12} sm={6} md={4} lg={3}>
                <CheckWidget checkName="Riordino Mercati" runsStats={{ amount: 4, unit: 'h' }} errorsStats={{amount: 0, unit: 'd'}} customer="Carrefour"></CheckWidget>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
                <CheckWidget checkName="QVAPP" runsStats={{ amount: 4, unit: 'h' }} errorsStats={{amount: 0, unit: 'd'}} customer="Carrefour"></CheckWidget>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
                <CheckWidget checkName="Verifica listini" runsStats={{ amount: 4, unit: 'h' }}  errorsStats={{amount: 2, unit: 'd'}}customer="Carrefour"></CheckWidget>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
                <CheckWidget checkName="RWImflpla" runsStats={{ amount: 4, unit: 'h' }}  errorsStats={{amount: 0, unit: 'd'}}customer="Carrefour"></CheckWidget>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
                <CheckWidget checkName="Passaggio di consegne" runsStats={{ amount: 3, unit: 'd' }}  errorsStats={{amount: 1, unit: 'd'}} customer="Carrefour"></CheckWidget>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
                <CheckWidget checkName="GSDP HEALTH" runsStats={{ amount: 5, unit: 'h' }}  errorsStats={{amount: 0, unit: 'd'}} customer="Stellantis"></CheckWidget>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
                <CheckWidget checkName="Controllo notturno" runsStats={{ amount: 48, unit: 'd' }}  errorsStats={{amount: 3, unit: 'd'}} customer="Gucci"></CheckWidget>
            </Grid>
        </Grid>
    </React.Fragment>

}