import AgentsOperatorView from "../../../components/agents/agentsOperatorView";

export default function StellantisAgents(){




    return <>
        <AgentsOperatorView poolName="StellantisServiceNowASG" svcName="agentsInStellantis"/>
    </>
}