import { Accordion, AccordionDetails, AccordionSummary, Box, Button, FormControl, FormControlLabel, Stack, Switch, Typography } from "@mui/material";
import React, { useContext, useState } from "react";
import { useParams } from 'react-router-dom';
import { capitalizeEachWord, fullDateStr } from "../../services/common/common";

import { getCredentialsForRole, listAvailableRoles, obtainBaseRoleCredentials } from '../../services/aws/grants';
import S3Image, { S3Image2, initS3Client, readJsonFromS3 } from '../../services/aws/s3old';
import { ServiceContext } from "../../hooks/serviceRegistry/serviceContext";
import useCabledMutation from "../../hooks/serviceRegistry/useCabledMutation";
import { useQueryClient } from "@tanstack/react-query";
// const SITES = [
//     { key: "pubblico", label: "Sito pubblico" },
//     { key: "privati", label: "Italia privati" },
//     { key: "corporate", label: "Italia corporate" }
// ]
// function parseUnicreditListKey(x) {
//     let split = x.split("/");
//     let filename = split[split.length - 1];
//     let filename_split = filename.split("_");
//     let outcome = filename_split.length > 1 ? filename_split[0] : null;
//     outcome = { 'false': false, 'true': true }[outcome];
//     let foldername = split[split.length - 2];
//     let foldername_split = foldername.split("_");
//     let ts = foldername_split[foldername_split.length - 1];
//     foldername_split.pop(foldername_split.length - 1);
//     let qualifier = capitalizeEachWord(foldername_split);
//     let dt = new Date(parseInt(ts, 10) * 1000);
//     let prefix = split[0] + "/" + split[1] + "/" + split[2] + "/";
//     return { dt, prefix, key: x, qualifier, outcome };
// }


// class UnicreditReport {
//     constructor(completedPages, expiration, reportName, reportOutcome, reportStatus, site, totalPages) {
//         this.status = reportStatus;
//         this.outcome = reportOutcome;
//         this.expiration = new Date(expiration * 1000);
//         this.fullname = reportName;
//         this.site = site;
//         this.timestamp = parseInt(reportName.replace(site, ""));
//         this.date = new Date(this.timestamp * 1000);
//         this.completedPages = completedPages;
//         this.totalPages = totalPages;
//         this.id = this.fullname;
//     }
//     static from_dynamo_record(record) {
//         return new UnicreditReport(
//             record.completedPages.N,
//             record.expiration.N,
//             record.reportName.S,
//             record.reportOutcome?.BOOL,
//             record.reportStatus.S,
//             record.site.S,
//             record.totalPages.N

//         )
//     }
// }



const BUCKET = 'itpa-customer-unicredit';

export default function UnicreditReportDetails() {

    const [reportData, setReportData] = useState({});
    const [reportDataError, setReportDataError] = useState(null);
    const [s3Client, setS3Client] = useState(null);
    const [displayOnlyProblems, setDisplayOnlyProblems] = useState(false);
    // prendo dal path - router il nome del report come parametro reportName
    const params = useParams();
    const reportName = params.reportName;
    const { getSvc } = useContext(ServiceContext);
    const s3 = getSvc('s3');
    const ddb = getSvc('ddb');
    // il reportName è composto da una parte alfabetica che è il sito e da una parte numerica, le separo con regex
    const site = reportName.match(/^[a-z]+/)[0];
    const timestamp = reportName.match(/\d+/)[0];
    const date = new Date(parseInt(timestamp, 10) * 1000);
    // calcolo la chiave su s3 del file del report
    const key = `reports/${site}/${reportName}.json`;
    const queryClient = useQueryClient();
    const togglePageRunningModeMutation = useCabledMutation({
        svcName: 'ddb',
        method: "updateItem",
        // onSuccess: ()=>queryClient.invalidateQueries
    });
  

    React.useEffect(() => {
        (async () => {
            try {
                let js = await s3.getFile({ bucket: BUCKET, key });
                if (typeof js === "string") {
                    js = JSON.parse(js);
                }
                setReportData(js);
            } catch (e) {
                console.error(e);
                setReportDataError("Impossibile scaricare questo report");
            }
        })();
    }, []);
    //leggo il file del report


    return <Box sx={{ width: '100%' }}>
        {reportDataError ? <Typography variant="h5" color="error">{reportDataError}</Typography>
            : (
                <>
                    <Typography variant="h5">{reportData?.site?.toUpperCase()} {fullDateStr(date)}</Typography>
                    
                    {reportData?.pages && reportData.pages.length > 0 && (

                        reportData.pages.map((x, i) => (
                            <React.Fragment key={i}>
                                {(!displayOnlyProblems || !x.processed || !x.outcome) ? (

                                    <Box>
                                        <Stack direction="row">
                                            <Box sx={{ width: "33%" }}>
                                                <Typography variant="h6">{capitalizeEachWord(x.page.replace("_", " "))}</Typography>
                                            </Box>
                                            {x.img ? (
                                                <S3Image2
                                                getter={async () => s3.getFile({ bucket: BUCKET, key: x.img, responseContentType: "image/png", returnUndecoded: true })}
                                                width="95%"
                                            />
                                            ) : (
                                                <p>Non è stato possibile raccogliere questa immagine.</p>
                                            )}
                                            
                                        </Stack>
                                    </Box>

                                ) : (<></>)}
                            </React.Fragment>
                        )
                        )
                    )
                    }


                </>
            )
        }

    </Box>


}