import { Autocomplete, Box, FormControlLabel, IconButton, LinearProgress, Paper, Stack, Switch, Tab, Tabs, TextField, Typography, styled } from "@mui/material"
import LoopOutlinedIcon from '@mui/icons-material/LoopOutlined';
import PropTypes from 'prop-types';
import { Fragment, createRef, isValidElement, useEffect, useState } from "react";
import dayjs from "dayjs";
import { useQueryClient } from "@tanstack/react-query";

import OmniBox from "../filters/omnibox";

import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import RemoveOutlinedIcon from '@mui/icons-material/RemoveOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import { switchScan } from "rxjs";
import { QueryLinearProgress } from "./queryLinearProgress";
function hasObjectPrototype(o) {
    return Object.prototype.toString.call(o) === '[object Object]'
}
function isPlainObject(o) {
    if (!hasObjectPrototype(o)) {
        return false
    }

    // If has modified constructor
    const ctor = o.constructor
    if (typeof ctor === 'undefined') {
        return true
    }

    // If has modified prototype
    const prot = ctor.prototype
    if (!hasObjectPrototype(prot)) {
        return false
    }

    // If constructor does not have an Object-specific method
    if (!prot.hasOwnProperty('isPrototypeOf')) {
        return false
    }

    // Most likely a plain Object
    return true
}
function hashQueryKey(queryKey) {
    return JSON.stringify(queryKey, (_, val) =>
        isPlainObject(val)
            ? Object.keys(val)
                .sort()
                .reduce((result, key) => {
                    result[key] = val[key]
                    return result
                }, {})
            : val,
    )
}

const DeckSwitch = function ({ switchState, setSwitchState, switchLabel }) {

    return <FormControlLabel control={<Switch size="small" checked={switchState} onChange={e => setSwitchState(e.target.checked)} />} label={switchLabel} />

}


const DeckContainer = styled(Paper, {
})(({ theme }) => ({
    width: "100%",
    // height: "5vh", 
    boxShadow: theme.shadows[3],
    padding: theme.spacing(1)
}));

export default function Deck({
    title = null,
    queryKey = null,

    hasRefresh = true,
    onRefresh = () => { },

    hasOmniBox = false,
    searchHandler = null,

    hasNewItem = false,
    onNewItem = () => { },

    hasDeleteItem = false,
    onDeleteItem = () => { },
    
    hasOptions = false,
    onOptions = () => { },

    spaceBelow = 1,
    switches = [],
    tabs = {}
}) {


    if (hasOmniBox && (
        searchHandler === null || searchHandler.search === null || searchHandler.setSearch === null || searchHandler.categories === null
    )) {
        throw new Error("For omnibox searchHandler is needed");
    }
    let queryHash = null;
    const [progress, setProgress] = useState(100);
    let queryClient = useQueryClient();
    const puInterval = createRef();
    const refetchInterval = createRef();

    if (queryKey !== null) {
        queryHash = hashQueryKey(queryKey);
        puInterval.current = -1;
    }



    const [canRefresh, setCanRefresh] = useState(hasRefresh);

    function refresh() {
        console.log("Refreshing",queryKey);
        if (canRefresh) {
            if (!!queryKey) queryClient.invalidateQueries(queryKey);
            console.log("Invalidated",queryKey);
            if (onRefresh) onRefresh();
            setCanRefresh(false);
            console.log("Locked refresh");
            setTimeout(() => { setCanRefresh(true); console.log("Unlocked refresh"); }, 3000);
        }
    }

    function updateStatus() {
        // console.log("use effect",queryHash)
        // console.log("Refresh loader for queryKey", queryKey, refetchInterval.current);
        let queryState = queryClient.getQueryState(queryKey)
        var updatedAt = queryState.error !== null
            ? queryState.errorUpdatedAt
            : queryState.dataUpdatedAt;
        let progr = 100 - parseInt(100 * Math.min((new Date() - new Date(updatedAt)) / refetchInterval.current, 1))
        setProgress(progr);
    }
    let qc, queries, queryCache;
    if (queryKey !== null) {
        qc = queryClient.getQueryCache();
        queries = qc.queries;
        queryCache = queries.find(x => x.queryHash === queryHash);
        refetchInterval.current = queryCache?.options?.refetchInterval;
    }


    // console.log(queryClient.getQueryData(queryKey))
    useEffect(() => {

        // console.log(JSON.stringify(queryCache));
        if (!!queryCache && queryKey && puInterval.current === -1 && !!refetchInterval.current) {
            // console.log("Setting pu Interval");
            let intLength = Math.max(parseInt((refetchInterval.current / 60000) * 250), 1000)
            // console.log("SETTING INTERVAL",refetchInterval,intLength);
            let pui = setInterval(() => updateStatus(), intLength);
            // console.log("PUI", pui)
            puInterval.current = pui;
        }
        return () => {
            if (puInterval.current) {
                // console.log("CLEAR INTERVAL");
                clearInterval(puInterval.current);
                puInterval.current = -1;
            }
        }
    }, [queryKey, refetchInterval.current]);

    if (queryKey !== null && !queryCache) {
        console.log("Missing queryCache for key", queryKey, "hash", queryHash);
        return <p>Errore: guarda in console...</p>
    }
    return (
        <Stack sx={{ mb: spaceBelow }} style={{position: "sticky", top: '0px', zIndex: 1000}}>
            <DeckContainer>
                <Stack direction="row" justifyContent="space-between">

                    {/* Refresh */}

                    {hasRefresh && (
                        <IconButton color="neutral" onClick={refresh}><LoopOutlinedIcon /></IconButton>
                    )}
                    {/* new item */}
                    {hasNewItem && (
                        <IconButton color="primary" onClick={() => onNewItem()}><AddOutlinedIcon /></IconButton>
                    )}
                    {/* remove item */}
                    {hasDeleteItem && (
                        <IconButton color="error" onClick={() => onDeleteItem()}><RemoveOutlinedIcon /></IconButton>
                    )}
                    {/* options */}
                    {hasOptions && (
                        <IconButton color="neutral" onClick={() => onOptions()}><SettingsOutlinedIcon /></IconButton>
                    )}

                    {/* Switches */}
                    {!!switches && switches.map((s, sI) => (
                        <Fragment key={sI}>
                            <Box sx={{ flexGrow: 1 }} />
                            <DeckSwitch {...s} key={sI} />
                        </Fragment>
                    ))}

                    {/* Tabs */}
                    {!!tabs && !!tabs.tabsList && !!tabs.tabsList.length && <Tabs value={tabs.index} onChange={tabs.setIndex} scrollButtons="auto" variant="scrollable">
                        {
                            tabs.tabsList.map((t, tI) => (
                                !!tabs.component ? <Tab component={tabs.component} label={t.name} id={t.id} key={tI} /> : <Tab label={t.name} id={t.id} key={tI} />
                            ))
                        }
                    </Tabs>}

                    {/* Title */}
                    {!!title && <>
                        {isValidElement(title) ? title : (<>
                            <Box sx={{ flexGrow: 10 }} />
                            <Box>
                                <Typography variant="h6">{title.toString()}</Typography>
                            </Box>
                        </>)}
                    </>
                    }

                    {/* Omnibox */}
                    {hasOmniBox && (
                        <>
                            <Box sx={{ flexGrow: 10 }} />
                            <Box>
                                <OmniBox
                                    data={queryClient.getQueryData(queryKey)}
                                    preprocess={searchHandler.preprocess || (x => x)}
                                    filters={searchHandler.search}
                                    setFilters={searchHandler.setSearch}
                                    categories={searchHandler.categories} />
                            </Box>
                        </>
                    )}
                    <Box sx={{ flexGrow: 1 }} />
                </Stack>
            </DeckContainer>
            {queryKey && refetchInterval && <QueryLinearProgress queryKey={queryKey} />}
        </Stack>
    )
}

//{queryKey && refetchInterval && <LinearProgress value={progress} color="primary" variant="determinate" />}

Deck.propTypes = {
    canRefresh: PropTypes.bool,
    onRefresh: PropTypes.func,
    mainQuery: PropTypes.any,
    newItem: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
    removeItem: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
    info: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
    setup: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
    refresh: PropTypes.oneOfType([PropTypes.bool, PropTypes.func])
}
