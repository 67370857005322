import { Grid, Stack } from "@mui/material";

const PageGrid = (props) => {
    const { children, height=null, targetColW=null } = props;
    var colW = {xs: 12, sm:12, md:6, lg:4, xl:4};
    
    if (targetColW){
        colW = Object.assign(colW,targetColW);
    }
    
    return <Grid container spacing={2} >
        {children?.map((ch,chI)=><Grid key={chI} item xs={colW.xs} sm={colW.sm} md={colW.md} lg={colW.lg} xl={colW.xl}>{ch}</Grid>)}
    </Grid>
    
}
export default PageGrid