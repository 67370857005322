import ArrowBack from "@mui/icons-material/ArrowBack";
import { Box, Button, ButtonGroup, Card, CardContent, Grid, IconButton, Skeleton, Stack, Tooltip, Typography } from "@mui/material";
import dayjs from 'dayjs';
import React from "react";
import { useSched, useSchedData } from "../../hooks/carrefour/carrefour";
import { dayMonthDateStr, hourMinuteDateStr } from "../../services/common/common";
import { LapideDati } from './lapideDati';

export function LapideTop({ title, start, stato }) {
    return (
        <Stack direction="row" justifyContent="space-between" sx={{ width: '100%' }} style={{ position: "relative", top: -15 }}>
            {title && title.length ? (
                <Typography variant="h5"><span style={{ position: "relative", top: 5 }}>{stato ? (stepSemaphore(stato)) : ""}</span> {title}</Typography>
                ) : (
                <Skeleton width="15%" variant="text" animation="wave" sx={{ fontSize: '2rem' }}></Skeleton>
            )}
            <Typography variant="h6">{hourMinuteDateStr(start)}</Typography>
        </Stack>)
}

export function LapideBottom({ start, end, middlecontent=null }) {
    return (
        <Stack direction="row" justifyContent="space-between">
            <Box>
                <Typography variant="h5">{dayMonthDateStr(start)}</Typography> 
            </Box>
            <Box style={{flexGrow: 1}}>
                {middlecontent || <></>}
            </Box>
            <Box >
                { end ? (
                    <Typography variant="h6">{hourMinuteDateStr(end)}</Typography>
                    ) : (
                    <Skeleton width="5%" variant="text" animation="wave" sx={{fontSize: '2rem'}}></Skeleton>
                ) }
            </Box>
        </Stack>
        // <>
        // <Box style={{ position: "relative", bottom: -20, left: 5, border: '1px solid black' }}>
        //     <Typography variant="h5">{dayMonthDateStr(start)}</Typography>
        // </Box>
        // // <Stack direction="row" justifyContent="space-between" width="100%" style={{ position: "relative", bottom: -20, border: '1px solid black' }}>
            
        // // </Stack>
        // {!!middlecontent ? middlecontent : <></>}
        // 
        // </>
    )
}



export function LapideGenerica({
    name,
    comptitle,
    datacomp,
    at,
    target_input,
    trigger,
    isFuture,
    w = 4
}) {
    if (!at) { at = new Date(); } //TODO: rimuovere
    let end = dayjs(at).add(20, 'minute').toDate();
    const [stati, setStati] = React.useState(null);
    const [meta, setMeta] = React.useState({});
    const [isClosed, setIsClosed] = React.useState(false);
    const [canFetchData, setCanFetchData] = React.useState(false);
    const [stableData, setStableData ] = React.useState(false);
    if (!isClosed){
        if (at < dayjs().subtract(2, 'hour').toDate()) {
            setIsClosed(true);
        }
        else if (stati != null && ['succeeded', 'failed'].includes(stati.main)) setIsClosed(true);
    }
    const schedData = useSched({ schedName: name, isClosed });
    let schedObtained = !!schedData.data;
    if (!canFetchData){
        if (stati != null) setCanFetchData(true);
    }
    const checkData = useSchedData({ schedName: name, enabled: canFetchData && !stableData, ttl: 20 });
    let dataObtained = !!checkData.data;
    if (dataObtained && isClosed && !stableData){ setStableData(true); }
    React.useEffect(() => {
        if (!schedData || !schedData.data) return;
        setStati(schedData.data.stati);
        setMeta(schedData.data.meta);
    }, [schedData.isFetching]);
    let restStati = {};
    const [display, setDisplay] = React.useState('stati');
    
    return (
        <Grid item xs={12} sm={display === "stati" ? w : 12} justifyContent="center">
            <Card variant="outlined" style={{ height: isFuture? 150 : (display === "stati" ? 300 : 500) }}>
                <CardContent style={{ height: '100%' }}>

                    <Stack justifyContent="space-between" style={{ height: '100%', position: "relative" }}>
                        <LapideTop title={comptitle} start={at} stato={stati?.main || "unknown"}></LapideTop>
                        {!isFuture && <Grid container spacing={1} sx={{ mt: 2 }} style={{ height: '75%' }}>
                            {display === 'stati' ? (
                                <>
                                    {
                                        restStati ? (Object.entries(restStati).map(([stateName, stateValue], xI) => (
                                            <>
                                                <Grid item xs={2} sx={{ mt: 1 }}>{stepSemaphore(stateValue)}</Grid>
                                                <Grid textAlign="left" item xs={4} sx={{ mt: 1 }}>{stateName}</Grid>
                                                <Grid item xs={6} sx={{ mt: 1 }}></Grid>
                                            </>
                                        ))) : (<></>)
                                    }

                                    {
                                        (!!datacomp) ? (
                                            <ButtonGroup variant="text">
                                                <Button onClick={() => setDisplay('dati')}> Dati letti </Button>
                                            </ButtonGroup>
                                        ) : (<></>)
                                    }
                                </>
                            ) : (<></>)}
                            {display === 'dati' ? (
                                <Grid container style={{ height: '95%' }}>
                                    <Grid item xs={1}>
                                        <IconButton>
                                            <ArrowBack onClick={() => setDisplay("stati")}></ArrowBack>
                                        </IconButton>
                                    </Grid>
                                    <Grid item xs={11} sx={{ mt: 2 }} style={{ overflow: 'auto', height: "100%" }}>
                                        <LapideDati schedName={name} datacomp={datacomp}></LapideDati>
                                    </Grid>
                                </Grid>
                            ) : (<></>)}
                        </Grid>}
                        <LapideBottom start={at} end={end}></LapideBottom>
                    </Stack>



                </CardContent>
            </Card>
        </Grid>
    )

}

export const stepSemaphore = function (stateValue) {
    switch (stateValue) {
        case 'pending':
            return <Tooltip title="In attesa"><span className="dot"></span></Tooltip>;
        case 'doing':
            return <Tooltip title="In esecuzione"><span className="dot amberdot"></span></Tooltip>;
        case 'succeeded':
            return <Tooltip title="Completato con successo"><span className="dot greendot"></span></Tooltip>;
        case 'failed':
            return <Tooltip title="Fallito"><span className="dot reddot"></span></Tooltip>;
        default:
            return <Tooltip title="Stato sconosciuto"><span className="dot"></span></Tooltip>;
    }

}