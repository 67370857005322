import { S3 } from 'aws-sdk';
import { AWSIamBasedServiceClient } from './awsIamBasedServiceClient';






export class S3Client extends AWSIamBasedServiceClient {

    constructor({ region, role }) {
        super({ region, role })
        this.client = null;
        this.ready = false;
    }

    async init() {
        this.refreshClient = async () => {
            await this.refreshCredentials();
            try {
                this.client = new S3({ credentials: this.credentials, region: this.region });
                this.ready = true;
            } catch (e) {
                throw new Error("Cannot init S3 Client due to " + e.toString());
            }
        }
        await this.refreshClient();
        return this;
    }


    async headFile({ bucket, key }) {
        await this.ensureCredentials();
        try {
            const result = await this.client.headObject({
                Bucket: bucket, Key: key
            }).promise();
            return result;
        } catch (err) {
            console.error('Error heading file:', err);
            return null;
        }
    }
    async getFile({ bucket, key, responseContentType = "application/json", returnUndecoded = false }) {
        await this.ensureCredentials();
        try {
            const result = await this.client.getObject({
                Bucket: bucket, Key: key,
                ResponseContentType: responseContentType
            }).promise();
            if (returnUndecoded) return result.Body;
            return result.Body.toString('utf-8');
        } catch (err) {
            console.error('Error getting file:', err);
            return null;
        }
    }
    async listFiles({ bucket, prefix }) {
        await this.ensureCredentials();
        const params = {
            Bucket: bucket,
            Prefix: prefix
        };

        let allObjects = [];

        try {
            let isTruncated = true;
            let continuationToken = undefined;

            while (isTruncated) {
                if (continuationToken) {
                    params.ContinuationToken = continuationToken;
                }

                const response = await this.client.listObjectsV2(params).promise();
                allObjects = allObjects.concat(response.Contents);

                isTruncated = response.IsTruncated;
                continuationToken = response.NextContinuationToken;
            }

            return allObjects;
        } catch (err) {
            console.error('Error listing objects:', err);
            return [];
        }
    }
    async uploadFile({bucket, key, file}){
        await this.ensureCredentials();
        if (!file) throw new Error('Invalid file');

        const params = {
            Bucket: bucket,
            Key: key,
            Body: file
        };
        try {
            const resp = await this.client.putObject(params).promise();
            console.log(resp);
            return true;
        } catch(e){
            console.error(e);
            return false;
        }
    }
    async downloadFile({bucket,key,responseContentType = "application/json",filename=null}){ // non usare in cabledquery
        await this.ensureCredentials();
        if (!filename){
            let s = key.split("/");
            filename = s[s.length-1];
        }
        const result = await this.client.getObject({
            Bucket:bucket,Key:key,
            ResponseContentType: responseContentType
        }).promise();
        const blob = new Blob([result.Body]);
        try {
            var element = document.createElement('a');
            let href = URL.createObjectURL(blob);
            element.setAttribute('href', href);
            element.setAttribute('download', filename);
            element.style.display = 'none';
            document.body.appendChild(element);
            element.click();
            setTimeout(() => {
                document.body.removeChild(element);
                URL.revokeObjectURL(href);
            });
        } catch (e) {
            console.error(e);
        }
    }
}