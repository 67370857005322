import ArrowBack from "@mui/icons-material/ArrowBack";
import { Box, Button, ButtonGroup, Card, CardContent, Grid, IconButton, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from "@mui/material";
import React from "react";
import { dayMonthDateStr, expected, hourMinuteDateStr } from "../../services/common/common";
import { LapideBottom, LapideTop, stepSemaphore } from "./lapide";
import './style.scss';
import dayjs from 'dayjs';




export default function DatiFineSerali({
    data
}) {
    console.log(data);
    let dd = null;
    let d = data.find(x => x.data_name === "fine_serali_check_data");
    console.log(d);
    if (d) {
        dd = JSON.parse(d.data_content);
    }
    console.log(dd);

    let bloccoRed = <></>;
    if (dd?.red?.length) {
        bloccoRed = (<>
            <Grid item xs={12}><Typography variant="h6">PDV in rosso:</Typography></Grid>
            {dd.red.map((pdv, pdvI) => <Grid item xs={6} key={pdvI}>{pdv}</Grid>)}
        </>);
    }
    let bloccoNotEnded = <></>;
    if (dd?.not_ended?.length) {
        bloccoNotEnded = (
            <>
                <Grid item xs={12}><Typography variant="h6">PDV non terminati:</Typography></Grid>
                {dd.not_ended.map((pdv, pdvI) => <Grid item xs={6} key={pdvI}>{pdv}</Grid>)}
            </>
        );
    }
    let bloccoPdvs = <></>;
    if (dd?.pdvs?.length) {
        bloccoPdvs = (
            <>
                <Grid item xs={12}><Typography variant="h6">Dati vecchi (da rimuovere):</Typography></Grid>
                {dd.pdvs.map((pdv, pdvI) => <Grid item xs={6} key={pdvI}>{pdv}</Grid>)}

            </>
        );
    }

    return (
        <Grid container>
            {(dd === null) ? ("La procedura non ha raccolto dati.") : (
                <>
                    {bloccoRed}
                    {bloccoNotEnded}
                    {bloccoPdvs}
                </>

            )}

        </Grid>
    )

}
