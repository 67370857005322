import { Box, Button, FormControl, FormGroup, Grid, InputLabel, MenuItem, Paper, Select, Stack, Step, StepLabel, Stepper, TextField, Typography, styled } from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import { Task } from "./tasks";
import { useSolutions } from "../../hooks/operatorTasks";
import { useMutation } from "@tanstack/react-query";
import ItpaApi from "../../services/api";
import { alertService } from "../../services/alerting/alerting";


function FeedbackText({
    environment,
    id,
    value,
    onChange,
    label,
    placeholder,
    error,
    required = false
}) {
    return (
        <TextField
            fullWidth={true}
            value={value}
            onChange={(e) => { e.preventDefault(); onChange(e.target.value); }}
            label={label}
            required={required}
            placeholder={placeholder}
        // error={!!feedbackErrors[fieldname]}
        // helperText={feedbackErrors[fieldname]}
        ></TextField>
    );
}

function FeedbackSelect({
    environment,
    id,
    value,
    onChange,
    label,
    placeholder,
    error,
    options = [],
    required = false
}) {
    return (
        <FormControl fullWidth
        //error={!!feedbackErrors[fieldname]}
        >
            <InputLabel>{label}</InputLabel>
            <Select
                defaultValue=""
                value={value}
                onChange={(e) => { e.preventDefault(); onChange(e.target.value) }}
                label={label}
            >
                {
                    options.map((o, j) => <MenuItem key={j} value={o}>{o}</MenuItem>)
                }

            </Select>
            {/* <FormHelperText>{feedbackErrors[fieldname]}</FormHelperText> */}
        </FormControl>
    );
}


function GenericField({ environment,
    id,
    value,
    onChange,
    label,
    placeholder,
    error,
    required = false }) {
    return <p>UNMAPPED {label}</p>
};


function TaskSolutionDetails({ feedback, formValues, setFormValues }) {




    const fieldMap = (fieldDesc, index) => {
        const fieldProps = { ...fieldDesc };
        fieldProps.value = formValues[fieldDesc.fieldname];
        fieldProps.onChange = (newValue) => setFormValues({ ...formValues, [fieldDesc.fieldname]: newValue });
        switch (fieldDesc.type) {
            case 'text':
                return <FeedbackText {...fieldProps} key={index} />
            case 'select':
                return <FeedbackSelect {...fieldProps} key={index} />
            default:
                return <GenericField {...fieldProps} key={index} />
        }
    }




    return (<Box sx={{ p: 2 }}>
        <form>
            <FormGroup >
                {(feedback.form || []).map((fieldDesc, index) => fieldMap(fieldDesc, index))}
            </FormGroup>
        </form>

    </Box>
    )
}


const ChoiceBox = styled(Paper, {
    shouldForwardProp: p => p !== "outcome"
})(({ theme, outcome }) => ({
    p: 1,
    wordWrap: true,
    display: 'flex',
    height: "100px",
    background: outcome ? "rgba(175,255,175,.5)" : "rgba(255,175,175,.5)",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: '5px',
    backdropFilter: 'blur(10px)',
    cursor: 'pointer',
    ':hover': {
        boxShadow: theme.shadows[5]
    }
}));



function SolutionChoices({ choices, outcomes, onSelect = () => { } }) {

    return <Stack direction="row" spacing={2} justifyContent="space-evenly" sx={{ p: 1 }}>
        {choices.map((choice, choiceI) => (
            <ChoiceBox
                key={choiceI}
                outcome={outcomes[choiceI]}
                onClick={() => onSelect(choiceI)}
            >
                <Typography sx={{ fontWeight: "bold" }}>{choice.toUpperCase()}</Typography>
            </ChoiceBox>

        ))}
    </Stack>
}

export default function TaskSolutions({ environment, id, onChange = ()=>{} }) {


    const steps = [
        "Soluzione",
        "Dettagli",
        "Conferma"
    ];

    const solutionsQuery = useSolutions({ environment, id });

    const [activeStep, setActiveStep] = useState(0);

    const [choosenSolution, setChoosenSolution] = useState(null);

    const [formValues, setFormValues] = useState({});
    const resetFormValues = () => setFormValues({});
    const initFormValues = (feedback) => {
        let fv = {};
        for (let f of feedback.form) {
            if (!f.fieldname) continue;
            fv[f.fieldname] = f.default || null;
        }
    };
    useEffect(resetFormValues, []);

    const handleNext = ({ solution = null } = {}) => {
        let cs = solution || choosenSolution;
        let newActiveStep = activeStep + 1;
        if (newActiveStep < 3) {
            if (newActiveStep === 1 && (!cs.form || !cs.form.length)) {
                newActiveStep = 2;
            }
            if (newActiveStep === 1) {
                initFormValues(cs);
            }
            setActiveStep(newActiveStep);

        }
    };

    const handleBack = () => {
        let newActiveStep = activeStep - 1;
        if (newActiveStep >= 0) {
            if (newActiveStep === 1 && (!choosenSolution.form || !choosenSolution.form.lenght)) {
                newActiveStep = 0;
            }
            setActiveStep(newActiveStep);
            if (newActiveStep === 0) {
                setChoosenSolution(null);
                resetFormValues();
            }
        }
    };

    const operatorMonitoring = ItpaApi.getService("operator-monitoring");
    const putTaskFeedbackMutation = useMutation({
        mutationFn: async ()=>{
            try {
                await operatorMonitoring.putTaskFeedback({environment,id,solutionId: choosenSolution.id,formFeedback:formValues});
                setTimeout(()=>putTaskFeedbackMutation.reset())
                onChange();
            } catch(e){
                alertService.error("Impossibile completare l'operazione: "+e.toString());
            }
        },
    });


    const NavigationButtons = ({
        onBack = false,
        onNext = false,
        onFinish = false
    }) => {
        return <>
            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                {!!onBack && <Button
                    color="inherit"
                    onClick={onBack}
                    sx={{ mr: 1 }}
                >
                    Back
                </Button>}
                <Box sx={{ flex: '1 1 auto' }} />


                {!!onNext && <Button
                    color="inherit"
                    sx={{ mr: 1 }}
                    onClick={onNext}>
                    Next
                </Button>}
                {!!onFinish && <Button
                    color="inherit"
                    sx={{ mr: 1 }}
                    onClick={onFinish}>
                    Finish
                </Button>}
            </Box>
        </>
    }

    return (
        <Box sx={{ width: '100%', p: 1 }}>
            {/* <Stepper activeStep={activeStep}>
                {steps.map((label, index) => {
                    const stepProps = {};
                    const labelProps = {};
                    if (index === 0 && activeStep > 0 && !!choosenSolution) {
                        labelProps.optional = choosenSolution.title;
                    }
                    return (
                        <Step key={index} {...stepProps}>
                            <StepLabel {...labelProps}>{label}</StepLabel>
                        </Step>
                    );
                })}
            </Stepper> */}


            {/* Scelta soluzione */}

            {activeStep === 0 && (
                <>
                    <SolutionChoices
                        choices={(solutionsQuery?.data || []).map(f => f.title)}
                        outcomes={(solutionsQuery?.data || []).map(f => f.outcome)}
                        onSelect={(choiceI) => { setChoosenSolution(solutionsQuery?.data[choiceI]); handleNext({ solution: solutionsQuery?.data[choiceI] }); }}
                    />
                    <NavigationButtons />
                </>
            )}

            {/* Dettagli soluzione */}

            {activeStep === 1 && (
                <>
                    <Typography variant="h6">Inserisci i dettagli richiesti</Typography>
                    <TaskSolutionDetails feedback={choosenSolution} formValues={formValues} setFormValues={setFormValues} />
                    <NavigationButtons onBack={handleBack} onNext={handleNext} />
                </>
            )}

            {/* Conferma */}

            {activeStep === 2 && (
                <>
                    <Typography variant="h6">Ecco quello che hai inserito.</Typography>
                    <Typography variant="comment" sx={{ fontSize: 20, fontWeight: "bold" }}>Soluzione: {choosenSolution.title}</Typography>
                    <Box><pre>{JSON.stringify(formValues, null, 2)}</pre></Box>
                    <Typography>Premi finish per confermare.</Typography>
                    <NavigationButtons onBack={handleBack} onFinish={() => putTaskFeedbackMutation.mutate()} />
                </>
            )}


        </Box>
    );
}