import { LinearProgress } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { createRef, useEffect, useState } from "react";

function hasObjectPrototype(o) {
    return Object.prototype.toString.call(o) === '[object Object]'
}
function isPlainObject(o) {
    if (!hasObjectPrototype(o)) {
        return false
    }

    // If has modified constructor
    const ctor = o.constructor
    if (typeof ctor === 'undefined') {
        return true
    }

    // If has modified prototype
    const prot = ctor.prototype
    if (!hasObjectPrototype(prot)) {
        return false
    }

    // If constructor does not have an Object-specific method
    if (!prot.hasOwnProperty('isPrototypeOf')) {
        return false
    }

    // Most likely a plain Object
    return true
}
function hashQueryKey(queryKey) {
    return JSON.stringify(queryKey, (_, val) =>
        isPlainObject(val)
            ? Object.keys(val)
                .sort()
                .reduce((result, key) => {
                    result[key] = val[key]
                    return result
                }, {})
            : val,
    )
}
export const QueryLinearProgress = ({
    queryKey,
    height = null,

}) => {
    const refetchInterval = createRef();
    const puInterval = createRef();
    const [progress, setProgress] = useState(100);
    const [isPristine, setIsPristine] = useState(true);
    const [isError, setIsError] = useState(false);
    let qc, queries, queryCache;
    let queryClient = useQueryClient();
    let queryHash = null;
    if (queryKey !== null) {
        queryHash = hashQueryKey(queryKey);
        puInterval.current = -1;
        qc = queryClient.getQueryCache();
        queries = qc.queries;
        queryCache = queries.find(x => x.queryHash === queryHash);
        refetchInterval.current = queryCache?.options?.refetchInterval;
    }
    function updateStatus() {
        let queryState = queryClient.getQueryState(queryKey);
        if (queryState.isInitialLoading) {
            setIsPristine(true);
        } else {
            setIsPristine(false);
        }
        if (queryState.error) {
            setIsError(true);
        } else {
            setIsError(false);
        }
        var updatedAt = queryState.error !== null
            ? queryState.errorUpdatedAt
            : queryState.dataUpdatedAt;
        let progr = 100 - parseInt(100 * Math.min((new Date() - new Date(updatedAt)) / refetchInterval.current, 1))
        setProgress(progr);
    }
    useEffect(() => {
        if (!!queryCache && queryKey && puInterval.current === -1 && !!refetchInterval.current) {
            let intLength = Math.max(parseInt((refetchInterval.current / 60000) * 250), 5000)
            let pui = setInterval(() => updateStatus(), intLength);
            puInterval.current = pui;
        }
        return () => {
            if (puInterval.current) {
                clearInterval(puInterval.current);
                puInterval.current = -1;
            }
        }
    }, [queryKey, refetchInterval.current]);
    if (queryKey !== null && !queryCache) {
        console.log("Missing queryCache for key", queryKey, "hash", queryHash);
        return <p>Errore: guarda in console...</p>
    }


    if (isPristine)
        return <
            LinearProgress
            color="primary" sx={{ width: "100%", height: (height || 2) + "px" }} />
    if (isError) {
        return <
            LinearProgress
            color="error" sx={{ width: "100%", height: (height || 2) + "px" }} />

    }

    return (
        (queryKey && refetchInterval) ? (
            <LinearProgress value={progress} color="primary" variant="determinate" sx={{ width: "100%", height: (height || 2) + "px" }} />) : <></>
    )
}