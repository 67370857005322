import React, { isValidElement } from 'react';
import { Button, TextAreaField } from "@aws-amplify/ui-react";
import { Box, Divider, FormControl, FormGroup, FormHelperText, InputLabel, MenuItem, Select, Stack, TextareaAutosize, TextField, Typography } from "@mui/material";
import cronstrue from 'cronstrue/i18n';
import TextInputField from '../forms/text';
import { useSchedulesRule } from '../../hooks/schedules';
import InDeck from '../queries/indeck';
import { QueryClient, useMutation, useQueryClient } from '@tanstack/react-query';
import ItpaApi from '../../services/api';
import { alertService } from '../../services/alerting/alerting';
import FormatLineSpacingOutlinedIcon from '@mui/icons-material/FormatLineSpacingOutlined';
const cronparser = require('cron-parser');


function CronToString(cron_expression) {
    try {
        return cronstrue.toString(cron_expression, { locale: "it", verbose: true });
    } catch (e) {
        return "Invalid: " + e.toString();
    }
}

const RuleDetails = ({
    name,
    group,
    targets = [],
    onChange = () => { },
    onDismiss = () => { }
}) => {

    const ruleQuery = useSchedulesRule({ group, name, enabled: true });

    function target2Name(target) {
        if (!target) return "NONE";
        let found = targets?.find(x => ((x.target_arn === target.target_arn || x.role_arn === target.role_arn)));
        if (found) return found.name; else return "custom";
    }
    const [modes, setModes] = React.useState({
        cron: false,
        target_input: false
    });
    const setCronEditing = () => { setData({ ...data, cron: ruleQuery?.data?.cron }); setModes({ ...modes, cron: true }); }
    const cancelCronEditing = () => { setData({ ...data, cron: null }); setModes({ ...modes, cron: false }); }
    const applyCronEditing = () => { editCronMutation.mutate(); setData({ ...data, cron: null }); setModes({ ...modes, cron: false }); }

    const setInputEditing = () => { setData({ ...data, target_input: ruleQuery?.data?.target_input }); setModes({ ...modes, target_input: true }); }
    const cancelInputEditing = () => { setData({ ...data, target_input: null }); setModes({ ...modes, target_input: false }); }
    const applyInputEditing = () => { editInputMutation.mutate(); setData({ ...data, target_input: null }); setModes({ ...modes, target_input: false }); }

    const [data, setData] = React.useState({
        cron: null, target_input: null
    });
    const [errors, setErrors] = React.useState({
        cron: null, target_input: null
    });



    // MUTATIONS
    const moniServ = ItpaApi.getService("monitoring");
    const queryClient = useQueryClient();

    const editCronMutation = useMutation({
        mutationFn: async () => {
            if (data.cron === ruleQuery?.data?.cron) return;
            if (!validateCron(data.cron)) return;
            try {
                let x = moniServ.updateScheduleRuleCron({ name, group, cron: data.cron });
                queryClient.invalidateQueries(ruleQuery.key);
                onChange();
                return x;
            } catch (e) {
                alertService.error("Cannot edit cron");
                return false;
            }
            setTimeout(() => editCronMutation.reset());
        }
    });

    const editInputMutation = useMutation({
        mutationFn: async () => {
            if (data.target_input === ruleQuery?.data?.target_input) return;
            if (!validateInput(data.target_input)) return;
            let d = data;
            try {d = {...d,target_input:JSON.stringify(JSON.parse(d.target_input))}} catch {}
            try {
                let x = moniServ.updateScheduleRuleInput({ name, group, target_input: d.target_input });
                queryClient.invalidateQueries(ruleQuery.key);
                onChange();
                return x;
            } catch (e) {
                alertService.error("Cannot edit target_input");
                return false;
            }
            setTimeout(() => editInputMutation.reset());
        }
    });

    const deleteRuleMutation = useMutation({
        mutationFn: async () => {
            try {
                let x = await moniServ.deleteScheduleRule({
                    group, name
                });
                alertService.success("Regola cancellata");
                onChange();
                onDismiss();
                return x;
            } catch (e) {
                alertService.error("Errore nella cancellazione della regola");
            }
            setTimeout(() => deleteRuleMutation.reset());
        }
    });


    const validateCron = cron => { try { cronparser.parseExpression(cron); return true; } catch { return false; } }
    const validateInput = input => { try { return typeof (JSON.parse(input)) === "object" } catch { return false; } }
    
    const beautifyInput = ()=>{
        let D = data;
        let val = data.target_input;
        try { val = JSON.stringify(JSON.parse(val),null,2)} catch{}
        setData({...D,target_input:val});
    };
    
    const onFieldChange = (field, newValue) => {
        var D = data;
        var E = errors;

        if (field === 'cron') {
            D = { ...D, cron: newValue };
            E = { ...E, cron: validateCron(newValue) ? null : "Non valido" };
        }
        
        if (field === 'input') {
            
            D = { ...D, target_input: newValue };
            E = { ...E, target_input: validateInput(newValue) ? null : "Non valido" };
        }
        setData(D);
        setErrors(E);
    };


    const isValid = () => (errors.name === null && errors.cron === null && errors.target === null && errors.input === null);

    return (
        <Box width="45vw">
            <Stack width="98%" height="95vh" justifyContent="flex-start" spacing={2} sx={{ mt: 4 }}>

                <Typography variant="h4">Dettagli regola</Typography>
                <Typography variant="h5">{group} / {name}</Typography>
                <Divider />
                <Box sx={{ flexGrow: 1 }}></Box>
                <FormGroup sx={{ width: '95%' }}>
                    <Box sx={{ mt: 2 }}><strong>Nome:&nbsp;</strong>{ruleQuery?.data?.name}</Box>

                    {modes?.cron ? (

                        <FormControl sx={{ mt: 2 }}>
                            <TextField
                                error={!!errors.cron}
                                label="Cron"
                                id="cron_field"
                                fullWidth={true}
                                placeholder="* * * * *"
                                value={data.cron || ""}
                                helpertText={errors.cron}
                                onChange={(e) => onFieldChange("cron", e.target.value)}
                            ></TextField>
                            <InDeck confirm={applyCronEditing} close={cancelCronEditing}></InDeck>
                        </FormControl>
                    ) : (
                        <Box sx={{ mt: 2 }}>
                            <strong>Cron:&nbsp;</strong>{ruleQuery?.data?.cron}
                            <InDeck edit={setCronEditing}></InDeck>
                        </Box>
                    )}
                    <Box sx={{ mt: 2 }}>
                        {
                            data.cron ? ((errors.cron === null) ? (
                                CronToString(data.cron)
                            ) : (
                                "Cron non valido"
                            )) : (<></>)
                        }
                    </Box>
                    <Box sx={{ mt: 2 }}><strong>Target:&nbsp;</strong>{target2Name(ruleQuery?.data?.target)}</Box>
                    {modes?.target_input ? (
                        <FormControl sx={{ mt: 2 }} error={errors.target_input}>
                            <TextareaAutosize
                                label="Input"
                                labelId="input_label"
                                minRows={8}
                                maxRows={16}
                                value={data.target_input || ""}
                                onChange={(e) => onFieldChange("input", e.target.value)}
                            ></TextareaAutosize>
                            {!!errors.input ? (<FormHelperText>{errors.target_input}</FormHelperText>) : (<></>)}
                            <InDeck confirm={applyInputEditing} close={cancelInputEditing} format={beautifyInput}></InDeck>
                        </FormControl>
                    ) : (
                        <Box sx={{ mt: 2 }}>
                            <strong>Input:&nbsp;</strong><br />{ruleQuery?.data?.target_input}
                            <InDeck edit={setInputEditing}></InDeck>
                        </Box>
                    )}



                </FormGroup>
                <Box sx={{ flexGrow: 1 }}></Box>
                <FormGroup sx={{ width: '95%' }}>
                    <Button onClick={() => deleteRuleMutation.mutate()} variant="outlined" color="error" sx={{ mt: 2 }}>Cancella regola</Button>
                </FormGroup>
                <Box sx={{ flexGrow: 1 }}></Box>

            </Stack>



        </Box>
    )
};


export default RuleDetails