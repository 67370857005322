import { Box, Drawer } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useQueryClient } from '@tanstack/react-query';
import { useContext, useEffect, useState } from 'react';
import Deck from '../../../components/queries/deck';
import { AppContext } from '../../../hooks/appContext';
import { useCabledQuery } from '../../../hooks/serviceRegistry/useCabledQuery';
import AdminCreateClientEditor from '../../../components/admin/clients/createClient';
import { alertService } from '../../../services/alerting/alerting';
class Client {

    constructor({ clientName, clientCode, services }) {
        this.clientName = clientName;
        this.clientCode = clientCode;
        this.services = services;
        this.id = clientName;
    }
}


const CONFIG = {
    queryRefetchInterval: 1 * 60 * 1000
};


export function AdminClientsDashboard() {
    const [clients, setClients] = useState([]);
    const [sideContent, setSideContent] = useState(null);
    // const startEditServices = (id) => {
    //     setOperatingClient(id);
    //     setSideContent("editConfig");
    // };
    // const startEditAssignment = (id) => {
    //     setOperatingClient(id);
    //     setSideContent("editAssignment");
    // }

    const columns = [
        { field: "clientName", headerName: "Client Name", flex: 3 },
        { field: "clientCode", headerName: "Client Code", flex: 1 },
        { field: "services", headerName: "Services", flex: 2, renderCell: p=>Object.keys(p.value || {}).length },
    ];

    const clientsQuery = useCabledQuery({
        svcName: 'clients',
        method: 'doGet',
        args: {
            path: '/clients'
        },
        enabled: true,
        refetchInterval: CONFIG.queryRefetchInterval,
        baseQueryKey: ['clients']
    });
    useEffect(() => {
        let pp = (clientsQuery?.query?.data || []).map(d => new Client(d))
        setClients(pp);
    }, [clientsQuery?.query?.data])

    function clearSideContent() { setSideContent(null); queryClient.invalidateQueries(['clients']) };
    const queryClient = useQueryClient();

    return <>
        <Deck
            //  hasOmniBox={true}
            //  searchHandler={{ search, setSearch, categories }}
            // queryHook={useFlows}
            // query={flowsQuery}
            queryKey={clientsQuery.queryKey}
            onRefresh={() => { console.log("REFRESHED!") }}
            
            hasNewItem={true}
            onNewItem={() => setSideContent("createClient")}
        />
        <Box sx={{ height: "82vh" }}>
            <DataGrid
                rows={clients}
                columns={columns}
            />
        </Box>
        <Drawer
            anchor="right"
            open={sideContent !== null}
            onClose={clearSideContent}>

           
            {sideContent === "createClient" && (
                <AdminCreateClientEditor
                    onCancel={() => setSideContent(null)}
                    onSuccess={(result) => {
                        setSideContent(null);
                        alertService.success("Client was created");
                        setTimeout(() => {queryClient.invalidateQueries(clientsQuery.queryKey)});
                    }}
                    onError={(error) => {
                        alertService.error(error?.detail || error?.message || "Client creation failed: "+error.toString())
                    }}
                />
            )}
        </Drawer>
    </>
}