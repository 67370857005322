const theme = {
    containerBoxStyle: {
        borderRadius: '15px',
        justifyContent: 'space-between',
        margin: 5,
        padding: 5,
        transition: 'box-shadow 0.5s',
        backdropFilter: 'blur(15px)',
        WebkitBackdropFilter: 'blur(15px)'
    },
    palette: {
        
        onLightBackground: {
            main: "#000000"
        },
        // primary: {
        //     contrastText: "#b9bec5",
        //     main: "#b9bec5",
        //     dark: "#053e85"
        // },
        danger: {
            dark: "#e53e3e",
            light: "#ef9a9a",
            main: "#f44336"
        },
        neutral: {
            main: '#64748B',
            contrastText: '#fff',
            50: "#F0F0F0"
        },

        background: {
            default: 'rgba(0,65,73,0.05)',
            // main: "#004149",
            main: "#3c4b64",
            glow: 'rgba(0,65,73,0.3)'
        },
        severities: {
            1: 'rgba(37, 234, 217, 0.75)',
            2: 'rgba(253, 253, 0, 0.75)',
            3: 'rgba(255, 212, 56, 0.75)',
            4: 'rgba(255, 57, 50, 0.75)',
            5: 'rgb(52, 12, 10, 0.75)',
        },
        severitiesLight: {
            1: 'rgba(37, 234, 217, 0.25)',
            2: 'rgba(253, 253, 0, 0.25)',
            3: 'rgba(255, 212, 56, 0.25)',
            4: 'rgba(255, 57, 50, 0.25)',
            5: 'rgba(52, 12, 10, 0.25)',
        },
        severitiesLighter: {
            1: 'rgba(37, 234, 217, 0.05)',
            2: 'rgba(253, 253, 0, 0.05)',
            3: 'rgba(255, 212, 56, 0.05)',
            4: 'rgba(255, 57, 50, 0.05)',
            5: 'rgba(52, 12, 10, 0.05)',
        },

        rng: [
            'rgba(255,0,0,.9)',
            'rgba(77,77,77,.9)',
            'rgba(0,255,76,.9)'
        ],
        rngLight: [
            'rgba(255,0,0,.3)',
            'rgba(77,77,77,.2)',
            'rgba(0,255,76,.2)'
        ]
    }
};

export default theme;