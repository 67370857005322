import { Box, Grid, Skeleton, Stack } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import dayjs from 'dayjs';
import React, { useState } from "react";
import { useRmsPh } from "../../hooks/carrefour/carrefour";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import Sizer from "../../utils/sizer";
import SwitchGroup from "../common/filters/switchGroup";
import Loader from "../page/loader";
import LapideRm from "./lapideRM";
import { useInfiniteCabledQuery } from "../../hooks/serviceRegistry/useCabledQuery";


export default function ReportRm({ displayFuture }) {

    const queryClient = useQueryClient();
    const date = dayjs().startOf('day').toDate()
    const rmsPhQuery = useInfiniteCabledQuery({
        svcName: 'carrefourstate',
        baseQueryKey: ['carrefourstate', 'rmsph'],
        method: 'doListSchedsRmPh',
        args: {
            date
        }

    })
    const [displayedScheds, setDisplayedScheds] = useState([]);
    const [tipologieVisibili, setTipologieVisibili] = useLocalStorage('carrefour-rm-tipologie-visibili', {
        Scatolame: true, Deperibili: true, 'No Food': true, Freschi: true, "Mostra schedulazioni future": false
    });

    React.useEffect(() => {
        // console.log("UE")
        try {
            let minDate = new Date('2970-01-01T00:00:00+01:00');
            let maxDate = new Date('1970-01-01T00:00:00+01:00');
            if (!rmsPhQuery?.query?.isFetched) return;
            let ds = displayedScheds;
            // console.log(rmsPhQuery?.query?.data?.pages?.length)
            for (let page of rmsPhQuery?.query?.data?.pages || [])
                for (let s of page.data) {
                    s.at = new Date(s.at);
                    let foundIndex = displayedScheds.findIndex(x => x.name === s.name);
                    if (foundIndex > -1) {
                        ds[foundIndex] = s;
                    } else {
                        ds.push(s);
                    }
                }

            ds.sort(function (a, b) {
                if (a.at < b.at)
                    return 1;
                else if (a.at === b.at) {
                    if (a.name < b.name) return 1; else return -1;
                }
                else return -1;
            })

            setTimeout(setDisplayedScheds(ds));
            if (new Date(minDate) > dayjs().startOf('week')) {
                if (rmsPhQuery.hasNextPage) {
                    setTimeout(() => rmsPhQuery?.query?.fetchNextPage());
                }
            }
        } catch (e) { console.error(e); }
    }, [rmsPhQuery?.query, rmsPhQuery?.query?.data]);




    let filteredDisplayedScheds = displayedScheds.filter(s => (tipologieVisibili["Mostra schedulazioni future"] || s.at <= new Date())).map(x => ({ ...x, isFuture: x.at > new Date() }));

    return (
        <>
            <Grid container spacing={4}>
                {rmsPhQuery?.query?.isLoading && <Loader />}
                <Grid item xs={12}>
                    <Stack direction="row" justifyContent="space-evenly">
                        <SwitchGroup
                            row={true}
                            switches={Object.entries(tipologieVisibili).map(([x, y]) => ({ name: x, value: y }))}
                            onSwitchChange={(name, value) => setTipologieVisibili({ ...tipologieVisibili, [name]: value })}
                        >
                        </SwitchGroup>
                    </Stack>
                </Grid>
                {!rmsPhQuery?.query?.isFetched ? <>
                    <Grid item xs={12}>
                        <Skeleton width="100%" height="100%" variant="rectangular" animation="wave"></Skeleton>
                    </Grid>
                    <Grid item xs={12}>
                        <Skeleton width="100%" height="100%" variant="rectangular" animation="wave"></Skeleton>
                    </Grid>
                    <Grid item xs={12}>
                        <Skeleton width="100%" height="100%" variant="rectangular" animation="wave"></Skeleton>
                    </Grid>
                </> : <></>}
                {filteredDisplayedScheds && filteredDisplayedScheds.length ? (
                    filteredDisplayedScheds.map((s, sI) => (
                        <React.Fragment key={sI}>
                            <LapideRm {...s} sizer={new Sizer({ xs: 12, md: 6, xl: 4 })} tipologieVisibili={tipologieVisibili}></LapideRm>
                        </React.Fragment>
                    ))
                ) : (<><p>In questo momento non sono disponibili schedulazioni di riordino mercati.</p></>)}
            </Grid>
        </>
    )

}