import { Box, Button, Grid, Paper, Stack, Tooltip, Typography, styled } from "@mui/material";
import { elapsed, expected, fullDateStr } from "../../services/common/common";
import { useContext, useState } from "react";
import { useOperatorTask } from "../../hooks/operatorTasks";
import TaskHistoryTimeline from "./taskHistory";
import PersonIcon from '@mui/icons-material/Person';
import NoteIcon from '@mui/icons-material/Note';
import UserMini from "../users/userMini";
import { UserContext } from "../../hooks/userContext";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import ItpaApi from "../../services/api";
import Markdown from "markdown-to-jsx";
import TaskDetails from "./taskDetails";
const TodoItem = styled(Paper, {
    shouldForwardProp: p => p != "severity"
})(({ theme, severity }) => {
    return {
        backgroundColor: theme.palette.severities[parseInt(severity)] || '#fff',
        ...theme.typography.body2,
        paddingLeft: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        backdropFilter: 'blur(10px)',
        ':hover': {
            boxShadow: theme.shadows[5]
        },
        minHeight: "30px",
        lineHeight: "30px",
    }
});












export function Task({
    id, display_name, name, severity, iat, eat, customer, service, description, environment, assignee, note_count, onChange = () => { }
}) {

    const [open, setOpen] = useState(false);

    const queryClient = useQueryClient();
    const handleTaskChange = () => {
        setOpen(false);
        setTimeout(() => {
            onChange(); 
        });
    }


    return (
        <>
            <TodoItem severity={severity} onClick={() => setOpen(!open)}>
                <Grid container spacing={1}>

                    <Grid item md={1} sx={{ display: { xs: 'none', md: 'flex' } }}>
                        {customer}
                    </Grid>
                    <Grid item md={1} sx={{ display: { xs: 'none', md: 'flex' } }}>
                        {service}
                    </Grid>
                    <Grid item xs={2} sx={{ display: { xs: 'flex', md: 'none' } }}>
                        {customer} / {service}
                    </Grid>
                    <Grid item xs={4} md={1}>
                        {display_name}
                    </Grid>
                    <Grid item xs={6} md={4}>
                        {description}
                    </Grid>
                    <Grid item sx={{ display: { xs: 'none', md: 'flex' } }} md={2}>
                        {iat ? elapsed(iat) : ""}
                    </Grid>
                    <Grid item sx={{ display: { xs: 'none', md: 'flex' } }} md={2}>
                        {eat ? expected(eat,true) : ""}
                    </Grid>
                    <Grid item sx={{ display: { xs: 'none', md: 'flex' } }} md={1}>
                        {!!assignee && <UserMini user={assignee} />}
                        {!!note_count && <Tooltip title={note_count + " note"}><NoteIcon /></Tooltip>}
                    </Grid>
                </Grid>
            </TodoItem>
            {open &&
                <TaskDetails {...{ id, environment, severity, assignee }} onChange={handleTaskChange} />

            }
        </>
    );
}
