import { elapsed, expected, splitCamelCase } from "../../services/common/common";

export function parseCheck(check){
    return {
        display_name: splitCamelCase(check.name).toUpperCase(),
        status: check.status,
        meta: check.meta,
        link: check.link,
        last_str: !!check.last ? elapsed(check.last) : "---",
        is_expired: (!!check.next && new Date(check.next) < new Date()),
        expected_str: (!!check.next ? 'next: ' + expected(check.next, true) : " ")
    };
}

export function sortParsedChecks(c1,c2){
    if (c1.is_expired) return -1;
    if (c2.is_expired) return 1;
    if (c1.status === null) return -1;
    if (c2.status === null) return 1;
    if (c1.status === false) return -1;
    if (c2.status === false) return 1;
    if (c1.display_name < c2.display_name) return -1;
    return 1;
}