import { useState } from 'react';
import { Button, Divider, Stack, Typography, Box } from "@mui/material";
import { alertService } from "../../../services/alerting/alerting";
import { ServiceContext } from "../../../hooks/serviceRegistry/serviceContext";
import { useContext } from "react";
import { useLocalStorage } from '../../../hooks/useLocalStorage';



const CRF_SAFETY_RESTART_LAMBDA_ARN = 'arn:aws:lambda:eu-west-1:745905534821:function:CarrefourSafetyRestart';

export default function PulsantoneCarrefour() {

    const { getSvc } = useContext(ServiceContext)
    const lambda = getSvc("client_pulsantone_crf");

    const [step, setStep] = useState(0);
    const [lastConfirmation, setLastConfirmation] = useLocalStorage('carrefourSafetyRestartLastConfirmation',0);

    const canRestart = (new Date().valueOf() - lastConfirmation) >= 30 * 60 * 1000;


    const onRpaRestartConfirmed = async () => {
        try {
            setLastConfirmation((new Date()).valueOf());
            let lambdaResult = await lambda.invoke({ arnOrName: CRF_SAFETY_RESTART_LAMBDA_ARN, payload: {} });
            if (lambdaResult.count_operations === lambdaResult.total_operations) {
                alertService.info("Restart RPA riuscito")
            } else {
                alertService.warn("Restart RPA riuscito parzialmente. ");

            }
        } catch (e) {
            console.error(e);
            alertService.error("Restart RPA fallito")
        }
        setLastConfirmation((new Date()).valueOf());
        setStep(0);
    }

    const gotoStep1 = () => setStep(1);
    const gotoStep0 = () => setStep(0);

    return <>
        <Stack spacing={2} justifyContent="space-between" sx={{ width: "100%", height: '90vh' }}>
            <Typography variant="h6" gutterBottom>
                Riavvio agenti
            </Typography>
            <Divider />
            <Box sx={{ flexGrow: .1 }} />
            {!canRestart ? (
                <Typography variant="body1" gutterBottom>Non è possibile azionare il meccanismo di sicurezza troppo frequentemente.</Typography>
            ) : (
                <>
                    <Typography variant="body1" gutterBottom>
                        Premendo il pulsante sotto si può riavviare l'automazione. Attenzione: l'operazione non è reversibile. Se il problema persiste rivolgersi ad un amministratore.
                    </Typography>
                    {step === 0 && (
                        <Button color="warning" size="large" onClick={canRestart && gotoStep1} variant="contained" disableElevation>
                            Riavvia automazione Carrefour
                        </Button>
                    )}
                    {step === 1 && (
                        <Stack spacing={2} direction="row" sx={{ width: "100%" }}>
                            <Button sx={{ width: "50%" }} color="primary" size="large" onClick={gotoStep0} variant="contained" disableElevation>
                                No, ci ho ripensato.
                            </Button>
                            <Button sx={{ width: "50%" }} color="error" size="large" onClick={onRpaRestartConfirmed} variant="contained" disableElevation>
                                Si, sono sicuro di voler riavviare l'automazione Carrefour
                            </Button>

                        </Stack>
                    )}
                </>
            )}

            <Box sx={{ flexGrow: 1 }} />
        </Stack>

    </>
}