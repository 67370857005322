import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useState, useEffect } from 'react';
import ItpaApi from '../services/api';

export function useClients() {  
    const clientService = ItpaApi.getService("clients");
    const queryClient = useQueryClient();
    const clientsQuery = useQuery({
        queryFn: clientService.listClients.bind(clientService),
        queryKey: ['all_clients'],
        cacheTime: 1000*60*60
    });
    clientsQuery.key = ['all_clients'];
    return clientsQuery;
}
export function useClient({clientId}) {  
    const clientService = ItpaApi.getService("clients");
    const queryClient = useQueryClient();
    const clientQuery = useQuery({
        queryFn: async ()=>await clientService.getClient.bind(clientService)({clientId}),
        queryKey: ['client',clientId],
        cacheTime: 1000*60*60
    });
    clientQuery.key = ['client',clientId];
    return clientQuery;
}

export function useServices({clientId,enabled}) {  
    const clientService = ItpaApi.getService("clients");
    const queryClient = useQueryClient();
    const fetchServices = async ()=>{
        if (!clientId){
            return [];
        }
        return await clientService.listServices.bind(clientService)({clientId});
    };
    const servicesQuery = useQuery({
        queryFn: fetchServices,
        queryKey: ['all_services',clientId],
        cacheTime: 1000*60*60,
        enabled
    });
    servicesQuery.key = ['all_services',clientId];
    return servicesQuery;
}
export function useService({clientId, serviceId, enabled}) {  
    const clientService = ItpaApi.getService("clients");
    const queryClient = useQueryClient();
    const serviceQuery = useQuery({
        queryFn: async ()=>await clientService.getService.bind(clientService)({clientId,serviceId}),
        queryKey: ['service',clientId,serviceId],
        cacheTime: 1000*60*60
    });
    serviceQuery.key = ['service',clientId,serviceId];
    return serviceQuery;
}
export function useProcesses({clientId,serviceId,enabled}) {  
    const clientService = ItpaApi.getService("clients");
    const queryClient = useQueryClient();
    const processesQuery = useQuery({
        queryFn: async ()=>await clientService.listProcesses.bind(clientService)({serviceId}),
        queryKey: ['all_processes',clientId, serviceId],
        cacheTime: 1000*60*60,
        enabled
    });
    processesQuery.key = ['all_processes',clientId, serviceId];
    return processesQuery;
}


export function useClientServices(){
    return {
        data: [
            {customer: 'Atlas', service: null},
            {customer: 'Atlas', service: "Development"},
            {customer: 'Stellantis', service: null},
            {customer: 'Stellantis', service: "Monitoraggio Splunk"},
            {customer: 'Carrefour', service: null},
            {customer: 'Carrefour', service: "Controlli"},
            {customer: 'Carrefour', service: "PassaggioDiConsegne"},
            {customer: 'Carrefour', service: "RM"},
            {customer: 'Bper', service: null},
            {customer: 'Bper', service: "Inoltro automatico"},
            {customer: 'Unicredit', service: null},
            {customer: 'Unicredit', service: "Monitoraggio siti"},
            {customer: 'Gucci', service: null},
            {customer: 'Gucci', service: "Controllo teams"}
        ]
    };   
}