import { Box, Divider, Stack, Table, TableBody, TableCell, TableRow, TextField, Typography } from "@mui/material";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { useQueryClient } from "@tanstack/react-query";
import cronstrue from 'cronstrue/i18n';
import dayjs from 'dayjs';
import 'dayjs/locale/it';
import React from 'react';
import { useOccurrences, useOccurrencesDemo, useSchedulesRule } from '../../hooks/schedules';
import { fullDateStr } from '../../services/common/common';
import TopDeck from "../queries/topdeck";
const cronparser = require('cron-parser');


function CronToString(cron_expression) {
    try {
        return cronstrue.toString(cron_expression, { locale: "it", verbose: true });
    } catch (e) {
        return "Invalid: " + e.toString();
    }
}

const RuleStatus = ({
    name,
    group,
    onChange = () => { },
    onDismiss = () => { }
}) => {

    const [timespan, setTimespan] = React.useState({ date_from: dayjs(), date_to: dayjs().add(2, 'day') })
    const queryClient = useQueryClient();
    const ruleQuery = useSchedulesRule({ group, name, enabled: true });

    const occsDemoQuery = useOccurrencesDemo({ group, name, date_from: timespan.date_from, date_to: timespan.date_to, enabled: !ruleQuery.isFetching });
    const occsQuery = useOccurrences({ group, name, date_from: timespan.date_from, date_to: timespan.date_to, enabled: !ruleQuery.isFetching });
    const [occs, setOccs] = React.useState([]);
    
    React.useEffect(() => {
        if (!occsDemoQuery || !occsDemoQuery.data) return;
        let _occs = [];
        for (let occ of occsDemoQuery.data[0]){
            let found = occsQuery?.data?.find(x => x.at === occ.at);
            let synced = found?.synced;
            _occs.push([occ, true,found,synced]);
        }
        for (let occ of occsDemoQuery.data[1]){
            let found = occsQuery?.data?.find(x => x.at === occ.at);
            let synced = found?.synced;
            _occs.push([occ, true,found,synced]);
        }
        _occs = _occs.sort((a, b) => (a[0].at <= b[0].at) ? -1 : 1);
        setOccs(_occs);
    }, [occsDemoQuery.isFetching,occsQuery.isFetching]);

    return ruleQuery.isFetching && occsDemoQuery.isFetching ? (<Box>Loading...</Box>) : (
        <Box width="45vw">
            <Stack width="98%" height="95vh" justifyContent="flex-start" spacing={2} sx={{ mt: 4 }}>

                <Typography variant="h4">Occorrenze regola</Typography>
                <Typography variant="h5">{group} / {name}</Typography>
                <Divider />
                <Box sx={{ mt: 2 }}><strong>Regola:&nbsp;</strong>{CronToString(ruleQuery?.data?.cron)}</Box>
                <Box sx={{ flexGrow: 1 }}></Box>
                <Box sx={{ mt: 2}}><TopDeck refresh={()=>{
                    queryClient.invalidateQueries(ruleQuery.key);
                    queryClient.invalidateQueries(occsDemoQuery.key);
                    queryClient.invalidateQueries(occsQuery.key);
                }}></TopDeck></Box>
                <Box>
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="it">
                        <DateTimePicker
                            ampm={false}
                            format="DD-MM-YYYY hh:mm:ss"
                            label="Da"
                            value={timespan.date_from}
                            onChange={(newValue) => setTimespan({ ...timespan, date_from: newValue })}
                            renderInput={(params) => <TextField size="small" {...params} />}
                        />
                        <DateTimePicker
                            ampm={false}
                            format="DD-MM-YYYY hh:mm:ss"
                            label="a"
                            value={timespan.date_to}
                            onChange={(newValue) => setTimespan({ ...timespan, date_to: newValue })}
                            renderInput={(params) => <TextField size="small" {...params} />}
                        />
                    </LocalizationProvider>
                </Box>
                <Box sx={{ flexGrow: 1 }}></Box>
                <Box sx={{ mt: 2, pl: 5, width: "100%", textAlign: "left" }}>
                    <Table>
                        <TableBody>
                            {occs?.map((occ, occI) => <TableRow key={occI}>
                                <TableCell>{fullDateStr(occ[0].at)}</TableCell>
                                <TableCell>{occ[1] ? "" : "EXC"}</TableCell>
                                <TableCell>
                                    {occ[2] ? "CREATA" : "NON CREATA"}
                                </TableCell>
                                <TableCell>
                                    {occ[3] ? "SCHEDULATA" : "NON SCHEDULATA"}
                                </TableCell>
                                {/* <TableCell>
                                    {occsQuery && occsQuery.data ? (
                                        (occsQuery.data.find(x => x.at === occ[0].at)?.synced) ? "SCHEDULATA" : "NON SCHEDULATA"
                                    ) : (
                                        "loading..."
                                    )}
                                </TableCell> */}



                            </TableRow>)}
                        </TableBody>
                    </Table>

                </Box>

                <Box sx={{ flexGrow: 1 }}></Box>

            </Stack>



        </Box>
    )
};


export default RuleStatus