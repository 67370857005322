import { useContext, useEffect, useState } from "react";
import ServiceRegistry from "../../services/serviceRegistry/serviceRegistry";
import { Outlet } from "react-router-dom";
import { Typography } from "@mui/material";
import { ServiceContext } from "../../hooks/serviceRegistry/serviceContext";
import { AppContext } from "../../hooks/appContext";
import Loader from "../page/loader";


function resolveRole(roleName, environ) {
    if (environ === "localdev" || environ === "collaudo") {
        return "C_OpDashboard_" + roleName + "_Role";
    } else if (environ === "production") {
        return "P_OpDashboard_" + roleName + "_Role";
    } else return null;
}

/**
 * Da usare per wrappare sezioni nelle quali si vuole rendere 
 * disponibili dei servizi del service registry
 */
export function ServiceRegistryWrapper({
    requiredServices = [], //inizializzerò questi client ({service,role,label})
    defaultRole = null // uso questo ruolo se non è indicato diversamente per ogni servizio
}) {

    const [error, setIsError] = useState(null);
    const { environ } = useContext(AppContext);
    const [svcs, setSvcs] = useState({});
    const [svcsOk, setSvcsOk] = useState(false);
    const getService = function (svcLabel) {
        if (!svcs[svcLabel]) {
            setIsError("Wrong configuration for a service.");
            throw new Error("Undefined Service " + svcLabel);
        }
        return svcs[svcLabel];
    };


    useEffect(() => {
        (async () => {
            let rs;
            if (typeof requiredServices === "function"){
                rs = await requiredServices();
            } else {
                rs = requiredServices;
            }
            // console.log("INITING",rs)

            if (rs.length) {
                let tmp_svcs = {};
                for (let requiredService of rs) {
                    
                    try {
                        let { label = null, service, role = null } = requiredService;
                        let name = label || service;
                        let applyingRole = role || defaultRole;
                        let svc = ServiceRegistry.getService(service, { role: resolveRole(applyingRole, environ) });
                        if (svc.init) await svc.init();
                        tmp_svcs[name] = svc;
                    } catch(e){
                        console.error("In",requiredService,e);
                        setIsError("Cannot init a service.");
                        return
                    }
                }
                setSvcs(tmp_svcs);
                setSvcsOk(true);
            } else {
                setSvcsOk(true);
            }
        })();

    }, [requiredServices, svcsOk]);



    if (svcsOk) {
        return <ServiceContext.Provider value={{ getSvc: getService, availableSvcs: Object.keys(svcs) }}>
            <Outlet />
        </ServiceContext.Provider>;
    } else if (error) {
        return <Typography variant="h6">Si è verificato un errore: {error}</Typography>
    } else {
        return <Typography variant="h6"><Loader /></Typography>
    }


}