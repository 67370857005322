

class SERVICEREGISTRY {

    constructor(){
        this.svcMap = {};
        this.svcInsts = {};
    }
    setSigV4Service(svcName, svcClass, {region,endpoint}){
        this.svcMap[svcName] = {
            svcBuilder: ({role})=>new svcClass({region,endpoint,role}),
            needs: ['role']
        };
        return this;
    }
    setAwsService(svcName,svcClass,{region}){
        this.svcMap[svcName] = {
            svcBuilder: ({role})=>new svcClass({region,role}),
            needs: ['role']
        };
        return this;
    }
    setGenericService(svcName,svcClass,args){
        this.svcMap[svcName] = {
            svcBuilder: ()=>new svcClass(args),
            needs: []
        };
        return this;
    }
    computeHash(svcName,args,orderedKeys){
        let hsh = svcName;
        for (let key of orderedKeys){
            if (typeof args[key] === "undefined"){
                throw new Error(`Service ${svcName} requires arg ${key}`);
            }
            hsh+=args[key];
        }
        return hsh;
    }
    getService(svcName, args){
        if (this.svcMap[svcName]){
            let svcBuilder = this.svcMap[svcName].svcBuilder;
            let needs = this.svcMap[svcName].needs;
            let hsh = this.computeHash(svcName,args,needs);
            // console.log("SERVICE REGISTRY GET BY HASH",hsh);
            if (!this.svcInsts[hsh]){
                // console.log("SERVICE REGISTRY GET BY HASH",hsh, "CREATING NEW");
                this.svcInsts[hsh] = svcBuilder(args)
            }
            return this.svcInsts[hsh];
        } else {
            throw new Error("Undefined service "+svcName);
        }
    }
}

const ServiceRegistry = new SERVICEREGISTRY();
export default ServiceRegistry;