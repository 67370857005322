import { Box, Grid, Stack, Typography, styled } from "@mui/material";
import PagePaper from "../../components/page/pagePaper";
import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../hooks/userContext";
import { useCabledQuery, useInfiniteCabledQuery } from "../../hooks/useCabledQuery";
import CheckMiniWidget from "../../components/checks/CheckMiniWidget";
import { useNavigate } from "react-router-dom";
import { TaskMini } from "../../components/tasks/tasksMini";
import { parseCheck, sortParsedChecks } from "../../components/checks/checksUtils";
import IncidentWidget from "../../components/incidents/IncidentWidget";
import IncidentWidgetMini from "../../components/incidents/IncidentWidgetMini";
import ChecksDefinitions from "../checks/checksdefinitions";
import ChecksForEnv from "../../components/checks/ChecksForEnv";



const Section = styled(Box)(({ theme }) => ({

    width: "33%",
    boxShadow: theme.shadows[1],
    borderRadius: '15px',
    padding: theme.spacing(1),
    '.sectionTitle': {
        fontSize: '20px',
        fontWeight: 'bold'
    }
}));


export default function Landing() {

    const { user, allowedEnvs } = useContext(UserContext);
    const navigate = useNavigate();

    const { query: tasksQuery } = useCabledQuery({
        apiService: 'operator-monitoring',
        method: 'listMyTasks',
        refetchInterval: 60 * 1000,
        baseQueryKey: 'operator-tasks-landing',
        filters: {}
    })

    const { query: problemsQuery } = useCabledQuery({
        apiService: 'operator-monitoring',
        method: 'listProblems',
        baseQueryKey: 'operator-problems-landing',
        refetchInterval: 60 * 1000,
        filters: { environment: '*', includeArchived: false },
        enabled: true
    });


    const [checksData, setChecksData] = useState([]);
    const { query: checksQuery } = useCabledQuery({
        apiService: 'operator-monitoring',
        method: 'listChecks',
        refetchInterval: 60 * 1000,
        baseQueryKey: 'operator-checks-landing',
        filters: { environment: "*" }
    })
    useEffect(() => {
        let cD = [];
        if (checksQuery.data && checksQuery.data.length) {
            for (let check of checksQuery.data) {
                cD.push(parseCheck(check));
            }
            setChecksData(cD.sort(sortParsedChecks));
        }
    }, [checksQuery.data]);

    return <PagePaper transparent={true}>
        <Box sx={{ width: '100%', height: '100%' }}>
            <Typography variant="h6">Hi, {user.username.toUpperCase()}</Typography>
            <Stack direction="row" spacing={1} sx={{ height: "95%", mt: 2 }}>

                {/* TASKS */}
                <Section>
                    <Typography className="sectionTitle" sx={{ mb: 1 }}>
                        TASKS
                    </Typography>
                    <Stack spacing={2}>
                        {tasksQuery && tasksQuery.data && tasksQuery.data.data && <>
                            {
                                !tasksQuery.data.data.length && <Typography>Non hai task in questo momento.</Typography>
                            }
                            {
                                !!tasksQuery.data.data.length && tasksQuery.data.data.sort((a, b) => a.severity > b.severity ? -1 : 1).map(
                                    (task, taskI) => <TaskMini {...task} key={taskI} onClick={() => { navigate('/tasks') }} />
                                )
                            }

                        </>}
                    </Stack>
                </Section>

                {/* INCIDENTS */}
                <Section>
                    <Typography className="sectionTitle" sx={{ mb: 1 }}>
                        INCIDENTS
                    </Typography>
                    <Stack spacing={2}>
                        {(problemsQuery.data || []).map((p, problemI) => (
                            <IncidentWidgetMini key={problemI}
                                status={p.status}
                                problemId={p.problem_id}
                                problemType={p.problem_type}
                                severity={p.severity}
                                startDate={p.start_date}
                                endDate={p.end_date}
                                onClick={() => navigate('/incidents')}
                            />
                        ))}
                    </Stack>
                </Section>

                {/* CHECKS */}
                <Section>
                    <Typography className="sectionTitle" sx={{ mb: 1 }}>
                        CHECKS
                    </Typography>
                    <Grid container spacing={2}>
                        {
                            allowedEnvs && allowedEnvs.map(
                                (envName) => (
                                    ChecksDefinitions[envName]?.length ? (
                                        <ChecksForEnv 
                                            mini={true} 
                                            key={envName} 
                                            envName={envName} 
                                            checks={ChecksDefinitions[envName]} 
                                            onClick={()=>navigate('/checks')}
                                            />
                                    ) : <></>
                                )
                            )
                        }

                    </Grid>
                </Section>
            </Stack>

        </Box>
    </PagePaper>
}