import { Authenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister';
import { QueryClient } from '@tanstack/react-query';
import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client';
import Amplify from 'aws-amplify';
import React, { useMemo, useState } from 'react';
import { RouterProvider } from 'react-router-dom';
import './App.scss';
import { AppContext } from './hooks/appContext';
import { useLocalStorage } from './hooks/useLocalStorage';
import { UserContext } from './hooks/userContext';
import router from './router/main';


import { SetupServices } from './servicesSetup';
import themes from './themes/all';

const hr = window.location.href
let domain = "localhost";
let environ = "localdev";

if (hr.startsWith("https://operator.atlasreplymonitoring.it")) { domain = "operator.atlasreplymonitoring.it"; environ = "production"; }
else if (hr.startsWith("https://dev.operator.atlasreplymonitoring.it")) { domain = "dev.operator.atlasreplymonitoring.it"; environ = "collaudo"; }
// console.log(hr, domain, environ);



Amplify.configure({
    Auth: {
        region: 'eu-west-1',
        userPoolId: environ === "production" ? 'eu-west-1_OQM9flRgY' : 'eu-west-1_qLdcsOf1X',
        userPoolWebClientId: environ === "production" ? '5cr73jdc73ians89cugbaecqk5' : '4ufsg0tdc4d7ud5glb0mlphje9',
        mandatorySignIn: false,
        authenticationFlowType: 'USER_SRP_AUTH',
        identityPoolId: environ === "production" ? 'eu-west-1:7cb5716d-0d7a-4966-b4ce-4d4a6823fa8c' : 'eu-west-1:0b8241e8-dccf-4cd7-9fa3-6d75cef6c6fb',
        cookieStorage: {
            domain: domain,
            secure: true
        }
    },
    Storage: {
        AWSS3: {
            bucket: 'itpa-customer-unicredit', //REQUIRED -  Amazon S3 bucket name
            region: 'eu-west-1', //OPTIONAL -  Amazon service region
            identityPoolId: environ === "production" ? 'eu-west-1:7cb5716d-0d7a-4966-b4ce-4d4a6823fa8c' : 'eu-west-1:0b8241e8-dccf-4cd7-9fa3-6d75cef6c6fb',
        }
    }
});

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            refetchInterval: 1000 * 60 * 1,
            retryDelay: 10*1000,
            retry: 2
        }
    }
});

SetupServices(domain);


const localStoragePersister = createSyncStoragePersister({ storage: window.localStorage })


function EnvironmentReactor(startingEnv = null) {
    this.currentEnvironment = startingEnv;
    this.callbacks = [];
}
EnvironmentReactor.prototype.changeEnv = function (newEnv) {
    this.callbacks.forEach(function (callback) { callback(this.currentEnvironment, newEnv) })
}
EnvironmentReactor.prototype.addListener = function (cb) {
    this.callbacks.push(cb);
}

function App() {


    const [currentThemeName, setCurrentThemeName] = useLocalStorage('user-theme', 'BG');
    const themeSelection = useMemo(
        () => ({
            toggleTheme: (newTheme) => {
                setCurrentThemeName(newTheme);
            }
        }), []
    );



    const theme = useMemo(() => {

        // console.log("SWITCH THEME");
        // console.log(currentThemeName);
        let th = themes[currentThemeName];
        if (!th) {
            th = theme["DEFAULT"];
        }
        // console.log("Current theme primary: ", th?.palette?.primary?.main);

        return createTheme(th)
    }, [currentThemeName]);


    const [currentEnvironment, setCurrentEnvironment] = useLocalStorage("app-selected-env", null);
    const [environmentSwitchListeners, setEnvironmentSwitchListeners] = useState([]);

    const switchEnvironment = function (newEnv) {
        environmentSwitchListeners.forEach(cb => cb(currentEnvironment, newEnv));
        setCurrentEnvironment(newEnv);
    }
    const addEnvironmentSwitchListener = function (cb) {
        setEnvironmentSwitchListeners([...environmentSwitchListeners, cb]);
    }
    const removeEnvironmentSwitchListener = function (cb) {
        let esl = [...environmentSwitchListeners];
        const index = esl.indexOf(cb);
        if (index > -1) {
            esl.splice(index, 1);
            setEnvironmentSwitchListeners(esl)
        }
    }
    const getUserInfo = function({signOut, user}){
        const userSession = user?.getSignInUserSession();
        const idToken = userSession?.getAccessToken();
        const groups = idToken?.payload["cognito:groups"] || [];
        const allowedEnvs = groups.filter(x => x.startsWith("ItpaEnv")).map(x => x.replace("ItpaEnv", ""))
        const sub = idToken?.payload.sub;
        return {user,groups,signOut,allowedEnvs,sub};
    }
    const isViewAllowed = function(userInfo){
        return !!userInfo && !!userInfo.user && (userInfo.groups.includes("Operator") || (userInfo.groups.includes("Admin")))
    }

    return (
        <AppContext.Provider value={{ domain, environ, currentEnvironment, setCurrentEnvironment: switchEnvironment, addEnvironmentSwitchListener, removeEnvironmentSwitchListener, currentThemeName, setCurrentThemeName }}>
            <ThemeProvider theme={theme}>
                <PersistQueryClientProvider client={queryClient} persistOptions={{ persister: localStoragePersister }}>
                    <Authenticator variation="modal" hideSignUp={true}>
                        {
                            ({ signOut, user }) => {
                                const userInfo = getUserInfo({signOut,user});
                                return (
                                    <UserContext.Provider value={userInfo}>
                                        {isViewAllowed(userInfo) ? (
                                            <div className="App" style={{ height: '100%' }}>
                                                <div>

                                                    <RouterProvider router={router(signOut)}></RouterProvider>
                                                </div>

                                            </div>
                                        ) : (
                                            <div style={{ margin: "15px" }}>
                                                <h1>Ops...</h1>
                                                <h2>Ciao <strong>{user.username}</strong>, sembra che tu non sia abilitato a questo accesso.</h2>
                                                <p>Ecco alcune possibili soluzioni:</p>
                                                <ul>
                                                    <li><a href="#" onClick={signOut}>Cambia utente</a></li>
                                                    <li><a href="mailto:info@atlasreplymonitoring.it">Richiedi accesso ad amministratore</a></li>
                                                </ul>
                                            </div>
                                        )}
                                    </UserContext.Provider>
                                )
                            }
                        }
                    </Authenticator>
                </PersistQueryClientProvider>
            </ThemeProvider>
        </AppContext.Provider>
    );
}

export default App;
