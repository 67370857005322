
import { Grid } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import React, { useEffect } from 'react';
import PagePaper from '../../components/page/pagePaper';
import { QueryLinearProgress } from '../../components/queries/queryLinearProgress';
import { useCabledQuery } from '../../hooks/serviceRegistry/useCabledQuery';
import { fullDateStr } from '../../services/common/common';



class StellantisAccumulator {
    constructor(dashboard, widget, armed, count, overcome, timeEnd, timeStart, value) {
        this.dashboard = dashboard;
        this.widget = widget;
        this.armed = armed;
        this.count = count;
        this.overcome = overcome;
        this.timeEnd = timeEnd;
        this.dateEnd = new Date(parseInt(timeEnd,10)*1000);
        this.timeStart = timeStart;
        this.dateStart = new Date(parseInt(timeStart,10)*1000);
        this.value = value;
        this.id = dashboard+widget;
    }
    static from_dynamo_record(record) {
        var split = record.widgetName.S.split("::");
        return new StellantisAccumulator(
            split[0],
            split[1],
            record.armed.BOOL,
            record.count.N,
            record.overcome.BOOL,
            record.timeEnd.N,
            record.timeStart.N,
            record.value.S

        )
    }
}


export default function StellantisDashboard() {


    const [runs, setRuns] = React.useState([]);
    
    const {query, queryKey} = useCabledQuery({
        svcName: 'ddb',
        method: 'scanTable',
        baseQueryKey: 'stlaccumulators',
        args: {
            tableName: 'stl.accumulators',
        },
        refetchInterval: 10 * 60 * 1000,
        enabled: true,
    })
    const alarmNames = {
        'stellantis-state/GsdpHealth-Reads': "GsdpHealth",
        // 'stellantis-state/GsdpHealthNafta-Reads': "GsdpHealthNafta",
    };
    const alarmsQuery = useCabledQuery({
        svcName: 'cw',
        method: 'getAlarmsState',
        refetchInterval: 2 * 60 * 1000,
        baseQueryKey: 'stlalarms',
        enabled: true,
        args: {
            alarmNames: Object.keys(alarmNames)
        }
    });
    const colors = {
        'R': 'Rosso',
        'B': 'Nero',
        'G': 'Verde',
        'M': 'Errore o mancante'
    };
    const statusMap = {
        true: true,
        'OK': true,
        false: false,
        'ALARM': false,
    };
    const columns = [
        {field: 'dashboard', headerName: 'Dashboard', flex: 1},
        {field: 'widget', headerName: 'Widget', flex: 3},
        {field: 'stato', headerName: 'Colore', flex: .5, valueGetter: p=>colors[p.row.value]},
        {field: 'count', headerName: 'Count rilevazioni', flex: .5},
        {field: 'dateStart', headerName: 'Prima rilevazione', flex: 1, valueGetter: p=>fullDateStr(p.row.dateStart)},
        {field: 'dateEnd', headerName: 'Ultima rilevazione', flex: 1, valueGetter: p=>fullDateStr(p.row.dateEnd)},
        {field: 'armed', headerName: 'Incident', flex: .5, valueGetter: p=>(p.row.armed ? 'SI':'NO')}
        
    ]

    useEffect(() => {
        if (query && query.data){
            var runs = query.data.map((d) => StellantisAccumulator.from_dynamo_record(d));
            runs = runs.sort((a,b) => b.date - a.date);
            setRuns(runs);
        }
    },[query.data]);

    return (

        <>
            {/* <Deck queryKey={queryKey} spaceBelow={1} title="Stato accumulatori"/> */}
            <Grid container spacing={2} columns={2} sx={{ mb: 2 }}>
                {(alarmsQuery && alarmsQuery.query && alarmsQuery.query.data) && Object.entries(alarmNames).map(([k, v]) => (
                    <Grid item xs={2} sm={1} key={v}>
                         
                    </Grid>

                ))}

            </Grid>
            <PagePaper height="80vh">
                <QueryLinearProgress queryKey={queryKey} height={5} />
                <div style={{width: "100%", height: "99%"}}>
                    <DataGrid pageSize={10} rowHeight={30} rows={runs} columns={columns} />
                </div>
            </PagePaper>
        </>

    );
}