import { Box, Card, CardContent, Stack, Typography } from "@mui/material";
import { elapsed, splitCamelCase } from "../../services/common/common";
// import './problemCard.scss';
import WidgetContainer from "../containers/widgetContainer";


const IncidentTypeWidget = ({
    problemType,
    environment,
    highlighted=false,
    onClick= ()=>{},
    onDoubleClick=()=>{}
}) => {


    return (
        <WidgetContainer 
            bgColor="#ffffff"
            highlighted={highlighted}
            sx={{minHeight: 'fit-content'}} 
            onClick={onClick} 
            onDoubleClick={()=>onDoubleClick({environment,problemType})}>
            <Stack justifyContent="space-between" sx={{ minHeight: "100%",p:1 }}>
                <Typography variant="h6">{splitCamelCase(problemType)}</Typography>
                {/* <Box sx={{ flexGrow: 1,minHeight: "20px" }}></Box>
                {!!endDate ? (
                    <Typography>Closed {elapsed(endDate)}</Typography>
                    ) : (
                        <Typography>Opened {elapsed(startDate)}</Typography>
                        )}

                <Box sx={{ flexGrow: 2,minHeight: "40px" }}></Box>
                <Typography className="impactedComponents">{impactedComponents.join(" - ")}</Typography>
                { !!displayId && <Typography variant="body2">{problemId}</Typography>} */}
            </Stack>
        </WidgetContainer>
    );
};


export default IncidentTypeWidget;