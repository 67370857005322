import { Box, Button, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { SigV4ApiClient } from "../../services/aws/sigV4api";






export default function Dev3() {


    const [credentials,setCredentials] = useState(null);
    const [client, setClient] = useState(null);
    useEffect(()=>{
        (async ()=>{
            var cl = new SigV4ApiClient({
                role: "OpDashboard-Operator",
                region: "eu-west-1",
                endpoint: "https://876er7x19j.execute-api.eu-west-1.amazonaws.com/prod"
            });
            await cl.init();
            setClient(cl);
        })();
    },[]);


    return <Box>
        <Typography variant="h1">SV4 Request</Typography>
        { !!client ? (
            <>
            <Typography variant="h6">Client</Typography>
            <pre>{JSON.stringify(client,null,2)}</pre>
            </>
        ) : (<Typography variant="h6">No client </Typography>)}
        { (!!client) && (
            <Button variant="outlined" onClick={()=>client.doRequest({
                method: 'GET', path: '/envs'
            })}>Do Request</Button>
        )}
    </Box>
}