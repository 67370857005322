import { Grid, Stack } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { applyOmniboxFilter } from "../../components/filters/omnibox";
import Deck from "../../components/queries/deck";
import { Task } from "../../components/tasks/tasks";
import { useCabledQuery } from "../../hooks/useCabledQuery";



export default function TasksDashboard() {


    const [displayedTasks, setDisplayedTasks] = useState([]);
    const [search, setSearch] = useState([]);
    const {query:operatorTaskQuery,queryKey: operatorTasksQueryKey} = useCabledQuery({
        apiService: 'operator-monitoring',
        method: 'listAllMyTasks',
        baseQueryKey: 'operator-tasks',
        refetchInterval: 60*1000,
        filters: {},
        enabled: true
    });
    const [sorterField,setSorterField] = useState(null);
    const [sortDir,setSortDir] = useState(1);
    
    const sorters = {
        random: (a,b)=>1,
        iat: (a,b)=>new Date(a.iat) < new Date(b.iat) ? -1 : 1,
        customer: (a,b)=>a.customer < b.customer ? -1 : 1,
        service: (a,b)=>a.service < b.service ? -1 : 1,
        eat: (a,b)=>new Date(a.eat) < new Date(b.eat) ? -1 : 1
    }    
    const sorter = sorterField === null ? sorters.random : (a,b)=>sortDir*sorters[sorterField](a,b)

    const handleSortChange = function(field){
        if (sorterField !== field){
            setSorterField(field);
        } else {
            setSortDir(-1*sortDir);
        }
    }
   


    useEffect(() => {
        let displayed = applyOmniboxFilter((operatorTaskQuery.data || []),search);
        setDisplayedTasks(displayed);
    }, [operatorTaskQuery.data, search.length]);

    const queryClient = useQueryClient();
    const handleTaskChange = () => {
        queryClient.invalidateQueries(operatorTaskQuery.key);
    }
    const SEVERITIES = ['','warning','minor','major','critical']
    const categories = [
        {
            title: 'Customer - Service',
            fields: {'customer':'customer','service':'service'},
            render: (data) => `${data.customer} - ${data.service || "nessun servizio"}`
        },
        {
            title: 'Severity',
            fields: {'severity':'severity'},
            render: (data) => SEVERITIES[data.severity]
        }
    ];
    return (

        <>
            <Stack spacing={1} >
                <Deck 
                    queryKey={operatorTasksQueryKey} 
                    hasRefresh={true}
                    hasOmniBox={true}
                    searchHandler={{search,setSearch,categories}}
                />
                
                {/* <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Stack direction="row" alignContent="flex-start" justifyContent="space-evenly">
                        <Box sx={{flexGrow:2}} />
                        <TopDeck
                            query={operatorTaskQuery}
                            refetchInterval={tasksRefetchInterval}
                            refresh={() => queryClient.invalidateQueries(operatorTaskQuery.key)}
                        ></TopDeck>
                        <Box sx={{flexGrow:1}} />
                        <ClientServiceFilter
                            customerServiceOptions={customerServiceOptions}
                            onChange={onChangeCustomerServiceSelection}
                            selection={selectedCustomerService}
                        />
                        </Stack>
                    </Grid>
                </Grid> */}
                <Grid container spacing={1}>

                    <Grid item xs={1} sx={{ display: { xs: 'none', md: 'flex' } }} onClick={()=>handleSortChange('customer')}>Customer</Grid>
                    <Grid item xs={1} sx={{ display: { xs: 'none', md: 'flex' } }}  onClick={()=>handleSortChange('service')}>Service</Grid>
                    <Grid item md={2} sx={{ display: { xs: 'flex', md: 'none' } }} onClick={()=>handleSortChange('customer')}>Customer <br /> Service</Grid>
                    {/* 2 */}
                    <Grid item xs={4} md={1}>Name</Grid>
                    {/* 3 - 6 */}
                    <Grid item xs={6} md={4}>Description</Grid>
                    {/* 7 - 12 */}
                    <Grid item sx={{ display: { xs: 'none', md: 'flex' } }} md={2} onClick={()=>handleSortChange('iat')}>Age</Grid>
                    {/* 9 - 12 */}
                    <Grid item sx={{ display: { xs: 'none', md: 'flex' } }} md={2} onClick={()=>handleSortChange('eat')}>Exp</Grid>
                    {/* 11 - 12 */}
                    <Grid item sx={{ display: { xs: 'none', md: 'flex' } }} md={1}>&nbsp;</Grid>
                    {/* 12 - 12 */}
                </Grid>
                {displayedTasks.sort(sorter).map((t, tI) => (
                    <Task {...t} key={tI} onChange={handleTaskChange} />
                ))}
            </Stack >
        </>
    );

}