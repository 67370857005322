import { useContext } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { UserContext } from "../hooks/userContext";


/**
 * Gestisce il setup di una sezione che richiede autorizzazioni.
 * Controlla che l'utente abbia le autorizzazioni richieste (env)
 */


export default function AuthorizedSection({
    env = null, // questa sezione ha bisogno di un certo env
    group = null, // questa sezione ha bisogno di un certo gruppo
}) {



    const { allowedEnvs, groups } = useContext(UserContext);
    const location = useLocation();

    const isAllowedByEnv = (!env || allowedEnvs.includes(env));
    const isAllowedByGroup = (!group || groups.includes(group));
    const isAllowed = (isAllowedByEnv || isAllowedByGroup);


    if (isAllowed) {
        return <Outlet />;
    }
    else {
        return <Navigate to="/" state={{ from: location }} />
    }
}