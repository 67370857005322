import { Auth } from "aws-amplify";
const AWS = require('aws-sdk');

export const getUserInfo = async ()=>{
    return await Auth.currentAuthenticatedUser();
}


export const hasGroup = async (group) => {
    const userInfo = await Auth.currentAuthenticatedUser();
    return userInfo.signInUserSession.idToken.payload["cognito:groups"].includes(group);
}
export const listAvailableRoles = async () => {
    const userInfo = await Auth.currentAuthenticatedUser();
    return userInfo.signInUserSession.idToken.payload["cognito:roles"];
}


export const obtainBaseRoleCredentials = async () => {
    // console.log("ObtainBaseRoleCredentials");
    const credentials = await Auth.currentCredentials();
    // console.log("credentials", credentials);
    return credentials;
}

export const getRoleArnForCredentials = async (credentials) => {
    let creds = {
        accessKeyId: credentials.accessKeyId || credentials.AccessKeyId,
        secretAccessKey: credentials.secretAccessKey || credentials.SecretKey,
        sessionToken: credentials.sessionToken || credentials.SessionToken,
        expiration: credentials.expiraiton || credentials.Expiration,
        identityId: credentials.identityId || credentials.IdentityId,
        authenticated: true
    }
    var sts = new AWS.STS({ region: 'eu-west-1', apiVersion: '2011-06-15', credentials: creds });
    const data = await sts.getCallerIdentity().promise();
    return data.Arn;
}



export const getCredentialsForRole = async (role=null,identityId=null) => {
    // console.log("getCredentialsForRole",role,identityId)
    if (role){
        if (identityId === null){
            // attenzione: perchè questo passaggio funzioni deve esserci un gruppo assegnato all'utente con una precedenza
            // esplicitamente minore della precedenza di tutti gli altri gruppi. In caso contrario si ottiene un errore
            // "Unauthenticated access is not allowed ... "
            let c = await obtainBaseRoleCredentials(); 
            identityId = c.identityId;
        }
        // console.log("IdentityId",identityId)
        const userInfo = await Auth.currentAuthenticatedUser();
        // console.log("userInfo",userInfo)
        const userPoolId = userInfo.pool.userPoolId;
        // console.log("userPoolId",userPoolId)
        const region = userPoolId.split("_")[0];
        var cognitoidentity = new AWS.CognitoIdentity({apiVersion: '2014-06-30', region: 'eu-west-1'});
        // console.log("cognitoidentity",cognitoidentity)
        const data = await cognitoidentity.getCredentialsForIdentity({
            IdentityId: identityId,
            CustomRoleArn: role,
            Logins: {
                [`cognito-idp.${region}.amazonaws.com/${userPoolId}`]:userInfo.signInUserSession.idToken.jwtToken
            }
        }).promise();
        let x = {...data?.Credentials,identityId: data?.IdentityId};
        return {
            accessKeyId: x.accessKeyId || x.AccessKeyId,
            secretAccessKey: x.secretAccessKey || x.SecretKey,
            sessionToken: x.sessionToken || x.SessionToken,
            expiration: x.expiration || x.Expiration,
            identityId: x.identityId || x.IdentityId,
            authenticated: x.authenticated
        }
    }

}