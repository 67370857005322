import AgentsOperatorView from "../../components/agents/agentsOperatorView";

export default function UnicreditAgents(){




    return <>
        <AgentsOperatorView poolName="UCRMonitoraggioSitiASG" svcName="agentsInUnicredit"/>
    </>
}