import { Drawer, Grid, Typography } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import AdminEnvironmentSelector from '../../../components/admin/environmentSelector';
import Deck from '../../../components/queries/deck';
import { applyOmniboxFilter } from '../../../components/filters/omnibox';
import FlowInstallationForm from '../../../components/flows/flowInstallationForm';
import FlowWidget from '../../../components/flows/flowWidget';
import { AppContext } from '../../../hooks/appContext';
import { useCabledQuery } from '../../../hooks/useCabledQuery';
import { alertService } from '../../../services/alerting/alerting';
import ItpaApi from '../../../services/api';




const AdminFlowList = () => {

    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const { currentEnvironment } = useContext(AppContext);
    if (!currentEnvironment){
        navigate("/admin/envs");
    }
    // const { query: flowsQuery, refetchInterval: flowsRefetchInterval,queryKey } = useFlows();
    const {query:flowsQuery,queryKey} = useCabledQuery({
        apiService: "monitoring",
        method: "listAllFlows",
        baseQueryKey: 'all_flows',
        refetchInterval: 10 * 60 * 1000,
        filters: {environment: currentEnvironment}
    });
    
    const moniServ = ItpaApi.getService("monitoring");
    const [sideContent, setSideContent] = useState(null);
    const [search, setSearch] = useState([]);
    const [flowData, setFlowData] = useState([]);



    

    const installFlow = async (newFlow) => {
        try {
            await moniServ.installFlow({
                flowName: newFlow.name,
                executor: newFlow.executor,
                externalFlowIdentity: newFlow.extFlowIdentity,
                environment: newFlow.environment,
                meta: newFlow.meta
            });
            setTimeout(() => queryClient.invalidateQueries(flowsQuery.key));
            setTimeout(() => installFlowMutation.reset());
            setSideContent(null);
            alertService.success("Flusso installato correttamente");
        } catch (e) {
            if (e.status === 409) alertService.error("Flusso già installato");
            else alertService.error("Impossibile installare il flusso.")

        }
        return false;
    }
    const installFlowMutation = useMutation({
        mutationFn: newFlow => installFlow(newFlow)
    })
    const clearSideContent = () => { setSideContent(null); }


    useEffect(() => {
        if (!flowsQuery.data) { setFlowData([]); return; }
        if (search === "") { setFlowData(flowsQuery.data); return; }
        setFlowData(applyOmniboxFilter(flowsQuery.data,search));
    }, [flowsQuery.data,search]);


    const categories = [
        {
            title: 'Customer - Service',
            fields: {'customer':'meta.customer','service':'meta.service'},
            render: (data) => `${data.customer} - ${data.service || "nessun servizio"}`
        }
    ];

    return (
        <>

            <Deck
                hasOmniBox = {true}
                searchHandler={{search,setSearch,categories}}
                // queryHook={useFlows}
                // query={flowsQuery}
                queryKey = {queryKey}
                onRefresh={() => {console.log("REFRESHED!")}}
                title={<AdminEnvironmentSelector />}
                hasNewItem={true}
                onNewItem={() => setSideContent("install")}
            />
            
            <Grid container sx={{ width: "100%" }}>
                {!flowData && <Grid item xs={12}><Typography variant="comment">Loading...</Typography></Grid>}
                {!!flowData && flowData.length && <>
                    {flowData.map((flow, flowI) => <Grid item xs={12} sm={6} md={4} xl={3} key={flowI}>
                        <FlowWidget key={flowI} {...flow} onDoubleClick={() => navigate(`./${flow.environment}/${flow.name}`)} />
                    </Grid>)}
                </>}
            </Grid>


            <Drawer
                anchor="right"
                open={sideContent !== null}
                onClose={clearSideContent}>

                {sideContent === "install" && (
                    <FlowInstallationForm onOk={installFlowMutation.mutate} onCancel={() => setSideContent(null)} />
                )}

            </Drawer>
        </>
    )
}



export default AdminFlowList