import React, { useEffect, useState } from "react";
import AgentsInPool from "./agentsInPool";
import { useCabledQuery } from "../../hooks/serviceRegistry/useCabledQuery";



class Agent {

    constructor({ pool_name, uid, agent_version, processStatus, runnerConnectionStatus, runnerWorkingStatus, runningFlag, sessionStatus, state, updatedAt }) {
        this.poolName = pool_name;
        this.uid = uid;
        this.agentVersion = agent_version;
        this.id = uid;
        this.processStatus = processStatus;
        this.runnerConnectionStatus = runnerConnectionStatus;
        this.runnerWorkingStatus = runnerWorkingStatus;
        this.runningFlag = runningFlag;
        this.sessionStatus = sessionStatus;
        this.state = state;
        this.updatedAt = new Date(1000 * parseInt(updatedAt, 10));

    }
}
const CONFIG = {
    queryRefetchInterval: 1 * 60 * 1000
};

export default function AgentsOperatorView({poolName, svcName = 'agents'}) {


    const [agents, setAgents] = useState([]);

    const agentsQuery = useCabledQuery({
        svcName,
        method: 'doGet',
        args: {
            path: `/pools/${poolName}/agents`
        },
        enabled: true,
        refetchInterval: CONFIG.queryRefetchInterval,
        baseQueryKey: ['agents', poolName]
    });
    useEffect(() => {
        let aa = (agentsQuery?.query?.data || []).map(d => new Agent(d))
        setAgents(aa);
    }, [agentsQuery?.query?.data]);

    return (

        <AgentsInPool poolName={poolName} queryData={agentsQuery?.query?.data || []} adminView={false} svcName={svcName} />

    );


}