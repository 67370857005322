
import { ApiClient } from '../api';

class CoreService extends ApiClient {


    // ENVIRONMENTS

    async hello() {
        return await this.do_get('/hello');
    }
    async test() {
        return await this.do_get('/test');
    }


    async listEnvironments(){
        return await this.do_get('/envs');
    }
    async getEnvironment({environment}){
        return await this.do_get('/envs/'+environment);
    }
    async createEnvironment({environment}){
        return await this.do_post('/envs', {name: environment});
    }
    async toggleEnvironmentStatus({environment,shouldBeDisabled}){
        return await this.do_put('/envs/'+environment+'/status',{status: !shouldBeDisabled});
    }
    
    





}


export default CoreService;