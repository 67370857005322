import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import { Box, Card, CardContent, Paper, Stack, Typography, styled } from '@mui/material';
import StepFunctionsIcon from '../../icons/stepfunctions';
import './widget.scss';
import { splitCamelCase } from '../../services/common/common';




const WidgetCard = styled(Paper)(({ theme }) => ({
    minHeight: '200px',
    position: 'relative',
    display: 'block',
    margin: theme.spacing(1),
    boxShadow: theme.shadows[2],
    ":hover": {
        boxShadow: theme.shadows[5]
    }
}));

const FlowWidget = ({
    name, executor, environment, meta = {}, onDoubleClick
}) => {

    let ExecutorIcon;
    let className = "widget";

    switch (executor) {
        case "stepfunctions":
            ExecutorIcon = <StepFunctionsIcon />;
            break;
        default:
            ExecutorIcon = <QuestionMarkIcon sx={{ color: 'orange' }} />;
            className += " missing";
    }

    var numGraphs = 3;

    return (
        <WidgetCard onDoubleClick={onDoubleClick}>
            <Stack justifyContent="space-between" sx={{ minHeight: "190px", p:1, }}>
                <Box sx={{ flexGrow: 1 }} />
                <Typography variant="h6" sx={{wordWrap:true}}>{splitCamelCase(name)}</Typography>
                <Box sx={{ flexGrow: 1 }}></Box>
                <Stack direction="row" justifyContent="space-between">
                    <Typography sx={{fontSize: '12px'}}>{(meta.customer || "").toUpperCase()}</Typography>
                    <Typography sx={{fontSize: '12px'}}>{(meta.service || "").toUpperCase()}</Typography>
                </Stack>
            </Stack>
            <Box sx={{ position: "absolute", top: 15, left: 15, width: "50px", height: "50px", borderRadius: '5px' }}>
                {ExecutorIcon}
            </Box>
        </WidgetCard>
        // <div className={className}>
        //     <Stack sx={{ width: "100%" }} justifyContent="space-evenly">
        //         <p>{name}</p>
        //         <p>{executor}</p>
        //         <p>{ExecutorIcon}</p>
        //         {/* <Stack direction="row" justifyContent="space-between" sx={{ width: '100%' }}>
        //             <span className='title'>{title || "STATO SISTEMA"}</span>
        //             {ExecutorIcon}
        //         </Stack>
        //         <p className='statusText'>{statusText}</p>
        //         <Box style={{ flexGrow: 1 }}></Box>
        //         <Stack direction="row" justifyContent="space-evenly" sx={{ width: "100%" }} spacing={2}>
        //             <WidgetLineChart title="ANDAMENTO" sx={{width: parseInt(100/numGraphs)+'%'}}></WidgetLineChart>
        //             <WidgetBarChart title="STORICO" sx={{width: parseInt(100/numGraphs)+'%'}}></WidgetBarChart>
        //             <WidgetPieChart title="RIPARTIZIONE" sx={{width: parseInt(100/numGraphs)+'%'}}></WidgetPieChart>
        //         </Stack>
        //         <Box style={{ flexGrow: 1 }}></Box> */}
        //     </Stack>
        // </div>
    )
}

export default FlowWidget