import styled from "@emotion/styled";
import { Box, Paper, Stack, Tooltip } from "@mui/material";
import { QueryLinearProgress } from "../queries/queryLinearProgress";



const StyledPaper = styled(Paper, {
    shouldForwardProp: p => !['status'].includes(p)
})(({ theme, status }) => {

    return {
        marginBottom: theme.spacing(2),
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100%',
        fontWeight: 'bolder',
        backgroundColor: {
            false: theme.palette.rngLight[0],
            true: theme.palette.rngLight[2],
            null: 'gray'
        }[status],
        backdropFilter: 'blur(10 px)'
    }
});


export const SimpleStatus = ({ name, width, height, status, top = <></>, sx = {}, tooltip = null }) => {

    const element = <StyledPaper status={status} style={{ width, height, position: 'relative' }} sx={{ m: 1, ...sx }}>
        <Stack>
            <Box style={{ width: "100%", margin: 0, padding: 0, position: 'absolute', left: 0, top: 0 }}>
                {top}
            </Box>
            <Box>
                {name}
            </Box>
        </Stack>
    </StyledPaper>

    if (tooltip){

        return <Tooltip title={tooltip}>
            {element}
        </Tooltip>
    }

    return element;
};


export const QuerySimpleStatus = ({ width, height, query, queryKey, refetchInterval, alias = null, tooltip = null, getStatus = (queryData) => queryData?.status }) => {


    const statusMap = {
        true: true,
        'OK': true,
        false: false,
        'ALARM': false,
    };

    let status = statusMap[getStatus(query.data)];
    if (typeof status === 'undefined')
        status = null;
    return (
        <SimpleStatus
            width={width}
            height={height}
            name={alias || query.data?.name || "---"}
            status={status}
            tooltip={tooltip}
            top={<
                QueryLinearProgress
                query={query}
                queryKey={queryKey}
            />}
        />
    )
}


