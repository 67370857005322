import './widget.scss'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import CrisisAlertIcon from '@mui/icons-material/CrisisAlert';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined';
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
import ErrorIcon from '@mui/icons-material/Error';
import DoneIcon from '@mui/icons-material/Done';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import { Box, Stack, Typography } from '@mui/material';
import WidgetLineChart from './WidgetLineChart';
import WidgetBarChart from './WidgetBarChart';
import WidgetPieChart from './WidgetPieChart';










const Widget = ({
    title = null,
    statusText = "",
    status = null
}) => {

    let StatusIcon;
    let className = "widget";

    switch (status) {
        case true:
            StatusIcon = <DoneIcon sx={{ color: 'green' }} />;
            break;
        case false:
            StatusIcon = <ErrorIcon sx={{ color: 'red' }} />;
            className += " ko";
            break;
        default:
            StatusIcon = <QuestionMarkIcon sx={{ color: 'orange' }} />;
            className += " missing";
    }

    var numGraphs = 3;

    return (
        <div className={className}>
            <Stack sx={{ width: "100%" }} justifyContent="space-evenly">
                <Stack direction="row" justifyContent="space-between" sx={{ width: '100%' }}>
                    <span className='title'>{title || "STATO SISTEMA"}</span>
                    {StatusIcon}
                </Stack>
                <p className='statusText'>{statusText}</p>
                <Box style={{ flexGrow: 1 }}></Box>
                <Stack direction="row" justifyContent="space-evenly" sx={{ width: "100%" }} spacing={2}>
                    <WidgetLineChart title="ANDAMENTO" sx={{width: parseInt(100/numGraphs)+'%'}}></WidgetLineChart>
                    <WidgetBarChart title="STORICO" sx={{width: parseInt(100/numGraphs)+'%'}}></WidgetBarChart>
                    <WidgetPieChart title="RIPARTIZIONE" sx={{width: parseInt(100/numGraphs)+'%'}}></WidgetPieChart>
                </Stack>
                <Box style={{ flexGrow: 1 }}></Box>
            </Stack>
        </div>
    )
}

export default Widget