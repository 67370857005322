import { Box, Button, TextField } from "@mui/material";
import { useQueryClient } from '@tanstack/react-query';
import { React, useState } from 'react';
import useCabledMutation from '../../hooks/useCabledMutation';
import { useCabledQuery } from '../../hooks/useCabledQuery';
import { alertService } from '../../services/alerting/alerting';

function updateEnvInEnvs(envsKnownData, envUpdate) {
    if (Array.isArray(envsKnownData)) {
        let found = false;
        for (let i = 0; i < envsKnownData.length; i++) {
            if (envsKnownData[i].name === envUpdate.name) {
                Object.assign(envsKnownData[i], envUpdate);
                found = true;
                break; // Termina il loop una volta trovato il match per "name"
            }
        }
        if (!found) {
            envsKnownData.push(envUpdate);
        }
    } else if (typeof envsKnownData === 'object') {
        if (envsKnownData.name === envUpdate.name) {
            Object.assign(envsKnownData, envUpdate);
        }
        
    }
    return envsKnownData;
}

export default function Dev2() {
    const queryClient = useQueryClient();
    const { query: envQuery, queryKey: envQueryKey  } = useCabledQuery({
        apiService: 'environments',
        method: 'getEnvironment',
        refetchInterval: 10 * 60 * 1000,
        baseQueryKey: ['envs', "Sys"],
        filters: { environment: "Sys" },

    });
    const { query: envsQuery, queryKey: envsQueryKey } = useCabledQuery({
        apiService: 'environments',
        method: 'listEnvironments',
        refetchInterval: 60 * 1000,
        baseQueryKey: ['envs'],
        filters: {},

    });


    const createEnvironmentMutation = useCabledMutation({
        apiService: 'environments',
        method: 'createEnvironment',
        resetOnSuccess: true,
        resetOnFailure: true,
        onSuccess: (result) => {
            alertService.info("Environment created " + JSON.stringify(result))
            queryClient.setQueriesData(['envs'], oldData => updateEnvInEnvs(oldData, result));
            // queryClient.setQueryData(envsQueryKey, oldData => updateEnvInEnvs(oldData, result));

        },
        onFailure: (e) => {
            alertService.error("Environment creation failed " + JSON.stringify(e))
        }
    })
    const toggleEnvStatusMutation = useCabledMutation({
        apiService: 'environments',
        method: 'toggleEnvironmentStatus',
        resetOnSuccess: true,
        resetOnFailure: true,
        onSuccess: (result) => {
            alertService.info("Environment status changed " + JSON.stringify(result))
            queryClient.setQueriesData(['envs'], oldData => updateEnvInEnvs(oldData, result));
            // queryClient.setQueryData(envQueryKey, oldData => ({ ...oldData, ...result}));
            // queryClient.setQueryData(envsQueryKey, oldData => updateEnvInEnvs(oldData, result));
            // queryClient.invalidateQueries(envQueryKey)
        },
        onFailure: (e) => {
            alertService.error("Environment status change failed " + JSON.stringify(e))
        }
    })
    const [envName, setEnvName] = useState("");

    return (
        <>
            <Box sx={{ width: "100%", height: "30vh", border: '1px solid purple', overflow: 'scroll' }}>
                <Button onClick={() => queryClient.invalidateQueries(envsQueryKey)}>Refresh!</Button>
                <pre>{envsQuery.isError ? "Query 2 in errore:" + envsQuery.error.toString() : JSON.stringify(envsQuery.data, null, 2)}</pre>
            </Box>
            <Box sx={{ width: "100%", height: "20vh", border: '1px solid purple', overflow: 'scroll' }}>
                <Button onClick={() => queryClient.invalidateQueries(envQueryKey)}>Refresh!</Button>
                <pre>{envQuery.isError ? "Query 2 in errore:" + envQuery.error.toString() : JSON.stringify(envQuery.data, null, 2)}</pre>
            </Box>
            <Box sx={{ width: "100%", height: "20vh", border: '1px solid purple', overflow: 'scroll' }}>
                <TextField label="Environment Name" value={envName} onChange={(e) => setEnvName(e.target.value)} />
                <Button onClick={() => createEnvironmentMutation.mutate({ environment: envName })}>Create Environment</Button>
            </Box>
            <Box sx={{ width: "100%", height: "20vh", border: '1px solid purple', overflow: 'scroll' }}>
                { !envQuery.data?.disabled && 'ATTIVATO'}
                { !!envQuery.data?.disabled && 'DISATTIVATO'}
                <Button onClick={() => toggleEnvStatusMutation.mutate({ environment: 'Sys', shouldBeDisabled: !envQuery.data.disabled })}>Toggle Environment status</Button>
            </Box>
        </>
    );
}