import { Box, Stack, Typography } from "@mui/material";

import styled from "@emotion/styled";
import './checkWidget.scss';

import CheckIcon from '@mui/icons-material/Check';
import ErrorIcon from '@mui/icons-material/Error';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import WarningIcon from '@mui/icons-material/Warning';
import { useNavigate } from "react-router-dom";
import WidgetContainer from "../containers/widgetContainer";


const WidgetCard = styled(WidgetContainer, {
    shouldForwardProp: p => p !== "statusValue"
})(({ theme, statusValue }) => ({
    minHeight: '150px',
    position: 'relative',
    display: 'block',
    background: theme.palette.rngLight[statusValue],
    cursor: 'pointer',
    // borderColor: theme.palette.rngLight[statusValue],
    // borderWidth: "1px",
    // borderStyle: "solid",
    ":hover": {
        boxShadow: theme.shadows[6]
    }
}));

const NullIndicator = () => (
    <Box>
        <QuestionMarkIcon sx={{ color: 'orange' }} fontSize="large" />
    </Box>
)
const OKIndicator = () => (
    <Box>
        <CheckIcon sx={{ color: 'green' }} fontSize="large" />
    </Box>
)
const WarnIndicator = () => (
    <Box>
        <WarningIcon sx={{ color: 'orange' }} fontSize="large" />
    </Box>
)
const KOIndicator = () => (
    <Box>
        <ErrorIcon sx={{ color: 'red' }} fontSize="large" />
    </Box>
)


const CheckWidget = function ({
    display_name = "",
    is_expired = false,
    status = null,
    meta = {},
    link = null,
    last_str = null,
    expected_str = ""
}) {
    const navigate = useNavigate();
    const statusValues = [false, null, true];
    const onDoubleClick = () => {
        if (!!link) {
            return navigate(link);
        }
    }
    return (
        <>
            <WidgetCard statusValue={statusValues.indexOf(status)} onDoubleClick={onDoubleClick}>
                <Stack justifyContent="space-between" sx={{ minHeight: "190px", p: 1, }}>
                    <Typography sx={{ fontSize: '16px' }}>{last_str}</Typography>
                    <Typography sx={{ fontSize: '22px', fontWeight: 'bolder', wordWrap: true }}>{display_name}</Typography>
                    <Box sx={{ flexGrow: 1 }} />

                    { is_expired && <WarnIndicator />}
                    { !is_expired && status === null && <NullIndicator />}
                    { !is_expired && status === true && <OKIndicator />}
                    { !is_expired && status === false && <KOIndicator />}

                    <Box sx={{ flexGrow: 1 }}></Box>
                    <Stack direction="row" justifyContent="space-between">
                        <Typography sx={{ fontSize: '12px' }}>{("NOME CLIENTE" || meta.customer || "").toUpperCase()}</Typography>
                        <Typography sx={{ fontSize: '12px' }}>{(meta.service || "").toUpperCase()}</Typography>
                    </Stack>
                </Stack>
            </WidgetCard>
            <Typography sx={{ fontSize: '12px' }}>{expected_str}</Typography>

        </>
    )
}


export default CheckWidget;