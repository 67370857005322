import { Subject } from 'rxjs';

const mySubject = new Subject();
const defaultId = 'default-alert';

export const alertService = {
    onAlert,
    success,
    error,
    info,
    warn,
    alert,
    clear
};

export const alertSeverity = {
    success: 'success',
    error: 'error',
    info: 'info',
    warning: 'warning'
}

function onAlert() {
    return mySubject.asObservable();//.pipe(filter(x=>x && x.id === id));
}
// convenience methods
function success(message, options) {
    alert({ ...options, severity:alertSeverity.success, message });
}

function error(message, options) {
    alert({ ...options, severity:alertSeverity.error, message });
}

function info(message, options) {
    alert({ ...options, severity:alertSeverity.info, message });
}

function warn(message, options) {
    alert({ ...options, severity:alertSeverity.warning, message });
}

// core alert method
function alert(alert) {
    alert.id = new Date().valueOf();
    mySubject.next(alert);
}

// clear alerts
function clear(id = defaultId) {
    mySubject.next({ id });
}