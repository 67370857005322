import PropTypes from 'prop-types';
import ArrowBack from "@mui/icons-material/ArrowBack";
import { Box, Button, ButtonGroup, Card, CardContent, Grid, IconButton, Skeleton, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from "@mui/material";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import dayjs from "dayjs";
import React from "react";
import { useSched, useSchedData } from "../../hooks/carrefour/carrefour";
import ItpaApi from "../../services/api";
import { dayMonthDateStr, expected, hourMinuteDateStr } from "../../services/common/common";
import { LapideBottom, LapideTop, stepSemaphore } from "./lapide";
import './style.scss';
import { TabellaDollaro } from "./tabellaDollaro";
import { styled, useTheme } from "@mui/material/styles";
import Sizer from '../../utils/sizer';

function OutcomeDollaro(rows) {
    let stati = { total: 0 };
    if (rows === null) { return null; }
    for (let row of rows) {
        stati[row.status] = stati[row.status] || 0;
        stati[row.status]++;
        stati.total++;
    }
    return stati
}

function PresentOutcomeDollaro(stati, stepStatus) {
    if (stepStatus === 'unknown' || stepStatus === 'pending') return '';
    if (stati === null || stati.total === 0) {
        return 'Il robottino non ha raccolto dati sufficienti.';
    }
    if (stati.Aborted && stati.Aborted > 0) {
        return 'Ci sono degli aborted';
    }
    if (stati["Time overrun"] && stati["Time overrun"] > 0) {
        return 'Ci sono dei time overrun';
    }
    if (stati["Launch wait"] && stati["Launch wait"] > 0) {
        return 'Nessun problema ma ci sono ancora dei launch wait';
    }
    if (stati["Event wait"] && stati["Event wait"] > 0) {
        return 'Nessun problema ma ci sono ancora degli event wait';
    }
    if (stati["Running"] && stati["Running"] > 0) {
        return 'Nessun problema ma ci sono ancora dei running';
    }
    if (stati["Disabled"] && stati["Disabled"] > 0) {
        return 'Nessun problema ma ci sono dei disabled';
    }
    if (stati.Completed && stati.Completed === stati.total) {
        return 'Tutti completed';
    }
    else return JSON.stringify(stati);
}

function PresentOutcomeVF(rows) {
    if (rows === null || !rows.length) {
        return 'Il robottino non ha raccolto dati sufficienti.';
    }

    for (let row of rows) {
        if (row.colli !== 0 || row.righe !== 0) {
            return 'Sono arrivati dei flussi.';
        }
    }

    return 'Non sono ancora arrivati dei flussi.';

}

function PresentOutcomeQuadratura(rows) {
    if (rows === null || !rows.length) {
        return 'Il robottino non ha raccolto dati sufficienti.';
    }
    let foundnz = false;
    let quadra = true;
    for (let r of rows) {
        if (r.e1_righe !== 0 || r.e2_righe !== 0 || r.er_righe !== 0) foundnz = true;
        if (r.e1_colli !== 0 || r.e2_colli !== 0 || r.er_colli !== 0) foundnz = true;
        quadra = (r.e1_righe + r.e2_righe === r.er_righe && r.e1_colli + r.e2_colli === r.er_colli) && quadra;
    }
    quadra = foundnz && quadra;
    return quadra ? "Quadra" : "Non quadra";
}



function ColoredStepName({ stepName, status }) {
    var color = 'gray';
    var title = "";
    switch (status) {
        case 'pending':
            color = 'gray'; title = "In attesa"; break;
        case 'doing':
            color = 'orange'; title = "In esecuzione"; break;
        case 'succeeded':
            color = 'green'; title = "Completato con successo"; break;
        case 'failed':
            color = 'red'; title = "Fallito"; break;
        default:
            color = 'gray'; title = "Stato sconosciuto"; break;
    }
    return <Tooltip title={title}><span style={{ color, fontWeight: 'bold' }}>{stepName.toUpperCase()}</span></Tooltip>;
}

const LapideRm = function LapideRm({
    name, rule_name, at, sizer = 12, tipologieVisibili, isFuture
}) {

    const [stati, setStati] = React.useState(null);
    const [meta, setMeta] = React.useState({});
    const [isClosed, setIsClosed] = React.useState(false);
    const [canFetchData, setCanFetchData] = React.useState(false);
    const [stableData, setStableData] = React.useState(false);
    if (!isClosed) {
        if (at < dayjs().subtract(2, 'hour').toDate()) {
            setIsClosed(true);
        }
        else if (stati != null && ['succeeded', 'failed'].includes(stati.main)) setIsClosed(true);
    }
    const schedData = useSched({ schedName: name, isClosed });
    let schedObtained = !!schedData.data;

    if (!canFetchData) {
        if (stati != null) setCanFetchData(true);
    }
    const checkData = useSchedData({ schedName: name, enabled: canFetchData && !stableData, ttl: 20 });
    let dataObtained = !!checkData.data;
    if (dataObtained && isClosed && !stableData) { setStableData(true); }

    React.useEffect(() => {
        if (!schedData || !schedData.data) return;
        setStati(schedData.data.stati);
        setMeta(schedData.data.meta);
    }, [schedData.isFetching]);


    let dep = null;
    let namesplit = name.split("_");
    if (namesplit.length > 4) { dep = namesplit[3]; }
    if (meta?.deposito) { dep = meta.deposito; }


    const start = meta?.start ? dayjs(meta.start, "HH:mm:ss") : at;
    const end = meta?.end ? dayjs(meta.end, "HH:mm:ss") : null

    //const middlecontent = (!!meta?.ora_raw && !!meta?.ora_elab) ? `RAW: ${meta.ora_raw} ELAB: ${meta.ora_elab}` : null;
    const middlecontent = <>
        <Stack direction="row" width="100%" justifyContent="space-evenly">
            {/* <span style={{position:"relative", fontSize: 8, top:-5, left: -5 }}>RAW</span> */}
            <Box style={{ fontSize: 20, fontWeight: 'bold' }}>
                {meta.ora_raw && meta.ora_raw !== 'None' ? meta.ora_raw.slice(0, 5) : '---'}
            </Box>
            <Box style={{ fontSize: 20, fontWeight: 'bold' }}>
                {meta.ora_elab && meta.ora_elab !== 'None' ? meta.ora_elab.slice(0, 5) : '---'}
            </Box>
            {/* <span style={{position:"relative", fontSize: 8, top:0 }}>ELAB</span> */}

        </Stack>
    </>;
    let stepPlaceholder = <Grid item xs={12}><Skeleton variant="rectangular" animation="wave" height="100"></Skeleton></Grid>


    let vfmeta = null;
    try {
        if (!!meta?.verificaflussi) {
            vfmeta = JSON.parse(meta.verificaflussi.replace(/'/g, '"'));
        }
    } catch { }
    let quadmeta = null;
    try {
        if (!!meta?.quadratura) {
            quadmeta = JSON.parse(meta.quadratura.replace(/'/, '"'));
        }
    } catch { }
    let rpmeta = null;
    try {
        if (!!meta?.riordprod) {
            rpmeta = JSON.parse(meta.riordprod.replace(/'/, '"'));
        }
    } catch { }
    let ermeta = null;
    try {
        if (!!meta?.elabrichieste) {
            ermeta = JSON.parse(meta.elabrichieste.replace(/'/, '"'));
        }
    } catch { }


    const [display, setDisplay] = React.useState('main');
    let outcome = { qu: null, vf: null, rp: null, er: null };
    let dati = { qu: null, vf: null, rp: null, er: null };
    if (checkData && checkData.data && checkData.data.length) {
        let data = checkData.data[0].data;
        for (let d of data) {
            if (d.data_name === "elabrichieste" && d.data_type === "json") {
                let dd = JSON.parse(d.data_content);

                outcome.er = dd.outcome;
                dati.er = dd.rows;
            }
            if (d.data_name === "riordprod" && d.data_type === "json") {
                let dd = JSON.parse(d.data_content);
                outcome.rp = dd.outcome;
                dati.rp = dd.rows;
            }
            if (d.data_name === "verificaflussi" && d.data_type === "json") {
                let dd = JSON.parse(d.data_content);

                dati.vf = dd.rows;
            }
            if (d.data_name === "quadratura" && d.data_type === "json") {
                let dd = JSON.parse(d.data_content);
                outcome.qu = true;
                dati.qu = dd.rows;
            }
        }
    }

    const cstate = ItpaApi.getService("carrefourstate");
    const queryClient = useQueryClient();
    const theme = useTheme();

    const snooze_quadratura_mutation = useMutation({
        mutationFn: async () => {
            try {
                await cstate.doUpdateSchedMeta({ schedName: name, meta: { snooze_quadratura: 1 } });
                queryClient.invalidateQueries(schedData.queryKey);
            } catch {

            }
        }
    })
    if (!!meta.tipologia && !tipologieVisibili[meta.tipologia]) return <></>;
    const RmCard = styled(Card)({
        borderRadius: 20,
        boxShadow: '-1px -1px 1px 1px rgb(25,25,25,25%)',
        ':hover': {
            boxShadow: '-2px -2px 2px 2px rgb(25,25,25,25%)'
        },
    });


    const CenteredTextGridItem = styled(Grid)({
        textAlign: 'center',
        lineHeight: '30px'
        // border: '1px solid black'
    })
    const StepGridItem = styled(CenteredTextGridItem)({
        fontSize: 20,
        fontWeight: 'bolder'
    })
    const DetailGridItem = styled(CenteredTextGridItem)({
        fontSize: 10,
        lineHeight: '10px',
        verticalAlign: 'middle'
    })


    let actualSizer = (display === 'main') ? (sizer) : (new Sizer({ xs: 12 }));

    return (
        <Grid item {...actualSizer} justifyContent="center">
            <RmCard variant="outlined" style={{ height: isFuture ? 100 : 300 }}>
                <CardContent style={{ height: '100%', margin: '10px' }}>
                    {/* {isClosed ? 'chiuso' : 'non chiuso'} {stableData ? 'stabile' : 'non stabile'} */}
                    {display === 'main' ? (
                        <Stack justifyContent="space-between" style={{ minHeight: '100%' }}>
                            <LapideTop title={dep ? 'DEP ' + dep : null} start={start} stato={PresentOutcomeQuadratura(dati?.qu) === "Non quadra" ? "failed" 
                            : (PresentOutcomeVF(dati?.vf) === "Non sono ancora arrivati dei flussi."  ? "failed" : (PresentOutcomeDollaro(OutcomeDollaro(dati?.rp, stati?.rp)) === "Ci sono degli aborted"  ? "failed" 
                            : (PresentOutcomeDollaro(OutcomeDollaro(dati?.er, stati?.er)) === "Ci sono degli aborted" ? "failed" :
                            (PresentOutcomeDollaro(OutcomeDollaro(dati?.er, stati?.er)) === "Ci sono dei time overrun" ||
                                PresentOutcomeDollaro(OutcomeDollaro(dati?.er, stati?.er)) === "Nessun problema ma ci sono ancora dei launch wait" ||
                                PresentOutcomeDollaro(OutcomeDollaro(dati?.er, stati?.er)) === "Nessun problema ma ci sono ancora degli event wait" ||
                                PresentOutcomeDollaro(OutcomeDollaro(dati?.er, stati?.er)) === "Nessun problema ma ci sono dei disabled" ||
                                PresentOutcomeDollaro(OutcomeDollaro(dati?.er, stati?.er)) === "Nessun problema ma ci sono ancora dei running" ?
                                "doing" : (PresentOutcomeDollaro(OutcomeDollaro(dati?.rp, stati?.rp)) === "Ci sono dei time overrun" ||
                                PresentOutcomeDollaro(OutcomeDollaro(dati?.rp, stati?.rp)) === "Nessun problema ma ci sono ancora dei launch wait" ||
                                PresentOutcomeDollaro(OutcomeDollaro(dati?.rp, stati?.rp)) === "Nessun problema ma ci sono ancora degli event wait" ||
                                PresentOutcomeDollaro(OutcomeDollaro(dati?.rp, stati?.rp)) === "Nessun problema ma ci sono dei disabled" ||
                                PresentOutcomeDollaro(OutcomeDollaro(dati?.rp, stati?.rp)) === "Nessun problema ma ci sono ancora dei running" ?
                                "doing" : (stati?.main || "unknown"))))))}></LapideTop>
                            { !isFuture &&
                            <Grid container spacing={1}>
                                {!schedObtained ? (<>
                                    {stepPlaceholder} {stepPlaceholder} {stepPlaceholder}
                                </>) : (<>
                                    {stati?.quadratura ? (<>
                                        <StepGridItem item xs={1}>{ColoredStepName({ stepName: "QU", status: dati.qu ? (PresentOutcomeQuadratura(dati.qu) === "Quadra" ? "succeeded" : "failed") : "unknown" })}</StepGridItem>
                                        <DetailGridItem item xs={3} >
                                            {!!meta.snooze_quadratura ? 'PROLUNGATO' : (
                                                stati?.quadratura === 'doing' ? <Button sx={{ fontSize: 12 }} onClick={() => snooze_quadratura_mutation.mutate()}>Prolunga</Button> : '')}
                                        </DetailGridItem>
                                        <CenteredTextGridItem item xs={6} >{PresentOutcomeQuadratura(dati.qu)}</CenteredTextGridItem>
                                        <CenteredTextGridItem item xs={2}>
                                            {dati.qu !== null ? <Button sx={{ fontSize: 12 }} onClick={() => setDisplay("dati-quadratura")}>DATI</Button> : ""}

                                        </CenteredTextGridItem>
                                    </>) : (<></>)}
                                    {stati?.verificaflussi ? (<>
                                        <StepGridItem item xs={1} >{ColoredStepName({ stepName: "VF", status: dati.vf ? (PresentOutcomeVF(dati.vf) === "Sono arrivati dei flussi." ? "succeeded" : "failed") : "unknown" })}</StepGridItem>
                                        <CenteredTextGridItem item xs={3} >
                                        </CenteredTextGridItem>
                                        <CenteredTextGridItem item xs={6} >{PresentOutcomeVF(dati.vf)}</CenteredTextGridItem>
                                        <CenteredTextGridItem item xs={2}>
                                            {dati.vf !== null ? <Button sx={{ fontSize: 12 }} onClick={() => setDisplay("dati-verificaflussi")}>DATI</Button> : ""}

                                        </CenteredTextGridItem>
                                    </>) : (<></>)}
                                    {stati?.riordprod ? (<>
                                        <StepGridItem item xs={1}>{ColoredStepName({stepName: "RP",status: dati.rp ? (PresentOutcomeDollaro(OutcomeDollaro(dati.rp, stati.rp)) === "Tutti completed" ? "succeeded" :
                                        (PresentOutcomeDollaro(OutcomeDollaro(dati.rp, stati.rp)) === "Ci sono degli aborted" ? "failed" :
                                        (PresentOutcomeDollaro(OutcomeDollaro(dati.rp, stati.rp)) === "Ci sono dei time overrun" ||
                                            PresentOutcomeDollaro(OutcomeDollaro(dati.rp, stati.rp)) === "Nessun problema ma ci sono ancora dei launch wait" ||
                                            PresentOutcomeDollaro(OutcomeDollaro(dati.rp, stati.rp)) === "Nessun problema ma ci sono ancora degli event wait" ||
                                            PresentOutcomeDollaro(OutcomeDollaro(dati.rp, stati.rp)) === "Nessun problema ma ci sono dei disabled" ||
                                            PresentOutcomeDollaro(OutcomeDollaro(dati.rp, stati.rp)) === "Nessun problema ma ci sono ancora dei running" ?
                                            "doing" : "failed"))) : "unknown"})}</StepGridItem>
                                        <DetailGridItem item xs={3} sx={{ fontSize: 8 }}>
                                            {rpmeta ? Object.keys(rpmeta).join(" - ") : ""}
                                        </DetailGridItem>
                                        <CenteredTextGridItem item xs={6} >{PresentOutcomeDollaro(OutcomeDollaro(dati.rp, stati.rp))}</CenteredTextGridItem>
                                        <CenteredTextGridItem item xs={2}>
                                            {dati.rp !== null ? <Button sx={{ fontSize: 12 }} onClick={() => setDisplay("dati-riordprod")}>DATI</Button> : ""}
                                        </CenteredTextGridItem>


                                    </>) : (<></>)}
                                    {stati?.elabrichieste ? (<>
                                        <StepGridItem item xs={1}>{ColoredStepName({stepName: "ER",status: dati.er ? (PresentOutcomeDollaro(OutcomeDollaro(dati.er, stati.er)) === "Tutti completed" ? "succeeded" :
                                        (PresentOutcomeDollaro(OutcomeDollaro(dati.er, stati.er)) === "Ci sono degli aborted" ? "failed" :
                                        (PresentOutcomeDollaro(OutcomeDollaro(dati.er, stati.er)) === "Ci sono dei time overrun" ||
                                            PresentOutcomeDollaro(OutcomeDollaro(dati.er, stati.er)) === "Nessun problema ma ci sono ancora dei launch wait" ||
                                            PresentOutcomeDollaro(OutcomeDollaro(dati.er, stati.er)) === "Nessun problema ma ci sono ancora degli event wait" ||
                                            PresentOutcomeDollaro(OutcomeDollaro(dati.er, stati.er)) === "Nessun problema ma ci sono dei disabled" ||
                                            PresentOutcomeDollaro(OutcomeDollaro(dati.er, stati.er)) === "Nessun problema ma ci sono ancora dei running" ?
                                            "doing" : "failed"))) : "unknown"})}</StepGridItem>
                                        <DetailGridItem item xs={3} sx={{ fontSize: 8 }}>
                                            {ermeta ? ermeta.task : ""}
                                        </DetailGridItem>
                                        <CenteredTextGridItem item xs={6} >{PresentOutcomeDollaro(OutcomeDollaro(dati.er, stati.er))}</CenteredTextGridItem>
                                        <CenteredTextGridItem item xs={2}>
                                            {dati.er !== null ? <Button sx={{ fontSize: 12 }} onClick={() => setDisplay("dati-elabrichieste")}>DATI</Button> : ""}
                                        </CenteredTextGridItem>


                                    </>) : (<></>)}
                                </>)}


                            </Grid>
                            }
                            <LapideBottom start={start} end={end}
                                middlecontent={<Typography variant="h5">{middlecontent}</Typography>}
                            ></LapideBottom>
                        </Stack>
                    ) : <></>}
                    {display === 'dati-quadratura' ? (
                        <Grid container>
                            <Grid item xs={1}>
                                <Stack height="100%">
                                    <IconButton>
                                        <ArrowBack onClick={() => setDisplay("main")}></ArrowBack>
                                    </IconButton>
                                    <Box>
                                        <Typography variant="h5">
                                            {ColoredStepName({ stepName: dep, status: PresentOutcomeQuadratura(dati?.qu) === "Non quadra" ? "failed" 
                                            : (PresentOutcomeVF(dati?.vf) === "Non sono ancora arrivati dei flussi."  ? "failed" : (PresentOutcomeDollaro(OutcomeDollaro(dati?.rp, stati?.rp)) === "Ci sono degli aborted"  ? "failed" 
                                            : (PresentOutcomeDollaro(OutcomeDollaro(dati?.er, stati?.er)) === "Ci sono degli aborted" ? "failed" :
                                            (PresentOutcomeDollaro(OutcomeDollaro(dati?.er, stati?.er)) === "Ci sono dei time overrun" ||
                                                PresentOutcomeDollaro(OutcomeDollaro(dati?.er, stati?.er)) === "Nessun problema ma ci sono ancora dei launch wait" ||
                                                PresentOutcomeDollaro(OutcomeDollaro(dati?.er, stati?.er)) === "Nessun problema ma ci sono ancora degli event wait" ||
                                                PresentOutcomeDollaro(OutcomeDollaro(dati?.er, stati?.er)) === "Nessun problema ma ci sono dei disabled" ||
                                                PresentOutcomeDollaro(OutcomeDollaro(dati?.er, stati?.er)) === "Nessun problema ma ci sono ancora dei running" ?
                                                "doing" : (PresentOutcomeDollaro(OutcomeDollaro(dati?.rp, stati?.rp)) === "Ci sono dei time overrun" ||
                                                PresentOutcomeDollaro(OutcomeDollaro(dati?.rp, stati?.rp)) === "Nessun problema ma ci sono ancora dei launch wait" ||
                                                PresentOutcomeDollaro(OutcomeDollaro(dati?.rp, stati?.rp)) === "Nessun problema ma ci sono ancora degli event wait" ||
                                                PresentOutcomeDollaro(OutcomeDollaro(dati?.rp, stati?.rp)) === "Nessun problema ma ci sono dei disabled" ||
                                                PresentOutcomeDollaro(OutcomeDollaro(dati?.rp, stati?.rp)) === "Nessun problema ma ci sono ancora dei running" ?
                                                "doing" : (stati?.main || "unknown"))))))})}
                                        </Typography>
                                    </Box>
                                    <Box>
                                        <Typography variant="h6">
                                            {ColoredStepName({ stepName: "QU", status: dati.qu ? (PresentOutcomeQuadratura(dati.qu) === "Quadra" ? "succeeded" : "failed") : "unknown" })}
                                        </Typography>
                                    </Box>
                                </Stack>
                            </Grid>
                            <Grid item xs={11} sx={{ overflow: 'hidden' }}>
                                <TableContainer style={{ overflowY: "initial" }} sx={{ maxHeight: 300 }}>
                                    <Table stickyHeader size="small" sx={{ mb: 4 }}>
                                        <TableHead> 
                                            <TableRow style={{ backgroundColor: 'rgba(255, 255, 255, 1)', position: 'sticky', top: 0, zIndex: 1000 }}>
                                                <TableCell align="center">PDV</TableCell>
                                                <TableCell align="center" colSpan={3}>Righe</TableCell>
                                                <TableCell align="center" colSpan={3}>Colli</TableCell>
                                            </TableRow>
                                            <TableRow style={{ backgroundColor: 'rgba(255, 255, 255, 1)', position: 'sticky', top: 0, zIndex: 1000 }}>
                                                <TableCell></TableCell>
                                                <TableCell align="center">E1</TableCell>
                                                <TableCell align="center">E2</TableCell>
                                                <TableCell align="center">EM</TableCell>
                                                <TableCell align="center">E1</TableCell>
                                                <TableCell align="center">E2</TableCell>
                                                <TableCell align="center">EM</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {dati.qu?.sort((a,b)=>(parseInt(a.pdv)<parseInt(b.pdv))?-1:1).map((r, i) => quadraturaRow(r, i))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                        </Grid>
                    ) : <></>}
                    {display === 'dati-verificaflussi' ? (
                        <Grid container>
                            <Grid item xs={1}>
                                <Stack height="100%">
                                    <IconButton>
                                        <ArrowBack onClick={() => setDisplay("main")}></ArrowBack>
                                    </IconButton>
                                    <Box>
                                        <Typography variant="h5">
                                            {ColoredStepName({ stepName: dep, status: PresentOutcomeQuadratura(dati?.qu) === "Non quadra" ? "failed" 
                                            : (PresentOutcomeVF(dati?.vf) === "Non sono ancora arrivati dei flussi."  ? "failed" : (PresentOutcomeDollaro(OutcomeDollaro(dati?.rp, stati?.rp)) === "Ci sono degli aborted"  ? "failed" 
                                            : (PresentOutcomeDollaro(OutcomeDollaro(dati?.er, stati?.er)) === "Ci sono degli aborted" ? "failed" :
                                            (PresentOutcomeDollaro(OutcomeDollaro(dati?.er, stati?.er)) === "Ci sono dei time overrun" ||
                                                PresentOutcomeDollaro(OutcomeDollaro(dati?.er, stati?.er)) === "Nessun problema ma ci sono ancora dei launch wait" ||
                                                PresentOutcomeDollaro(OutcomeDollaro(dati?.er, stati?.er)) === "Nessun problema ma ci sono ancora degli event wait" ||
                                                PresentOutcomeDollaro(OutcomeDollaro(dati?.er, stati?.er)) === "Nessun problema ma ci sono dei disabled" ||
                                                PresentOutcomeDollaro(OutcomeDollaro(dati?.er, stati?.er)) === "Nessun problema ma ci sono ancora dei running" ?
                                                "doing" : (PresentOutcomeDollaro(OutcomeDollaro(dati?.rp, stati?.rp)) === "Ci sono dei time overrun" ||
                                                PresentOutcomeDollaro(OutcomeDollaro(dati?.rp, stati?.rp)) === "Nessun problema ma ci sono ancora dei launch wait" ||
                                                PresentOutcomeDollaro(OutcomeDollaro(dati?.rp, stati?.rp)) === "Nessun problema ma ci sono ancora degli event wait" ||
                                                PresentOutcomeDollaro(OutcomeDollaro(dati?.rp, stati?.rp)) === "Nessun problema ma ci sono dei disabled" ||
                                                PresentOutcomeDollaro(OutcomeDollaro(dati?.rp, stati?.rp)) === "Nessun problema ma ci sono ancora dei running" ?
                                                "doing" : (stati?.main || "unknown")))))) })}
                                        </Typography>
                                    </Box>
                                    <Box>
                                        <Typography variant="h6">
                                            {ColoredStepName({ stepName: "VF", status: dati.vf ? (PresentOutcomeVF(dati.vf) === "Sono arrivati dei flussi." ? "succeeded" : "failed") : "unknown" })}
                                        </Typography>
                                    </Box>
                                </Stack>
                            </Grid>
                            <Grid item xs={11} sx={{ overflow: 'hidden' }}>
                                <TableContainer style={{ overflowY: "initial" }} sx={{ maxHeight: 280 }}>
                                    <Table stickyHeader size="small" sx={{ mb: 4 }}>
                                        <TableHead>
                                            <TableRow style={{ backgroundColor: 'rgba(255, 255, 255, 1)', position: 'sticky', top: 0, zIndex: 1000 }}>
                                                <TableCell align="center">PDV</TableCell>
                                                <TableCell align="center">Righe</TableCell>
                                                <TableCell align="center">Colli</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {(dati.vf && dati.vf.map) ? dati.vf?.map((r, i) => verificaflussiRow(r, i)) : <></>}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                        </Grid>
                    ) : <></>}

                    {display === 'dati-riordprod' ? (
                        <Grid container>
                            <Grid item xs={1}>
                                <Stack height="100%">
                                    <IconButton>
                                        <ArrowBack onClick={() => setDisplay("main")}></ArrowBack>
                                    </IconButton>
                                    <Box>
                                        <Typography variant="h5">
                                            {ColoredStepName({ stepName: dep, status: PresentOutcomeQuadratura(dati?.qu) === "Non quadra" ? "failed" 
                                            : (PresentOutcomeVF(dati?.vf) === "Non sono ancora arrivati dei flussi."  ? "failed" : (PresentOutcomeDollaro(OutcomeDollaro(dati?.rp, stati?.rp)) === "Ci sono degli aborted"  ? "failed" 
                                            : (PresentOutcomeDollaro(OutcomeDollaro(dati?.er, stati?.er)) === "Ci sono degli aborted" ? "failed" :
                                            (PresentOutcomeDollaro(OutcomeDollaro(dati?.er, stati?.er)) === "Ci sono dei time overrun" ||
                                                PresentOutcomeDollaro(OutcomeDollaro(dati?.er, stati?.er)) === "Nessun problema ma ci sono ancora dei launch wait" ||
                                                PresentOutcomeDollaro(OutcomeDollaro(dati?.er, stati?.er)) === "Nessun problema ma ci sono ancora degli event wait" ||
                                                PresentOutcomeDollaro(OutcomeDollaro(dati?.er, stati?.er)) === "Nessun problema ma ci sono dei disabled" ||
                                                PresentOutcomeDollaro(OutcomeDollaro(dati?.er, stati?.er)) === "Nessun problema ma ci sono ancora dei running" ?
                                                "doing" : (PresentOutcomeDollaro(OutcomeDollaro(dati?.rp, stati?.rp)) === "Ci sono dei time overrun" ||
                                                PresentOutcomeDollaro(OutcomeDollaro(dati?.rp, stati?.rp)) === "Nessun problema ma ci sono ancora dei launch wait" ||
                                                PresentOutcomeDollaro(OutcomeDollaro(dati?.rp, stati?.rp)) === "Nessun problema ma ci sono ancora degli event wait" ||
                                                PresentOutcomeDollaro(OutcomeDollaro(dati?.rp, stati?.rp)) === "Nessun problema ma ci sono dei disabled" ||
                                                PresentOutcomeDollaro(OutcomeDollaro(dati?.rp, stati?.rp)) === "Nessun problema ma ci sono ancora dei running" ?
                                                "doing" : (stati?.main || "unknown")))))) })}
                                        </Typography>
                                    </Box>
                                    <Box>
                                        <Typography variant="h6">
                                            {ColoredStepName({ stepName: "RP", status: dati.rp ? (PresentOutcomeDollaro(OutcomeDollaro(dati.rp, stati.rp)) === "Tutti completed" ? "succeeded" :
                                        (PresentOutcomeDollaro(OutcomeDollaro(dati.rp, stati.rp)) === "Ci sono degli aborted" ? "failed" :
                                        (PresentOutcomeDollaro(OutcomeDollaro(dati.rp, stati.rp)) === "Ci sono dei time overrun" ||
                                            PresentOutcomeDollaro(OutcomeDollaro(dati.rp, stati.rp)) === "Nessun problema ma ci sono ancora dei launch wait" ||
                                            PresentOutcomeDollaro(OutcomeDollaro(dati.rp, stati.rp)) === "Nessun problema ma ci sono ancora degli event wait" ||
                                            PresentOutcomeDollaro(OutcomeDollaro(dati.rp, stati.rp)) === "Nessun problema ma ci sono dei disabled" ||
                                            PresentOutcomeDollaro(OutcomeDollaro(dati.rp, stati.rp)) === "Nessun problema ma ci sono ancora dei running" ?
                                            "doing" : "failed"))) : "unknown" })}
                                        </Typography>
                                    </Box>
                                </Stack>
                            </Grid>
                            <Grid item xs={11} sx={{ overflow: 'hidden' }}>
                                <TableContainer style={{ overflowY: "initial" }} sx={{ maxHeight: 280 }}>
                                    <TabellaDollaro data={dati.rp} />
                                </TableContainer>
                            </Grid>
                        </Grid>
                    ) : <></>}
                    {display === 'dati-elabrichieste' ? (
                        <Grid container>
                            <Grid item xs={1}>
                                <Stack height="100%">
                                    <IconButton>
                                        <ArrowBack onClick={() => setDisplay("main")}></ArrowBack>
                                    </IconButton>
                                    <Box>
                                        <Typography variant="h5">
                                            {ColoredStepName({ stepName: dep, status: PresentOutcomeQuadratura(dati?.qu) === "Non quadra" ? "failed" 
                                            : (PresentOutcomeVF(dati?.vf) === "Non sono ancora arrivati dei flussi."  ? "failed" : (PresentOutcomeDollaro(OutcomeDollaro(dati?.rp, stati?.rp)) === "Ci sono degli aborted"  ? "failed" 
                                            : (PresentOutcomeDollaro(OutcomeDollaro(dati?.er, stati?.er)) === "Ci sono degli aborted" ? "failed" :
                                            (PresentOutcomeDollaro(OutcomeDollaro(dati?.er, stati?.er)) === "Ci sono dei time overrun" ||
                                                PresentOutcomeDollaro(OutcomeDollaro(dati?.er, stati?.er)) === "Nessun problema ma ci sono ancora dei launch wait" ||
                                                PresentOutcomeDollaro(OutcomeDollaro(dati?.er, stati?.er)) === "Nessun problema ma ci sono ancora degli event wait" ||
                                                PresentOutcomeDollaro(OutcomeDollaro(dati?.er, stati?.er)) === "Nessun problema ma ci sono dei disabled" ||
                                                PresentOutcomeDollaro(OutcomeDollaro(dati?.er, stati?.er)) === "Nessun problema ma ci sono ancora dei running" ?
                                                "doing" : (PresentOutcomeDollaro(OutcomeDollaro(dati?.rp, stati?.rp)) === "Ci sono dei time overrun" ||
                                                PresentOutcomeDollaro(OutcomeDollaro(dati?.rp, stati?.rp)) === "Nessun problema ma ci sono ancora dei launch wait" ||
                                                PresentOutcomeDollaro(OutcomeDollaro(dati?.rp, stati?.rp)) === "Nessun problema ma ci sono ancora degli event wait" ||
                                                PresentOutcomeDollaro(OutcomeDollaro(dati?.rp, stati?.rp)) === "Nessun problema ma ci sono dei disabled" ||
                                                PresentOutcomeDollaro(OutcomeDollaro(dati?.rp, stati?.rp)) === "Nessun problema ma ci sono ancora dei running" ?
                                                "doing" : (stati?.main || "unknown")))))) })}
                                        </Typography>
                                    </Box>
                                    <Box>
                                        <Typography variant="h6">
                                            {ColoredStepName({ stepName: "ER", status: dati.er ? (PresentOutcomeDollaro(OutcomeDollaro(dati.er, stati.er)) === "Tutti completed" ? "succeeded" :
                                        (PresentOutcomeDollaro(OutcomeDollaro(dati.er, stati.er)) === "Ci sono degli aborted" ? "failed" :
                                        (PresentOutcomeDollaro(OutcomeDollaro(dati.er, stati.er)) === "Ci sono dei time overrun" ||
                                            PresentOutcomeDollaro(OutcomeDollaro(dati.er, stati.er)) === "Nessun problema ma ci sono ancora dei launch wait" ||
                                            PresentOutcomeDollaro(OutcomeDollaro(dati.er, stati.er)) === "Nessun problema ma ci sono ancora degli event wait" ||
                                            PresentOutcomeDollaro(OutcomeDollaro(dati.er, stati.er)) === "Nessun problema ma ci sono dei disabled" ||
                                            PresentOutcomeDollaro(OutcomeDollaro(dati.er, stati.er)) === "Nessun problema ma ci sono ancora dei running" ?
                                            "doing" : "failed"))) : "unknown" })}
                                        </Typography>
                                    </Box>
                                </Stack>
                            </Grid>
                            <Grid item xs={11} sx={{ overflow: 'hidden' }}>
                                <TableContainer style={{ overflowY: "initial" }} sx={{ maxHeight: 280 }}>
                                    <TabellaDollaro data={dati.er} />
                                </TableContainer>
                            </Grid>
                        </Grid>
                    ) : <></>}

                </CardContent>
            </RmCard>
        </Grid>
    )

}
LapideRm.propTypes = {
    name: PropTypes.string,
    rule_name: PropTypes.string,
    at: PropTypes.object,
    tipologieVisibili: PropTypes.object,
    sizer: PropTypes.instanceOf(Sizer)
};


function quadraturaRow(r, i) {
    let className = "quadratura-row";
    if (r.quadra) className += " ok"; else className += " ko";
    return (
        <TableRow key={i}>
            <TableCell className={className} align="center">{r.pdv}</TableCell>
            <TableCell className={className} align="center">{r.e1_righe}</TableCell>
            <TableCell className={className} align="center">{r.e2_righe}</TableCell>
            <TableCell className={className} align="center">{r.er_righe}</TableCell>
            <TableCell className={className} align="center">{r.e1_colli}</TableCell>
            <TableCell className={className} align="center">{r.e2_colli}</TableCell>
            <TableCell className={className} align="center">{r.er_colli}</TableCell>
        </TableRow>
    )
}
function verificaflussiRow(r, i) {

    return (
        <TableRow key={i}>
            <TableCell align="center">{r.pdv}</TableCell>
            <TableCell align="center">{r.righe}</TableCell>
            <TableCell align="center">{r.colli}</TableCell>
        </TableRow>
    )
}


export default LapideRm;