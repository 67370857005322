import { Card, CardContent, Grid, Paper, Skeleton } from "@mui/material";
import { elapsed } from "../../services/common/common";


const Notes = ({ notesData,creatingNotes }) => {



    return <>
        {
            creatingNotes.map((n, j) => <Paper key={j} style={{ margin: "5px 60px 10px 2px", padding: "20px 20px" }}>
                <Grid container wrap="nowrap" spacing={2}>
                    <Grid item justifyContent="left" xs zeroMinWidth>
                        <Skeleton variant="rectangular" animation="wave" width="100%" />
                    </Grid>
                </Grid>
            </Paper>)
        }
        {
            notesData?.map(({ created_at, author, content, creating }, j) => <Paper key={j} style={{ margin: "5px 60px 10px 2px", padding: "20px 20px" }}>
                <Grid container wrap="nowrap" spacing={2}>
                    {
                        creating ? (
                            <Grid item justifyContent="left" xs zeroMinWidth>
                                <Skeleton variant="rectangular" animation="wave" width="100%" />
                            </Grid>
                        ) : (
                            <Grid item justifyContent="left" xs zeroMinWidth>
                                <h4 style={{ margin: 0, textAlign: "left" }}>{author}</h4>
                                <p style={{ textAlign: "left" }}>
                                    {content}
                                </p>
                                <p style={{ textAlign: "left", color: "gray" }}>
                                    {elapsed(created_at)}
                                </p>
                            </Grid>
                        )
                    }
                </Grid>
            </Paper>)
        }
    </>


};

export default Notes;