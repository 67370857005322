import { useInfiniteQuery, useQuery, useQueryClient } from '@tanstack/react-query';
import ItpaApi from '../services/api';

export function useOperatorTasks({}) {  
    
    const monServ = ItpaApi.getService("operator-monitoring");
    const refetchInterval = 60 * 1000;
    const tasksQuery = useInfiniteQuery({
        queryKey: ['operator-tasks'],
        queryFn: async ({pageParam = null})=>{
            return await monServ.listMyTasks({nextToken: pageParam})
        },
        refetchInterval: refetchInterval,
        refetchOnWindowFocus: false,
        getNextPageParam: (lastPage,pages)=>lastPage.next_token
    });


    return {tasksQuery, refetchInterval};
}
export function useOperatorTask({environment,id}) {  
    
    const monServ = ItpaApi.getService("operator-monitoring");

    const tasksQuery = useQuery({
        queryKey: ['operator-tasks',environment,id],
        queryFn: async ()=>{
            return await monServ.describeTask({environment,id})
        },
        refetchInterval: 60 * 1000,
        refetchOnWindowFocus: false
    });


    return tasksQuery;
}
export function useSolutions({environment,id}) {  
    
    const monServ = ItpaApi.getService("operator-monitoring");

    const taskSolutionsQuery = useQuery({
        queryKey: ['operator-task-solutions',environment,id],
        queryFn: async ()=>{
            
            return await monServ.listTaskSolutions({environment,id})
        },
        refetchInterval: 10 * 60 * 1000,
        refetchOnWindowFocus: false
    });


    return taskSolutionsQuery;
}