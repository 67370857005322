import { Avatar, Grid, List, ListItem, ListItemText, Stack, Typography, styled } from "@mui/material";
import React, { useContext } from "react";
import PagePaper from "../../components/page/pagePaper";
import { AppContext } from "../../hooks/appContext";
import { getUserInfo } from "../../services/aws/grants";

function capitalizeName(name) {
    const words = name.toLowerCase().split(' ');
    const capitalizedWords = words.map(word => word.charAt(0).toUpperCase() + word.slice(1));
    return capitalizedWords.join(' ');
}

function UserInfo(props) {
    const { username, avatar, groups, roles, email, auth_time, exp, sub, preferredMFA } = props;

    const StyledList = styled(List)({
        backgroundColor: props => props.theme.palette.background.paper,
    });

    const StyledListItem = styled(ListItem)({
        paddingLeft: props => props.theme.spacing(2),
        paddingRight: props => props.theme.spacing(2),
        borderBottom: props => `1px solid ${props.theme.palette.divider}`,
    });

    const StyledAvatar = styled(Avatar)({
        marginRight: "15px",
    });

    return (
        <StyledList>
            <StyledListItem>
                <StyledAvatar src={avatar}></StyledAvatar>
                <ListItemText primary={capitalizeName(username)} />
            </StyledListItem>
            <StyledListItem >
                <ListItemText primary="Gruppi" secondary={groups.join(', ')} />
            </StyledListItem>
            <StyledListItem >
                <ListItemText primary="Email" secondary={email} />
            </StyledListItem>
            <StyledListItem >
                <ListItemText primary="Ruoli disponibili" secondary={roles.map(r => r.split("/")[1]).join(', ')} />
            </StyledListItem>
            <StyledListItem >
                <ListItemText primary="Ultima autenticazione" secondary={new Date(auth_time * 1000).toLocaleString()} />
            </StyledListItem>
            <StyledListItem >
                <ListItemText primary="Scadenza autenticazione" secondary={new Date(exp * 1000).toLocaleString()} />
            </StyledListItem>
            <StyledListItem >
                <ListItemText primary="Id utente" secondary={sub} />
            </StyledListItem>
            <StyledListItem >
                <ListItemText primary="Preferenze MFA" secondary={preferredMFA} />
            </StyledListItem>
        </StyledList>
    );
}

function ThemeSelector() {

    const {currentThemeName, setCurrentThemeName} = useContext(AppContext);

    const themes = {
        'Default': 'DEFAULT',
        'BlueGray':'BG'
    }

    return <>
        <Typography variant="h6" gutterBottom>Scelta tema</Typography>
        <Grid container spacing={2} sx={{width: "100%"}}>
            { Object.entries(themes).map(([displayName,name])=>(
                <Grid item xs={2} key={name} style={{p:1, border: "1px solid black", borderRadius: '10px'}} onClick={()=>setCurrentThemeName(name)}>
                    <Typography variant="body1" style={{textAlign: "center"}}>{displayName}</Typography>
                </Grid>
            ))}
            
        </Grid>
        
        </>
}


export default function User() {

    const [userInfo, setUserInfo] = React.useState(null);
    
    React.useEffect(() => {
        getUserInfo()
        //.then(x => { console.log(x); return x })
        .then(setUserInfo);
    }, []);

    return <PagePaper>
        <Stack spacing={2}>
            {userInfo ? (
                <UserInfo
                    username={userInfo.attributes?.preferred_username || userInfo.username} avatar=""
                    groups={userInfo.signInUserSession.idToken.payload['cognito:groups']}
                    roles={userInfo.signInUserSession.idToken.payload['cognito:roles']}
                    email={userInfo.signInUserSession.idToken.payload.email}
                    auth_time={userInfo.signInUserSession.idToken.payload.auth_time}
                    exp={userInfo.signInUserSession.idToken.payload.exp}
                    sub={userInfo.signInUserSession.idToken.payload.sub}
                    preferredMFA={userInfo.preferredMFA}
                ></UserInfo>
            ) : (<p>Retrieving user info ...</p>)}
            <ThemeSelector />
        </Stack>
    </PagePaper>


}