import { Grid, Typography } from "@mui/material";
import { useCabledQuery } from "../../hooks/serviceRegistry/useCabledQuery";
import CWAlarmWidget from "../cwalarms/cwAlarmWidget";


const ChecksForEnv = ({
    envName, //mostro checks per questo ambiente
    checks, // mostro questi checks
    mini = false, // versione ridotta per landing page
    onClick = () => { }
}) => {

    console.log("CHECKS FOR ENV")
    if (mini) {
        return (
            <>
                {checks?.length && checks.map(({ alarms, name }) => (
                    <Grid item xs={12} sm={6} key={name}>
                        <CWAlarmWidget onClick={onClick} mini={true} svcName={'alarms_in_ItpaEnv' + envName} name={envName+"/"+name} alarms={alarms} baseQueryKey={['checks', envName, name]} />
                    </Grid>
                ))}

            </>
        )
    }
    return <Grid container spacing={1}>
        <Grid item xs={12}>
            <Typography variant="h6">Checks for {envName}</Typography>
        </Grid>
        {checks?.length && checks.map(({ alarms, name }) => (
            <Grid item xs={12} sm={6} key={name}>
                <CWAlarmWidget
                    onClick={onClick}
                    svcName={'alarms_in_ItpaEnv' + envName}
                    name={name}
                    alarms={alarms}
                    baseQueryKey={['checks', envName, name]} />
            </Grid>
        ))}

    </Grid>
}


export default ChecksForEnv;