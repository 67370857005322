import { Box, Button, IconButton, Stack, Typography } from "@mui/material";
import CWAlarmWidget from "../../components/cwalarms/cwAlarmWidget";
import { useContext, useState } from "react";
import { ServiceContext } from "../../hooks/serviceRegistry/serviceContext";
import ExcelIcon from "../../icons/excel";
import { MuiFileInput } from "mui-file-input";
import AttachFileIcon from '@mui/icons-material/AttachFile'
import { alertService } from "../../services/alerting/alerting";
import { useCabledQuery } from "../../hooks/serviceRegistry/useCabledQuery";
import { QuerySimpleStatus, SimpleStatus } from "../../components/simpleStatus/simpleStatus";
const alarms = [
    { alarmName: "stellantis-servicenow-state/NewTicketsHealth-Reads" }
];

const BUCKET = 'itpa-customer-stellantis';

function S3DownloadIcon({
    downloadingKey,
    designatedFilename,
    iconW = 50,
    iconH = 50
}) {
    iconW = parseInt(iconW, 10);
    iconH = parseInt(iconH, 10);
    const { getSvc } = useContext(ServiceContext);
    const s3 = getSvc("s3");

    const downloadXl = async () => {
        await s3.downloadFile({ bucket: BUCKET, key: downloadingKey, responseContentType: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", filename: designatedFilename })

    }

    return (<Box sx={{ width: "100%", height: "100%" }}>
        <IconButton sx={{ width: iconW + 'px', height: iconH + 'px' }} onClick={downloadXl}>
            <ExcelIcon w={iconW} h={iconH}></ExcelIcon>
        </IconButton>
    </Box>);
}


function TicketAssignmentDashboard() {

    const [myFile, setMyFile] = useState(null);
    const { getSvc } = useContext(ServiceContext);
    const s3 = getSvc("s3");
    const onFileChange = (x) => {
        setMyFile(x);
    }
    const resetFile = () => {
        setMyFile(null);
    }
    const uploadFile = async (file) => {

        let x = await s3.uploadFile({
            bucket: BUCKET,
            key: 'ticket_assignment/input_working_hours.xlsx',
            file: myFile
        });
        if (x) {
            alertService.success("File caricato correttamente");
        } else {
            alertService.error("File non caricato.");
        }
        setMyFile(null);

    };

    const loginLockedQuery = useCabledQuery({
        svcName: 'loginctrl',
        refetchInterval: 2 * 60 * 1000,
        baseQueryKey: ['stellantis', 'ticketAssignment', 'isLoginLocked'],
        method: 'doGet',
        args: {
            path: '/isLoginLocked/itpa_stellantis_servicenow_credentials'
        }
    });

    return (
        <>
            <Stack justifyContent="space-evenly" sx={{ height: '88vh' }}>
                <Box sx={{ height: '45%', m: 1, backgroundColor: "#ddd", borderRadius: '15px', boxShadow: '1px 1px 2px 2px' }}>
                    <Stack direction="row" justifyContent="space-evenly" sx={{ height: "100%" }}>
                        <Box sx={{ width: '50%', height: "100%" }}>
                            <Stack justifyContent="space-between" sx={{ height: "100%" }}>
                                <Typography variant="h6">
                                    Stato funzionamento bot lettura
                                </Typography>
                                <Box sx={{ flexGrow: 1 }} />
                                <Box sx={{ heigth: "50px", width: "90%", ml: 3 }}>
                                    <CWAlarmWidget
                                        svcName="cw"
                                        name="Bot service now"
                                        alarms={alarms}
                                        baseQueryKey={['stellantis ticket assignment bot alarm']} />
                                </Box>
                                <Box sx={{ flexGrow: 1 }} />
                            </Stack>
                        </Box>
                        <Box sx={{ width: '50%', height: "100%" }}>
                            <Stack justifyContent="space-between" sx={{ height: "100%" }}>
                                <Typography variant="h6">
                                    Stato blocco credenziali
                                </Typography>
                                <Box sx={{ flexGrow: 1 }} />
                                <Box sx={{ heigth: "50px", width: "90%", ml: 5 }}>
                                    <QuerySimpleStatus
                                        width="90%"
                                        height="50px"
                                        query={loginLockedQuery?.query}
                                        queryKey={loginLockedQuery?.queryKey}
                                        alias="Blocco credenziali"
                                        getStatus={queryData => !(queryData?.isLocked === true)}
                                    />
                                </Box>
                                <Box sx={{ flexGrow: 1 }} />

                            </Stack>
                        </Box>
                    </Stack>
                </Box>
                <Box sx={{ height: '45%', m: 1, backgroundColor: "#ddd", borderRadius: '15px', boxShadow: '1px 1px 2px 2px' }}>
                    <Stack direction="row" justifyContent="space-evenly" sx={{ height: "100%" }}>
                        <Box sx={{ width: '25%', height: "100%" }}>
                            <Stack justifyContent="space-between" sx={{ height: "100%" }}>
                                <Typography variant="h6">
                                    Scarica template excel orari
                                </Typography>
                                <S3DownloadIcon
                                    iconW={200} iconH={200}
                                    downloadingKey="ticket_assignment/tpl_input_working_hours.xlsx"
                                    designatedFilename="tpl_input_working_hours.xlsx"
                                />
                            </Stack>
                        </Box>
                        <Box sx={{ width: '25%', height: "100%" }}>
                            <Stack justifyContent="space-between" sx={{ height: "100%" }}>
                                <Typography variant="h6">
                                    Scarica attuale excel orari
                                </Typography>
                                <S3DownloadIcon
                                    iconW={200} iconH={200}
                                    downloadingKey="ticket_assignment/input_working_hours.xlsx"
                                    designatedFilename="input_working_hours.xlsx"
                                />
                            </Stack>
                        </Box>
                        <Box sx={{ width: '50%', height: "100%" }}>
                            <Stack justifyContent="space-between" sx={{ height: "100%" }}>
                                <Typography variant="h6">
                                    Carica nuovo excel orari
                                </Typography>
                                <Stack sx={{ height: "100%" }} justifyContent="space-evenly">
                                    <MuiFileInput InputProps={{
                                        inputProps: {
                                            accept: '*.xlsx'
                                        },
                                        startAdornment: <AttachFileIcon />
                                    }} placeholder="Scegli un file" value={myFile} onChange={onFileChange} sx={{ m: 2, width: '95%' }}></MuiFileInput>
                                    <Stack direction="row" justifyContent="space-evenly" sx={{ width: "100%" }}>
                                        <Button variant="outlined" onClick={uploadFile} disabled={!myFile}>Carica</Button>
                                        <Button variant="outlined" onClick={resetFile} color="error">Annulla</Button>
                                    </Stack>
                                </Stack>
                            </Stack>
                        </Box>
                    </Stack>
                </Box>
            </Stack>
        </>
    );
}


export default TicketAssignmentDashboard;