import { Grid } from "@mui/material";
import React from "react";
import './style.scss';




export default function DatiCanaliMq({
    data
}) {
    let dd = null;
    let d = data.find(x => x.data_name === "canalimq_check_data");
    if (d) {
        dd = JSON.parse(d.data_content);
    }

    return (
        <>  
            {dd === null ? ("La procedura non ha raccolto dati.") : (
                <Grid container spacing={2}>
                    {
                        Object.entries(dd).map(([k, v], itemI) => <React.Fragment key={itemI}>
                            <Grid item xs={6}>
                                {k}
                            </Grid>
                            <Grid item xs={6}>
                                {v === true ? 'OK' : 'KO'}
                            </Grid>
                        </React.Fragment>)
                    }
                </Grid>

            )}

        </>
    )

}
