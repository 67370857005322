import React from "react";
import { createHashRouter, createRoutesFromElements, Route } from "react-router-dom";
import Layout from "../components/layout/Layout";





import ChecksDashboard from "../pages/checks/checksDashboard";
import Dev1 from "../pages/development/dev1";
import Dev2 from "../pages/development/dev2";
import Dev3 from "../pages/development/dev3";
import ErrorPage from "../pages/errorPage";
import SystemStatusDashboard from "../pages/hub/systemStatusDashboard";
import OperatorIncidentDetails from "../pages/incidents/incidentDetail";
import OperatorIncidentsList from "../pages/incidents/incidentsList";
import Landing from "../pages/landing/landing";
import TasksDashboard from "../pages/tasks/tasksDashboard";
import User from "../pages/user/user";
import BperRoutes from "./bper";
import { TextB } from "./breadcrumbs";
import SalaMacchineRoutes from "./salamacchine";
import StellantisRoutes from './stellantis';
import UnicreditRoutes from './unicredit';
import AdminRoutes from './admin';
import { ServiceRegistryWrapper } from "../components/serviceRegistry/serviceRegistryWrapper";
import { getUserInfo } from "../services/aws/grants";
import MonitoraggioRisorse from "../pages/acme/monitoraggioRisorse";
import Controlli from "../pages/descartes/controlli";
import AuthorizedSection from "./authorizedSection";

//const keepParams = ({ params }) => params;


const evaluateServicesForChecks = async () => {
    let uI = await getUserInfo();
    let groups = uI.signInUserSession.idToken.payload['cognito:groups'];
    let out = [];
    for (let group of groups)
        if (group.startsWith("ItpaEnv"))    
            out.push(
                    { service: 'cw', role: group, label: 'alarms_in_'+group },
                )
    return out;
}


const router = (so) => createHashRouter(createRoutesFromElements(
    <Route path="/" element={<Layout so={so} />} errorElement={<ErrorPage />}>
        <Route element={<ServiceRegistryWrapper key="srw_checks" requiredServices={evaluateServicesForChecks} />} >
            <Route index element={<Landing />} />
            <Route path="tasks">
                <Route index element={<TasksDashboard />} handle={{ crumb: (data) => TextB(0, "Tasks") }} />
            </Route>
            <Route path="incidents">
                <Route index element={<OperatorIncidentsList />} handle={{ crumb: (data) => TextB(0, "Incidents") }} />
                <Route path=":problem_id" element={<OperatorIncidentDetails />} handle={{ crumb: (data) => TextB(0, "Incidents") }} />
            </Route>
            <Route path="checks">
                <Route index element={<ChecksDashboard />} handle={{ crumb: (data) => TextB(0, "Checks") }} />
            </Route>
            <Route path="systemstatus">
                <Route index element={<SystemStatusDashboard environment="SalaMacchine" />} handle={{ crumb: (data) => TextB(0, "Stato dei sistemi") }} />
            </Route>
            <Route element={<ServiceRegistryWrapper key="acme_service" requiredServices={[{service: "acme", role: "ItpaEnvAcme"}]}/>} path="acme">
                <Route index element={<MonitoraggioRisorse />} handle={{ crumb: (data) => TextB(0, "Monitoraggio Risorse") }} />
            </Route>
            
            <Route path="descartes" element={<AuthorizedSection env="ItpaEnvDescartes" />}>
                <Route element={<ServiceRegistryWrapper key="descartes_service" defaultRole="ItpaEnvDescartes_Role" requiredServices={[{service: "s3"}]}/>}>
                    <Route index element={<Controlli />} handle={{ crumb: (data) => TextB(0, "Controlli") }} />
                </Route>
            </Route>

            {StellantisRoutes}
            {UnicreditRoutes}
            {SalaMacchineRoutes}
            {BperRoutes}
            <Route path="user">
                <Route index element={<User />} handle={{ crumb: (data) => TextB(0, "Utente") }} />
            </Route>


            <Route element={<ServiceRegistryWrapper key="test_autenticazione_service" requiredServices={[{service: "agentservice", role: "Admin"}]}/>} path="dev">
                <Route index element={<Dev1 />} handle={{ crumb: (data) => TextB(0, "Dev1") }} />
                <Route path="dev2" element={<Dev2 />} handle={{ crumb: (data) => TextB(0, "Dev2") }} />
                <Route path="dev3" element={<Dev3 />} handle={{ crumb: (data) => TextB(0, "Dev3") }} />

            </Route>

        </Route>
        {AdminRoutes}
    </Route>
));
export default router;

