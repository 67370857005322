import { useMutation } from "@tanstack/react-query";
import ItpaApi from "../services/api";

const useCabledMutation = ({
    apiService, 
    method, 
    resetOnSuccess = true, 
    resetOnFailure = true,
    onSuccess = () => {},
    onFailure = () => {}
})=>{
    let resetOnSuccessTimeout = 0;
    if (resetOnSuccess === true){
        resetOnSuccessTimeout = 3000;
    }
    let resetOnFailureTimeout = 0;
    if (resetOnFailure === true){
        resetOnFailureTimeout = 3000;
    }
    console.log("Use cabled mutation",apiService,method)
    const service = ItpaApi.getService(apiService);
    const mutation = useMutation({
        mutationFn: async (pld) => {
            console.log("Mutation fn", pld);
            try {
                let result = await service[method].bind(service)(pld);
                if (!!resetOnSuccess){
                    setTimeout(()=>{
                        mutation.reset();
                    }, resetOnSuccessTimeout || resetOnSuccess);
                
                }
                return result;
            } catch (e) {
                
                if (!!resetOnFailure){
                    setTimeout(()=>{
                        mutation.reset();
                    }, resetOnFailureTimeout || resetOnFailure);
                
                }
                
                
                throw e;
    
            }
            
        },
        onSuccess: (data, variables, context) => {
            console.log("Cabled Mutation Succeeded",data,variables,context);
            onSuccess(data, variables, context);
        },
        onError: (error, variables, context) => {
            if (error instanceof Error && !!error.message)
            {
                error = JSON.parse(error.message);
            }
            console.log("Cabled Mutation Failed",error,variables,context);
            onFailure(error, variables, context);
        }

    });
    return mutation;
}
export default useCabledMutation;
