import ClearallIcon from '@mui/icons-material/ClearAll';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ErrorOutlinedIcon from '@mui/icons-material/ErrorOutlined';
import HomeIcon from '@mui/icons-material/Home';
import KeyIcon from '@mui/icons-material/Key';
import ListIcon from '@mui/icons-material/List';
import LogoutIcon from '@mui/icons-material/Logout';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
// import './sidebar.scss';

import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Collapse from '@mui/material/Collapse';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { Link } from 'react-router-dom';
import { AppContext } from '../../hooks/appContext';
import { useLocalStorage } from '../../hooks/useLocalStorage';
import { UserContext } from '../../hooks/userContext';
import Items from '../../menuDefinitions';
import { styled } from '@mui/material';


const drawerWidth = 240;

const ListMenuIcon = function ({ text, iconTag }) {
    const icons = {
        dashboard: DashboardIcon,
        querystats: QueryStatsIcon,
        alerts: ClearallIcon,
        home: HomeIcon,
        list: ListIcon,
        secret: KeyIcon,
        error: ErrorOutlinedIcon
    };
    if (iconTag && icons[iconTag]) {
        const It = icons[iconTag];
        return <It className="icon" />
    } else {
        return <Chip label={text[0]} />
    }

}
const StyledListItem = styled(ListItem,{
    shouldForwardProp: p => p!= "tier"
})(({theme, tier})=>({
    '& span': {
        textDecoration: 'none',
        color: theme.palette?.primary?.contrastText,
        fontWeight: tier === 0 ? 'bold': 'normal'
    }
}));

const MenuBlock = function ({ blockDefinition, open, onClick }) {
    return <React.Fragment>
        {blockDefinition.route ? (
            <StyledListItem component={Link} to={blockDefinition.route} >
                <Divider />
                <ListItemButton onClick={onClick}>
                    <ListItemText className="tier0" primary={blockDefinition.name}></ListItemText>
                    {blockDefinition.items?.length && (<>{open ? <ExpandLess /> : <ExpandMore />}</>)}
                </ListItemButton>
            </StyledListItem>
        ) : (
            <StyledListItem>
                <Divider />
                <ListItemButton onClick={onClick}>
                    <ListItemText className="tier0" primary={blockDefinition.name}></ListItemText>
                    {blockDefinition.items?.length && (<>{open ? <ExpandLess /> : <ExpandMore />}</>)}
                </ListItemButton>
            </StyledListItem>
        )}
        {blockDefinition.items?.length && (
            <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    {blockDefinition.items.map((ii, j) => {
                        const text = ii.name;
                        return (ii.external ?
                            <StyledListItem component="a" href={ii.route} key={j}>
                                <ListItemIcon>
                                    <ListMenuIcon text={text} iconTag={ii.iconTag} />
                                </ListItemIcon>
                                <ListItemText className="tier1" primary={text} />
                            </StyledListItem>
                            :
                            <StyledListItem component={Link} to={ii.route} key={j}>
                                <ListItemIcon>
                                    <ListMenuIcon text={text} iconTag={ii.iconTag} />
                                </ListItemIcon>
                                <ListItemText primary={text} />
                            </StyledListItem>
                        )
                    })}
                </List>
            </Collapse>
        )}
    </React.Fragment>
}



const MyDrawer = function ({ }) {
    const navigate = useNavigate();
    const { user, groups, signOut, allowedEnvs } = useContext(UserContext);
    const userIsAdmin = groups.includes("admin");
    const { currentEnvironment } = useContext(AppContext);
    const [open, setOpen] = useLocalStorage("desktop-menu-open", {});
    const toggleOpen = (i) => setOpen({ ...open, [i]: !open[i] });
    const handleClick = (tier, index) => tier === 0 ? toggleOpen(index) : {};
    React.useEffect(() => {
        const op = open;
        Items.forEach(i => op[i] = open[i] || false);
        setOpen(op);
    }, []);
    return (
        <Box>
            <Box justifyContent="center" sx={{ display: { xs: 'flex' }, width: "100%" }}>
                <IconButton
                    sx={{ display: { md: 'none' } }}
                    size="large"
                    edge="end"
                    color="inherit"
                    onClick={() => navigate('/user')}
                >
                    <Chip avatar={<Avatar>{user.username[0].toUpperCase()}</Avatar>} label={user.username} />
                </IconButton>

            </Box>
            <List
                component="nav"
                sx={{ width: '100%', maxWidth: 360 }}
            >
                {
                    Items.map((i, k) => {

                        if (i.env && !allowedEnvs.includes(i.env)) { return <React.Fragment key={k} /> }
                        if (i.groups && !groups.some(x => i.groups.includes(x))) { return <React.Fragment key={k} /> }
                        return <MenuBlock blockDefinition={i} key={k} open={open[k]} onClick={() => handleClick(0, k)} />

                    })
                }
                <ListItem sx={{ display: { md: "none" } }}>
                    {/* <Divider /> */}
                    <ListItemButton onClick={signOut} key="logout">
                        <ListItemIcon><LogoutIcon /></ListItemIcon>
                        <ListItemText primary="Logout"></ListItemText>
                    </ListItemButton>
                </ListItem>
            </List>
            <Box sx={{minHeight: '150px'}} />
        </Box>
    );
}


export function MobileDrawer({
    open,
    onToggleDrawer = ()=>{},
    window
}) {
    const container = window !== undefined ? () => window().document.body : undefined;
    
    return (
        <Drawer
            container={container}
            variant="temporary"
            open={open}
            onClose={onToggleDrawer}
            ModalProps={{
                keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
                
                
                display: { xs: 'block', lg: 'none' },
                '& .MuiDrawer-paper': { 
                    width: drawerWidth, 
                    mt: 8,
                    backgroundColor: 'background.main',
                },
            }}
        >
            <MyDrawer />
        </Drawer>
    );
}

export function DesktopDrawer({window}) {
    const container = window !== undefined ? () => window().document.body : undefined;
    return (
        <Drawer
            variant="permanent"
            sx={{
                mt: 40,
                display: { xs: 'none', lg: 'block' },
                '& .MuiDrawer-paper': { 
                    mt: 10,
                    boxSizing: 'border-box', 
                    width: drawerWidth,
                    backgroundColor: 'background.main',
                },
            }}

            open
        >
            <MyDrawer />

        </Drawer>
    );
}