import { useMutation } from "@tanstack/react-query";
import { useContext } from "react";
import { ServiceContext } from "./serviceContext";

const useCabledMutation = ({
    svcName,
    method,
    resetOnSuccess = true,
    resetOnFailure = true,
    onSuccess = () => { },
    onFailure = () => { }
}) => {
    const { getSvc } = useContext(ServiceContext); //viene preparato in ServiceRegistryWrapper
    const svc = getSvc(svcName);
    let resetOnSuccessTimeout = 0;
    if (resetOnSuccess === true) {
        resetOnSuccessTimeout = 3000;
    }
    let resetOnFailureTimeout = 0;
    if (resetOnFailure === true) {
        resetOnFailureTimeout = 3000;
    }

    const mutationFn = async (pld) => {
        try {
            let result = await svc[method].bind(svc)(pld);
            if (!!resetOnSuccess) {
                setTimeout(() => {
                    mutation.reset();
                }, resetOnSuccessTimeout || resetOnSuccess);

            }
            return result;
        } catch (e) {

            if (!!resetOnFailure) {
                setTimeout(() => {
                    mutation.reset();
                }, resetOnFailureTimeout || resetOnFailure);

            }
            throw e;
        }

    };
   

    const mutation = useMutation({
        mutationFn: mutationFn,
        onSuccess: (data, variables, context) => {
            onSuccess(data, variables, context);
        },
        onError: (error, variables, context) => {
            if (error instanceof Error && !!error.message) {
                try {
                    error = JSON.parse(error.message);
                } catch (e) {
                    error = error.message;
                }
            }
            onFailure(error, variables, context);

        }

    });
    return mutation;
}
export default useCabledMutation;
