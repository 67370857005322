import { Button, Stack, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useInfiniteQuery, useQueryClient } from "@tanstack/react-query";
import ItpaApi from "../../services/api";
import { elapsed } from "../../services/common/common";
import { useExecutions } from "../../hooks/executions";


const Title = ({ text }) => <Typography sx={{ fontWeight: 'bold' }}>{text}</Typography>
const Value = ({ text }) => <Typography sx={{ fontStyle: 'italic' }}>{text}</Typography>
const MyLink = ({ text, target }) => <Typography sx={{ fontStyle: 'italic' }}>
    <a href={target} target="_blank">{text}</a>
</Typography>


const FlowExecutionList = ({ flowName, executionsQuery, onCellDoubleClick = () => { } }) => {

    const queryClient = useQueryClient();
    
    const renderDateCell = (row, cell) => (
        <div style={{ textAlign: "center" }}>
            {row[cell] ? elapsed(new Date(row[cell])) : ""}
        </div>

    );

    const columns = [
        { field: 'alias', headerName: 'Name', flex: 2 },
        { field: 'start_date', headerName: 'Start', flex: 1, renderCell: ({ row }) => renderDateCell(row, 'start_date') },
        { field: 'end_date', headerName: 'End', flex: 1, renderCell: ({ row }) => renderDateCell(row, 'end_date') },
        { field: 'status', headerName: 'Status', flex: 1 }
    ];

    const flattenExecutionPages = (data) => {
        let flattened = data.pages.reduce((p, c) => [...p, ...c.data], []);
        return flattened.map(d => Object.assign(d, { id: d.name }));
    }
   



    return (
        <Stack sx={{ height: '90%' }}>
            <div style={{ height: '90%' }}>
                { executionsQuery.data && (<DataGrid
                    rowHeight={30}
                    rows={flattenExecutionPages(executionsQuery.data)}
                    columns={columns}
                    // loading={status === 'loading'}
                    onCellDoubleClick={onCellDoubleClick}
                ></DataGrid>


                )}
            </div>
            <div style={{ height: '10%' }}>
                {/* {hasNextPage ? <Button color="primary" onClick={() => fetchNextPage()}>Carica altri</Button> : <></>} */}
            </div>
        </Stack>
    )



};

export default FlowExecutionList;