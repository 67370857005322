import { APIServiceError, getJwt } from "./common/common";
import { SigV4ApiClient } from "./aws/sigV4api";


class ItpaAPI {

    constructor(){
        this.serviceMap = {};
    }
    setSigV4Service(serviceName, {role,region,endpoint}){
        this.serviceMap[serviceName] = new SigV4ApiClient({role,region,endpoint})
    }
    setService(serviceName,service){
        this.serviceMap[serviceName] = service;
        return this;
    }
    getService(serviceName){
        if (this.serviceMap[serviceName]){
            return this.serviceMap[serviceName];
        } else {
            throw new Error("Undefined service "+serviceName);
        }
    }
}

export class ApiClient {
    constructor(baseUrl) {
        if (typeof baseUrl === 'object')
            this.baseUrl = baseUrl.baseUrl;
        else if (typeof baseUrl === 'string')
            this.baseUrl = baseUrl;
        else
            throw new Error("Invalid baseUrl");
    }

    async do_get(url, filters={}, pagination={}, qs={}) {
        let res;
        if (filters){
            qs.filters = this.encode_qs(filters);
        }
        if (pagination){
            qs.pagination = this.encode_qs(pagination);
        }
        let qss = new URLSearchParams(qs).toString();
        try {
            res = await fetch(this.baseUrl + url+`?${qss}`, { mode: 'cors', headers: { Authorization: (await getJwt()) } });
        } catch (e) {
            throw new APIServiceError(res.status,e.toString());
        }
        if (!res.ok) {
            throw new APIServiceError(res.status,await res.text())
        }
        const data = await res.json();
        // console.log("DATA for", url);
        // console.log(data);
        return data;
    }
    async do_post(url, body) {
        let res;
        try {
            res = await fetch(this.baseUrl + url, { body:JSON.stringify(body), method: 'POST', mode: 'cors', headers: { 'content-type':'application/json', Authorization: (await getJwt()) } });
        } catch (e) {
            throw new APIServiceError(res.status,e.toString());
        }
        if (!res.ok) {
            throw new APIServiceError(res.status,await res.text());
        }
        const data = await res.json();
        // console.log("DATA for", url);
        // console.log(data);
        return data;
    }
    async do_put(url, body) {
        let res;
        try {
            res = await fetch(this.baseUrl + url, { body:JSON.stringify(body), method: 'PUT', mode: 'cors', headers: { 'content-type':'application/json', Authorization: (await getJwt()) } });
        } catch (e) {
            throw new APIServiceError(res.status,e.toString());
        }
        if (!res.ok) {
            throw new APIServiceError(res.status,await res.text());
        }
        const data = await res.json();
        // console.log("DATA for", url);
        // console.log(data);
        return data;
    }
    async do_delete(url, body) {
        let res;
        try {
            res = await fetch(this.baseUrl + url, { method: 'DELETE', mode: 'cors', headers: { Authorization: (await getJwt()) } });
        } catch (e) {
            throw new APIServiceError(res.status,e.toString());
        }
        if (!res.ok) {
            throw new APIServiceError(res.status,await res.text());
        }
        const data = await res.json();
        // console.log("DATA for", url);
        // console.log(data);
        return data;
    }
    async do_get_all_pages(url,filters={}){
        let nt = null;
        let pageCount = 0;
        let output = [];
        while (pageCount++ < 100){
            let pagination = ({next_token: nt});
            const page = await this.do_get(url,filters,pagination);
            nt = page.next_token;
            output.push(...page.data);
            if (!page.data.length || !page.next_token) break;
        }
        return output;
    }
    encode_qs(qsDict) {
        let qsString = JSON.stringify(qsDict);
        let qsEncoded = btoa(qsString);
        return qsEncoded;
    }
}


const ItpaApi = new ItpaAPI();
export default ItpaApi;