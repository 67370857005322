import { Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";


export class MyLocation {

    constructor(){
        this.breadcrumbs = [];
    }
    reset(){
        this.breadcrumbs = [];
        return this;
    }
    addText(text){
        this.breadcrumbs.push(<Typography key={this.breadcrumbs.length} style={{ fontSize: 14 }} noWrap>{text}</Typography>)
        return this;
    }
    addLink(text,link){
        this.breadcrumbs.push(
            <Link underline="hover" style={{ fontSize: 14, color: 'inherit' }} key={this.breadcrumbs.length} to={link}>{text}</Link>)
        return this;
    }
    
}

export const MyLoc = React.createContext();