import { useInfiniteQuery, useQuery, useQueryClient } from '@tanstack/react-query';
import ItpaApi from '../services/api';



export function useCabledQuery({
    apiService, method, refetchInterval = 60 * 1000, baseQueryKey, filters, enabled=true
}) {

    const queryClient = useQueryClient();

    const service = ItpaApi.getService(apiService);
    const queryKey = (Array.isArray(baseQueryKey) ? baseQueryKey : [baseQueryKey]);
    if (filters) queryKey.push(filters);
    const query = useQuery({
        queryFn: async () => service[method].bind(service)(filters),
        queryKey,
        refetchInterval,
        staleTime: parseInt(refetchInterval / 2), 
        enabled
    });
    window.QC = queryClient;
    return { query, queryKey };
}

export function useInfiniteCabledQuery({
    apiService, method, refetchInterval = 60 * 1000, baseQueryKey, filters, enabled=true
}) {

    const queryClient = useQueryClient();

    const service = ItpaApi.getService(apiService);
    const queryKey = (Array.isArray(baseQueryKey) ? baseQueryKey : [baseQueryKey]).concat([filters]);
    const query = useInfiniteQuery({
        queryFn: async () => service[method].bind(service)(filters),
        queryKey,
        refetchInterval,
        staleTime: parseInt(refetchInterval / 2),
        getNextPageParam: (lastPage, pages) => lastPage.data ? lastPage.next_token : null, 
        enabled
    });
    return { query, queryKey };
}


// export function useQueryyyy({queryKey}){
//     const queryClient = useQueryClient();
//     const query = queryClient.getQueriesData({queryKey})
// }

