import { Box, Stack, Typography } from '@mui/material';
import 'dayjs/locale/it';
import React, { useContext, useState } from 'react';
import { AppContext } from '../../hooks/appContext';
import useCabledMutation from '../../hooks/useCabledMutation';
import { useCabledQuery } from '../../hooks/useCabledQuery';
import { alertService } from '../../services/alerting/alerting';
import ItpaApi from '../../services/api';
import { queryAwaiter } from '../../services/common/common';
import OpinionatedForm from '../forms/opinionatedForm';
import { useQueryClient } from '@tanstack/react-query';
const AdminRemoveIncidentType = ({
    problemType,
    onSuccess = () => { },
    onCancel = () => { }
}) => {
    const { currentEnvironment } = useContext(AppContext);
    const queryClient = useQueryClient();
    const [formValues, setFormValues] = useState({});
    const moniServ = ItpaApi.getService("monitoring");


    const deleteIncidentTypeMutation = useCabledMutation({
        apiService: 'monitoring',
        method: 'deleteProblemType',
        onSuccess: (result) => {
            alertService.success("Incident type deleted");
            return onSuccess();
        },
        onFailure: (e) => {
            alertService.error("Cannot delete incident type")
        }
    })
    const buttons = [
        { name: "Cancel", onClick: () => onCancel(), color: "neutral" },
        {
            name: "Delete", onClick: () => {
                let payload = { environment: currentEnvironment, problemType: problemType};
          
                
                let x = deleteIncidentTypeMutation.mutate(payload);
                
            }, color: "error"
        }
    ];
    return (
        <Stack spacing={2} sx={{ minWidth: "25vw" }} alignItems="center">
            <Box sx={{ height: "10vh" }} />
            <Typography variant="h6" component="div" gutterBottom>Delete incident type</Typography>
            <Typography variant="h5" component="div" gutterBottom>{problemType}</Typography>
            <Typography variant="body2" component="div" gutterBottom>
                Are you sure you want to delete this incident type?<br />
                This action is irreversible.
            </Typography>
            <OpinionatedForm fields={[]} buttons={buttons} formValues={formValues} setFormValues={setFormValues}/>
        </Stack>

    )
}



export default AdminRemoveIncidentType