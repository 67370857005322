import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import PagePaper from "../../components/page/pagePaper";
import { QueryLinearProgress } from "../../components/queries/queryLinearProgress";
import { useCabledQuery } from "../../hooks/serviceRegistry/useCabledQuery";
import { fullDateStr } from "../../services/common/common";



class UnicreditLogin {
    constructor(category, timestamp, expiration, loginStatus) {
        this.id = category + timestamp.toString();
        this.category = category;
        this.timestamp = timestamp;
        this.timestamp_date = new Date(timestamp * 1000);
        this.expiration_date = new Date(expiration * 1000);
        this.loginStatus = loginStatus;
    }
    static from_dynamo_record(record) {
        return new UnicreditLogin(
            record.category.S,
            parseInt(record.timestamp.N),
            parseInt(record.expiration.N),
            record.loginStatus.S

        )
    }
}




/**
 * 
 * Dashboard con l'elenco dei login su Unicredit
 */
export default function UnicreditLogins() {


    const [logins, setLogins] = useState([]);

    const { query: oQuery, queryKey: oqueryKey } = useCabledQuery({
        svcName: "ddb",
        method: 'scanTable',
        baseQueryKey: 'ucrlogins',
        args: {
            tableName: 'ucr.loginregister'
        },
        refetchInterval: 2 * 60 * 1000,
        enabled: true
    })

    const statusMap = {
        'REQUESTED': 'Iniziato e mai terminato',
        'SUCCEEDED': 'Completato',
        'FAILED': 'Annullato o fallito'
    };

    const columns = [
        { field: 'category', headerName: 'Sito', flex: 1 },
        {
            field: 'timestamp_date', headerName: 'DataOra', flex: 1,
            valueGetter: (p) => fullDateStr(p.row.timestamp_date)
        },
        { field: 'loginStatus', headerName: 'Stato', flex: 1, valueGetter: p => statusMap[p.row.loginStatus] || 'Sconosciuto' }
    ];

    useEffect(() => {
        var reports = oQuery?.data?.map((d) => UnicreditLogin.from_dynamo_record(d)) || [];
        reports.sort((a, b) => a.timestamp < b.timestamp ? 1 : -1);
        setLogins(reports);
    }, [oQuery.data]);

    return (
        <>
            <PagePaper height="90vh">
                <QueryLinearProgress queryKey={oqueryKey} query={oQuery} />
                <div style={{ width: "100%", height: "100%" }}>
                    <DataGrid
                        pageSize={20}
                        rowHeight={30}
                        rows={logins}
                        columns={columns}
                    ></DataGrid>
                </div>
            </PagePaper>
        </>
    )
}