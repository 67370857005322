import AccountTreeOutlinedIcon from '@mui/icons-material/AccountTreeOutlined';
import AssignmentTurnedInOutlinedIcon from '@mui/icons-material/AssignmentTurnedInOutlined';
import LastPageOutlinedIcon from '@mui/icons-material/LastPageOutlined';
import PersonIcon from '@mui/icons-material/Person';
import StartOutlinedIcon from '@mui/icons-material/StartOutlined';
import { Box, Divider, Grid, Menu, MenuItem, Paper, Stack, Typography, styled } from "@mui/material";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import Markdown from "markdown-to-jsx";
import { useContext, useState } from "react";
import { useOperatorTask } from "../../hooks/operatorTasks";
import { UserContext } from "../../hooks/userContext";
import ItpaApi from "../../services/api";
import { fullDateStr } from "../../services/common/common";
import NewTaskComment from "./newTaskComment";
import TaskHistoryTimeline from "./taskHistory";
import TaskSolutions from "./taskSolutions";
import TaskComments from "./tasksComments";
const SeparatorGrid = styled(Grid, {

})(({ theme }) => ({
    minHeight: "30px"
}));
const SectionGrid = styled(Grid, {

})(({ theme }) => ({
    // border: "1px solid black", borderRadius: "15px", padding: theme.spacing(1), boxShadow: "2px 2px"
}));


const SideIconContainer = styled(Stack)(({theme})=>({
    boxShadow: theme.shadows[1], 
    p: 1, 
    borderRadius: '5px', 
    border: '1px solid black',
    ":hover": {
        boxShadow: theme.shadows[3], 
    },
    minHeight: "80px"
}));

const SideIcon = ({ Icon, comment }) => {
    return (
        <SideIconContainer justifyContent="space-around" alignItems="center" alignContent="center">

            <Icon sx={{ height: "20px", width: "20px" }} />
            <Typography variant="comment">{comment}</Typography>
        </SideIconContainer>
    );
}

const FlowLinkSideIcon = ({ execution }) => {
    let title = "";
    if (!execution || execution === "NO_FLOW")
        title = "Attività libera"
    else {
        title = "Fa parte di " + execution;
    }
    return (
        <SideIconContainer justifyContent="space-around" alignItems="center" alignContent="center" sx={{ p: 1, borderRadius: '5px', border: '1px solid black' }}>

            <AccountTreeOutlinedIcon sx={{ height: "20px", width: "20px" }} />
            <Typography variant="comment">{title}</Typography>
        </SideIconContainer>
    );
}

const UserAssigneeSideIcon = ({ user, onTakeCharge = () => { }, onRelease = () => { } }) => {

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const { sub, user: currentUser } = useContext(UserContext);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    let rilasciaEnabled = false;
    let prendiInCaricoEnabled = true;
    let title = "Non assegnata";
    if (user === sub || user === "user::" + sub || user === currentUser.username || user === "user::" + currentUser.username) {
        title = "Assegnata a me";
        rilasciaEnabled = true;
        prendiInCaricoEnabled = false;
    } else if (user !== null) {
        title = "Assegnata ad altri";
    }

    return (
        <>
            <SideIconContainer
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick} justifyContent="space-around" alignItems="center" alignContent="center" sx={{ p: 1, borderRadius: '5px', border: '1px solid black' }}>

                <PersonIcon sx={{ height: "20px", width: "20px" }} />
                <Typography variant="comment">{title}</Typography>
            </SideIconContainer>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                {rilasciaEnabled && <MenuItem onClick={() => { onRelease(); handleClose(); }}>Non me ne occupo più</MenuItem>}
                {prendiInCaricoEnabled && <MenuItem onClick={() => { onTakeCharge(); handleClose(); }}>Prendo in carico</MenuItem>}
            </Menu>
        </>
    );
}


const DetailsSectionTitleText = styled(Typography)(({ theme }) => ({
    color: 'text.secondary',
    display: 'block',
    fontSize: '14px',
    fontWeight: "bolder"
}));
const DetailsSectionTitle = ({ title, onClick = () => { } }) => {
    return (
        <>
            <SeparatorGrid item xs={12} />
            <Grid item xs={3} sm={4} md={5} sx={{ display: { xs: 'block', lg: 'none' } }}>
                <Divider sx={{ pt: 2 }} />
            </Grid>
            <Grid item xs={6} sm={4} md={2} lg={1} sx={{ alignContent: 'flex-start', display: 'flex' }} onClick={onClick}>
                <DetailsSectionTitleText>{title.toUpperCase()}&nbsp;&nbsp;</DetailsSectionTitleText>
            </Grid>
            <Grid item xs={3} sm={4} md={5} lg={10}>
                <Divider sx={{ pt: 2, ml: 2 }} />
            </Grid>
        </>
    );
}


const TodoDetails = styled(Paper, {
    shouldForwardProp: p => p != "severity"
})(({ theme, severity }) => {
    return {
        backgroundColor: theme.palette.severitiesLighter[parseInt(severity)] || '#fff',
        width: "100%",
        position: 'relative',
        ...theme.typography.body2,
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        backdropFilter: 'blur(20px)',
        '& .MuiPaper-root': {
            margin: '10px',
            width: "95%"
            // backgroundColor: theme.palette.severitiesLight[parseInt(severity)] || '#fff',

        }
    }
});

const DescriptionText = ({ text }) => {
    let txt = text.replace(/\\n/g, "\n");
    return <Box sx={{ p: 1, textAlign: "left", wordWrap: true }}>
        <Markdown>
            {txt}
        </Markdown>
    </Box>

}

export default function TaskDetails({

    severity,
    environment,
    id,
    assignee,
    onChange = () => { }
}) {
    const { sub } = useContext(UserContext);
    const [displayComments, setDisplayComments] = useState(false);
    const [displayDescription, setDisplayDescription] = useState(false);
    const [displayHistory, setDisplayHistory] = useState(false);
    const [displaySolutions, setDisplaySolutions] = useState(false);
    const detailsQuery = useOperatorTask({ environment, id });
    const opMoniServ = ItpaApi.getService('operator-monitoring');
    const queryClient = useQueryClient();
    const assignmentMutation = useMutation({
        mutationFn: async () => {
            try {
                opMoniServ.taskTakeCharge({ environment, id });
                queryClient.invalidateQueries(detailsQuery.key);
            } catch (e) {
                console.error(e);
            }
            setTimeout(() => assignmentMutation.reset());
        }
    });
    const unassignmentMutation = useMutation({
        mutationFn: async () => {
            try {
                opMoniServ.releaseTaskAssignment({ environment, id });
                queryClient.invalidateQueries(detailsQuery.key);
            } catch (e) {
                console.error(e);
            }
            setTimeout(() => unassignmentMutation.reset());
        }
    });
    const addNoteMutation = useMutation({
        mutationFn: async ({ content }) => {
            try {
                console.log(1);
                opMoniServ.putTaskNote({ environment, id, content });
                console.log(2);
                queryClient.invalidateQueries(detailsQuery.key);
            } catch (e) {
                console.error(e);
            }
            console.log(3);
            setTimeout(() => { addNoteMutation.reset(); console.log(5); });
            console.log(4);
        }
    });
    const collapseAll = () => {
        setDisplayComments(false);
        setDisplayDescription(false);
        setDisplayHistory(false);
        setDisplaySolutions(false);
    }

    const handleChangeOnDetails = () => {
        collapseAll();
        setTimeout(() => {
            queryClient.invalidateQueries(detailsQuery.key);
            onChange();
        })
    }





    return (
        <TodoDetails severity={severity} elevation={3}>
            {!detailsQuery.data && <p>Loading...</p>}
            {!!detailsQuery.data &&
                <Stack direction="row" sx={{width: "100%"}} spacing={1}>


                    <Grid container spacing={1} sx={{width: `calc(100%-100px)`}}>
                        {/* blocco descrizione */}

                        {/* <DetailsSectionTitle title="Decrizione" /> */}
                        <SectionGrid item xs={12}>
                            <DescriptionText text={detailsQuery.data.meta.description_long || "*Non è stata fornita una descrizione*"}></DescriptionText>
                        </SectionGrid>

                        {/* blocco soluzioni */}
                        {/* <DetailsSectionTitle title="Risolvi" /> */}
                        <Grid item xs={12}>
                            <TaskSolutions {...{ environment, id }} onChange={handleChangeOnDetails} />
                        </Grid>

                        {/* blocco commenti */}

                        <DetailsSectionTitle title="Commenti" onClick={() => setDisplayComments(!displayComments)} />
                        <Grid item xs={12} >
                            {displayComments && <>
                                <NewTaskComment onAddNote={addNoteMutation.mutate} />
                            </>
                            }
                        </Grid>
                        <Grid item xs={12} >
                            {displayComments && <>
                                <TaskComments notes={detailsQuery.data.notes || []} />
                            </>
                            }
                        </Grid>

                        {/* blocco storia */}
                        <DetailsSectionTitle title="Storia" onClick={() => setDisplayHistory(!displayHistory)} />
                        <Grid item xs={12}>

                            {displayHistory && (
                                <TaskHistoryTimeline history={detailsQuery.data.history} />
                            )}
                        </Grid>
                    </Grid>
                    <Stack spacing={2} justifyContent="space-evenly" alignContent="center" sx={{ width: '90px',display:'block', overflow:'auto'}}>
                        <SideIcon Icon={AssignmentTurnedInOutlinedIcon} comment={detailsQuery.data.display_name} />
                        <SideIcon Icon={StartOutlinedIcon} comment={fullDateStr(detailsQuery.data.iat)} />
                        <SideIcon Icon={LastPageOutlinedIcon} comment={!!detailsQuery.data.eat ? fullDateStr(detailsQuery.data.eat) : ("Nessuna scadenza")} />
                        <FlowLinkSideIcon execution={detailsQuery.data.execution} />
                        <UserAssigneeSideIcon user={assignee} onTakeCharge={() => assignmentMutation.mutate()} onRelease={() => unassignmentMutation.mutate()} />
                    </Stack>


                </Stack>
            }
        </TodoDetails>
    )
}