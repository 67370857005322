import React, { isValidElement } from 'react';
import { Button, TextAreaField } from "@aws-amplify/ui-react";
import { Box, Divider, FormControl, FormGroup, FormHelperText, Grid, InputLabel, MenuItem, Select, Stack, TextareaAutosize, TextField, Typography } from "@mui/material";
import cronstrue from 'cronstrue/i18n';
import TextInputField from '../forms/text';
import { fullDateStr } from '../../services/common/common';
import InDeck from '../queries/indeck';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import dayjs from 'dayjs';
import 'dayjs/locale/it';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import ItpaApi from '../../services/api';
import { alertService } from '../../services/alerting/alerting';
import { useSchedulesRule } from '../../hooks/schedules';
import { retry } from '@aws-amplify/core';
const cronparser = require('cron-parser');
dayjs.locale("it");

function CronToString(cron_expression) {
    try {
        return cronstrue.toString(cron_expression, { locale: "it", verbose: true });
    } catch (e) {
        return "Invalid: " + e.toString();
    }
}

const RuleExceptions = ({
    name,
    group,
    initialExceptions = [],
    onChange = () => { },
    onDismiss = () => { }
}) => {
    const ruleQuery = useSchedulesRule({ group, name, enabled: true });
    const [exceptions, setExceptions] = React.useState([]);
    React.useEffect(() => {
        let exc = [];
        if (!ruleQuery || !ruleQuery.data) return;
        console.log(ruleQuery.data);
        for (let e of ruleQuery?.data?.exceptions) {
            exc.push(e);
        }
        setExceptions(exc)
    }, [ruleQuery.isFetching]);

    const removeException = (excIndex) => {
        setExceptions(exceptions.filter((e, i) => i !== excIndex));
    }
    const addException = () => {
        setExceptions([...exceptions, { date_from: dayjs(), date_to: dayjs() }]);
    }
    const moniServ = ItpaApi.getService("monitoring");
    const queryClient = useQueryClient();
    const applyChangesMutation = useMutation({
        mutationFn: async () => {
            try {
                await moniServ.updateScheduleExceptions({ group, name, exceptions });
                onChange();
                onDismiss()
                console.log("Pubblico", exceptions);
            } catch {
                alertService.error("Cannot update exceptions");
            }
        }
    });
    const applyDate = (excIndex, whichDate, newValue) => {
        setExceptions(exceptions.map((e, i) => (i === excIndex ? Object.assign(e, { [whichDate]: newValue }) : e)));
    }


    const isValid = (e) => e.date_from >= e.date_to;

    return ruleQuery?.isFetching ? (<Box>Loading...</Box>) : (
        <Box width="45vw">
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="it">
                <Stack width="98%" height="95vh" justifyContent="flex-start" spacing={2} sx={{ mt: 4 }}>

                    <Typography variant="h4">Eccezioni regola</Typography>
                    <Typography variant="h5">{group} / {name}</Typography>
                    <Typography variant="subtitle1">Durante questi periodi la regola non produce schedulazioni. <br />Tutti gli orari sono sul fuso orario locale.</Typography>
                    <Divider />
                    <Box sx={{ flexGrow: 1 }}></Box>
                    <Grid container spacing={2}>
                        {!exceptions.length ? (
                            <Grid item xs={12}><Typography variant="h5">Nessuna eccezione prevista</Typography></Grid>
                        ) : (
                            <>
                            </>
                        )}
                        <Grid item xs={5}>DA</Grid>
                        <Grid item xs={5}>A</Grid>
                        <Grid item xs={2}></Grid>
                        {exceptions.map((exc, excI) => <React.Fragment key={excI} >
                            <Grid item xs={5}>
                                <DateTimePicker
                                    ampm={false}
                                    format="DD-MM-YYYY hh:mm:ss"
                                    label="Da"
                                    value={exc.date_from}
                                    onChange={(newValue) => applyDate(excI, "date_from", newValue)}
                                    renderInput={(params) => <TextField size="small" {...params} />}
                                />
                            </Grid>
                            <Grid item xs={5}>
                                <DateTimePicker
                                    ampm={false}
                                    format="DD-MM-YYYY hh:mm:ss"
                                    label="a"
                                    value={exc.date_to}
                                    onChange={(newValue) => applyDate(excI, "date_to", newValue)}
                                    renderInput={(params) => <TextField size="small" {...params} />}
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <InDeck
                                    removeItem={() => removeException(excI)}
                                >

                                </InDeck>
                            </Grid>
                        </React.Fragment>

                        )}
                        <Grid item xs={5}></Grid>
                        <Grid item xs={5}></Grid>
                        <Grid item xs={2}><InDeck newItem={addException}></InDeck></Grid>
                    </Grid>
                    <Box sx={{ flexGrow: 1 }}></Box>


                    <FormGroup sx={{ width: '95%' }}>
                        <Button onClick={() => applyChangesMutation.mutate()} variant="outlined" color="error" sx={{ mt: 2 }}>Applica modifiche</Button>
                    </FormGroup>
                </Stack>
            </LocalizationProvider>



        </Box >
    )

};


export default RuleExceptions