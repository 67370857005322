import { useQuery, useQueryClient } from '@tanstack/react-query';
import ItpaApi from '../services/api';

export function useFlows({environment=null}={}) {
    //const [isOnline, setIsOnline] = useState(null);
    const env = environment || "*"
    const monServ = ItpaApi.getService("monitoring");

    const refetchInterval = 2 * 60 * 1000;
    const queryKey = ['all_flows', {environment:env}];
    const query = useQuery({
        queryFn: async () => monServ.listAllFlows.bind(monServ)({ environment: env }),
        queryKey: queryKey,
        refetchInterval: refetchInterval,
        staleTime: parseInt(refetchInterval/2)
    });    
    
    return {query,refetchInterval,queryKey};
}

export function useFlow({ flowName, environment }={}) {

    const moniServ = ItpaApi.getService("monitoring");
    const refetchInterval = 5 * 60 * 1000;

    const fetchFlow = async (flowName) => {
        let data = await moniServ.getFlow.bind(moniServ)({ flowName, environment });
        return data;
    }

    const flowQuery = useQuery({
        queryKey: ['flow', environment, flowName],
        queryFn: () => fetchFlow(flowName),
        refetchInterval
    });

    

    return {flowQuery,refetchInterval};

}


