import { Route } from "react-router-dom";
import BperProcessReport from "../pages/bper/report";
import AuthorizedSection from "./authorizedSection";
import { TextB } from "./breadcrumbs";
import { ServiceRegistryWrapper } from "../components/serviceRegistry/serviceRegistryWrapper";

const routes = [
    <Route path="bper" element={<AuthorizedSection env="Bper" />}>
        <Route element={<ServiceRegistryWrapper defaultRole="ItpaEnvBper" requiredServices={[
            {service: 's3'}
        ]} /> }>
        <Route index element={<BperProcessReport />} handle={{ crumb: (data) => TextB(0, "Bper") }} />
        </Route>
    </Route>
];
export default routes;