import { Grid, Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";



const Specchietto = ({
    entity,
    fields = null
}) => {
    /**
     * Mostra uno specchietto riassuntivo delle proprietà di un oggetto.
     * Fields identifica quali campi mostrare e in che ordine.
     */


    let FFF = [];
    if (fields === null) {
        FFF = Object.keys(entity);
    } else {
        FFF = fields;
    }

    // let CELLS = [];
    // let rowIndex = 0;
    // while (true){
    //     let found = false;
    //     for (let col of FFF){
    //         if (col.length >=rowIndex+1){
    //             CELLS.push([col[rowIndex],entity[col[rowIndex]]]);
    //             found = true;
    //         } else {
    //             CELLS.push(["",""]);
    //         }
    //     }
    //     if (found) rowIndex++;
    //     else break;
    // }

    return (
        <Stack direction="row" justifyContent="space-evenly" spacing={2} width="100%">
            {
                FFF.map((field, CI) => <Box sx={{
                    p: 1, m: 1, bgcolor: 'grey'
                }} key={CI}>
                    <Typography variant="caption" style={{ position: "relative", left: 1, top: 1 }}>{field}</Typography>
                    <Typography variant="h5">{entity[field]}</Typography>
                </Box>)
            }
        </Stack>

    )

}
export default Specchietto;