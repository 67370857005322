import CachedOutlinedIcon from '@mui/icons-material/CachedOutlined';
import { Box, IconButton, List, ListItem, ListItemText, Stack, Typography, styled } from "@mui/material";
import dayjs from "dayjs";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../hooks/appContext";
import { ServiceContext } from '../../hooks/serviceRegistry/serviceContext';
import { useCabledQuery } from '../../hooks/serviceRegistry/useCabledQuery';
import ExcelIcon from "../../icons/excel";
import LogsIcon from "../../icons/logs";
import { alertService } from '../../services/alerting/alerting';
import { fullDateStr, hourMinuteDateStr } from "../../services/common/common";
function findLastStatusTimestamp(arr) {
    for (let i = arr.length - 1; i >= 0; i--) {
        const str = arr[i];
        if (str.endsWith("OK")) {
            const timestampStr = str.substr(0, 19);
            return { date: dayjs(timestampStr).toDate(), outcome: 'OK' };
        } else if (str.endsWith("KO")) {
            const timestampStr = str.substr(0, 19);
            return { date: dayjs(timestampStr).toDate(), outcome: 'KO' };
        }
    }
    return null; // se non viene trovata nessuna stringa con "OK" o "KO"
}

const CONFIG = {
    // xlLambda: "arn:aws:lambda:eu-west-1:745905534821:function:Teams-function",
    xlLambda: "arn:aws:lambda:eu-west-1:745905534821:function:GucciState",
    bucketName: 'itpa-customer-gucci',
    queryRefetchInterval: 10 * 60 * 1000
};


export default function GucciNightProcessReport({ s3_pointer }) {


    const {getSvc} = useContext(ServiceContext);
    const s3Svc = getSvc("s3");
    const lambdaSvc = getSvc("lambda");
    const [desc, setDesc] = useState(null);
    const [err, setErr] = useState(null);
    const [logs, setLogs] = useState(null);
    const [mails, setMails] = useState(null);
    const [lastOutcome, setLastOutcome] = useState(null);
    const [xlMile, setXlMile] = useState(0);

    const generateXl = async () => {
        let result = null;
        try {
            result = await lambdaSvc.invoke({
                arnOrName: CONFIG.xlLambda,
                payload: { data: s3_pointer.dt }
            });
            if(typeof result === "string"){
                result = JSON.parse(result)
            }
            if (result.errorMessage){
                result=false
            }
        } catch(e){
            console.error("Errore generazione excel ",e);
            result = false;
        }
        if (result)
            alertService.info("Excel generato correttamente");
        else
            alertService.error("Errore nella generazione dell'excel");
        setXlMile(xlMile + 1);
        
    }

    const downloadLogs = async () => {
        return await s3Svc.downloadFile({
            bucket: CONFIG.bucketName,
            key: s3_pointer.key.replace('teams.json', 'log.txt'),
            responseContentType: 'text/plain'
        })
    }
    const downloadXl = async () => {
        return await s3Svc.downloadFile({
            bucket: CONFIG.bucketName,
            key: s3_pointer.key.replace('teams.json', 'teams.xlsx'),
            responseContentType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            filename: 'gucci_' + s3_pointer.dt + '.xlsx'
        })
    }

    const jsQuery = useCabledQuery({
        svcName: 's3',
        method: 'getFile',
        refetchInterval:CONFIG.queryRefetchInterval,
        baseQueryKey: ['guccireport', s3_pointer.date],
        args: {
            bucket: CONFIG.bucketName,
            key: s3_pointer.key
        }
    });
    const emailsQuery = useCabledQuery({
        svcName: 's3',
        method: 'getFile',
        refetchInterval: CONFIG.queryRefetchInterval,
        baseQueryKey: ['guccireport','mails',s3_pointer.date],
        args: {
            bucket: CONFIG.bucketName,
            key: s3_pointer.key.replace('teams.json', 'mail.json')
        }
    });
    const logsQuery = useCabledQuery({
        svcName: 's3',
        method: 'getFile',
        refetchInterval: CONFIG.queryRefetchInterval,
        baseQueryKey: ['guccireport','logs',s3_pointer.date],
        args: {
            bucket: CONFIG.bucketName,
            key: s3_pointer.key.replace('teams.json', 'log.txt'),
            responseContentType: "text/plain"
        }
    });
    const xlExistsQuery = useCabledQuery({
        svcName: 's3',
        method: 'headFile',
        refetchInterval: CONFIG.queryRefetchInterval,
        baseQueryKey: ['guccireport','xlexists',s3_pointer.date],
        args:{
            bucket: CONFIG.bucketName,
            key: s3_pointer.key.replace('teams.json', 'teams.xlsx'),
        }

    });
    let xlExists = !!xlExistsQuery?.query?.data;
    useEffect(() => {
        if (jsQuery?.query?.data) {
            try {
                let js = JSON.parse(jsQuery.query.data);
                js.sort((x, y) => x.timestamp - y.timestamp);
                setDesc(js);

            } catch (e) {
                setErr("Impossibile recuperare le informazioni richieste.")
            }
        }
        
    }, [jsQuery?.query?.data]);
    useEffect(() => {
        if (emailsQuery?.query?.data) {
            try {
                let js = JSON.parse(emailsQuery.query.data);
                setMails(js);
            } catch (e) {
                setErr("Impossibile recuperare le informazioni richieste.")
            }
        }
        
    }, [emailsQuery?.query?.data]);
    useEffect(() => {
        if (logsQuery?.query?.data) {
            try {
                let logs = logsQuery?.query?.data;
                setLogs(logs);
                if (logs) {
                    let llogs = logs.split("\r\n");
                    setLastOutcome(findLastStatusTimestamp(llogs));
                }
            } catch (e) {
                setErr("Impossibile recuperare le informazioni richieste.")
            }
        }
        
    }, [logsQuery?.query?.data]);


    const StyledList = styled(List)({

    });
    const StyledListItem = styled(ListItem, {
        shouldForwardProp: p => p !== "text"
    })(({ theme, text }) => {


        let out = {
            '.MuiTypography-root': { color: 'black', fontSize: '12px' },
            // '.MuiTypography-body2': { color: 'black', fontSize: '16px' }
        };
        if (text && text.includes("GIORNALIERO 1")) {
            out['.MuiTypography-body2'] = { color: "rgba(255,0,0,.9)", fontSize: '16px' }
        } else if (text && text.includes("GIORNALIERO 2")) {
            out['.MuiTypography-body2'] = { color: "rgba(0,255,0,.9)", fontSize: '16px' }
        } else if (text && text.includes("CICLO_STANDARD")) {
            out['.MuiTypography-body2'] = { color: "rgba(0,0,255,.9)", fontSize: '16px' }
        } else if (text && text.includes("QUALITYUPDATE")) {
            out['.MuiTypography-body2'] = { color: "rgba(95,95,95,.9)", fontSize: '16px' }
        }

        return out;
    }
    );

    if (jsQuery.query.isError){
        setErr("Impossibile recuperare le informazioni richieste");
    }

    return (
        err ? (
            <Typography variant="h6">Si è verificato un errore: {err.toString()}</Typography>
        ) : (
            <Stack justifyContent="space-between" width="100%">
                <Box>
                    <Stack direction="row" width="100%" justifyContent="space-around">
                        {xlExists && (
                            <IconButton sx={{ width: "50px", height: "50px" }} onClick={downloadXl}>
                                <ExcelIcon></ExcelIcon>
                            </IconButton>
                        )
                        }
                        <IconButton sx={{ width: "50px", height: "50px" }} onClick={generateXl}>
                            <CachedOutlinedIcon></CachedOutlinedIcon>
                            <ExcelIcon></ExcelIcon>
                        </IconButton>
                        {
                            logs && <IconButton sx={{ width: "50px", height: "50px" }} onClick={downloadLogs}>
                                <LogsIcon></LogsIcon>
                            </IconButton>
                        }
                    </Stack >
                </Box >

                <Stack direction="row">
                    <Box width="50%">
                        <Typography variant="h6">Lettura Teams</Typography>
                        {lastOutcome && <p>Ultima lettura: {hourMinuteDateStr(lastOutcome.date)} {lastOutcome.outcome}</p>}
                        {desc && desc.length && (
                            <StyledList>
                                {
                                    desc.map(
                                        (d, dI) => (
                                            <StyledListItem key={dI} text={d.intestazione}>
                                                <ListItemText primary={fullDateStr(new Date(d?.timestamp))} secondary={d.intestazione}></ListItemText>
                                            </StyledListItem>
                                        )
                                    )
                                }
                            </StyledList>
                        )}
                    </Box>
                    <Box width="50%">
                        <Typography variant="h6">Email</Typography>
                        <p>&nbsp;</p>
                        {!!mails && mails.length && (
                            <StyledList>
                                {mails.map((m, mI) => (
                                    <StyledListItem key={mI} text={m.subject}>
                                        <ListItemText primary={fullDateStr(new Date(m.date + "Z"))} secondary={m.subject}></ListItemText>
                                    </StyledListItem>
                                )
                                )
                                }
                            </StyledList>
                        )}
                    </Box>
                </Stack>
            </Stack >
        )
    );
}