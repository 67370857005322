import React from 'react';
import Sidebar from "../sidebar/Bars";
import { Outlet } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import './layout.scss';
import AlertDeck from '../alerts/alertDeck';
import Box from '@mui/material/Box';
import { useContext } from 'react';
import sfondo3 from '../../images/sfondo3.png';
function Layout({ so }) {
    return (

        <Box sx={{ display: 'flex', height: '100vh' }}>
            <Sidebar />
            <Box
                component="main"
                sx={{ flexGrow: 1, p: 3, pt: 10 }}
                style={{ overflow: "auto" }}
            // style={{ overflow: "auto", backgroundImage: `url(${sfondo3})` }}
            >
                <AlertDeck></AlertDeck>
                <Outlet />


            </Box>
        </Box>


    );
}

export default Layout;