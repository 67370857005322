import { Grid, Skeleton } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import dayjs from 'dayjs';
import React from "react";
import { useSchedsPh } from "../../hooks/carrefour/carrefour";
import { LapideGenerica } from './lapide';
import DatiCanaliMq from "./lapideCanaliMq";
import DatiDollaro from "./lapideDollaro";
import DatiFineSerali from "./lapideFineSerali";
import DatiAstra from "./lapideFlussiAstra";
import DatiListini from "./lapideListini";
import DatiPartenzeSerali from "./lapidePartenzeSerali";


const MappingLapide = (rule_name) => {
    if (rule_name.startsWith('flussi_astra')) return {
        comp: LapideGenerica,
        datacomp: DatiAstra,
        title: "Controllo Flussi Astra"
    }
    if (rule_name.startsWith('partenze_serali')) return {
        comp: LapideGenerica,
        datacomp: DatiPartenzeSerali,
        title: "Partenze Serali"
    }
    if (rule_name.startsWith('middle_serali')) return {
        comp: LapideGenerica,
        datacomp: null,
        title: "Middle Serali"
    }
    if (rule_name.startsWith('fine_serali')) return {
        comp: LapideGenerica,
        datacomp: DatiFineSerali,
        title: "Fine Serali"
    }
    if (rule_name.startsWith('canali_mq')) return {
        comp: LapideGenerica,
        datacomp: DatiCanaliMq,
        title: "Canali MQ su Easy"
    }
    if (rule_name.startsWith('pfsede')) return {
        comp: LapideGenerica,
        datacomp: null,
        title: "Procedura serale di sede"
    }
    if (rule_name.startsWith('listini')) return {
        comp: LapideGenerica,
        datacomp: DatiListini,
        title: "Listini Ortofrutta e Pesce"
    }
    if (rule_name.startsWith('incassi')) return {
        comp: LapideGenerica,
        datacomp: DatiDollaro,
        title: "Verifica incassi"
    }
    if (rule_name.startsWith('qvapp') || rule_name.startsWith('qv_app')) return {
        comp: LapideGenerica,
        datacomp: DatiDollaro,
        title: "Verifica QV APP"
    }
    if (rule_name.startsWith('fgdepe')) return {
        comp: LapideGenerica,
        datacomp: DatiDollaro,
        title: "Fine Giornata su DEPE"
    }
    if (rule_name.startsWith('fg_depe')) return {
        comp: LapideGenerica,
        datacomp: DatiDollaro,
        title: "Fine Giornata su DEPE"
    }
    if (rule_name.startsWith('imflpla')) return {
        comp: LapideGenerica,
        datacomp: DatiDollaro,
        title: "RW_IMFLPLA ed RW_LISTINID"
    }
    if (rule_name.startsWith('flussiastra')) return {
        comp: LapideGenerica,
        datacomp: DatiAstra,
        title: "Flussi Astra"
    }
    if (rule_name.startsWith('generico_dollaro')) return {
        comp: LapideGenerica,
        datacomp: null,
        title: "Verifica Aborted e T.O."
    }
    if (rule_name.startsWith('aborted')) return {
        comp: LapideGenerica,
        datacomp: null,
        title: "Verifica Aborted"
    }
    if (rule_name.startsWith('CarrefourControlloAborted')) return {
        comp: LapideGenerica,
        datacomp: null,
        title: "Verifica Aborted"
    }

};

export default function ReportControlli({ displayFuture }) {

    const queryClient = useQueryClient();
    const schedsPhQuery = useSchedsPh({date:dayjs().subtract(1,'day').toDate()});
    const [displayedScheds, setDisplayedScheds] = React.useState([]);

    React.useEffect(() => {
        try {
            let minDate = new Date('2970-01-01T00:00:00+01:00');
            let maxDate = new Date('1970-01-01T00:00:00+01:00');
            if (!schedsPhQuery.isFetched) return;
            let ds = displayedScheds;
            for (let page of schedsPhQuery.data.pages)
                for (let s of page.data) {
                    let lapidedef = MappingLapide(s.rule_name);
                    if (lapidedef) {
                        s.comp = lapidedef.comp;
                        s.comptitle = lapidedef.title;
                        s.datacomp = lapidedef.datacomp;
                    } else {
                        s.comp = LapideGenerica;
                        s.comptitle = "Controllo non censito (" + s.name + ")";
                    }
                    s.env = s.name[0] === 'C' ? 'collaudo' : 'production';
                    s.trigger = s.name[2] === 'R' ? 'auto' : 'manual';
                    s.at = new Date(s.at);
                    minDate = new Date(Math.min(minDate,s.at));
                    let foundIndex = displayedScheds.findIndex(x => x.name === s.name);
                    if (foundIndex > -1) {
                        ds[foundIndex] = s;
                    } else {
                        ds.push(s);
                    }
                }

            ds.sort(function (a, b) {
                if (a.at < b.at)
                    return 1;
                else if (a.at === b.at) {
                    if (a.name < b.name) return 1; else return -1;
                }
                else return -1;
            })
            
            setDisplayedScheds(ds);
            // console.log(schedsPhQuery);
            console.log("SCHEDS QUERY HAS NEXT PAGE? ",schedsPhQuery.hasNextPage);
            try {
                console.log(minDate.toISOString(), minDate < dayjs().subtract(1,'day').toDate())
            } catch { console.log("XXXX",minDate)}
            if (schedsPhQuery.hasNextPage) {
                setTimeout(() => schedsPhQuery.fetchNextPage(),3000);
            }
        } catch (e) { console.error(e); }
    }, [schedsPhQuery.isFetching]);


    let filteredDisplayedScheds = displayedScheds.filter(s => (displayFuture || s.at <= new Date())).map(x=>({...x,isFuture: x.at > new Date()}));

    return (
        <Grid container spacing={4}>
            {!schedsPhQuery.isFetched ? <>
                <Grid item xs={4}>
                    <Skeleton width="100%" height="100%" variant="rectangular" animation="wave"></Skeleton>
                </Grid>
                <Grid item xs={4}>
                    <Skeleton width="100%" height="100%" variant="rectangular" animation="wave"></Skeleton>
                </Grid>
                <Grid item xs={4}>
                    <Skeleton width="100%" height="100%" variant="rectangular" animation="wave"></Skeleton>
                </Grid>
            </> : <></>}
            {filteredDisplayedScheds && filteredDisplayedScheds.length ? (
                filteredDisplayedScheds.map((s, sI) => (
                    <React.Fragment key={sI}>
                        {
                            s.comp ? (
                                <s.comp {...s}></s.comp>
                            ) : (JSON.stringify(s, null, 2))
                        }
                    </React.Fragment>
                ))
            ) : (<><p>In questo momento non sono disponibili schedulazioni.</p></>)}
        </Grid>
    )

}