import LogoutIcon from '@mui/icons-material/Logout';
import { AppBar, Breadcrumbs, Toolbar, Typography, styled } from "@mui/material";
import React, { useContext } from 'react';
// import './sidebar.scss';

import MenuIcon from '@mui/icons-material/Menu';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import IconButton from '@mui/material/IconButton';
import { useLocation, useMatches, useNavigate } from 'react-router-dom';
import { AppContext } from '../../hooks/appContext';
import { UserContext } from '../../hooks/userContext';




export const StyledAppBar = styled(AppBar)(({ theme }) => ({
    //height: '40px',
    backgroundColor: theme.palette?.background?.main,
    zIndex: 9999
}));
export const MyToolbar = styled(Toolbar)(({ theme }) => ({
    //height: '40px',
    verticalAlign: 'middle'
    // backgroundColor: theme.palette.primary,
    // zIndex: 9999
}));

export const MyBreadcrumbs = styled(Breadcrumbs)(({ theme }) => ({
    '& p': {
        color: theme.palette?.primary?.contrastText || 'red',
        fontSize: "larger",
        fontWeight: "bolder"
    },
    '& a': {
        color: theme.palette?.primary?.contrastText || 'red',
        textDecoration: 'none'
    }
}));

const EnvironBanner = function ({ environ }) {
    return (environ !== 'production') ? (
        <Typography sx={{
            fontSize: { xs: '10px', md: '24px' },
            fontWeight: 'bolder',
            color: 'red'
        }}>::::::{environ.toUpperCase()}::::::</Typography>
    ) : (
        <></>
    )
}



export default function MyAppBar({
    onToggleDrawer = () => { }
}) {
    let matches = useMatches();
    const location = useLocation();
    const navigate = useNavigate();
    const { user, groups, signOut } = useContext(UserContext);
    const { domain, environ } = useContext(AppContext);
    return (
        <StyledAppBar position="fixed">
            <MyToolbar>
                <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    edge="start"
                    onClick={onToggleDrawer}
                    sx={{ mr: 2, display: { lg: 'none' }, pb: 4 }}
                >
                    <MenuIcon />
                </IconButton>

                <Box sx={{ display: { xs: 'none', sm: 'flex' }, height: '100%' }}>
                    <MyBreadcrumbs >{
                        matches.filter((match) => Boolean(match.handle?.crumb)).map((match) => match.handle.crumb(match.data)) //TODO: verificare questione key
                    }</MyBreadcrumbs>
                </Box>
                <Box sx={{ flexGrow: 1 }} />
                <EnvironBanner environ={environ} />
                <Box sx={{ flexGrow: 1 }} />
                {environ === "localdev" && <>
                    <Box sx={{ display: { xs: 'flex', sm: 'none' }, height: '100%' }}>XS</Box>
                    <Box sx={{ display: { xs: 'none', sm: 'flex', md: 'none' }, height: '100%' }}>SM</Box>
                    <Box sx={{ display: { xs: 'none', md: 'flex', lg: 'none' }, height: '100%' }}>MD</Box>
                    <Box sx={{ display: { xs: 'none', lg: 'flex', xl: 'none' }, height: '100%' }}>LG</Box>
                    <Box sx={{ display: { xs: 'none', xl: 'flex' }, height: '100%' }}>XL</Box>
                </>}
                
                <Box sx={{ display: { xs: 'none', md: 'flex' }, height: '100%' }}>
                    <IconButton
                        size="large"
                        edge="end"
                        aria-label="account of current user"
                        // aria-controls={menuId}
                        aria-haspopup="true"
                        onClick={() => navigate('/user')}
                        color="inherit"
                    >
                        <Chip avatar={<Avatar className="avatar" />} label={user.username.toUpperCase()} />
                    </IconButton>
                    <IconButton onClick={signOut}><LogoutIcon /></IconButton>

                </Box>
            </MyToolbar>
        </StyledAppBar>
    );
}