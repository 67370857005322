import React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';
import LoopIcon from '@mui/icons-material/Loop';

import { useParams } from "react-router-dom";

import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineDot from '@mui/lab/TimelineDot';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from "react-router-dom";
import { IconButton } from '@mui/material';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));

const AdminResourceDetails = () => {


    const [updating, setUpdating] = React.useState(false);
    const [data, setData] = React.useState({});
    const [refreshData, setRefreshData] = React.useState(() => () => null);


    const navigate = useNavigate();

    React.useEffect(() => {
        setData({
            timeline: [
                {assignee: "Pippo", closed_at: "10:00:00",created_at: "09:55:01"},
                {assignee: "Pluto", closed_at: "09:55:00",created_at: "09:50:01"},
                {assignee: "Pippo", closed_at: "09:50:00",created_at: "09:45:01"},
                {assignee: "Pluto", closed_at: "09:45:00",created_at: "09:43:00"},
                {assignee: "Pippo", closed_at: "09:42:00",created_at: "09:40:00"},
                {assignee: "Pluto", closed_at: "09:36:00",created_at: "09:35:00"},
            ],
            details: {
                client: "Cliente",
                service: "MyService",
                rType: "EC2",
                image: "dummyimage",
                rClass: "low"
            }
        });
    }, [])
    const params = useParams();
    return (
        <div>
            <h1>Dettaglio risorsa {params.resourceid}</h1>
            <Grid container spacing={2}>
                <Grid item xs={1} justifyContent="center">
                    <IconButton  onClick={()=>navigate("/admin/resources")} >
                    <ArrowBackIcon/>
                    </IconButton>
                </Grid>
                <Grid item xs={11} justifyContent="center">
                    {updating ? <IconButton><CircularProgress></CircularProgress></IconButton> : <IconButton onClick={() => refreshData()}><LoopIcon></LoopIcon></IconButton>}
                </Grid>
                <Grid item xs={8} p={2}>
                    <Box sx={{ width: '100%', height: "700px" }}>
                        <h2>Timeline</h2>
                        <div style={{ height: '500px', width: '95%', overflowY: "auto" }}>
                            <Timeline>
                                {
                                    (data && data.timeline) ?
                                    data.timeline.map(
                                        (r,i) => (
                                            <React.Fragment key={i}>
                                            <TimelineItem>
                                                <TimelineOppositeContent color="text.secondary">
                                                    {r.closed_at}
                                                </TimelineOppositeContent>
                                                <TimelineSeparator>
                                                    <TimelineDot color="secondary" />
                                                    <TimelineConnector />
                                                </TimelineSeparator>
                                                <TimelineContent>{r.assignee}</TimelineContent>
                                            </TimelineItem>
                                            <TimelineItem>
                                                <TimelineOppositeContent color="text.secondary">
                                                    {r.assignee}
                                                </TimelineOppositeContent>
                                                <TimelineSeparator>
                                                    <TimelineDot color="primary" />
                                                    <TimelineConnector />
                                                </TimelineSeparator>
                                                <TimelineContent>{r.created_at}</TimelineContent>
                                            </TimelineItem>
                                            </React.Fragment>
                                        )
                                    ) : <></>
                                }

                            </Timeline>
                        </div>
                    </Box>
                </Grid>
                <Grid item xs={4} p={2}>
                    <Box sx={{ width: '100%', height: "700px" }}>
                        <h2>Dettagli</h2>
                        <Stack spacing={2}>
                            {(data && data.details) ? Object.keys(data.details).map((d,i)=>(<Item key={i}><b>{d}:</b> {data.details[d]}</Item>)) : <></>}
                        </Stack>
                    </Box>
                </Grid>

            </Grid>
        </div>
    )
}



export default AdminResourceDetails