import { Button, Stack, Typography } from '@mui/material';
import React from 'react';
import useCabledMutation from '../../hooks/serviceRegistry/useCabledMutation';


const AdminEnvDisable = ({
    name,
    onCancel = () => { },
    onSuccess = () => { },
    onError = () => { }
}) => {


    const toggleDisabledMutation = useCabledMutation({
        svcName: 'environments',
        method: 'doPost',
        onSuccess,
        onFailure: onError
    }
    )


    return (

        <Stack spacing={2} justifyContent="space-between" sx={{ height: "100%", minWidth: "25vw", ml: 2, mr: 2 }}>

            <Typography variant="h6" sx={{ mt: 10, textAlign: "center" }}>Disattivazione ambiente</Typography>
            <Typography variant="body1" sx={{ textAlign: "center" }}>Procedendo con la disattivazione, ogni attività di questo ambiente verrà inibita.<br />
                Le attività in corso o già schedulate potrebbero essere parzialmente processate.<br /><br />
                Sei sicuro di voler disattivare l'ambiente {name}?</Typography>


            <Stack direction="row" spacing={2} justifyContent="center" sx={{ mb: 2 }}>
                <Button
                    type="button"
                    onClick={() => toggleDisabledMutation.mutate({ path: '/envs/'+name+'/disable', body: {disabled: true} })}
                >Confirm</Button>
                <Button
                    color="secondary"
                    type="button"
                    onClick={onCancel}
                >Cancel</Button>
            </Stack>
        </Stack>



    )
}



export default AdminEnvDisable