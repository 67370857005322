import AssignmentIcon from '@mui/icons-material/Assignment';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturing';
import SettingsIcon from '@mui/icons-material/Settings';
import { Box, Drawer, Stack, Typography } from '@mui/material';
import { DataGrid, GridActionsCellItem, GridRowEditStopReasons, GridRowModes } from '@mui/x-data-grid';
import { useQueryClient } from '@tanstack/react-query';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AdminCreatePoolEditor from '../../../components/admin/agents/createPool';
import AdminPoolAssignmentEditor from '../../../components/admin/agents/editAssignment';
import AdminPoolConfigurationEditor from '../../../components/admin/agents/editConfiguration';
import AdminEnvironmentSelector from '../../../components/admin/environmentSelector';
import Deck from '../../../components/queries/deck';
import { AppContext } from '../../../hooks/appContext';
import { useCabledQuery } from '../../../hooks/serviceRegistry/useCabledQuery';
import { alertService } from '../../../services/alerting/alerting';
import { renderLongString } from '../../../services/common/common';
class AgentPool {

    constructor({ pool_name, agentChannel, agentConfiguration, assignment, environment, minAgentVersion }) {
        this.poolName = pool_name;
        this.agentChannel = agentChannel;
        this.agentConfiguration = agentConfiguration;
        this.assignment = assignment;
        this.environment = environment;
        this.minAgentVersion = minAgentVersion;
        this.id = pool_name;
    }
}


const LocalStrategyCell = (params) => <Typography>LOCAL</Typography>
const QueueStrategyCell = (params) => <Typography>Q: {renderLongString(params.queue,50)}</Typography>
const ActivitiesStrategyCell = (params) => <Typography>{params.activities?.length || 0} Activities</Typography>

const CONFIG = {
    queryRefetchInterval: 10 * 60 * 1000
};


export function AdminPoolsDashboard() {
    const { currentEnvironment } = useContext(AppContext);
    const navigate = useNavigate();
    const [pools, setPools] = useState([]);
    const [sideContent, setSideContent] = useState(null);
    const [operatingPool, setOperatingPool] = useState(null);
    
    if (!currentEnvironment){
        navigate('/admin/envs');
    }
    
    const gotoAgentsList = (id)=>{
        let pool = pools.find(x=>x.id === id);
        if (!!pool){
            let poolName = pool.poolName;
            console.log("Navigate to "+'./'+poolName)
            navigate('./'+poolName);
        }
    };

    const startEditConfiguration = (id) => {
        setOperatingPool(id);
        setSideContent("editConfig");
    };
    const startEditAssignment = (id) => {
        setOperatingPool(id);
        setSideContent("editAssignment");
    }

    const columns = [
        { field: "poolName", headerName: "Pool Name", flex: 3 },
        { field: "agentChannel", headerName: "Agent Channel", flex: 1 },

        {
            field: "assignment", headerName: "Assignment", flex: 2, renderCell: (params) => {
                switch (params.row.assignment.strategy) {
                    case 'local':
                        return <LocalStrategyCell {...params.row.assignment} />
                    case 'queue':
                        return <QueueStrategyCell {...params.row.assignment} />
                    case 'activities':
                        return <ActivitiesStrategyCell {...params.row.assignment} />
                    default:
                        return <pre>UNKNOWN</pre>
                }
            }
        },

        { field: "minAgentVersion", headerName: "Min Agent Version", flex: 1 },
        {
            field: "actions", type: "actions", headerName: "Actions", flex: 1, cellClassName: "actions",
            getActions: ({ id }) => {

                return [
                    <GridActionsCellItem
                        icon={<AssignmentIcon />}
                        label="Edit Assignment"
                        className="textPrimary"
                        onClick={() => startEditAssignment(id)}
                        color="inherit"
                    />,
                    <GridActionsCellItem
                        icon={<SettingsIcon />}
                        label="Edit Configuration"
                        className="textPrimary"
                        onClick={() => startEditConfiguration(id)}
                        color="inherit"
                    />,
                    <GridActionsCellItem
                        icon={<PrecisionManufacturingIcon />}
                        label="Agents"
                        onClick={() => gotoAgentsList(id)}
                        color="inherit"
                    />,
                    <GridActionsCellItem
                        icon={<DeleteIcon />}
                        label="Delete"
                        onClick={() => setSideContent("deletePool")}
                        color="inherit"
                    />,
                ];
            }
        }
    ];

    const poolsQuery = useCabledQuery({
        svcName: 'agentservice',
        method: 'listPools',
        args: {
            envName: currentEnvironment
        },
        enabled: true,
        refetchInterval: CONFIG.queryRefetchInterval,
        baseQueryKey: ['agents', 'pools']
    });
    useEffect(() => {
        let pp = (poolsQuery?.query?.data || []).map(d => new AgentPool(d))
        setPools(pp);
    }, [poolsQuery?.query?.data])

    function clearSideContent() { setSideContent(null); queryClient.invalidateQueries(['agents','pools']) };
    const queryClient = useQueryClient();

    return <>
        <Deck
            //  hasOmniBox={true}
            //  searchHandler={{ search, setSearch, categories }}
            // queryHook={useFlows}
            // query={flowsQuery}
            queryKey={poolsQuery.queryKey}
            onRefresh={() => { console.log("REFRESHED!") }}
            title={<AdminEnvironmentSelector />}
            hasNewItem={true}
            onNewItem={() => setSideContent("createPool")}
        />
        <Box sx={{ height: "82vh" }}>
            <DataGrid
                rows={pools}
                columns={columns}
            />
        </Box>
        <Drawer
            anchor="right"
            open={sideContent !== null}
            onClose={clearSideContent}>

            {sideContent === "editConfig" && (
                <AdminPoolConfigurationEditor
                    poolName={pools.find(p => p.id === operatingPool)?.poolName}
                    onCancel={() => setSideContent(null)}
                    onSuccess={(result) => {
                        setSideContent(null);
                        alertService.success("Config was updated");
                        queryClient.invalidateQueries([poolsQuery.queryKey])
                        // queryClient.setQueriesData(['environments'], oldData => updateEnvInEnvs(oldData, result));
                    }}
                    onError={(error) => {
                        alertService.error(error?.detail || "Config was not updated")
                    }}
                />
            )}
            {sideContent === "editAssignment" && (
                <AdminPoolAssignmentEditor
                    poolName={pools.find(p => p.id === operatingPool)?.poolName}
                    onCancel={() => setSideContent(null)}
                    onSuccess={(result) => {
                        setSideContent(null);
                        alertService.success("Assignment was updated");
                        setTimeout(() => {queryClient.invalidateQueries(poolsQuery.queryKey)});
                    }}
                    onError={(error) => {
                        alertService.error(error?.detail || "Assignment was not updated")
                    }}
                />
            )}
            {sideContent === "deletePool" && (
                <Stack spacing={2} sx={{ minWidth: "25vw" }} alignItems="center">
                    <Box sx={{ height: "10vh" }} />
                    <Typography variant="h6" component="div" gutterBottom>Delete agent pool</Typography>
                    <Typography>Questa operazione è stata disabilitata per questioni di sicurezza.<br />
                        Per cancellare un agent pool, eliminare la riga corrispondente nella tabella DynamoDB.
                    </Typography>
                </Stack>
            )}
            {sideContent === "createPool" && (
                <AdminCreatePoolEditor
                    onCancel={() => setSideContent(null)}
                    onSuccess={(result) => {
                        setSideContent(null);
                        alertService.success("Pool was created");
                        setTimeout(() => {queryClient.invalidateQueries(poolsQuery.queryKey)});
                    }}
                    onError={(error) => {
                        console.log("eee",error);
                        alertService.error(error?.detail || error?.message || "Pool creation failed")
                    }}
                />
            )}
        </Drawer>
    </>
}