import { Paper, Typography } from "@mui/material";
import InDeck from "../queries/indeck";

import './pagePaper.scss';
import { isValidElement } from "react";

const PagePaper = ({
    children,
    title = null,
    subtitle = null,
    height = null,
    minWidth = null,
    minHeight = null,
    highlight = false,
    onClick = null,
    transparent = false,
    verticalScroll = true,
    sx = {},
    style = {},
    deckProps = {},
}) => {

    return <Paper className="pagePaper" onClick={onClick} elevation={highlight ? 20 : 5}
        sx={{ p: 2, height, minHeight, minWidth, position: 'relative', ...sx, backgroundColor: transparent ? 'rgba(0,0,0,0)' : 'white' }}
        style={{ overflowY: verticalScroll ? 'auto' : 'hidden', ...style }}
    >
        <InDeck withStyle={{ position: 'absolute', right: '10px' }}
            {...deckProps}
        ></InDeck>
        {title ? (

            isValidElement(title) ?  title :
                <Typography className="page-paper-title" variant="h4" sx={{ mb: 3 }}>{title.toString()}</Typography>

        ) : (<></>)}
        {subtitle ? (
            <Typography variant="h6" sx={{ mb: 1 }}>{subtitle}</Typography>
        ) : (<></>)}
        {children}
    </Paper>
}

export default PagePaper;