import { Autocomplete, TextField, Typography } from "@mui/material";
import { useStepFunctionRegions, useStepFunctions } from "../../hooks/stepfunctions";
import { useEffect, useMemo, useState } from "react";



export default function StepFunctionFilter({

    onChange = () => { },
    selection = [],
}) {
    const [stepFunctionsOptions, setStepFunctionsOptions] = useState([]);
    console.log(stepFunctionsOptions.length);
    const regionsQuery = useStepFunctionRegions({ enabled: true });
    const regions = useMemo(()=>regionsQuery.data || [],[regionsQuery]);
    console.log("regions", regions);
    const stepFunctionsQueries = useStepFunctions({ regions, enabled: !!regions.length });

    useEffect(() => {
        console.log("USE EFFECT");
        if (!stepFunctionsQueries || !stepFunctionsQueries.length) return;
        let sfs = [];
        for (let query of stepFunctionsQueries){
            if (query.data)
                sfs = [...sfs,...query.data];
        }
        setStepFunctionsOptions(sfs);
    }, [stepFunctionsQueries.map(x=>x.isFetching).some(x=>x),regions]);
    if (!stepFunctionsQueries.length) return <p>"Loading..."</p>;

    return <Autocomplete
        size="small"
        options={stepFunctionsOptions}
        groupBy={(option) => option.region}
        onChange={(e, v) => {
            onChange(v);
        }}
        value={selection}
        getOptionLabel={(option) => `${option.region} - ${option.machine_name}`}
        renderOption={(props, data) => (
            <Typography key={data.id} {...props} variant="body1">
                {data.machine_name}
            </Typography>
        )}
        style={{ width: 500 }}
        renderInput={(params) => (
            <TextField
                {...params}
                variant="outlined"
                label="Stepfunction"
            />
        )}
    />;


}