
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import Stack from '@mui/material/Stack';
import React, { useEffect } from 'react';
import { alertService, alertSeverity } from '../../services/alerting/alerting';

class AlertDeck extends React.Component {
    constructor(props) {
        super(props);
        this.state = { alerts: []};
    }
    componentDidMount() {
        // subscribe to new alert notifications
        this.subscription = alertService.onAlert(this.props.id)
            .subscribe(alert => {
                this.setState({ alerts: [...this.state.alerts, alert] });
                console.log("ALERT COUNT",this.state.alerts.length)
                setTimeout(() => this.removeAlert(alert), 5000);
            });
    }

    componentWillUnmount() {
        this.subscription.unsubscribe();
    }

    removeAlert(alert) {
        const alertWithFade = { ...alert, fade: true };
        this.setState({ alerts: this.state.alerts.map(x => x === alert ? alertWithFade : x) });

        // remove alert after faded out
        setTimeout(() => {
            this.setState({ alerts: this.state.alerts.filter(x => x !== alertWithFade) })
        }, 250);
    }

   
    render() {
        const { alerts } = this.state;
        if (!alerts.length) return null;
        return (
            // <Stack spacing={2} sx={{ width: '100%' }}>
            //     </Stack>
            <>
            {
                alerts.map(a=>(
                    <Alert variant="filled" key={a.id} severity={a.severity} sx={{mt: -5,ml:-2}} style={{width: '100%', position:'absolute', zIndex:20000}}>{a.message}</Alert>
                ))
            }
            </>
            // <div className="m-3">
            //     {alerts.map((alert, index) =>
            //         <div key={index} className={this.cssClasses(alert)}>
            //             <a className="close" onClick={() => this.removeAlert(alert)}>&times;</a>
            //             <span dangerouslySetInnerHTML={{__html: alert.message}}></span>
            //         </div>
            //     )}
            // </div>
        );
    }
}

// const AlertDeck = ()=>{


//     const [alerts,setAlerts] = React.useState([{key: 0, severity:"error",text:"Pappappero!", open:true, timer:null}]);


//     React.useEffect(()=>{
//         alertService.onAlert()
//             .subcribe(alert=>{console.log("alert",alert)})
//     })

//     return (
//         <Stack spacing={2} sx={{ width: '100%' }}>
//             {
//                 alerts.map(a=>(
//                     <Snackbar key={a.key} autoHideDuration={6000}>
//                         <Alert severity={a.severity}>{a.text}</Alert>
//                     </Snackbar>
//                 ))
//             }
            
//         </Stack>
//     )


// }


export default AlertDeck