
import { getJwt } from '../common/common';

class NotificationService {


    constructor(baseUrl) {
        this.baseUrl = baseUrl;

    }
    async do_get(url, onSuccess = null, onError = null) {
        try {

            const res = await fetch(this.baseUrl + url, { mode: 'cors', headers: { Authorization: (await getJwt()) } });
            if (!res.ok) {
                console.error("Status: ", res.status);
                if (onError) {
                    return await onError(res.status, await res.json());
                } else {
                    return await res.json();
                }
            }
            const data = await res.json();
            console.log("DATA", data);
            if (onSuccess) {
                return await onSuccess(data);
            } else {
                return data;
            }
        } catch (e) {
            if (onError) onError(null, e)
        }

    }
    async do_post(url, body, onSuccess = null, onError = null) {
        try {

            const res = await fetch(this.baseUrl + url, {
                method: 'POST', mode: 'cors', headers: { 'content-type': 'application/json', Authorization: (await getJwt()) },
                body: JSON.stringify(body)
            });
            if (!res.ok) {
                console.error("Status: ", res.status);
                if (onError) {
                    return await onError(res.status, await res.json());
                } else {
                    return await res.json();
                }
            }


            if (onSuccess) {
                return await onSuccess(await res.json());
            } else {
                return await res.json();
            }
        } catch (e) {
            if (onError) onError(null, e)
        }

    }
    async do_delete(url, onSuccess = null, onError = null) {
        try {

            const res = await fetch(this.baseUrl + url, {
                method: 'DELETE', mode: 'cors', headers: { Authorization: (await getJwt()) }
            });
            if (!res.ok) {
                console.error("Status: ", res.status);
                if (onError) {
                    return await onError(res.status, await res.json());
                } else {
                    return await res.json();
                }
            }


            if (onSuccess) {
                return await onSuccess(await res.json());
            } else {
                return await res.json();
            }
        } catch (e) {
            if (onError) onError(null, e)
        }

    }
    // ALERTS

    async listAlerts({ filtri = {}, nextToken = null }) {
        console.log("LIST ALERTS");
        console.log(this.baseUrl);
        let filtri_encoded = "eyJlbnYiOiAiSW5mcmFzdHJ1Y3R1cmUiLCAiY3VzdHNlcnYiOiBbeyJjIjogIlN0ZWxsYW50aXMiLCAicyI6ICJFTUVBIn0sIHsiYyI6ICJDYXJyZWZvdXIifV19";
        let pagination_encoded = btoa(JSON.stringify({next_token:nextToken}));
        // const res = await fetch(this.baseUrl + '/activities?filtri=' + filtri_encoded + "&pagination=" + pagination_encoded, { mode: 'cors', headers: { Authorization: (await getJwt()) } });
        // const data = await res.json();
        // return data;
        return await this.do_get('/activities?filtri=' + filtri_encoded + "&pagination=" + pagination_encoded)
    }

    async getActivityDescription({ activityId }) {
        const res = await fetch(this.baseUrl + '/activities/' + activityId + '/description', { mode: 'cors', headers: { Authorization: (await getJwt()) } });
        const data = await res.json();
        return data;
    }



    async getActivityCommands({ activityId }) {
        const res = await fetch(this.baseUrl + '/activities/' + activityId + '/commands', { mode: 'cors', headers: { Authorization: (await getJwt()) } });
        const data = await res.json();
        return data;
    }
    async sendCommand({ activityId, commandId }) {
        const res = await fetch(this.baseUrl + '/activities/' + activityId + '/commands/' + commandId, {
            mode: 'cors',
            headers: { 'content-type': 'application/json', Authorization: (await getJwt()) },
            method: 'POST',

        });
        const data = await res.json();
        return data;
    }
    async getActivityFeedback({ activityId }) {
        const res = await fetch(this.baseUrl + '/activities/' + activityId + '/feedback', { mode: 'cors', headers: { Authorization: (await getJwt()) } });
        const data = await res.json();
        return data;
    }
    async listActivityNotes({ activityId }) {
        const res = await fetch(this.baseUrl + '/activities/' + activityId + '/notes', { mode: 'cors', headers: { Authorization: (await getJwt()) } });
        const data = await res.json();
        return data;
    }

    async requireActivityChange({activityId,changePayload},{onSuccess,onError}){
        let url = '/activities/'+activityId+'/changes';
        return await this.do_post(url,changePayload,onSuccess,onError);
    }
    async requireActivityNoteChange({activityId,changeId,content},{onSuccess,onError}){
        return await this.requireActivityChange({
            activityId,
            changePayload:{
                change_id: changeId,
                change_type:"PUTNOTE",
                change_dt:new Date().toISOString(),
                change_details: {
                    content
                }
            }},{onSuccess,onError});
    }
    async requireActivityAssignmentChange({activityId,changeId},{onSuccess,onError}){
        return await this.requireActivityChange({
            activityId,
            changePayload:{
                change_id: changeId,
                change_type:"ASSIGN",
                change_dt:new Date().toISOString(),
                change_details: {}
            }},{onSuccess,onError});
    }
    async requireActivityCommandChange({activityId,changeId, commandId},{onSuccess,onError}){
        return await this.requireActivityChange({
            activityId,
            changePayload:{
                change_id: changeId,
                change_type:"COMMAND",
                change_dt:new Date().toISOString(),
                change_details: { command_id:commandId}
            }},{onSuccess,onError});
    }
    async requireActivityFeedbackChange({activityId,changeId, values},{onSuccess,onError}){
        return await this.requireActivityChange({
            activityId,
            changePayload:{
                change_id: changeId,
                change_type:"FEEDBACK",
                change_dt:new Date().toISOString(),
                change_details: { feedback_field_values: values} //[{field_name,field_value}]
            }},{onSuccess,onError});
    }

    //Deprecated
    async execFeedback({ activityId, values }) {
        const res = await fetch(this.baseUrl + '/activities/' + activityId + '/feedback', {
            mode: 'cors',
            headers: { 'content-type': 'application/json', Authorization: (await getJwt()) },
            method: 'POST',
            body: JSON.stringify({
                feedback_field_values: values
            })

        });
        const data = await res.json();
        return data;
    }



}





// //Deprecated
// async addActivityNote({ activityId, noteText }) {
//     const res = await fetch(this.baseUrl + '/activities/' + activityId + '/notes', {
//         mode: 'cors',
//         headers: { 'content-type': 'application/json', Authorization: (await getJwt()) },
//         method: 'POST',
//         body: JSON.stringify({
//             content: noteText,
//             request_id: new Date().valueOf().toString()
//         })
//     });
//     const data = await res.json();
//     return data;
// }
// //Deprecated
// async assignActivity({ activityId }) {
//     const res = await fetch(this.baseUrl + '/activities/' + activityId + '/assignment', {
//         mode: 'cors',
//         headers: { 'content-type': 'application/json', Authorization: (await getJwt()) },
//         method: 'POST',
//         body: JSON.stringify({
//             assignment: {
//                 request_id: new Date().valueOf().toString()
//             }
//         })

//     });
//     const data = await res.json();
//     return data;
// }
// //Deprecated
// async unassignActivity({ activityId }) {
//     const res = await fetch(this.baseUrl + '/activities/' + activityId + '/unassignment', {
//         mode: 'cors',
//         headers: { 'content-type': 'application/json', Authorization: (await getJwt()) },
//         method: 'POST',
//         body: JSON.stringify({
//             assignment: {
//                 request_id: new Date().valueOf().toString()
//             }
//         })

//     });
//     const data = await res.json();
//     return data;
// }


export default NotificationService