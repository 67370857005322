
import { ApiClient } from '../api';
import dayjs from 'dayjs';


class AcmeService extends ApiClient {

    async doListResources() {
        let out = [
            {serverName: "Server1", cpu: .74, ram: .50, disk: .67},
            {serverName: "Server2", cpu: .44, ram: .77, disk: .67},
            {serverName: "Server3", cpu: .54, ram: .50, disk: .67},
            {serverName: "Server4", cpu: .71, ram: .23, disk: .67}
        ];
        return new Promise((resolve, reject) => {
            setTimeout(() => {resolve(out)}, 2000)
            //setTimeout(() => {resolve([])}, 2000)
            //setTimeout(() => {reject("pippo")}, 2000)
        });
    }

}

export default AcmeService;