import { Avatar, Chip, Drawer } from '@mui/material';
import Grid from '@mui/material/Grid';
import { useQueryClient } from '@tanstack/react-query';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import AdminEnvironmentSelector from '../../../components/admin/environmentSelector';
import AdminCreateIncident from '../../../components/incidents/AdminCreateIncident';
import IncidentWidget from '../../../components/incidents/IncidentWidget';
import Deck from '../../../components/queries/deck';
import { AppContext } from '../../../hooks/appContext';
import { useCabledQuery } from '../../../hooks/useCabledQuery';
import { useLocalStorage } from '../../../hooks/useLocalStorage';
import './list.scss';
const AdminIncidentList = () => {


    const { currentEnvironment } = useContext(AppContext);
    const [tabIndex, setTabIndex] = useLocalStorage('adminproblemlistenvironmenttab', 0);
    const [search, setSearch] = useState([]);
    const [openProblems, setOpenProblems] = useState([]);
    const [closedProblems, setClosedProblems] = useState([]);
    const [sideContent, setSideContent] = useState(null);
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const [includeArchived, setIncludeArchived] = useLocalStorage('admin-problems-list-include-archived', false);
    const { query: problemsQuery, queryKey: problemsQueryKey } = useCabledQuery({
        apiService: 'monitoring',
        method: 'listProblems',
        baseQueryKey: 'problems',
        refetchInterval: 60 * 1000,
        filters: {
            environment: currentEnvironment,
            includeArchived
        }
    })
    // const {problemsQuery,refetchInterval:problemsRefetchInterval} = useProblems({ includeArchived });


    useEffect(() => {
        let op = [];
        let cp = [];
        if (problemsQuery && problemsQuery.data) {
            problemsQuery.data.forEach(
                problem => {
                    if (problem.status === "OPEN") {
                        op.push(problem);
                    } else {
                        cp.push(problem);
                    }
                }
            )
            op = op.sort((a, b) => b.start_date - a.start_date);
            cp = cp.sort((a, b) => a.end_date - b.end_date);
            setOpenProblems(op);
            setClosedProblems(cp);
        }
    }, [problemsQuery.data, search]);
    const SEVERITIES = ['', 'warning', 'minor', 'major', 'critical']
    const categories = [
        {
            title: 'Customer - Service',
            fields: { 'customer': 'customer', 'service': 'service' },
            render: (data) => `${data.customer} - ${data.service || "nessun servizio"}`
        },
        {
            title: 'Severity',
            fields: { 'severity': 'severity' },
            render: (data) => SEVERITIES[data.severity]
        }
    ];

    if (problemsQuery.isError) return <div>Error in retrieving incidents: {problemsQuery.error.message}</div>;
    // console.log("LAST",problemsUpdatedAt,"NEXT",nextProblemsRefresh);
    const clearSideContent = () => { setSideContent(null); }
    return (<>
        <Deck
            hasOmniBox={true}
            searchHandler={{ search, setSearch, categories }}
            queryKey={problemsQueryKey}
            onRefresh={() => console.log("REFRESHED!")}
            title={<AdminEnvironmentSelector />}
            switches={[
                { switchState: includeArchived, setSwitchState: setIncludeArchived, switchLabel: 'Archive' }
            ]}
            hasNewItem={true}
            onNewItem={() => setSideContent('create')}
            hasOptions={true}
            onOptions={()=>navigate('./types')}
        />



        <Grid container spacing={2} sx={{mt:1}}>

            <Grid item xs={12} className="problemCountIndicator">
                <Chip label="Aperti" avatar={<Avatar>{openProblems.length}</Avatar>} />
            </Grid>
          
            {
                openProblems?.map(p => (
                    <Grid item xs={12} sm={6} md={4} justifyContent="center" key={p.problem_id} >
                        <IncidentWidget
                            displayId={true}
                            environment={p.environment}
                            status={p.status}
                            problemId={p.problem_id}
                            problemType={p.problem_type}
                            impactedComponents={p.impacted_components}
                            severity={p.severity}
                            startDate={p.start_date}
                            endDate={p.end_date}
                            onDoubleClick={({problemId,environment}) => {
                                navigate('./' + problemId)
                            }}
                        />
                    </Grid>
                ))
            }
            <Grid item xs={12} />
            <Grid item xs={12} className="problemCountIndicator">
                <Chip label="Chiusi" avatar={<Avatar>{closedProblems.length}</Avatar>} />
            </Grid>
           
            {
                closedProblems?.map(p => (
                    <Grid item xs={12} sm={6} md={4} justifyContent="center" key={p.problem_id} >
                        <IncidentWidget
                            displayId={true}
                            environment={p.environment}
                            status={p.status}
                            problemId={p.problem_id}
                            problemType={p.problem_type}
                            impactedComponents={p.impacted_components}
                            severity={p.severity}
                            startDate={p.start_date}
                            endDate={p.end_date}
                            onDoubleClick={({problemId,environment}) => {
                                navigate('./' + problemId)
                            }}
                        />



                    </Grid>
                ))
            }





        </Grid>
        <Drawer
            anchor="right"
            open={sideContent !== null}
            onClose={clearSideContent}>

            {sideContent === "create" && (
                <AdminCreateIncident onCancel={()=>clearSideContent()} onSuccess={()=>{queryClient.invalidateQueries(problemsQueryKey); clearSideContent()}} />
            )}

        </Drawer>
    </>
    )
}



export default AdminIncidentList;