import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import './report.scss';

import React from 'react';


import PageMainStack from '../../../../components/page/pageMainStack';
import PagePaper from '../../../../components/page/pagePaper';


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    {children}
                    {/* <Typography></Typography> */}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};
const StellantisReport = () => {
    
    

    return (
        <PageMainStack>
            <PagePaper height="90vh" title="Stellantis" subtitle="Stato Monitoraggio">
                <Box style={{width: '90%', height:'50vh', background: 'purple', marginLeft: 'auto', marginRight: 'auto'}}></Box>
            </PagePaper>
        </PageMainStack>
    )
}

export default StellantisReport