import { Paper, styled } from "@mui/material"



const WidgetContainer = styled(Paper,{
    shouldForwardProp: p=>{
        return ![
            'bgColor',
            'highlighted'
        ].includes(p)
    }
})(({
    theme,
    bgColor="#333333",
    highlighted=false
})=>{

    let style = {
        ...theme.containerBoxStyle,
        borderRadius: '15px',
        boxShadow: theme.shadows[2],
        backgroundColor: (function(){
            if (typeof bgColor === "string") return bgColor;
            if (typeof bgColor === "function") return bgColor();
        })(),

        justifyContent: 'space-between',
        padding: 1,
        
        ':hover':{
            boxShadow: theme.shadows[5],
        }
    };
    if (!!highlighted){
        style.border = `2px solid ${theme.palette.primary.main}`;
    }
    
    return style;
})

export default WidgetContainer