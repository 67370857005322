import { useInfiniteQuery, useQuery, useQueryClient } from '@tanstack/react-query';
import { useContext } from 'react';
import { ServiceContext } from './serviceContext';


/**
 * CabledQueries basate su Service Registry e Service Context
 */



export function useCabledQuery({
    svcName,
    method, refetchInterval = 60 * 1000, baseQueryKey, args={}, enabled=true
}) {

    const {getSvc} = useContext(ServiceContext); //viene preparato in ServiceRegistryWrapper
    if (!getSvc){
        throw new Error("UseCabledQuery must be used inside ServiceRegistryWrapper route");
    }
    const svc = getSvc(svcName);
    // console.log("UCQ",svc);
    const queryKey = (Array.isArray(baseQueryKey) ? baseQueryKey : [baseQueryKey]);
    const query = useQuery({
        queryFn: async () => svc[method].bind(svc)(args),
        queryKey: [...queryKey,args],
        refetchInterval,
        staleTime: parseInt(refetchInterval / 2), 
        enabled
    });
    return { query, queryKey: [...queryKey,args] };
}

export function useInfiniteCabledQuery({
    svcName, method, refetchInterval = 60 * 1000, baseQueryKey, args={}, enabled=true, next_page_key = "next_token",
    pageParam2Args = null
}) {

    if (!pageParam2Args){
        
        pageParam2Args = (pageParam, existingArgs) => {
            if (!pageParam) return existingArgs;
            const args = Object.assign({},existingArgs);
            args['queryParams'] = args['queryParams'] || {};
            args['queryParams']["page"] = pageParam;
            return args;
        }
    
    }


    const {getSvc} = useContext(ServiceContext); //viene preparato in ServiceRegistryWrapper
    const svc = getSvc(svcName);
    const queryKey = (Array.isArray(baseQueryKey) ? baseQueryKey : [baseQueryKey]).concat([args]);
    const query = useInfiniteQuery({
        queryFn: async ({pageParam = null}) => {
            args = pageParam2Args(pageParam, args);
            // console.log("UICQ",args);
            return await svc[method].bind(svc)(args)
        },
        queryKey: [...queryKey,args],
        refetchInterval,
        staleTime: parseInt(refetchInterval / 2),
        getNextPageParam: (lastPage, pages) => { return lastPage ? lastPage[next_page_key] : null}, 
        enabled
    });
    return { query, queryKey: [...queryKey,args] };
}

