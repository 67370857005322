import React from 'react';
import { Button, TextAreaField } from "@aws-amplify/ui-react";
import { Box, Divider, FormControl, FormGroup, FormHelperText, InputLabel, MenuItem, Select, Stack, TextareaAutosize, TextField, Typography } from "@mui/material";
import cronstrue from 'cronstrue/i18n';
import TextInputField from '../forms/text';
import ItpaApi from '../../services/api';
import { useMutation } from '@tanstack/react-query';
import { alertService } from '../../services/alerting/alerting';
const cronparser = require('cron-parser');


function CronToString(cron_expression) {
    try {
        return cronstrue.toString(cron_expression, { locale: "it", verbose: true });
    } catch (e) {
        return "Invalid: " + e.toString();
    }
}

const CreateRule = ({
    group,
    targets=[],
    onChange = ()=>{},
    onDismiss = ()=>{},
})=>{

    const [data,setData] = React.useState({
        name: "", cron: "", target: "",input: "{}"
    });
    const [errors,setErrors] = React.useState({
        name: null, cron: null, target: null, input: null
    });

    const onFieldChange = (field,newValue) =>{
        var D = data;
        var E = errors;
        if (field === 'name'){
            D = {...D,name:newValue};
            if (newValue && newValue.length < 5 ) E = {...E,name:"Non valido"};
            else E = {...E,name:null};
        }
        if (field === 'cron'){
            D = {...D,cron:newValue};
            try {
                cronparser.parseExpression(newValue);
                E = {...E,cron:null};
            } catch{
                E = {...E,cron:"Non valido"};
            }
        }
        if (field === 'target'){
            D = {...D,target:newValue};
            if (targets.find(t=>t.target_arn === newValue.target_arn && t.role_arn === newValue.role_arn)) E = {...E,target:null};
            else E = {...E,target:"Non valido"};
        }
        if (field === 'input'){
            D = {...D,input:newValue};
            try {
                let x = typeof(JSON.parse(newValue)) === "object";
                E = {...E,input:null};
            } catch {
                E = {...E,input:"Non valido"};
            }
            
        }
        setData(D);
        setErrors(E);
    };
    
    const isFilled = ()=>(data.name.length && data.cron.length && !!data.target )
    const isValid = ()=>(errors.name === null && errors.cron === null && errors.target === null && errors.input === null);


    const moniServ = ItpaApi.getService("monitoring");
    const createRuleMutation = useMutation({ mutationFn: async () => {
        try {
            let x = await moniServ.createScheduleRule({
                group: group, name: data.name, cron: data.cron,
                target_arn: data.target.target_arn, role_arn: data.target.role_arn,
                target_input: data.input
            });
            alertService.success("Regola creata");
            onChange();
            onDismiss();
            return x;
        } catch (e) {
            alertService.error("Errore nella creazione della regola");
        }
        setTimeout(() => createRuleMutation.reset());
    } });

    return (
        <Box width="30vw">
            <Stack width="98%" height="95vh" justifyContent="flex-start" spacing={2} sx={{mt:4}}>
                
                <Typography variant="h4">Nuova regola</Typography>
                <Divider />
                <Box>
                    {JSON.stringify(data,null,2)}
                </Box>
                <FormGroup sx={{width: '95%'}}>
                    <FormControl sx={{mt:2}}>
                        <TextField
                            error={!!errors.name}
                            label="Nome" 
                            id="name_field"
                            fullWidth={true}
                            value={data.name || ""}
                            helpertText={errors.name}
                            onChange={(e)=>onFieldChange("name",e.target.value)}
                        ></TextField>
                    </FormControl>
                    <FormControl  sx={{mt:2}}>
                        <TextField
                            error={!!errors.cron}
                            label="Cron"
                            id="cron_field"
                            fullWidth={true}
                            placeholder="* * * * *"
                            value={data.cron || ""}
                            helpertText={errors.cron}
                            onChange={(e)=>onFieldChange("cron",e.target.value)}
                        ></TextField>
                    </FormControl>
                    <Box sx={{mt:2}}>
                        {
                            data.cron ? ( (errors.cron === null) ? (
                                CronToString(data.cron)
                            ) : (
                                "Cron non valido"
                            ) ) : (<></>)
                        }
                    </Box>
                    <FormControl  sx={{mt:2}} error={errors.target}>
                        <InputLabel id="target_label">Target</InputLabel>
                        <Select
                            labelId='target_label'
                            label="Target"
                            id="target_field"
                            fullWidth={true}
                            placeholder="* * * * *"
                            value={data.target || ""}
                            renderValue={t=>t.name}
                            onChange={(e)=>onFieldChange("target",e.target.value)}
                        >
                            { targets?.map((t,index)=><MenuItem key={index} value={t}>{t.name}</MenuItem>)}
                        </Select>
                        {!!errors.target ? (<FormHelperText>{errors.target}</FormHelperText>) : (<></>)}
                    </FormControl>
                    <FormControl sx={{mt:2}} error={errors.input}>
                        <TextareaAutosize
                            label="Input"
                            labelId="input_label"
                            minRows={8}
                            maxRows={16}
                            value={data.input || ""}
                            onChange={(e)=>onFieldChange("input",e.target.value)}
                        ></TextareaAutosize>
                        {!!errors.input ? (<FormHelperText>{errors.input}</FormHelperText>) : (<></>)}
                    </FormControl>
                </FormGroup>
                <Box sx={{flexGrow: 1}}></Box>
                <FormGroup sx={{width: '95%'}}>
                    {isFilled() && isValid()?<Button onClick={()=>createRuleMutation.mutate()} color="primary">Crea</Button>:<></>}
                </FormGroup>


            </Stack>



        </Box>
    )
};


export default CreateRule