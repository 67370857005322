import { Box, Stack, Table, TableBody, TableCell, TableRow } from "@mui/material";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import CheckTimeline from "../../components/checks/checkTimeline";
import PagePaper from "../../components/page/pagePaper";

import { TableHead } from "@aws-amplify/ui-react";
import { useCabledQuery } from "../../hooks/serviceRegistry/useCabledQuery";
import "./report.scss";




function parseBperListKey(x){
    return {
        key: x.Key,
        outcome: true,
        filename: x.Key.replace("public/reports/", "").replace(".json", ""),
        dt: new Date(1000 * parseInt(x.Key.replace("public/reports/", "").replace(".json", "").replace("report_bper_", "")))
    };
}
export default function BperProcessReport() {


    const [descriptors, setDescriptors] = useState(null);
    const [details,setDetails] = useState({});
    const [displayDetails,setDisplayDetails] = useState(false);
    
    const reportsQuery = useCabledQuery({
        svcName: 's3',
        method: 'listFiles',
        refetchInterval: 10 * 60 * 1000,
        baseQueryKey: ['bperreports'],
        args: {
            bucket: "itpa-customer-bper",
            prefix: "public/reports"
        }
    });
    useEffect(()=>{
        let ddd = [];
        if (reportsQuery?.query?.data){
            console.log(reportsQuery.query.data);
            ddd = reportsQuery.query.data.filter(x=>x.Key.endsWith(".json")).map(parseBperListKey);
            ddd = ddd.sort((a,b)=>b.dt.getTime()-a.dt.getTime());
        }
        setDescriptors(ddd);
    },[reportsQuery?.query?.data]);
    

    const detailsQuery = useCabledQuery({
        svcName: 's3',
        method: 'getFile',
        refetchInterval: 10 * 60 * 1000,
        baseQueryKey: ['bper'],
        args: {
            bucket: "itpa-customer-bper",
            key: displayDetails ? displayDetails : ""
        },
        enabled: !!displayDetails
    });

    useEffect(()=>{
        if (detailsQuery?.query?.data){
            try {
                let data = JSON.parse(detailsQuery.query.data);
                setDetails({...details,[displayDetails]:data});
            } catch(e){
                console.error("Cannot get details");
            }
        }
    },[detailsQuery?.query?.data]);

    

    function onSelectEvent(event){
        setDisplayDetails(event?.key);
    }



    return (
        <PagePaper transparent={true} verticalScroll={false}>
            <Stack direction="row" spacing={2} sx={{overflow: "hidden", height: "100%"}}>
                <Box className="report-timeline" sx={{minWidth: (!!displayDetails ? '15%' : '100%')}}>
                    {descriptors && descriptors.length && (
                        <CheckTimeline events={descriptors} onSelect={onSelectEvent} />
                    )}
                </Box>
                <Box sx={{flexGrow:1}} />
                {!!displayDetails && <Box className="report-details">
                    {details[displayDetails] && (
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>ID</TableCell>
                                    <TableCell>CODICE REMOTO</TableCell>
                                    <TableCell>DATA</TableCell>
                                    <TableCell>SCELTO</TableCell>
                                    <TableCell>URL</TableCell>
                                    <TableCell>AZIONI</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {details[displayDetails].map((d,dI)=>(
                                    <TableRow key={dI}>
                                        <TableCell>{d.ID}</TableCell>
                                        <TableCell>{d.CODICE_REMOTO}</TableCell>
                                        <TableCell>{d.DATA}</TableCell>
                                        <TableCell>{d.IS_INTERESTING ? 'SI' : 'NO'}</TableCell>
                                        <TableCell>{d.HISTORY_TEXT}</TableCell>
                                        <TableCell>{d.AZIONI || d.ACTIONS}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    )}
                </Box> }
            </Stack>
        </PagePaper>
    );
}