import DynamoDB from 'aws-sdk/clients/dynamodb';
import { AWSIamBasedServiceClient } from './awsIamBasedServiceClient';


export class DynamoClient extends AWSIamBasedServiceClient {

    constructor({region, role}) {
        super({ region, role });
        this.client = null;
        this.ready = false;
        // console.log("DDB CLIENT CONSTRUCTED",this);
    }
    async init() {
        // console.log("DDB CLIENT INIT");
        this.refreshClient = async () => {
            await this.refreshCredentials();
            // console.log("DDB CLIENT INIT ",!!this.credentials);
            try {
                this.client = new DynamoDB({ credentials: this.credentials, region: this.region });
                this.ready = true;
                // console.log("DDB CLIENT INITED");
            } catch (e) {
                throw new Error("Cannot init DDB Client due to " + e.toString());
            }
        }
        await this.refreshClient();
        return this;
    }

    async getItem({tableName,dynamoKey}) {
        await this.ensureCredentials();
        const baseParams = {
            TableName: tableName,
            Key: dynamoKey
        }
        let resp = await this.client.getItem(baseParams).promise();
        if (resp.$response.httpResponse.statusCode === 200){
            return resp.Item;
        } else {
            console.error(resp);
            throw new Error("DDB "+resp.$response.httpResponse.statusCode.toString());
        }
    }

    async updateItem({tableName,dynamoKey,updateExpression, expressionAttributeNames = null, expressionAttributeValues = null}) {
        await this.ensureCredentials();
        const baseParams = {
            TableName: tableName,
            Key: dynamoKey,
            UpdateExpression: updateExpression
        }
        if (!!expressionAttributeNames) baseParams['ExpressionAttributeNames'] = expressionAttributeNames
        if (!!expressionAttributeValues) baseParams['ExpressionAttributeValues'] = expressionAttributeValues
        let resp = await this.client.updateItem(baseParams).promise();
        console.log(resp);
        if (resp.$response.httpResponse.statusCode === 200){
            return resp.Item;
        } else {
            console.error(resp);
            throw new Error("DDB "+resp.$response.httpResponse.statusCode.toString());
        }
    }

    async scanTable({tableName, filters = null}) {
        await this.ensureCredentials();
        // console.log("DYNAMO SCAN TABLE",{tableName, filters})
        const baseParams = {
            TableName: tableName,
        };
        if (filters) {
            Object.assign(baseParams, filters);
        }
        async function scanAllRecords(client, params, allRecords = []) {
            try {
                const data = await client.scan(params).promise();
                allRecords = allRecords.concat(data.Items);
                if (data.LastEvaluatedKey) {
                    params.ExclusiveStartKey = data.LastEvaluatedKey;
                    return scanAllRecords(params, allRecords);
                } else {
                    return allRecords;
                }
            } catch (err) {
                console.error('Errore nella scansione della tabella DynamoDB:', err);

                throw err;
            }
        }

        return scanAllRecords(this.client, baseParams);

    }

}
