import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import ItpaApi from '../../services/api';
import { fullDateStr } from '../../services/common/common';

export function useSched({schedName,isClosed=null,enabled=true}) {  
    let refetchInterval = 60*1000;
    if (isClosed === true) refetchInterval = 100*60*1000;
    const CS = ItpaApi.getService("carrefourstate");
    
    const fetchSched = async () => {
        const data = await CS.doGetSched.bind(CS)({schedName});
        return data;
    };
    
    const schedQuery = useQuery({
        queryKey: ['carrefour','scheds',schedName],
        queryFn: fetchSched,
        refetchInterval,
        refetchOnWindowFocus: false,
        enabled
    });


    return schedQuery;
}
export function useScheds({enabled}) {  
    
    const CS = ItpaApi.getService("carrefourstate");

    const fetchScheds = async ({pageParam = null}) => {
        const data = await CS.doListScheds.bind(CS)({next_token:pageParam});
        return data;
    };
    
    const schedsQuery = useInfiniteQuery({
        queryKey: ['carrefour','scheds'],
        queryFn: fetchScheds,
        refetchInterval: 10 * 60 * 1000,
        refetchOnWindowFocus: false,
        getNextPageParam: (lastPage, pages) => {
            if (lastPage.next_token && lastPage.data.length)
                return lastPage.next_token
            return undefined;
        },
        enabled
    });


    return schedsQuery;
}
export function useSchedsPh({date=null}={}) {  
    
    const CS = ItpaApi.getService("carrefourstate");

    const fetchSchedsPh = async ({pageParam = null}) => {
        const data = await CS.doListSchedsPh.bind(CS)({date,next_token:pageParam});
        
        return data;
    };
    
    const schedsPhQuery = useInfiniteQuery({
        queryKey: ['carrefour','schedsph'],
        queryFn: fetchSchedsPh,
        refetchInterval: 60 * 1000,
        refetchOnWindowFocus: true,
        getNextPageParam: (lastPage, pages) => {
            if (lastPage.next_token && lastPage.data.length)
                return lastPage.next_token
            return undefined;
        }
    });


    return schedsPhQuery;
}


export function useSchedsPh2({date=null}={}) {  
    
    const CS = ItpaApi.getService("carrefourstate");
    
    const fetchSchedsPh = async ({pageParam = null}) => {
        const data = await CS.doListSchedsPh.bind(CS)({date,next_token:pageParam});
        
        return data;
    };
    
    const schedsPhQuery = useInfiniteQuery({
        queryKey: ['carrefour','schedsph'],
        queryFn: fetchSchedsPh,
        refetchInterval: 60 * 1000,
        refetchOnWindowFocus: true,
        getNextPageParam: (lastPage, pages) => {
            if (lastPage.next_token && lastPage.data.length)
                return lastPage.next_token
            return undefined;
        }
    });


    return schedsPhQuery;
}
export function useRmsPh({date=null}={}) {  
    if (date === null) date = new Date();
    const CS = ItpaApi.getService("carrefourstate");

    const fetchRmsPh = async ({pageParam = null}) => {
        console.log("fetchRmsPh pageParam:",pageParam);
        const data = await CS.doListSchedsRmPh.bind(CS)({date,next_token:pageParam});
        return data;
    };
    
    const rmsPhQuery = useInfiniteQuery({
        queryKey: ['carrefour','rmsph'],
        queryFn: fetchRmsPh,
        refetchInterval: 20 * 1000,
        refetchOnWindowFocus: true,
        keepPreviousData : true,
        getNextPageParam: (lastPage, pages) => {
            if (lastPage.next_token && lastPage.data.length)
                return lastPage.next_token
            return undefined;
        },
        enabled: false
    });


    return rmsPhQuery;
}
export function useRms() {  
    
    const CS = ItpaApi.getService("carrefourstate");

    const fetchRms = async ({pageParam = null}) => {
        const data = await CS.doListSchedsRm.bind(CS)({next_token:pageParam});
        return data;
    };
    
    const rmsQuery = useInfiniteQuery({
        queryKey: ['carrefour','rms'],
        queryFn: fetchRms,
        refetchInterval: 60 * 1000,
        refetchOnWindowFocus: false,
        getNextPageParam: (lastPage, pages) => {
            if (lastPage.next_token && lastPage.data.length)
                return lastPage.next_token
            return null;
        }
    });


    return rmsQuery;
}
export function useSchedData({schedName, enabled, ttl = null}) {  
    let refetchInterval = (ttl != null) ? ttl : 2*60*1000;
    if (refetchInterval < 1000) refetchInterval *= 1000;
    const CS = ItpaApi.getService("carrefourstate");

    const fetchSchedData = async () => {
        // console.log("Fetch sched data",schedName);
        const data = await CS.do_list_sched_data.bind(CS)({sched:schedName});
        return data;
    };
    
    const schedDataQuery = useQuery({
        queryKey: ['carrefour','scheddata',schedName],
        queryFn: fetchSchedData,
        refetchInterval,
        refetchOnWindowFocus: false,
        enabled
    });


    return schedDataQuery;
}
export function usePdc({which=null}={}) {  
    
    const CS = ItpaApi.getService("carrefourstate");

    const fetchPDCData = async () => {
        const data = await CS.do_get_pdc.bind(CS)({which});
        return data;
    };
    
    const pdcQuery = useQuery({
        queryKey: ['carrefour','pdc',which ? which : "current"],
        queryFn: fetchPDCData,
        refetchInterval: 60 * 10 * 1000,
        refetchOnWindowFocus: false,
    });


    return pdcQuery;
}
export function useDollaroStatus() {  
    
    const CS = ItpaApi.getService("carrefourstate");

    const fetchDollaroData = async () => {
        const data = await CS.do_get_dollaro_status.bind(CS)();
        const lastUpdate = await CS.do_get_dollaro_last_update.bind(CS)();
        data.data = data.data.map(
            x=>({...x,lastUpdate:lastUpdate[x.data_name] ? fullDateStr(new Date(lastUpdate[x.data_name])) : "null"})
        )
        console.log(data);
        
        return data;
    };
    
    const dollaroStatusQuery = useQuery({
        queryKey: ['carrefour','dollaro'],
        queryFn: fetchDollaroData,
        refetchInterval: 60 * 5 * 1000,
        refetchOnWindowFocus: false,
    });


    return dollaroStatusQuery;
}

export function useCredentials() {  
    
    const CS = ItpaApi.getService("carrefourstate");

    const fetchCredentials = async () => {
        const data = await CS.doListCreds.bind(CS)();
        return data;
    };
    
    const credsQuery = useQuery({
        queryKey: ['carrefour','creds'],
        queryFn: fetchCredentials,
        refetchInterval: 60 * 1000,
        refetchOnWindowFocus: false,
    });


    return credsQuery;
}
