import { Typography } from '@mui/material';
import './loader.scss';


export default function Loader({txt=null}){

    return (
        <div className="overlay">
    <div className="loader">
        {txt && <Typography variant="h6">{txt}</Typography>}
        <img src="https://atlas-public-assets.s3.eu-west-1.amazonaws.com/canvas.png" />
        {/* <img src="https://atlas-public-assets.s3.eu-west-1.amazonaws.com/canvas.png" />
        <img src="https://atlas-public-assets.s3.eu-west-1.amazonaws.com/canvas.png" />
        <img src="https://atlas-public-assets.s3.eu-west-1.amazonaws.com/canvas.png" /> */}
    </div>
    </div>
    )
}