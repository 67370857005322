import { AWSIamBasedServiceClient } from "./awsIamBasedServiceClient";
import sigV4Client from "./sigV4Client";
import { getJwt } from "../../services/common/common";


export class SigV4ApiClient extends AWSIamBasedServiceClient {
    constructor({
        region, endpoint, role
    }) {
        super({region,role});
        this.endpoint = endpoint;
        this.client = null;
        this.ready = false;
    }
    async init(){

        this.refreshClient = async ()=>{

            try {
                await this.refreshCredentials();
                this.client = sigV4Client.newClient({
                    accessKey: this.credentials.accessKeyId,
                    secretKey: this.credentials.secretAccessKey,
                    sessionToken: this.credentials.sessionToken,
                    region: this.region,
                    endpoint: this.endpoint
                });
                this.ready = true;
                // console.log("SIGV4CLIENT INITED");
            } catch(e){
                throw new Error("Cannot init SigV4ApiClient due to "+e.toString());
            }
        }
        await this.refreshClient();

        return this;

    }
    static pageParams2Args = (pageParam, existingArgs) => {
        if (!pageParam) return existingArgs;
        const args = Object.assign({},existingArgs);
        args['queryParams'] = args['queryParams'] || {};
        args['queryParams']["nextToken"] = pageParam;
        return args;
    }
    async doRequest({method, path, headers={},body=null,queryParams={}}){
        await this.ensureCredentials();
        if (!this.ready){
            throw new Error("Uninited SigV4Client");
        }
        const signedRequest = this.client.signRequest({
            method,
            path,
            headers,
            queryParams,
            body
        });
        body = body ? JSON.stringify(body) : body;
        headers = signedRequest.headers;
        const results = await fetch(signedRequest.url,{
            method,
            path,
            headers,
            body
        });
        if (results.status !== 200){
            throw new Error(await results.text());
        }
        return results.json();
    }

    async doGet({path,headers={},queryParams={}}){
        // console.log("!!!",path,headers,queryParams);
        return await this.doRequest({
            method: 'GET', path, headers, queryParams
        })
    }

    async doPost({path,headers={},queryParams={},body={}}){
        return await this.doRequest({
            method: 'POST', path, headers, queryParams,body
        })
    }

    async doPostWithCaller({path,headers={},queryParams={},body={}}) {
        body.caller = await getJwt();
        return await this.doPost({path,headers,queryParams, body})
    }

    async doPut({path,headers={},queryParams={},body={}}){
        return await this.doRequest({
            method: 'PUT', path, headers, queryParams,body
        })
    }

    async doDelete({path,headers={},queryParams={}}){
        return await this.doRequest({
            method: 'DELETE', path, headers, queryParams
        })
    }
}

