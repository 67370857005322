import { Box, Stack, Typography } from "@mui/material";
import { useContext } from "react";
import { Navigate, useLocation, useRouteError } from "react-router-dom";
import CenterCanvasLogo from "../components/common/centerCanvasLogo";
import PagePaper from "../components/page/pagePaper";
import { AppContext } from "../hooks/appContext";


export default function ErrorPage() {

    const ee = useRouteError();
    const {currentEnvironment} = useContext(AppContext);
    const location = useLocation();
    console.log(ee,currentEnvironment,location);
    if (ee && ee.status === 404){
        
        if (!!currentEnvironment){
            if (location.pathname === `/${currentEnvironment}`){
                return <Navigate to={`/`} state={{ from: location }} />
                
            } else {
                return <Navigate to={`/${currentEnvironment}`} state={{ from: location }} />;
            }
        }
    }
    return <PagePaper>
        <Stack spacing={2} justifyContent="space-evenly" height="100%" >
            <CenterCanvasLogo />
            <Typography variant="h2">...ops!</Typography>
            <Box sx={{flexGrow:1}}></Box>
            <Box sx={{width: "100%"}}>
                Qualcosa &egrave; andato storto. La pagina che hai cercato non esiste o si è verificato un comportamento inatteso.
                Puoi segnalare la cosa ad un amministratore, che te ne sarà grato in eterno.
            </Box>
        </Stack>
    </PagePaper>
}