import AdminHome from "../pages/admin/Home";
import AdminResourceDetails from "../pages/admin/resourcedetails/Resourcedetails";
import AdminAgents from "../pages/admin/resources.deprecated/typesList.deprecated";

import { Route } from "react-router-dom";
import { ServiceRegistryWrapper } from "../components/serviceRegistry/serviceRegistryWrapper";
import { AdminAgentsDashboard } from "../pages/admin/agents/agentsDashboard";
import { AdminPoolsDashboard } from "../pages/admin/agents/poolsDashboard";
import { AdminClientsDashboard } from "../pages/admin/clients/clientsDashboard";
import AdminEnvironmentList from "../pages/admin/envs/AdminEnvironmentList";
import AdminFlowDetails from "../pages/admin/flows/details";
import AdminExecutionDetails from "../pages/admin/flows/execution";
import AdminFlowList from "../pages/admin/flows/list";
import AdminIncidentDetails from "../pages/admin/problems/AdminIncidentDetails";
import AdminIncidentList from "../pages/admin/problems/AdminIncidentList";
import AdminIncidentTypes from "../pages/admin/problems/AdminIncidentTypes";
import AdminInstallProblemType from "../pages/admin/problems/install";
import AdminSchedulesDetails from "../pages/admin/schedules/details";
import AdminSchedulesList from '../pages/admin/schedules/list';
import AuthorizedSection from "./authorizedSection";
import { LinkB, TextB } from "./breadcrumbs";
// import AdminSystemComponentsList from "../pages/admin/systemComponents/ListSystemComponents";

const keepParams = ({ params }) => params;

const routes = [
    <Route path="admin" element={<AuthorizedSection group="Admin" />}>
        <Route element={<ServiceRegistryWrapper defaultRole="Admin" requiredServices={[
            { service: 'monitoring' },
            { service: 'cw' },
            { service: 'environments' },
            { service: 'agents' },
            { service: 'agentservice' },
            { service: 'clients' },
            { service: 'ddb' },
            { service: 'sqs' }
        ]} />}>
            <Route index element={<AdminHome />} handle={{ crumb: (data) => TextB(0, "Amministrazione") }} />

            <Route path="envs">
                <Route index element={<AdminEnvironmentList />} handle={{ crumb: (data) => TextB(1, "Ambienti") }} />
            </Route>
            <Route path="incidents">
                <Route index element={<AdminIncidentList />} handle={{ crumb: (data) => TextB(1, "Incidents") }} />
                <Route path="types">
                    <Route index element={<AdminIncidentTypes />} handle={{ crumb: (data) => TextB(2, "Incident types") }} />
                    <Route path=":environment/:problem_type" element={<AdminInstallProblemType />} handle={{ crumb: (data) => TextB(3, "Edit tipo problema") }} />
                </Route>
                <Route path=":problem_id" element={<AdminIncidentDetails />} handle={{ crumb: (data) => [LinkB(0, "Incidents", '/admin/incidents'), TextB(1, "Incident details")] }} />
            </Route>
            <Route path="flows">
                <Route index element={<AdminFlowList />} handle={{ crumb: (data) => TextB(1, "Flussi installati") }} />
                {/* <Route path="install_new_flow" element={<AdminFlowInstall />} /> */}
                <Route
                    path=":environment/:flow_name"
                    element={<AdminFlowDetails />}
                    loader={keepParams}
                    handle={{ crumb: (data) => [LinkB(0, "Flussi installati", '/admin/flows'), TextB(1, data.flow_name)] }} />
                <Route
                    path=":environment/:flow_name/:execution_name"
                    element={<AdminExecutionDetails />}
                    loader={keepParams}
                    handle={{ crumb: (data) => [LinkB(0, "Flussi installati", '/admin/flows'), LinkB(0, data.flow_name, `/admin/flows/${data.environment}/${data.flow_name}`), TextB(1, "Dettagli esecuzione")] }}
                />
            </Route>
            {/* <Route path="components"> //disattivato perchè non utilizzato
                <Route index element={<AdminSystemComponentsList />} />
            </Route> */}
            <Route path="agents">
                <Route index element={<AdminPoolsDashboard />} handle={{ crumb: (data)=>[TextB(1, 'Agents Pools')]}}/>
                <Route path=":pool" element={<AdminAgentsDashboard />} loader={keepParams} handle={{ crumb: (data)=>[LinkB(0, 'Agents Pools','/admin/agents'),TextB(1,'Pool '+data?.pool)]}}/>
            </Route>
            
            <Route path="clients">
                <Route index element={<AdminClientsDashboard />} handle={{ crumb: (data) => TextB(1, "Clienti e servizi") }} />
            </Route>
            <Route path="schedules">
                <Route index element={<AdminSchedulesList />} handle={{ crumb: (data) => TextB(1, "Schedulazioni") }} />
                <Route path=":groupAndName" element={<AdminSchedulesDetails />} handle={{ crumb: (data) => TextB(1, "Schedulazioni") }} />
            </Route>
        </Route>
    </Route>
];

export default routes;