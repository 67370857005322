import Timeline from '@mui/lab/Timeline';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import { Box, Paper, Stack, Typography, styled } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from "react-router-dom";


import Deck from '../../../components/queries/deck';
import ExecutionDetails from '../../../components/flows/executionDetails';
import { useCabledQuery } from '../../../hooks/useCabledQuery';
import ItpaApi from '../../../services/api';
import { elapsed, fullDateStr } from '../../../services/common/common';
const MyPaper = styled(Paper)(({ theme }) => ({
    marginTop: 0,
    padding: theme.spacing(1)
}));


const StartTimelineItem = ({ data }) => {
    return <TimelineItem>
        <TimelineOppositeContent color="text.secondary">
            {fullDateStr(data.start_date)}<br />({elapsed(data.start_date)})
        </TimelineOppositeContent>
        <TimelineSeparator>
            <TimelineDot sx={{backgroundColor:"green"}} />
            <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent>
            <Box sx={{ maxWidth: 300, maxHeight: 200 }}>
                <Typography variant="h6" component="div">
                    STARTED
                </Typography>
                <Typography sx={{ fontSize: 12 }} color="text.secondary" gutterBottom>

                </Typography>
            </Box>
        </TimelineContent>
    </TimelineItem>
}
const EndTimelineItem = ({ data }) => {
    const colorPerStatus = {
        'TIMED_OUT': 'red', 'FAILED': 'red','SUCCEEDED': 'green','ABORTED':'grey'
    };
    return <TimelineItem>
        <TimelineOppositeContent color="text.secondary">
            {fullDateStr(data.end_date)}<br />({elapsed(data.end_date)})
        </TimelineOppositeContent>
        <TimelineSeparator>
            <TimelineDot sx={{backgroundColor:colorPerStatus[data.status]}} />
        </TimelineSeparator>
        <TimelineContent>
            <Box sx={{ maxWidth: 300, maxHeight: 200 }}>
                <Typography variant="h6" component="div">
                    FINISHED
                </Typography>
                <Typography sx={{ fontSize: 12 }} color="text.secondary" gutterBottom>
                    {data.status}
                </Typography>
            </Box>
        </TimelineContent>
    </TimelineItem>
}
const ActivityTimelineItem = ({ title,details,color,dt }) => {
    return <TimelineItem>
        <TimelineOppositeContent color="text.secondary">
            {fullDateStr(dt)}<br />({elapsed(dt)})
        </TimelineOppositeContent>
        <TimelineSeparator>
            <TimelineDot sx={{backgroundColor:color}} />
            <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent>
            <Box sx={{ maxWidth: 300, maxHeight: 200 }}>
                <Typography variant="h6" component="div">
                    {title}
                </Typography>
                <Typography sx={{ fontSize: 12 }} color="text.secondary" gutterBottom>
                    {details}
                </Typography>
            </Box>
        </TimelineContent>
    </TimelineItem>
}

const AdminExecutionDetails = () => {
    const params = useParams();
    const environment = params.environment;
    const flowName = params.flow_name;
    const executionName = params.execution_name;
    const navigate = useNavigate();
    if (!flowName) {
        navigate('../..');
    }
    if (!executionName) {
        navigate('..');
    }


    const monServ = ItpaApi.getService("monitoring");

    const stopFlow = async () => {
        const response = await monServ.abortFlowExecution.bind(monServ)({ flowName, executionName, environment });
        console.log("ABORT FLOW RESPONSE", response);
        setTimeout(() => {
            queryClient.invalidateQueries(executionQueryKey)    
        }, 1000);
        
    }

    const stopFlowMutation = useMutation({
        mutationFn: stopFlow
    })


    const queryClient = useQueryClient();
    const { query: executionQuery, queryKey: executionQueryKey } = useCabledQuery({
        apiService: 'monitoring',
        method: 'getExecution',
        refetchInterval: 60 * 1000,
        baseQueryKey: 'execution',
        filters: { flowName, executionName, environment },

    })
    const [activities,setActivities] = useState([]);
    useEffect(()=>{
        let acts = [];
        if (executionQuery.data && executionQuery.data.activities && executionQuery.data.activities.length){
            for (let activity of executionQuery.data.activities){
                acts.push({
                    title: "TASK: "+activity.name,
                    details: "STARTED",
                    color: 'green',
                    dt: new Date(activity.iat)
                })
                if (activity.closed_at){
                    acts.push({
                        title: "TASK: "+activity.name,
                        details: activity.status+ " " + (activity.closed_by ? `(${activity.closed_by})` : ""),
                        color: activity.status === "SUCCEEDED" ? 'green' : 'red',
                        dt: new Date(activity.closed_at)
                    })

                }
            }
        }
        acts.sort((a,b)=>a.dt<b.dt ? -1 : 1);
        setActivities(acts);

    },[executionQuery?.data?.activities])


    

    if (executionQuery.isFetching) {
        console.log("Execution is loading");
        return <div>Loading...</div>
    }
    if (executionQuery.isError) {
        console.log("Execution is error:", executionQuery.error.message);
        return <div>Execution is error: {executionQuery.error.message}</div>
    }

    return (
        <Stack justifyContent="space-between">
            <Deck title="Stato esecuzione" queryKey={executionQueryKey} spaceBelow={1} />
            {executionQuery.data && (
                <Stack direction="row" justifyContent="space-between">
                    <MyPaper sx={{ width: "49%", height: "70vh" }}>
                        <ExecutionDetails 
                            executionData={executionQuery.data} 
                            onStopExecution={()=>stopFlowMutation.mutate()}
                            />
                    </MyPaper>
                    <Box sx={{ flexGrow: 1 }} />
                    <MyPaper sx={{ width: "49%", height: "70vh", }}>
                        <Timeline>
                            <StartTimelineItem data={executionQuery.data} />
                            {
                                activities?.map((act, i) => <ActivityTimelineItem key={i} {...act} />)
                            }
                            {!!executionQuery.data.end_date && (
                                <EndTimelineItem data={executionQuery.data} />
                            )}

                        </Timeline>
                    </MyPaper>
                </Stack>
            )}

        </Stack>
    )
}



export default AdminExecutionDetails