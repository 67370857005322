import { Paper, styled } from "@mui/material"



const SeverityWidgetContainer = styled(Paper,{
    shouldForwardProp: p=>![
            'severity',
            'lightness'
        ].includes(p)
})(({
    theme,
    severity=1,
    lightness="normal"
})=>{

    let style = {
        ...theme.containerBoxStyle,
        boxShadow: theme.shadows[3],
        backgroundColor: (function(){
            switch(lightness){
                case 'light':
                    return theme.palette.severitiesLight[severity];
                case 'lighter':
                    return theme.palette.severitiesLighter[severity];
                default:
                    return theme.palette.severities[severity];
            }
        })(),
        
        

        ':hover':{
            boxShadow: theme.shadows[7],
        }
    };
    return style;
});

export default SeverityWidgetContainer;