import AcmeService from "./services/acme/acmeService";
import ItpaApi from "./services/api";
import { CloudWatchClient } from "./services/aws/cloudwatch";
import { DynamoClient } from "./services/aws/dynamo";
import { LambdaClient } from "./services/aws/lambda";
import { S3Client } from "./services/aws/s3";
import { SigV4ApiClient } from "./services/aws/sigV4api";
import { SqsClient } from "./services/aws/sqs";
import CarrefourStateService from "./services/carrefourstate/carrefourStateService";
import ClientsService from "./services/clients/clientsService";
import CoreService from "./services/monitoring/core";
import MonitoringService from "./services/monitoring/monitoringService";
import OperatorMonitoringService from "./services/monitoring/operatorMonitoringService";
import NotificationService from "./services/notification/notificationService";
import ResourcesService from "./services/resources/resourcesService";
import ServiceRegistry from "./services/serviceRegistry/serviceRegistry";
import AgentService from "./services/agents/agentService";

export function SetupServices(domain) {

    // installazione servizi
    if (domain === 'localhost') {
        ServiceRegistry.setSigV4Service('environments',SigV4ApiClient,{region: 'eu-west-1',endpoint: 'https://x4niy1w6of.execute-api.eu-west-1.amazonaws.com/collaudo'});
        ServiceRegistry.setSigV4Service('agents',SigV4ApiClient,{region: 'eu-west-1',endpoint: 'https://9fycx44g3g.execute-api.eu-west-1.amazonaws.com/coll'});
        ServiceRegistry.setSigV4Service('clients',SigV4ApiClient,{region: 'eu-west-1',endpoint: 'https://vq7w01t8p2.execute-api.eu-west-1.amazonaws.com/sandbox'});
        ServiceRegistry.setSigV4Service('loginctrl',SigV4ApiClient,{region: 'eu-west-1',endpoint: 'https://hwj3dodno2.execute-api.eu-west-1.amazonaws.com/prod'});
        ServiceRegistry.setAwsService('ddb',DynamoClient,{region: 'eu-west-1'});
        ServiceRegistry.setAwsService('cw',CloudWatchClient,{region: 'eu-west-1'});
        ServiceRegistry.setAwsService('s3',S3Client,{region: 'eu-west-1'});
        ServiceRegistry.setAwsService('lambda',LambdaClient,{region: 'eu-west-1'});
        ServiceRegistry.setAwsService('sqs',SqsClient,{region: 'eu-west-1'});
        ServiceRegistry.setGenericService('acme', AcmeService, {})
        
        ServiceRegistry.setGenericService('carrefourstate',CarrefourStateService,{baseUrl:"https://collaudo.crf.atlasreplymonitoring.it"});
        ServiceRegistry.setGenericService('monitoring',MonitoringService,{baseUrl:"https://collaudo.monitoring.atlasreplymonitoring.it"});
        ServiceRegistry.setSigV4Service('agentservice',AgentService,{region: 'eu-west-1',endpoint: 'https://9fycx44g3g.execute-api.eu-west-1.amazonaws.com/coll'});
        
        ItpaApi.setService('operator-monitoring', new OperatorMonitoringService('https://collaudo.monitoring.atlasreplymonitoring.it/operator'));
        ItpaApi.setService('notification', new NotificationService('https://collaudo.notification.atlasreplymonitoring.it'));
        ItpaApi.setService('monitoring', new MonitoringService('https://collaudo.monitoring.atlasreplymonitoring.it'));
        ItpaApi.setService('clients', new ClientsService('https://uhamtmw2e0.execute-api.eu-west-1.amazonaws.com/dev'));
        ItpaApi.setService('resources', new ResourcesService('https://collaudo.resources.atlasreplymonitoring.it'));
        ItpaApi.setService('carrefourstate', new CarrefourStateService('https://collaudo.crf.atlasreplymonitoring.it'));
        
        
        ItpaApi.setService('core', new CoreService('https://bc13dwsnek.execute-api.eu-west-1.amazonaws.com/prod'));
    } else if (domain === "dev.operator.atlasreplymonitoring.it") {
        ServiceRegistry.setSigV4Service('environments',SigV4ApiClient,{region: 'eu-west-1',endpoint: 'https://x4niy1w6of.execute-api.eu-west-1.amazonaws.com/collaudo'});
        ServiceRegistry.setSigV4Service('agents',SigV4ApiClient,{region: 'eu-west-1',endpoint: 'https://9fycx44g3g.execute-api.eu-west-1.amazonaws.com/coll'});
        ServiceRegistry.setSigV4Service('clients',SigV4ApiClient,{region: 'eu-west-1',endpoint: 'https://grws24ny6k.execute-api.eu-west-1.amazonaws.com/collaudo'});
        ServiceRegistry.setSigV4Service('loginctrl',SigV4ApiClient,{region: 'eu-west-1',endpoint: 'https://hwj3dodno2.execute-api.eu-west-1.amazonaws.com/prod'});
        ServiceRegistry.setAwsService('ddb',DynamoClient,{region: 'eu-west-1'});
        ServiceRegistry.setAwsService('cw',CloudWatchClient,{region: 'eu-west-1'});
        ServiceRegistry.setAwsService('s3',S3Client,{region: 'eu-west-1'});
        ServiceRegistry.setAwsService('lambda',LambdaClient,{region: 'eu-west-1'});
        ServiceRegistry.setAwsService('sqs',SqsClient,{region: 'eu-west-1'});
        ServiceRegistry.setGenericService('carrefourstate',CarrefourStateService,{baseUrl:"https://collaudo.crf.atlasreplymonitoring.it"});
        ServiceRegistry.setGenericService('monitoring',MonitoringService,{baseUrl:"https://collaudo.monitoring.atlasreplymonitoring.it"});
        ServiceRegistry.setSigV4Service('agentservice',AgentService,{region: 'eu-west-1',endpoint: 'https://9fycx44g3g.execute-api.eu-west-1.amazonaws.com/coll'});
        
        ItpaApi.setService('operator-monitoring', new OperatorMonitoringService('https://collaudo.monitoring.atlasreplymonitoring.it/operator'));
        ItpaApi.setService('notification', new NotificationService('https://collaudo.notification.atlasreplymonitoring.it'));
        ItpaApi.setService('monitoring', new MonitoringService('https://collaudo.monitoring.atlasreplymonitoring.it'));
        ItpaApi.setService('clients', new ClientsService('https://uhamtmw2e0.execute-api.eu-west-1.amazonaws.com/dev'));
        ItpaApi.setService('resources', new ResourcesService('https://collaudo.resources.atlasreplymonitoring.it'));
        ItpaApi.setService('carrefourstate', new CarrefourStateService('https://collaudo.crf.atlasreplymonitoring.it'));
        ItpaApi.setService('core', new CoreService('https://bc13dwsnek.execute-api.eu-west-1.amazonaws.com/prod'));
        // ItpaApi.setService('environments', new EnvironmentService('https://x4niy1w6of.execute-api.eu-west-1.amazonaws.com/prod'));
    } else if (domain === "operator.atlasreplymonitoring.it") {
        ServiceRegistry.setSigV4Service('environments',SigV4ApiClient,{region: 'eu-west-1',endpoint: 'https://mnzkbfw9rh.execute-api.eu-west-1.amazonaws.com/production'});
        ServiceRegistry.setSigV4Service('agents',SigV4ApiClient,{region: 'eu-west-1',endpoint: 'https://9fycx44g3g.execute-api.eu-west-1.amazonaws.com/coll'});
        ServiceRegistry.setSigV4Service('clients',SigV4ApiClient,{region: 'eu-west-1',endpoint: 'https://hsxuavp09f.execute-api.eu-west-1.amazonaws.com/production'});
        ServiceRegistry.setSigV4Service('loginctrl',SigV4ApiClient,{region: 'eu-west-1',endpoint: 'https://hwj3dodno2.execute-api.eu-west-1.amazonaws.com/prod'});
        ServiceRegistry.setAwsService('ddb',DynamoClient,{region: 'eu-west-1'});
        ServiceRegistry.setAwsService('cw',CloudWatchClient,{region: 'eu-west-1'});
        ServiceRegistry.setAwsService('s3',S3Client,{region: 'eu-west-1'});
        ServiceRegistry.setAwsService('lambda',LambdaClient,{region: 'eu-west-1'});
        ServiceRegistry.setAwsService('sqs',SqsClient,{region: 'eu-west-1'});
        ServiceRegistry.setGenericService('carrefourstate',CarrefourStateService,{baseUrl:"https://production.crf.atlasreplymonitoring.it"});
        ServiceRegistry.setGenericService('monitoring',MonitoringService,{baseUrl:"https://production.monitoring.atlasreplymonitoring.it"});
        ServiceRegistry.setSigV4Service('agentservice',AgentService,{region: 'eu-west-1',endpoint: 'https://9fycx44g3g.execute-api.eu-west-1.amazonaws.com/coll'});

        ItpaApi.setService('operator-monitoring', new OperatorMonitoringService('https://production.monitoring.atlasreplymonitoring.it/operator'));
        ItpaApi.setService('notification', new NotificationService('https://production.notification.atlasreplymonitoring.it'));
        ItpaApi.setService('monitoring', new MonitoringService('https://production.monitoring.atlasreplymonitoring.it'));
        ItpaApi.setService('clients', new ClientsService('https://uhamtmw2e0.execute-api.eu-west-1.amazonaws.com/prod'));
        ItpaApi.setService('resources', new ResourcesService('https://production.resources.atlasreplymonitoring.it'));
        ItpaApi.setService('carrefourstate', new CarrefourStateService('https://production.crf.atlasreplymonitoring.it'));
        ItpaApi.setService('core', new CoreService('https://bc13dwsnek.execute-api.eu-west-1.amazonaws.com/prod'));
        // ItpaApi.setService('environments', new EnvironmentsService('https://prod.envservice.atlasreplymonitoring.it/envs'));

    }
}