import { Box, Stack, Typography } from '@mui/material';
import 'dayjs/locale/it';
import React, { useContext, useState } from 'react';
import { AppContext } from '../../hooks/appContext';
import useCabledMutation from '../../hooks/useCabledMutation';
import { useCabledQuery } from '../../hooks/useCabledQuery';
import { alertService } from '../../services/alerting/alerting';
import ItpaApi from '../../services/api';
import { queryAwaiter } from '../../services/common/common';
import OpinionatedForm from '../forms/opinionatedForm';
const AdminCreateIncidentType = ({
    onSuccess = () => { },
    onCancel = () => { }
}) => {
    const { currentEnvironment } = useContext(AppContext);
    const [formValues, setFormValues] = useState({});

    const { query: flowsQuery } = useCabledQuery({
        apiService: "monitoring",
        method: "listAllFlows",
        baseQueryKey: 'all_flows',
        refetchInterval: 10 * 60 * 1000,
        filters: { environment: currentEnvironment }
    });

    const moniServ = ItpaApi.getService("monitoring");


    const createIncidentTypeMutation = useCabledMutation({
        apiService: 'monitoring',
        method: 'createProblemType',
        onSuccess: (result) => {
            alertService.success("Incident type created " + JSON.stringify(result, null, 2));
            return onSuccess();
        },
        onFailure: (e) => {
            if (e.status === 409) alertService.error("Duplicated incident type");
            else alertService.error("Cannot create incident type")
        }
    })
    const loadFlows = queryAwaiter({
        query: flowsQuery,
        transformData: data=>data?.map(x => x.name) || [],
        trasformError: () => []
    });

    const fields = [
        { name: 'problemType', type: 'text', required: true, label: 'Incident type', validator: v=>{
            if (!v || v.length < 5) return "Incident type must be at least 5 characters long";
            return null;
        } },
        {
            name: 'severity1', type: 'select', label: 'On warning', getOptions: loadFlows
        }, {
            name: 'severity2', type: 'select', label: 'On minor', getOptions: loadFlows
        }, {
            name: 'severity3', type: 'select', label: 'On major', getOptions: loadFlows
        }, {
            name: 'severity4', type: 'select', label: 'On critical', getOptions: loadFlows
        }

    ];
    const buttons = [
        { name: "Cancel", onClick: () => onCancel(), color: "error" },
        {
            name: "Create", onClick: () => {
                let payload = { environment: currentEnvironment, problemType: formValues.problemType, flowRules: []};
                if (formValues.severity1) payload.flowRules.push({ flow_name: formValues.severity1, severity_min: 1 });
                if (formValues.severity2) payload.flowRules.push({ flow_name: formValues.severity2, severity_min: 2 });
                if (formValues.severity3) payload.flowRules.push({ flow_name: formValues.severity3, severity_min: 3 });
                if (formValues.severity4) payload.flowRules.push({ flow_name: formValues.severity4, severity_min: 4 });
                
                let x = createIncidentTypeMutation.mutate(payload);
                
            }, color: "success", disabled: (formIsValid) => !formIsValid
        }
    ];

    return (
        <Stack spacing={2} sx={{ minWidth: "25vw" }} alignItems="center">
            <Box sx={{ height: "10vh" }} />
            <Typography variant="h6" component="div" gutterBottom>New incident type</Typography>
            <OpinionatedForm fields={fields} buttons={buttons} formValues={formValues} setFormValues={setFormValues} displayJson={true} validateOnChange={true} />
        </Stack>

    )
}



export default AdminCreateIncidentType