import { Box, Stack, Typography } from '@mui/material';
import React, { useContext, useState } from 'react';



import { AppContext } from '../../../hooks/appContext';
import useCabledMutation from '../../../hooks/serviceRegistry/useCabledMutation';
import OpinionatedForm from '../../forms/opinionatedForm';




const AdminCreateClientEditor = ({
    onCancel = () => { },
    onSuccess = () => { },
    onError = () => { }
}) => {
    const { currentEnvironment } = useContext(AppContext);
    const [formValues, setFormValues] = useState({});
    const createClientMutation = useCabledMutation({
        svcName: 'clients',
        method: 'doPost',
        onSuccess,
        onFailure: onError
    });

    const fields = [
        {
            name: "clientName", label: "Client Name", type: "text", required: true
        },
        {
            name: "clientCode", label: "Client Code", type: "text", required: true, placeholder: "CLI"
        },
        {
            name: "services", label: "Servizi", type: "autocomplete", required: false, getOptions: ()=>[], multiple:true, freeSolo: true
        }
    ];
    const buttons = [
        { name: "Cancel", onClick: () => onCancel(), color: "error" },
        {
            name: "Create", onClick: () => {
                createClientMutation.mutate({
                    path: `/clients`, body: {
                        clientName: formValues.clientName,
                        clientCode: formValues.clientCode,
                        services: formValues.services || [],
                    }
                })
            }, color: "success", disabled: (formIsValid) => !formIsValid
        }
    ];

    return (

        <Stack spacing={2} sx={{ minWidth: "25vw" }} alignItems="center">
            <Box sx={{ height: "10vh" }} />
            <Typography variant="h6" component="div" gutterBottom>Create client</Typography>
            <OpinionatedForm fields={fields} buttons={buttons} formValues={formValues} setFormValues={setFormValues} displayJson={true} validateOnChange={true} />
        </Stack>
    )
}
export default AdminCreateClientEditor