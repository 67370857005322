
import { ApiClient } from '../api';
import dayjs from 'dayjs';

class CarrefourStateService extends ApiClient {


    async doGetSched({schedName}){
        return await this.do_get(`/fe/scheds/${schedName}`,{},{});
    }
    async doListScheds({next_token=null}){
        return await this.do_get(`/fe/scheds`,{},{next_token});
    }
    async doListSchedsPh({page_size=100,date,next_token=null}){
        // console.log("doListSchedsPh",arguments);
        return await this.do_get(`/fe/scheds/ph`,{at:{gt:dayjs(date).startOf('day').toDate()}},{page_size, next_token});
    }
    async doListSchedsRmPh({page_size=100, date,next_token=null}){
        return await this.do_get(`/fe/rms/ph`,{at:{gt:dayjs(date).startOf('day').toDate()}},{page_size, next_token});
    }
    async doListSchedsRm({next_token=null}){
        return await this.do_get(`/fe/rms`,{},{next_token});
    }
    async do_get_sched_status({sched}){
        return await this.do_get(`/fe/scheds/${sched}/status`);
    }
    async do_list_sched_data({sched}){
        return await this.do_get_all_pages(`/fe/scheds/${sched}/data`);
    }
    async do_get_pdc({which}){
        if (!which) which = "current";
        return await this.do_get(`/fe/pdcs/${which}`);
    }
    async do_get_dollaro_status(){
        return await this.do_get(`/fe/dollaro`);
    }
    async do_get_dollaro_last_update(){
        return await this.do_get(`/fe/dollaro/lastupdate`);
    }
    async doListCreds(){
        return await this.do_get(`/fe/creds`);
    }
    async doUpdateSchedMeta({schedName,meta}){
        return await this.do_put(`/fe/scheds/${schedName}/meta`,meta);
    }
    async doListStats({dayGt=null,dayLt=null,labels=null}){
        let pld = {};
        if (dayGt || dayLt) pld.day = {};
        if (dayGt) pld.day.gt = dayGt;
        if (dayGt) pld.day.lt = dayLt;
        if (labels) pld.label = labels;
        return await this.do_get(`/fe/stats`,pld);
    }


}







export default CarrefourStateService