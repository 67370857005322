import { Drawer } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import React, { useState } from 'react';

import AdminEnvironmentSelector from '../../../components/admin/environmentSelector';
import Deck from '../../../components/queries/deck';
import AdminEnvCreate from '../../../components/environments/create';
import EnvWidget from '../../../components/environments/envWidget';
import AdminEnvDisable from '../../../components/environments/disable';
import AdminEnvEnable from '../../../components/environments/enable';
import { updateEnvInEnvs } from '../../../hooks/updaters/environments';
import { alertService } from '../../../services/alerting/alerting';
import { useCabledQuery } from '../../../hooks/serviceRegistry/useCabledQuery';

const AdminEnvironmentList = () => {
    const [enablingEnvironment, setEnablingEnvironment] = useState(null);
    const [disablingEnvironment, setDisablingEnvironment] = useState(null);
    const [sideContent, setSideContent] = useState(null);
    const queryClient = useQueryClient();
    const { query: envsQuery, queryKey: envsQueryKey } = useCabledQuery({
        svcName: 'environments',
        method: 'doGet',
        baseQueryKey: 'environments',
        args: { path: '/envs' },
        refetchInterval: 10 * 60 * 1000
    });

    if (envsQuery.isLoading) return <div>Loading...</div>
    if (envsQuery.isError) return <div>Impossibile scaricare i dati degli ambienti</div>
    const clearSideContent = () => { setSideContent(null); }
    const clearEnvironmentEnabling = () => { setEnablingEnvironment(null); setDisablingEnvironment(null); }
    return <>

        <Deck
            hasOmniBox={false}
            queryKey={envsQueryKey}
            hasRefresh={true}
            onRefresh={() => console.log("REFRESHED!")}
            title={<AdminEnvironmentSelector />}
            hasNewItem={true}
            onNewItem={() => setSideContent("create")}
        />



        {
            envsQuery.data.map((env, envI) => (
                <EnvWidget
                    key={envI}
                    name={env.name}
                    disabled={env.disabled || false}
                    onChange={() => queryClient.invalidateQueries(envsQueryKey)}
                    onEnablingEnvironment={() => { setEnablingEnvironment(env.name); setSideContent("enable"); }}
                    onDisablingEnvironment={() => { setDisablingEnvironment(env.name); setSideContent("disable"); }}
                />
            ))
        }


        <Drawer
            anchor="right"
            open={sideContent !== null}
            onClose={clearSideContent}>

            {sideContent === "create" && (
                <AdminEnvCreate
                    onCancel={() => setSideContent(null)}
                    onSuccess={(result) => {
                        setSideContent(null);
                        alertService.success("Environment created");
                        queryClient.setQueriesData(['environments'], oldData => updateEnvInEnvs(oldData, result));
                    }}
                    onError={(error) => {
                        alertService.error(error?.detail || "Cannot create environment")
                    }}
                />
            )}
            {sideContent === "enable" && (
                <AdminEnvEnable
                    name={enablingEnvironment}
                    onCancel={() => { setSideContent(null); clearEnvironmentEnabling() }}
                    onSuccess={(result) => {
                        setSideContent(null);
                        alertService.success("Environment enabled");
                        queryClient.setQueriesData(['environments'], oldData => updateEnvInEnvs(oldData, result));
                    }}
                    onError={(error) => {
                        alertService.error(error?.detail || "Cannot enable environment")
                    }}
                />
            )}
            {sideContent === "disable" && (
                <AdminEnvDisable
                    name={disablingEnvironment}
                    onCancel={() => { setSideContent(null); clearEnvironmentEnabling() }}
                    onSuccess={(result) => {
                        setSideContent(null);
                        alertService.success("Environment disabled");
                        queryClient.setQueriesData(['environments'], oldData => updateEnvInEnvs(oldData, result));
                    }}
                    onError={(error) => {
                        alertService.error(error?.detail || "Cannot disable environment")
                    }}
                />
            )}

        </Drawer>
    </>


}



export default AdminEnvironmentList;