import AssignmentReturnedOutlinedIcon from '@mui/icons-material/AssignmentReturnedOutlined';
import AssignmentTurnedInOutlinedIcon from '@mui/icons-material/AssignmentTurnedInOutlined';
import CloseIcon from '@mui/icons-material/Close';
import { AppBar, Box, Chip, CircularProgress, IconButton, Tab, Tabs, Toolbar, Tooltip, Typography } from '@mui/material';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import Markdown from 'markdown-to-jsx';
import PropTypes from 'prop-types';
import React from 'react';
import { alertService } from '../../services/alerting/alerting';
import ItpaApi from '../../services/api';
import ActivityDetailsCommands from './detailsCommands';
import ActivityDetailsFeedback from './detailsFeedback';
import ActivityDetailsNotes from './detailsNotes';

import "./details.scss";

const DescriptionText = ({text})=>{
    
    return <div style={{textAlign:"left",wordWrap:true}}>
        <Markdown>
            {text}
        </Markdown>
    </div>
    
}


const ActivityDetails = (props) => {

    const [tabFocus, setTabFocus] = React.useState(0);
    const [descriptions, setDescriptions] = React.useState({});
    const [chargeActivityLoading, setChargeActivityLoading] = React.useState(false);
    function TabPanel(props) {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box sx={{ p: 3 }}>
                        <Typography>{children}</Typography>
                    </Box>
                )}
            </div>
        );
    }

    TabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.number.isRequired,
        value: PropTypes.number.isRequired,
    };


    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }
    const notiServ = ItpaApi.getService('notification');
    const queryClient = useQueryClient();

    const fetchDescription = async () => {
        const data = await notiServ.getActivityDescription.bind(notiServ)({ activityId: props.activity.activity_id });
        setDescriptions(Object.assign(descriptions, { [data.activity_id]: true }));
        return data.description || "";
    }

    const descriptionQuery = useQuery({
        queryKey: ['activity', props.activity.activity_id, 'description'],
        queryFn: fetchDescription
    });

    const takeChargeActivity = async () => {
        const changeId = "CA" + (new Date().valueOf().toString());
        setChargeActivityLoading(true);
        let is_fulfilled = false;
        while (!is_fulfilled) {
            const res = await notiServ.requireActivityAssignmentChange({
                activityId: props.activity.activity_id,
                changeId
            }, {
                onSuccess: ({ changeId, fulfilled }) => {
                    is_fulfilled = fulfilled;
                    if (fulfilled) {
                        setChargeActivityLoading(false);
                        
                        queryClient.invalidateQueries(['all_alerts'])
                    }
                },
                onError: () => alertService.error("Impossibile assegnare")
            });
        }
    }
    const takeChargeActivityMutation = useMutation({
        mutationKey: ['take_charge_activity'],
        mutationFn: takeChargeActivity
    });

    const changeTab = (e, v) => { setTabFocus(v); };

    return <Box sx={{ height: props.viewHeight }}>
        <AppBar sx={{ position: 'relative' }} color="background" className="details-toolbar">
            <Toolbar>
                <IconButton
                    edge="start"
                    color="inherit"
                    onClick={() => props.toggleDetailsDisplay(null)}
                    aria-label="close"
                >
                    <CloseIcon />
                </IconButton>
                <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                    {props.activity.activity_name}
                </Typography>
                {chargeActivityLoading ? (
                    <CircularProgress></CircularProgress>
                ) : (
                    (!!props.activity?.assignment?.assignee) ? (
                        <Tooltip title={"Assegnato a " + props.activity.assignment.assignee + "  Click per disassegnare."}>
                            <IconButton>
                                <AssignmentTurnedInOutlinedIcon></AssignmentTurnedInOutlinedIcon>
                            </IconButton>
                        </Tooltip>) : (
                        <Tooltip title="Prendi in carico">
                            <IconButton onClick={() => takeChargeActivityMutation.mutate()}>
                                <AssignmentReturnedOutlinedIcon></AssignmentReturnedOutlinedIcon>
                            </IconButton>
                        </Tooltip>
                    )

                )}
                <Chip className={`alertrow-${props.activity.severity}`} label={props.activity.severity} variant="outlined" />
            </Toolbar>
        </AppBar>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={tabFocus} onChange={changeTab} aria-label="basic tabs example">
                <Tab label="Dettagli"  {...a11yProps(0)} />
                <Tab label="Comandi"  {...a11yProps(1)} />
                <Tab label="Feedback"  {...a11yProps(2)} />
                <Tab label="Note"  {...a11yProps(3)} />
            </Tabs>
        </Box>

        <TabPanel value={tabFocus || 0} index={0}>
            <Box className="alertDetailsInfo" sx={{ pt: 2 }}>

                    {
                        (descriptionQuery.isFetching) ? (<span>Loading...</span>) : (
                            descriptionQuery.isError ? (
                                <span>{descriptionQuery.error.message}</span>
                            ) : (
                                <DescriptionText text={descriptionQuery.data}></DescriptionText>
                            )
                        )
                    }
            </Box>
        </TabPanel>
        <TabPanel value={tabFocus || 0} index={1}>
            <ActivityDetailsCommands onClose={() => props.toggleDetailsDisplay(null)} activity={props.activity}></ActivityDetailsCommands>
        </TabPanel>
        <TabPanel value={tabFocus || 0} index={2}>
            <ActivityDetailsFeedback onClose={() => props.toggleDetailsDisplay(null)} activity={props.activity}></ActivityDetailsFeedback>
        </TabPanel>

        <TabPanel value={tabFocus || 0} index={3}>
            <ActivityDetailsNotes activity={props.activity}></ActivityDetailsNotes>
        </TabPanel>
        <div style={{position: "relative",bottom: "5px"}}>{props.activity.activity_id}</div>
    </Box>
}


export default ActivityDetails;