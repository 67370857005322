import { useQueries, useQuery, useQueryClient } from '@tanstack/react-query';
import ItpaApi from '../services/api';

export function useStepFunctionRegions({ enabled }) {
    const monServ = ItpaApi.getService("monitoring");
    const stepFunctionRegionsQuery = useQuery({
        queryFn: async () => await monServ.listSFRegions.bind(monServ)(),
        queryKey: ['all_sf_regions'],
        refetchInterval: 1000 * 60 * 60,
        enabled
    });
    return stepFunctionRegionsQuery;
}

export function useStepFunctions({ regions, enabled }) {
    const monServ = ItpaApi.getService("monitoring");
    const fetchStepFunctions = async ({ region }) => {
        return await monServ.listSFPerRegion.bind(monServ)({ region });
    };
    const stepFunctionsQueries = useQueries({
        queries: (regions || []).map((region) => ({
            queryFn: async () => await fetchStepFunctions({ region }),
            queryKey: ['all_sf', region],
            refetchInterval: 1000 * 60 * 60,
            enabled

        }))
    });
    return stepFunctionsQueries;


}

