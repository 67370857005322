import { Box, Button, FormGroup, Grid, IconButton, Paper, TextField, Typography, styled } from "@mui/material";
import { useState } from "react";
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
const AddNoteContainer = styled(Paper)`
  margin-top: 16px;
  padding: 16px;
`;

const MyForm = styled(FormGroup)`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export default function NewTaskComment({ onAddNote }) {
    const [content, setContent] = useState('');

    const handleSubmit = (event) => {
        event.preventDefault();
        onAddNote({content});
        setContent('');
    };

    return (
        <Box sx={{ width: "100%" }}>
            <MyForm>
                <Grid container spacing={1} sx={{ width: "100%" }} columns={16}>
                    <Grid item xs={16} sx={{ display: "flex", height: "20px" }}></Grid>
                    <Grid item xs={1} sx={{ display: "flex" }}></Grid>
                    <Grid item xs={12} sm={14} sx={{ display: "flex" }}>
                        <TextField
                            label="Nuova nota"
                            value={content}
                            onChange={(e) => setContent(e.target.value)}
                            multiline
                            rows={2}
                            required
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={3} sm={1}>
                        <Box sx={{ width: "100%", height: "100%"}} display="flex" justifyContent="center" alignItems="center">

                            <IconButton type="submit" variant="contained" color="primary" onClick={handleSubmit} >
                                <SendOutlinedIcon />
                            </IconButton>
                        </Box>

                    </Grid>
                </Grid>


            </MyForm >
        </Box>
    );
};