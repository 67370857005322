import styled from "@emotion/styled";
import { Box, Grid, Paper, Stack } from "@mui/material";
import { useCabledQuery } from "../../hooks/serviceRegistry/useCabledQuery";
import { QueryLinearProgress } from "../queries/queryLinearProgress";
import { SimpleStatus } from "../simpleStatus/simpleStatus";

import ThumbUpOutlinedIcon from '@mui/icons-material/ThumbUpOutlined';
import ThumbDownOutlinedIcon from '@mui/icons-material/ThumbDownOutlined';
import QuestionMarkOutlinedIcon from '@mui/icons-material/QuestionMarkOutlined';
import MemoryOutlinedIcon from '@mui/icons-material/MemoryOutlined';
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
const StyledPaper = styled(Paper, {
    shouldForwardProp: p => !['status','mini'].includes(p)
})(({ theme, status, mini = null }) => {

    let out = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: "30px",
        width: '100%',
        height: '100%',
        borderRadius: "15px",
        boxShadow: theme.shadows[5],
        border: "1px solid gray",
        overflow: 'hidden'
    };
    if (mini){
        out['backgroundColor'] = {true:'green',false:'red'}[status] || 'gray'
    }
    return out;
});

export default function CWAlarmWidget({
    refetchInterval = 2 * 60 * 1000,
    name = null,
    enabled = true,
    alarms = null,
    baseQueryKey = null,
    tooltip = null,
    displayProgress = true,
    statusMap = null,
    width,
    height,
    svcName = 'cw',
    mini = false,
    onClick = ()=>{}
}) {
    // console.log("cwalarmwidget",name)
    let confOk = true;
    if (!alarms || !name || !baseQueryKey) {
        confOk = false;
    }

    if (!statusMap) {
        statusMap = {
            true: true,
            'OK': true,
            false: false,
            'ALARM': false,
        };
    }
    const alarmNames = [];
    for (let a of alarms) {
        alarmNames.push(a.alarmName);
    }
    const alarmQuery = useCabledQuery({
        svcName,
        method: 'getAlarmsState',
        refetchInterval,
        baseQueryKey,
        enabled: enabled && !!confOk,
        args: { alarmNames }
    });

    let overallStatus = true;
    let stati = {};
    if (!confOk) {
        return <Box sx={{ border: '1px solid red' }}>Misconfigured AlarmWidget</Box>
    }
    if (alarmQuery.query.isLoading) {
        return <Box>Loading...</Box>
    } else if (alarmQuery.query.isError) {
        return <Box>{name}: {alarmQuery.query.error.toString()}</Box>
    } else if (alarmQuery.query.data) {
        for (let a of alarmQuery.query.data) {
            stati[a.name] = statusMap[a.status] || false;
            overallStatus = overallStatus && stati[a.name];
        }
    }
    return <>
        <StyledPaper sx={{ width, height, position: 'relative' }} status={overallStatus} mini={mini}>
            {/* <Box sx={{ position: 'absolute', left: '15px', top: '0px' }}>
                
            </Box> */}
            <Stack sx={{ width: "100%" }}>

                <Box sx={{ fontWeight: 'bolder', lineHeight: '20px' }}  onClick={onClick}>
                    {overallStatus === true && <ThumbUpOutlinedIcon fontSize="10px" />}
                    {overallStatus === false && <ThumbDownOutlinedIcon fontSize="10px" />}
                    {overallStatus === null && <QuestionMarkOutlinedIcon fontSize="10px" />}
                    &nbsp;
                    {name}
                </Box>
                {!mini && displayProgress && <QueryLinearProgress query={alarmQuery.query} queryKey={alarmQuery.queryKey} />}
                {/* <hr style={{ width: "100%", margin: 0, padding: 0 }} /> */}
                {!mini && <Box sx={{width: "100%"}}>
                    {
                        alarms.map((a, i) => (
                                <Box sx={{ m: 0, p: 0 }} key={i}>
                                    <SimpleStatus status={stati[a.alarmName]} name={a.icon ? a.icon : a.alarmName} sx={{ m: 0, p: 0 }} />
                                </Box>
                        ))
                    }

                </Box>}
                
                {/* <Grid container columns={alarms.length} spacing={0} sx={{ width: "100%", m: 0, p: 0 }}>
                    {
                        alarms.map((a, i) => (
                            <Grid item xs={1} key={i} sx={{ m: 0, p: 0 }}>
                                <Box sx={{ height: "100%", m: 0, p: 0 }}>
                                    <SimpleStatus status={stati[a.alarmName]} name={a.icon ? a.icon : a.alarmName} sx={{ m: 0, p: 0 }} />
                                </Box>
                            </Grid>
                        ))
                    }
                </Grid> */}

            </Stack>
        </StyledPaper>

        {/* <SimpleStatus name={name} width="100%" height="100%" tooltip={tooltip || ""}
            top={displayProgress ? <QueryLinearProgress query={alarmQuery.query} queryKey={alarmQuery.queryKey} /> : <></>}
            status={statusMap[alarmQuery.query.data.find(x => x.name === alarmName)?.status]} /> */}
    </>

}