import { Box, Grid, Stack } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { useContext, useEffect, useState } from "react";
import CheckWidget from "../../components/checks/CheckWidget";
import { parseCheck, sortParsedChecks } from "../../components/checks/checksUtils";
import { applyOmniboxFilter } from "../../components/filters/omnibox";
import Deck from "../../components/queries/deck";
import { useCabledQuery as useCabledQueryOld } from "../../hooks/useCabledQuery";
import { ServiceContext } from "../../hooks/serviceRegistry/serviceContext";
import { useCabledQuery } from "../../hooks/serviceRegistry/useCabledQuery";
import ChecksForEnv from "../../components/checks/ChecksForEnv";

import ChecksDefinitions from "./checksdefinitions";
import { UserContext } from "../../hooks/userContext";


export default function ChecksDashboard() {

    const { allowedEnvs } = useContext(UserContext);

    return (
        <Stack spacing={1} >
            {
                allowedEnvs && allowedEnvs.map(
                    (envName) => (
                        ChecksDefinitions[envName]?.length ? (
                            <ChecksForEnv key={envName} envName={envName} checks={ChecksDefinitions[envName]} />
                        ) : <></>
                    )
                )
            }
        </Stack >


    );
}