import { Box, Stack, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';



import OpinionatedForm from '../../forms/opinionatedForm';
import useCabledMutation from '../../../hooks/serviceRegistry/useCabledMutation';
import { useCabledQuery } from '../../../hooks/serviceRegistry/useCabledQuery';


class AgentConfiguration {
    constructor(
        { executables = {}, rdp = {} } = {}
    ) {
        this.executablesBucket = executables?.bucket || '';
        this.executablesPrefix = executables?.prefix || '';
        this.executablesFolder = executables?.folder || '';
        this.rdpDomain = rdp?.domain || '';
        this.rdpUsername = rdp?.username || '';
        this.rdpPasswordSecret = rdp?.passwordSecret || '';
    }


}

const AdminPoolConfigurationEditor = ({
    poolName,
    onCancel = () => { },
    onSuccess = () => { },
    onError = () => { }
}) => {




    const [formValues, setFormValues] = useState(new AgentConfiguration());
    const [retrieved, setRetrieved] = useState(false);
    const [existingAgentConfiguration, setExistingAgentConfiguration] = useState({});

    const existingAgentConfigurationQuery = useCabledQuery({
        svcName: 'agents',
        method: 'doGet',
        args: {
            path: `/pools/${poolName}/config`
        },
        refetchInterval: 100 * 60 * 1000,
        baseQueryKey: ['agents', 'pools', poolName, 'config'],
        enabled: !!poolName
    });
    useEffect(() => {
        if (existingAgentConfigurationQuery?.query?.data) {
            setFormValues(new AgentConfiguration(existingAgentConfigurationQuery.query.data));
            setRetrieved(true);
        }

    }, [existingAgentConfigurationQuery?.query?.data]);


    const updateConfigMutation = useCabledMutation({
        svcName: 'agents',
        method: 'doPost',
        onSuccess,
        onFailure: onError
    })

    const fields = [
        {
            name: "executablesBucket", label: "Executables Bucket", type: "select", required: true,
            getOptions: () => ['itpa-artifacts']
        },
        {
            name: "executablesPrefix", label: "Executables Prefix", type: "select", required: true,
            getOptions: () => ['deployed-packages']
        },
        { name: "executablesFolder", label: "Executables Folder", type: "text", placeholder: "C:/rpa/assets", required: true },
        {
            name: "rdpDomain", label: "RDP Domain", type: "select", required: true,
            getOptions: () => ['EC2AMAZ-3845E3B']
        },
        {
            name: "rdpUsername", label: "RDP Username", type: "select", required: true,
            getOptions: () => [existingAgentConfigurationQuery?.query?.data?.rdp?.username || ''].concat(['Administrator'])
        },
        {
            name: "rdpPasswordSecret", label: "RDP Password Secret", type: "select", required: true,
            getOptions: () => [existingAgentConfigurationQuery?.query?.data?.rdp?.passwordSecret || ''].concat(['itpa/agents/password/generic/Administrator'])
        },
    ];
    const buttons = [
        { name: "Cancel", onClick: () => onCancel(), color: "error" },
        { name: "Update config", onClick: () => { updateConfigMutation.mutate({ path: `/pools/${poolName}/config`, body: { 
            executables: {
                bucket: formValues.executablesBucket,
                prefix: formValues.executablesPrefix,
                folder: formValues.executablesFolder
            },
            rdp: {
                domain: formValues.rdpDomain,
                username: formValues.rdpUsername,
                passwordSecret: formValues.rdpPasswordSecret
            }
         } }) }, color: "success", disabled: (formIsValid) => !formIsValid }
    ];

    return (

        <Stack spacing={2} sx={{ minWidth: "25vw" }} alignItems="center">
            <Box sx={{ height: "10vh" }} />
            <Typography variant="h5" component="div" gutterBottom>{poolName}</Typography>
            <Typography variant="h6" component="div" gutterBottom>Edit pool configuration</Typography>
            {existingAgentConfigurationQuery.query.isLoading && <Typography variant="h6" component="div" gutterBottom>Loading...</Typography>}
            {existingAgentConfigurationQuery.query.isError && <Typography variant="h6" component="div" gutterBottom>Error</Typography>}
            {retrieved && (
                <OpinionatedForm fields={fields} buttons={buttons} formValues={formValues} setFormValues={setFormValues} displayJson={true} validateOnChange={true} />
            )}
        </Stack>



    )
}



export default AdminPoolConfigurationEditor