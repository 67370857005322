import ArrowBack from "@mui/icons-material/ArrowBack";
import { Box, Button, ButtonGroup, Card, CardContent, Grid, IconButton, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from "@mui/material";
import React from "react";
import { dayMonthDateStr, expected, hourMinuteDateStr } from "../../services/common/common";
import { LapideBottom, LapideTop } from "./lapide";
import './style.scss';


// const representStep = function ({ start, outcome, end }) {
//     if (new Date() < start) {
//         return;
//     }
//     if (new Date() < end) {
//         return "termina " + expected(end);
//     }
//     if (outcome === "failed") {
//         return "fallito";
//     }
//     if (outcome === "succeeded") {
//         return "completato";
//     }
// }
const stepSemaphore = function ({ start, outcome, end }) {
    if (new Date() < start) {
        let sss = "parte " + expected(start);
        return <Tooltip title={sss}><span className="dot"></span></Tooltip>
    }
    if (new Date() < end) {
        let sss = "termina " + expected(end);
        return <Tooltip title={sss}><span className="dot amberdot"></span></Tooltip>
    }
    if (outcome === "failed") {
        return <Tooltip title="Alcuni problemi"><span className="dot reddot"></span></Tooltip>
    }
    if (outcome === "succeeded") {
        return <Tooltip title="Nessun problema"><span className="dot greendot"></span></Tooltip>
    }

    return <Tooltip title="Stato sconosciuto"><span className="dot"></span></Tooltip>

}


export default function DatiAstra({
    data
}) {
    console.log(data);
    let dd = null;
    let d = data.find(x => x.data_name === "flussi_astra_check_data");
    console.log(d);
    if (d) {
        dd = JSON.parse(d.data_content);
        dd = dd?.pdvs;
    }


    return (
        <Grid container>
            {(dd === null) ? ("La procedura non ha raccolto dati.") : (
                <>
                    {/* {dd?.map((x, xI) => <p key={xI}>{JSON.stringify(x)}</p>)} */}
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell>Descrizione</TableCell>
                                <TableCell>Flussi attivi</TableCell>
                                <TableCell>Flussi ricevuti</TableCell>
                                <TableCell>Flussi Elaborati</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {dd?.map((x, xI) => <TableRow key={xI}>
                                <TableCell>{x.descrizione}</TableCell>
                                <TableCell>{x.flussi_attivi}</TableCell>
                                <TableCell>{x.flussi_ricevuti}</TableCell>
                                <TableCell>{x.flussi_elaborati}</TableCell>
                            </TableRow>)}
                        </TableBody>
                    </Table>

                </>

            )}

        </Grid>
    )

}
