import Timeline from "@mui/lab/Timeline/Timeline";
import { Button, Grid, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableRow, TextareaAutosize, Typography, styled } from "@mui/material";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import moment from "moment";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import PageMainStack from "../../components/page/pageMainStack";
import PagePaper from "../../components/page/pagePaper";
import { ProblemTimelineItem } from "../../components/incidents/problemTimeLine";
import { useProblemExecutions } from "../../hooks/executions";
import { useOperatorProblem } from "../../hooks/problems";
import { alertService } from "../../services/alerting/alerting";
import ItpaApi from "../../services/api";
import { elapsed } from "../../services/common/common";


const DetailsPaper = styled(Paper, {
    shouldForwardProp: p => p !== "status"
})(({ theme, status }) => ({
    minWidth: "40vh",
    boxShadow: theme.shadows[3],
    padding: theme.spacing(2),
    height: (status === "OPEN") ? '60vh' : '87vh'
    //title="Dettagli problema" height={problemQuery.data?.status === "OPEN" ? '60vh' : '87vh'} minWidth="40vh"
}));



const OperatorIncidentDetails = () => {
    const params = useParams();
    const problemId = params.problem_id;


    const navigate = useNavigate();
    if (!problemId) {
        navigate('..');
    }

    const moniServ = ItpaApi.getService("operator-monitoring");
    const [closeProblemConfirmation, setCloseProblemConfirmation] = useState(0);
    const [closeProblemSolution, setCloseProblemSolution] = useState("");
    const askForCloseProblemConfirmation = () => setCloseProblemConfirmation(1);
    const cancelCloseProblemConfirmation = () => setCloseProblemConfirmation(0);
    const askForCloseProblemConfirmation2 = () => setCloseProblemConfirmation(2);

    const queryClient = useQueryClient();

    const closeProblemMutation = useMutation({
        mutationFn: async () => {
            try {
                await moniServ.closeProblem.bind(moniServ)({ problemId, solution: closeProblemSolution, environment: problemQuery.data.environment });
                alertService.success("Problema chiuso");
                setCloseProblemConfirmation(0);
                queryClient.invalidateQueries(problemQuery.key);
            } catch (e) {
                alertService.error("Impossibile chiudere il problema");
                setCloseProblemConfirmation(0);
            }
        }
    })


    const timelineItems = [];
    const severities = ['', 'warning', 'minor', 'major', 'critical']

    const { problemQuery } = useOperatorProblem({ problemId })
    const executionsQuery = useProblemExecutions({ problemId, enabled: false })// !!problemQuery.data });
    var detailsBlock = <></>;
    var solutionsBlock = <></>;
    if (problemQuery.isFetching) {
        detailsBlock = <div>Loading problem...</div>
    }
    else if (executionsQuery.isFetching) {
        solutionsBlock = <div>Loading execution...</div>
    }
    else if (problemQuery.isError) {
        detailsBlock = <div>Error: {problemQuery.error.message}</div>
    }
    else if (executionsQuery.isError) {
        solutionsBlock = <div>Error: {executionsQuery.error.message}</div>
    }
    else if (problemQuery.data && executionsQuery.data) {
        for (let ev of problemQuery.data?.events || []) {
            timelineItems.push({
                dt: moment(new Date(ev.dt)),
                evtType: ev.evt_type,
                details: ev.evt_details
            });
        }
        for (let ev of executionsQuery.data) {
            timelineItems.push({
                dt: moment(new Date(ev.start_date)),
                evtType: 'ExecRunning',
                details: { status: ev.status }
            });
        }
        console.log(timelineItems);
        solutionsBlock = <Timeline>
            {
                timelineItems ? timelineItems.map((ev, i) => (<ProblemTimelineItem
                    key={'ev' + i}
                    ev={ev}
                ></ProblemTimelineItem>
                )) : <p>Dati esecuzione non disponibili</p>
            }
        </Timeline>;
    }
    const [data,setData] = useState(null);
    useEffect(()=>{
        if (!problemQuery.data) return;
        
        let d = {
            type: problemQuery.data.problem_type,
            id: problemQuery.data.problem_id,
            sd: problemQuery.data.start_date,
            ed:  problemQuery.data.end_date,
            sev:  problemQuery.data.severity,
            status:  problemQuery.data.status,
            cb:  problemQuery.data.closed_by,
            sol:  problemQuery.data.solution,
            comps: []
        };
        for (let comp of  problemQuery.data.impacted_components){
            if (comp.startsWith("c::"))
                d["cust"] = comp.replace("c::","");
            else if (comp.startsWith("s::"))
                d["serv"] = comp.replace("s::","");
            else
                d.comps.push(comp);
        }
        for (let comp of  problemQuery.data.related_components){
            if (comp.startsWith("c::"))
                d["cust"] = comp.replace("c::","");
            else if (comp.startsWith("s::"))
                d["serv"] = comp.replace("s::","");
            else
                d.comps.push(comp);
        }
        setData(d);



    },[problemQuery.data]);



    return (
        <PageMainStack height="98vh">
            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    {!data && <Typography variant="comment">Loading...</Typography>}
                    {!!data && <DetailsPaper status={data.status}>
                        <TableContainer>
                            <Table size="small">
                                <TableBody>
                                    <TableRow>
                                        <TableCell>Cliente</TableCell>
                                        <TableCell>{data.cust || "Non definito"}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Servizio</TableCell>
                                        <TableCell>{data.serv || "Non definito"}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Tipo</TableCell>
                                        <TableCell>{data.type}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>ID</TableCell>
                                        <TableCell>{data.id}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Segnalato</TableCell>
                                        <TableCell>{elapsed(data.sd)}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Risolto</TableCell>
                                        <TableCell>{data.ed ? elapsed(data.ed) : 'Non risolto'}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Riguarda:</TableCell>
                                        <TableCell>{
                                            data.comps.map((c, cI) => <p key={cI}>{c}</p>)
                                        }</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Severity:</TableCell>
                                        <TableCell>{severities[data.sev]}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Stato:</TableCell>
                                        <TableCell>{data.status}</TableCell>
                                    </TableRow>
                                    {data?.status === "CLOSED" && <>
                                        <TableRow>
                                            <TableCell>Chiuso da:</TableCell>
                                            <TableCell>{data.cb}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Soluzione:</TableCell>
                                            <TableCell>{data.sol}</TableCell>
                                        </TableRow>
                                    </>}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </DetailsPaper >}
                </Grid>
            </Grid>
            {problemQuery.data?.status === "OPEN" && (<PagePaper subtitle="Chiudi problema" height="27vh">
                {
                    closeProblemConfirmation === 2 ? (
                        <>
                            <Typography>Dettagli sulla soluzione</Typography>
                            <div><TextareaAutosize
                                value={closeProblemSolution}
                                onChange={(e) => setCloseProblemSolution(e.target.value)}
                                style={{ width: '80%' }}
                                minRows={4}
                            ></TextareaAutosize></div>
                            <Stack direction="row" justifyContent="space-evenly">
                                <Button color="primary" onClick={() => closeProblemMutation.mutate()}> Chiudi </Button>
                                <Button color="secondary" onClick={() => cancelCloseProblemConfirmation()}>
                                    No, ci ho ripensato.
                                </Button>
                            </Stack>
                        </>
                    ) : (
                        (closeProblemConfirmation === 1) ? (
                            <>
                                <Typography>Confermi?</Typography>
                                {/* <Button color="primary" onClick={() => closeProblemMutation.mutate()}> */}
                                <Stack direction="row" justifyContent="space-evenly">
                                    <Button color="primary" onClick={() => askForCloseProblemConfirmation2()}>
                                        Sì, chiudi problema!
                                    </Button>
                                    <Button color="secondary" onClick={() => cancelCloseProblemConfirmation()}>
                                        No, ci ho ripensato.
                                    </Button>
                                </Stack>
                            </>
                        ) : (
                            <Button onClick={() => askForCloseProblemConfirmation()}>
                                Chiudi problema
                            </Button>

                        )
                    )
                }
            </PagePaper>)}
        </PageMainStack>
    )
}



export default OperatorIncidentDetails