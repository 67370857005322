//import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import React, { useContext } from "react";
import { AppContext } from "../../hooks/appContext";
import { S3Client } from "../../services/aws/s3"
import { getCredentialsForRole, listAvailableRoles, obtainBaseRoleCredentials } from "../../services/aws/grants";
import { initS3Client, readJsonFromS3 } from "../../services/aws/s3old";





import { useEffect, useState } from "react";
import { useCabledQuery } from "../../hooks/serviceRegistry/useCabledQuery";
import { QueryLinearProgress } from '../../components/queries/queryLinearProgress';
import { DataGrid } from '@mui/x-data-grid';
import PagePaper from '../../components/page/pagePaper';
import { raceWith } from 'rxjs';
import { parseString } from 'cron-parser';

export default function Controlli({ s3_pointer }) {

    function parseCustomerJiraDone(x,customer,perimeter) {

        return {
            key: x.Key,
            customer: customer,
            perimeter: perimeter,
            filename: x.Key.replace(customer+"/done/", "").replace(".csv", ""),
            dt: new Date(x.LastModified)
        }
    }

    const [descriptors, setDescriptors] = useState([]);
    const [descriptorsConad, setDescriptorsConad] = useState([]);
    const [descriptorsStellantis, setDescriptorsStellantis] = useState([]);
    const [descriptorsDespar, setDescriptorsDespar] = useState([]);

    let hasRenderingData = descriptors.length;
    let hasRenderingDataConad = descriptorsConad.length;
    let hasRenderingDataStellantis = descriptorsStellantis.length;
    let hasRenderingDataDespar = descriptorsDespar.length;

    function getCabledQuery(key) {
        let cabled = {
            svcName: 's3',
            method: 'listFiles',
            refetchInterval: 30 * 60 * 1000,
            baseQueryKey: ['controllidescartes'],
            args: {
                bucket: "atlasreply-ibi-210",
                prefix: key
            } 
        }
        console.log(cabled)
        return cabled;
    }

    const reportsQuery = useCabledQuery(getCabledQuery("carrefour/done"))
    const conadReportsQuery = useCabledQuery(getCabledQuery("conad/done"))
    const desparReportsQuery = useCabledQuery(getCabledQuery("despar/done"))
    const stellantisReportsQuery = useCabledQuery(getCabledQuery("stellantis/done"))


    function filterReports(data, customer, perimeter) {
        let ddd = data.filter(x => x.Key.endsWith(".csv")).map(item => parseCustomerJiraDone(item,customer, perimeter));
        ddd = ddd.sort((a, b) => b.dt.getTime() - a.dt.getTime());
        ddd = ddd.slice(0, 3);
        console.log(ddd)
        return ddd
    }

    useEffect(() => {
        let ddd = [];
        let ccc = [];
        let sss = [];
        let eee = [];
        if (reportsQuery?.query?.data) {
            console.log(reportsQuery.query.data)
            ddd = filterReports(reportsQuery.query.data, "carrefour", "jira")
            console.log(ddd)
        }
        setDescriptors(ddd);

        if (conadReportsQuery?.query?.data) {
            console.log(conadReportsQuery.query.data)
            ccc = filterReports(conadReportsQuery.query.data, "conad", "jira")
            console.log(ccc)
        }
        setDescriptorsConad(ccc);
        
        if (stellantisReportsQuery?.query?.data) {
            console.log(stellantisReportsQuery.query.data)
            sss = filterReports(stellantisReportsQuery.query.data, "stellantis", "servicenow")
            console.log(sss)
        }
        setDescriptorsStellantis(sss);

        if (desparReportsQuery?.query?.data) {
            console.log(desparReportsQuery.query.data)
            eee = ddd = filterReports(desparReportsQuery.query.data, "despar", "mantis")
            console.log(eee)
        }
        setDescriptorsDespar(eee);
    }, [reportsQuery?.query?.data,conadReportsQuery?.query?.data,stellantisReportsQuery?.query?.data,desparReportsQuery?.query?.data]);

    console.log(descriptors)

    const dateOptions = {weekday: 'short', year: 'numeric', day: 'numeric', month: 'short', hour: 'numeric', minute: 'numeric'}

    return (

        <>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table" size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>Customer</TableCell>
                            <TableCell>Perimeter</TableCell>
                            <TableCell>File Name</TableCell>
                            <TableCell align="left">Uploaded At</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {hasRenderingData ? (
                            descriptors?.map((row, i) => (
                                <TableRow
                                    key={i}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >   
                                    <TableCell component="th" scope="row">
                                        {row.customer}
                                    </TableCell>
                                    <TableCell align="left">{row.perimeter}</TableCell>
                                    <TableCell align="left">{row.filename}</TableCell>
                                    <TableCell align="left">{row.dt.toLocaleDateString('it-IT', dateOptions)}</TableCell>
                                </TableRow>
                            ))
                        ): 
                        (
                          <TableRow><TableCell align="center" colSpan={4}>Nessun record in Carrefour</TableCell></TableRow>
                        ) }
                        {hasRenderingDataConad ? (
                            descriptorsConad?.map((row, i) => (
                                <TableRow
                                    key={i}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >   
                                    <TableCell component="th" scope="row">
                                        {row.customer}
                                    </TableCell>
                                    <TableCell align="left">{row.perimeter}</TableCell>
                                    <TableCell align="left">{row.filename}</TableCell>
                                    <TableCell align="left">{row.dt.toLocaleDateString('it-IT', dateOptions)}</TableCell>
                                </TableRow>
                            ))
                        ): 
                        (
                          <TableRow><TableCell align="center" colSpan={4}>Nessun record in Conad</TableCell></TableRow>
                        ) }
                        {hasRenderingDataStellantis ? (
                            descriptorsStellantis?.map((row, i) => (
                                <TableRow
                                    key={i}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >   
                                    <TableCell component="th" scope="row">
                                        {row.customer}
                                    </TableCell>
                                    <TableCell align="left">{row.perimeter}</TableCell>
                                    <TableCell align="left">{row.filename}</TableCell>
                                    <TableCell align="left">{row.dt.toLocaleDateString('it-IT', dateOptions)}</TableCell>
                                </TableRow>
                            ))
                        ): 
                        (
                          <TableRow><TableCell align="center" colSpan={4}>Nessun record in Stellantis</TableCell></TableRow>
                        ) }
                        {hasRenderingDataDespar ? (
                            descriptorsDespar?.map((row, i) => (
                                <TableRow
                                    key={i}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >   
                                    <TableCell component="th" scope="row">
                                        {row.customer}
                                    </TableCell>
                                    <TableCell align="left">{row.perimeter}</TableCell>
                                    <TableCell align="left">{row.filename}</TableCell>
                                    <TableCell align="left">{row.dt.toLocaleDateString('it-IT', dateOptions)}</TableCell>
                                </TableRow>
                            ))
                        ): 
                        (
                          <TableRow><TableCell align="center" colSpan={4}>Nessun record in Despar</TableCell></TableRow>
                        ) }
                    </TableBody>
                </Table>
            </TableContainer>
        </>

    )
}