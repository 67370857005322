import { useMemo, useRef } from "react";
import { getCredentialsForRole, listAvailableRoles } from "../../services/aws/grants";

async function initCredentials(role) {
    // console.log("Init credentials",role);
    if (role === null) {
        throw new Error('Role must be provided');
    }
    let roles = [];
    try {
        roles = await listAvailableRoles();
    } catch (e) {
        throw new Error('Unable to list available roles');
    }
    // console.log("Available roles",roles);
    let found_role = roles.find(x => x.includes(role));
    if (!found_role){
        found_role = roles.find(x => x.includes(role.replace('C_','').replace('_Role','')));
    }
    let credentials = null;
    // console.log("Found role",found_role);
    if (!found_role){
        throw new Error("User is not able to assume this role: "+role);
    }
    try {
        credentials = localStorage.getItem("AWS__"+found_role);
        if (!!credentials){
            credentials = JSON.parse(credentials);
            // console.log("for role",role,"got creds from localstorage");
            if (new Date(credentials.expiration) < new Date()){
                credentials = null;
                localStorage.removeItem("AWS__"+found_role);
                // console.log("for role",role,"creds expired");
            } else {
                // console.log("for role",role,"creds ok");
            }
        }
    } catch(e){
        console.error(e);
    }
    if (credentials === null){
        try {
            credentials = await getCredentialsForRole(found_role);
            localStorage.setItem("AWS__"+found_role,JSON.stringify(credentials));
        } catch (e) {
            throw new Error('Unable to get credentials for role');
        }
    }
    // console.log("Got credentials");
    return credentials;

}   
function areCredentialsExpired(credentials) {
    return !!credentials && !!credentials.expiration && new Date(credentials.expiration) <= new Date()
}
export const AWSCredentialsHelper = {
    to: null,
    requests: [],
    requestedCredentialsRoles: {},
    process: async ()=>{
        AWSCredentialsHelper.to = setTimeout(AWSCredentialsHelper.process,100);
        if (AWSCredentialsHelper.requests.length){
            const requestedRole = AWSCredentialsHelper.requests.shift();
            // console.log("PROCESS",requestedRole);
            let existingCredentials = AWSCredentialsHelper.requestedCredentialsRoles[requestedRole];
            // console.log("PROCESS",!!existingCredentials);
            if (areCredentialsExpired(existingCredentials)){
                AWSCredentialsHelper.requestedCredentialsRoles[requestedRole] = null;
                existingCredentials = null;
            }
            if (!existingCredentials){
                AWSCredentialsHelper.requestedCredentialsRoles[requestedRole] = await initCredentials(requestedRole);
            }
            // console.log("PROCESS",AWSCredentialsHelper.requestedCredentialsRoles[requestedRole]);
        }
    },
    init: ()=>AWSCredentialsHelper.to ? {} : AWSCredentialsHelper.to = setTimeout(AWSCredentialsHelper.process,100),
    getCredentialsForRole: async (role)=>{
        AWSCredentialsHelper.requests.push(role);
        let requestTime = new Date();
        return new Promise((a,b)=>{
            var interv = setInterval(() => {
                if (new Date()-requestTime > 10000){
                    clearInterval(interv);
                    b(null);
                    return;
                }
                if (AWSCredentialsHelper.requestedCredentialsRoles && AWSCredentialsHelper.requestedCredentialsRoles[role]){
                    // console.log("GetCredentialsForRole",AWSCredentialsHelper.requestedCredentialsRoles[role],areCredentialsExpired(AWSCredentialsHelper.requestedCredentialsRoles[role]))
                    if (areCredentialsExpired(AWSCredentialsHelper.requestedCredentialsRoles[role]))
                        AWSCredentialsHelper.requestedCredentialsRoles[role] = null;
                    else {
                        clearInterval(interv);
                        a(AWSCredentialsHelper.requestedCredentialsRoles[role]);
                    }
                }
            },100);
        });
    }
}



export function useCredentialsForRole(role) {


    const creds = useRef({});
    const credentialsRequests = useRef({});

    async function initCredentials() {
        if (role === null) {
            throw new Error('Role must be provided');
        }
        let roles = [];
        try {
            roles = await listAvailableRoles();
        } catch (e) {
            throw new Error('Unable to list available roles');
        }
        let found_role = roles.find(x => x.includes(role));
        let credentials = null;
        try {
            credentials = await getCredentialsForRole(found_role);
        } catch (e) {
            throw new Error('Unable to get credentials for role');
        }
        return credentials;
    
    }    

    async function obtainCredentials() {
        
        if (!creds.current){
            creds.current = {};
        }
        if (!credentialsRequests.current){
            creds.current = {};
        }
        if (!creds.current[role]) {
   
            creds.current[role] = new Promise((a,b)=>{

            })
            console.log("OBTAINING NEW CREDENTIALS FOR ROLE",role);
            let credentials = await initCredentials();
            creds.current[role] = credentials;
        } else {
            console.log("USING EXISTING CREDENTIALS FOR ROLE",role);
        }
        return creds.current[role];
    }

    const memoizedObtainCredentials = useMemo(()=>obtainCredentials,[role]);

    return memoizedObtainCredentials;
}