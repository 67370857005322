import { Box, Stack, Typography } from '@mui/material';
import 'dayjs/locale/it';
import React, { useContext, useState } from 'react';
import { AppContext } from '../../hooks/appContext';
import useCabledMutation from '../../hooks/useCabledMutation';
import { useCabledQuery } from '../../hooks/useCabledQuery';
import { alertService } from '../../services/alerting/alerting';
import ItpaApi from '../../services/api';
import { queryAwaiter } from '../../services/common/common';
import OpinionatedForm from '../forms/opinionatedForm';
const AdminCreateIncident = ({
    onSuccess = () => { },
    onCancel = () => { }
}) => {
    const { currentEnvironment } = useContext(AppContext);
    const [formValues, setFormValues] = useState({});

    const { query: problemTypesQuery } = useCabledQuery({
        baseQueryKey: 'problem_types',
        apiService: 'monitoring',
        method: 'listAllProblemTypes',
        refetchInterval: 10 * 60 * 1000,
        filters: {
            environment: currentEnvironment
        }

    })

    const moniServ = ItpaApi.getService("monitoring");

    const { query: componentsQuery, queryKey: componentsQueryKey } = useCabledQuery({
        apiService: 'monitoring',
        method: 'listComponents',
        baseQueryKey: 'components',
        filters: { environment: currentEnvironment },
        refetchInterval: 60 * 1000
    });

    const installFlowMutation = useCabledMutation({
        apiService: 'monitoring',
        method: 'createProblem',
        onSuccess: (result) => {
            alertService.success("Incident created "+JSON.stringify(result,null,2));
            return onSuccess();
        },
        onFailure: (e) => {
            if (e.status === 409) alertService.error("Duplicated incident");
            else alertService.error("Cannot create incident")
        }
    })
    const loadProblemTypes = queryAwaiter({
        query: problemTypesQuery,
        transformData: data=>data?.map(x => x.problem_type) || [],
        trasformError: ()=>[]
    });
    const loadComponents = queryAwaiter({
        query: componentsQuery,
        transformData: data=>data?.map(x => x.name) || [],
        trasformError: ()=>[]
    });
    const fields = [
        { name: 'problemType', type: 'autocomplete', required: true, label: 'Incident type', getOptions: loadProblemTypes },
        { name: 'startDate', type: 'datepicker', required: true, label: 'Start time', initialValue: new Date() },
        {
            name: 'severity', type: 'select', required: true, label: 'Severity', getOptions: () => [
                { value: "1", label: "Warning" },
                { value: "2", label: "Minor" },
                { value: "3", label: "Major" },
                { value: "4", label: "Critical" }
            ]
        },
        { name: 'impactedComponents', multiple: true, type: 'autocomplete', freeSolo: true, label: 'Impacted components', getOptions: loadComponents },
        { name: 'relatedComponents', multiple: true, type: 'autocomplete',  freeSolo: true, label: 'Related components', getOptions: loadComponents }

    ];
    const buttons = [
        { name: "Cancel", onClick: () => onCancel(), color: "error" },
        { name: "Create", onClick: () => {
            let x = installFlowMutation.mutate({environment:currentEnvironment,...formValues});
        }, color: "success", disabled: (formIsValid) => !formIsValid }
    ];

    return (
        <Stack spacing={2} sx={{ minWidth: "25vw" }} alignItems="center">
            <Box sx={{ height: "10vh" }} />
            <Typography variant="h6" component="div" gutterBottom>New incident</Typography>
            <OpinionatedForm fields={fields} buttons={buttons} formValues={formValues} setFormValues={setFormValues} displayJson={true} validateOnChange={true} />
        </Stack>

    )
}



export default AdminCreateIncident