import { SvgIcon } from "@mui/material";



export default function StepFunctionsIcon({ w = 256, h = 256 }) {
    return <SvgIcon xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
        width={w} height={h}
        viewBox="0 0 256 256">

        <defs>
            <linearGradient id="logosAwsStepFunctions0" x1="0%" x2="100%" y1="100%" y2="0%">
                <stop offset="0%" stop-color="#B0084D" />
                <stop offset="100%" stop-color="#FF4F8B" />
            </linearGradient>
        </defs>
        <path fill="url(#logosAwsStepFunctions0)" d="M0 0h256v256H0z" />
        <path fill="#FFF" d="M144 140.8h67.2v-22.4H144v22.4Zm70.4-28.8h-73.6a3.2 3.2 0 0 0-3.2 3.2V144a3.2 3.2 0 0 0 3.2 3.2h73.6a3.2 3.2 0 0 0 3.2-3.2v-28.8a3.2 3.2 0 0 0-3.2-3.2Zm-75.2 88c0 6.176-5.024 11.2-11.2 11.2c-6.176 0-11.2-5.024-11.2-11.2c0-6.176 5.024-11.2 11.2-11.2c6.176 0 11.2 5.024 11.2 11.2Zm-94.4-49.6h57.6v-12.8H44.8v12.8Zm0-32h57.6v-12.8H44.8v12.8Zm72-62.4c0-6.176 5.024-11.2 11.2-11.2c6.176 0 11.2 5.024 11.2 11.2c0 6.176-5.024 11.2-11.2 11.2c-6.176 0-11.2-5.024-11.2-11.2Zm62.4 113.6H76.8v-12.8h28.8a3.2 3.2 0 0 0 3.2-3.2v-19.2a3.2 3.2 0 0 0-3.2-3.2H76.8v-6.4h28.8a3.2 3.2 0 0 0 3.2-3.2v-19.2a3.2 3.2 0 0 0-3.2-3.2H76.8v-9.6h102.4v12.8h6.4v-16a3.2 3.2 0 0 0-3.2-3.2h-51.2v-9.907c8.18-1.51 14.4-8.682 14.4-17.293c0-9.706-7.898-17.6-17.6-17.6c-9.702 0-17.6 7.894-17.6 17.6c0 8.611 6.22 15.782 14.4 17.293V83.2H73.6a3.2 3.2 0 0 0-3.2 3.2v12.8H41.6a3.2 3.2 0 0 0-3.2 3.2v19.2a3.2 3.2 0 0 0 3.2 3.2h28.8v6.4H41.6a3.2 3.2 0 0 0-3.2 3.2v19.2a3.2 3.2 0 0 0 3.2 3.2h28.8v16a3.2 3.2 0 0 0 3.2 3.2h51.2v6.707c-8.18 1.51-14.4 8.682-14.4 17.293c0 9.706 7.898 17.6 17.6 17.6c9.702 0 17.6-7.894 17.6-17.6c0-8.611-6.22-15.782-14.4-17.293V176h51.2a3.2 3.2 0 0 0 3.2-3.2v-15.914h-6.4V169.6Z" />
    </SvgIcon>
}