
import { ApiClient } from '../api';



class ResourcesService extends ApiClient {


    async listAgentTypes() {
        return await this.do_get_all_pages("/agents/agenttypes");
    
    }

    async createAgentType({name,environment,customer, service, agentFunction}){
        return this.do_post("/agents/agenttypes",{name,environment,customer, service, function:agentFunction});
        //return {id:"12345",name,environment,customer};
    }


    async describeAgentType({name}){
        return this.do_get(`/agents/agenttypes/${name}`);
    }

    async listAgentTypeConfigurationVersions({name}){
        return this.do_get(`/agents/agenttypes/${name}/versions`);
    }


    async listNetworkPlacements(){
        return this.do_get("/agents/network_placements");
    }

    async listIamProfiles(){
        return this.do_get("/agents/profiles");
    }
    async listAgentImages(){
        return this.do_get("/agents/images");
    }
    async listAgentSgs(){
        return this.do_get("/agents/sgs");
    }
    async listAgentPasswordSecrets(){
        return this.do_get("/agents/passwordSecrets");
    }
    async listAgentInstanceTypes(){
        return this.do_get("/agents/instanceTypes");
    }


    async createAgentConfiguration({agentTypeName, env_vars,provider,provider_details}){
        return this.do_post(`/agents/agenttypes/${agentTypeName}/versions`,{
            env_vars,
            provider,
            provider_details
        });
    }

    async getAgentConfigurationDeploymentStatus({agentTypeName, version}){
        return this.do_get(`/agents/agenttypes/${agentTypeName}/versions/${version}/deployment`);
    }
    async getAgentConfigurationSchedules({agentTypeName, version}){
        return this.do_get(`/agents/agenttypes/${agentTypeName}/versions/${version}/schedules`);
    }
    async deployAgentConfigurationVersion({agentTypeName, version}){
        return this.do_post(`/agents/agenttypes/${agentTypeName}/versions/${version}/deployment`);
    }


    async listAgentsPerPool({pool}){
        return this.do_get(`/agentstats/pools/${pool}/agents`,{},{},{})
    }
    async getAgentActivationStatus({provider,uid,last_minutes=1}){
        return this.do_get(`/agentstats/activation_status`,{},{},{agent_id:`${provider}/${uid}`,last_minutes})
    }
    async getAgentInNetworkStatus({provider,uid,network_ip_start,last_minutes=1}){
        return this.do_get(`/agentstats/in_network`,{},{},{agent_id:`${provider}/${uid}`,network_ip_start,last_minutes})
    }
    async getAgentDesktopStatus({provider,uid,last_minutes=1}){
        return this.do_get(`/agentstats/is_desktop_ok`,{},{},{agent_id:`${provider}/${uid}`,last_minutes})
    }
    async getAgentLinkStatus({provider,uid,last_minutes=1}){
        return this.do_get(`/agentstats/link`,{},{},{agent_id:`${provider}/${uid}`,last_minutes})
    }
    async getAgentDutyStatus({provider,uid}){
        return this.do_get(`/agentstats/duty`,{},{},{agent_id:`${provider}/${uid}`})
    }
    async getAgentStats({provider,uid,network_ip_start,from_date,to_date}){
        return this.do_get(`/agentstats/stats`,{},{},{
            agent_id:`${provider}/${uid}`,
            network_ip_start:network_ip_start,
            from_date: from_date.toISOString(), to_date:to_date.toISOString()
        })
    }
    async getAgentTasks({provider,uid,from_date,to_date}){
        return this.do_get(`/agentstats/tasks`,{},{},{
            agent_id:`${provider}/${uid}`,
            from_date: from_date.toISOString(), to_date:to_date.toISOString()
        })
    }
    async getPoolAgentsTasks({pool,fromDate,toDate}){
        return this.do_get(`/agentstats/pools/${pool}/tasks`,{},{},{
            from_date: fromDate.toISOString(), to_date:toDate.toISOString()
        })
    }

    async getPoolSupportsReplace({pool}){
        return this.do_get(`/agentstats/pools/${pool}/can_replace`,{},{},{})
    }
    async replaceAgentInPool({pool,provider,uid}){
        return this.do_post(`/agentstats/pools/${pool}/replace?agent_id=${provider}/${uid}`,{})
    }





}







export default ResourcesService