import React, { useState } from "react";
import { Switch, FormGroup, FormControlLabel } from "@mui/material";

export default function SwitchGroup({ switches, onSwitchChange, row=false }) {
  const [switchStates, setSwitchStates] = useState(
    switches.reduce((obj, item) => {
      obj[item.name] = item.value || false;
      
      return obj;
    }, {})
  );
    
  const handleSwitchChange = (name) => (event) => {
    const value = event.target.checked;
    setSwitchStates((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    onSwitchChange && onSwitchChange(name, value);
  };

  return (
    <FormGroup row={row} width="100%">
      {switches.map(({ name, label, value }) => (
        <FormControlLabel
          key={name}
          control={
            <Switch
              size="small"
              checked={switchStates[name]}
              onChange={handleSwitchChange(name)}
              value={name}
              color="background"
            />
          }
          label={label || name}
        />
      ))}
    </FormGroup>
  );
}
