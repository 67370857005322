import { useQuery, useQueryClient } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { useContext } from 'react';
import ItpaApi from '../services/api';
import { AppContext } from './appContext';

export function useProblems({enabled = true,includeArchived = false}) {  
    
    const monServ = ItpaApi.getService("monitoring");
    const { currentEnvironment } = useContext(AppContext);
    const refetchInterval = 10*60*1000;

    const fetchProblems = async () => {
        const data = await monServ.listProblems.bind(monServ)({environment: currentEnvironment,includeArchived});
        problemsQuery.nextRefresh = dayjs().add(refetchInterval,'millisecond').toDate();
        return data;
    };
    
    const problemsQuery = useQuery({
        queryKey: ['all_problems', currentEnvironment,includeArchived],
        queryFn: fetchProblems,
        refetchInterval,
        refetchOnWindowFocus: false,
        enabled: enabled
    });
    problemsQuery.nextRefresh = null;

    

    return {problemsQuery,refetchInterval};
}


export function useProblem({problemId}){
    
    const monServ = ItpaApi.getService("monitoring");
    const { currentEnvironment } = useContext(AppContext);
    const refetchInterval = 10*60*1000;
    
    
    const fetchProblem = async () => {
        let data = await monServ.getProblem.bind(monServ)({environment:currentEnvironment, problemId});
        return data;
    }
    const problemQuery = useQuery({
        queryKey: ['problem', currentEnvironment, problemId],
        queryFn: fetchProblem,
        refetchInterval,
        refetchOnWindowFocus: false
    });
    return {problemQuery, refetchInterval};
}


export function useOperatorProblems({environment = null, enabled = true,includeArchived = false}) {  
    const env = environment || "*";
    const monServ = ItpaApi.getService("operator-monitoring");
    const refetchInterval = 10*60*1000;
    
    const fetchProblems = async () => {
        const data = await monServ.listProblems.bind(monServ)({environment: env,includeArchived});
        return data;
    };
    
    const problemsQuery = useQuery({
        queryKey: ['operator','all_problems', env, includeArchived],
        queryFn: fetchProblems,
        refetchInterval,
        refetchOnWindowFocus: false,
        enabled: enabled
    });

    

    return {problemsQuery,refetchInterval};
}



export function useOperatorProblem({environment = null, problemId}){
    const env = environment || "*";
    const monServ = ItpaApi.getService("operator-monitoring");
    const refetchInterval = 10*60*1000;
    
    const fetchProblem = async () => {
        let data = await monServ.getProblem.bind(monServ)({environment:env, problemId});
        return data;
    }
    const problemQuery = useQuery({
        queryKey: ['operator','problem', env, problemId],
        queryFn: fetchProblem,
        refetchInterval,
        refetchOnWindowFocus: false,
        
    });
    return {problemQuery,refetchInterval}
}