const Items = [
    {
        name: 'HOME',
        route: '/'
    },
    {
        name: 'TASKS',
        route: '/tasks'
    },
    {
        name: 'INCIDENTS',
        route: '/incidents'
    },
    {
        name: 'CHECKS',
        route: '/checks'
    },
    {   
        name: 'SALA MACCHINE',
        env: "SalaMacchine",
        items: [
            {
                name: "Gucci",
                route: '/salamacchine/gucci',
                iconTag: null
            },
            {
                name: "Carrefour",
                route: '/salamacchine/carrefour',
                iconTag: null
            }
        ]
    },
    {   
        name: 'UNICREDIT',
        env: "Unicredit",
        items: [
            {
                name: "Controllo siti",
                route: '/unicredit/siti',
                iconTag: 'alerts'
            },
            {
                name: "Storico login",
                route: '/unicredit/logins',
                iconTag: 'alerts'
            },
            {
                name: "Stop robottino",
                route: '/unicredit/pulsantone',
                iconTag: 'alerts'
            },
            {
                name: "Agenti RPA",
                route: '/unicredit/agents',
                iconTag: 'alerts'
            }
        ]
    },
    {   
        name: 'STELLANTIS',
        env: "Stellantis",
        items: [
            {
                name: "Splunk",
                route: '/stellantis/splunk',
                iconTag: 'alerts'
            },
            {
                name: "Ticket assignment",
                route: '/stellantis/ticketassignment',
                iconTag: 'alerts'
            },
            {
                name: "Agenti RPA",
                route: '/stellantis/agents',
                iconTag: 'alerts'
            }
        ]
    },
    {   
        name: 'BPER',
        env: "Bper",
        items: [
            {
                name: "Inoltro automatico",
                route: '/bper',
                iconTag: null
            }
        ]
    },
    {   
        name: 'ACME',
        env: "Acme",
        items: [
            {
                name: "Monitoraggio Risorse",
                route: '/acme',
                iconTag: null
            }
        ]
    },
    {   
        name: 'DESCARTES',
        env: "Descartes",
        items: [
            {
                name: "Controlli",
                route: '/descartes',
                iconTag: null
            }
        ]
    },
    {
        name: 'DEV',
        groups: ["Admin"],
        items: [
            {
                name: "Dev1",
                route: '/dev',
                iconTag: 'list'
            },
            {
                name: "Dev2",
                route: '/dev/dev2',
                iconTag: 'list'
            }
        ]
    },
    {   
        name: 'ADMIN',
        groups: ["Admin"],
        items: [
            {
                name: "Home",
                route: '/admin',
                iconTag: 'home'
            },
            {
                name: "Ambienti",
                route: '/admin/envs',
                iconTag: 'list'
            },
            // {
            //     name: "Risorse",
            //     route: '/admin/resources',
            //     iconTag: 'list'
            // },
            {
                name: "Agenti",
                route: '/admin/agents',
                iconTag: 'list'
            },
            {
                name: "Clienti e Servizi",
                route: '/admin/clients',
                iconTag: 'list'
            },
            // { // disattivato perchè inutilizzato
            //     name: "Componenti",
            //     route: '/admin/components',
            //     iconTag: 'list'
            // },
            {
                name: "Incidents",
                route: '/admin/incidents',
                iconTag: 'list'
            },
            {
                name: "Flussi",
                route: '/admin/flows',
                iconTag: 'list'
            },
            {
                name: "Schedulazioni",
                route: '/admin/schedules',
                iconTag: 'list'
            }
        ]
    },
];

export default Items;
