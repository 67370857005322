import { useContext } from "react";
import { AppContext } from "../../hooks/appContext";
import { Box, Stack, Typography } from "@mui/material";
import { Link } from "react-router-dom";


function AdminEnvironmentSelector(){


    const {currentEnvironment} = useContext(AppContext);

    return <Box sx={{width: "100%", height: "4vh", backgroundColor: 'yellow', borderRadius: '15px'}}>
        <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{width: "100%", height: "100%"}}>
            <Typography variant="h6" sx={{marginLeft: "1rem"}}>{currentEnvironment}</Typography>
            <Box sx={{flexGrow: 9}} />
            <Link to="/admin/envs"><span>Cambia</span></Link>
            <Box sx={{flexGrow: 1}} />
        </Stack>
    </Box>



}

export default AdminEnvironmentSelector;