import { Box, Divider, Stack, Table, TableBody, TableCell, TableRow, TextField, Typography } from "@mui/material";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import 'dayjs/locale/it';
import { useEffect, useState } from "react";

const FromToPicker = function ({
    initial = null,
    onChange = ()=>{}
}) {

    const [dateFrom, setDateFrom] = useState(initial?.dateFrom || dayjs().toDate());
    const [dateTo, setDateTo] = useState(initial?.dateTo || dayjs().toDate());


    const onChangeValue = function(newValue,which){
        if (which === "from"){
            setDateFrom(newValue);
        } else if (which === "to"){
            setDateTo(newValue);
        }
        onChange({dateFrom, dateTo});
    }

    useEffect(()=>{
        setTimeout(()=>onChange({dateFrom, dateTo}));
    },[]);

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="it">
            <Stack direction="row" spacing={2} sx={{width: '100%'}} justifyContent="space-evenly" className="fromToPicker">
                <DateTimePicker
                    ampm={false}
                    format="DD-MM-YYYY hh:mm:ss"
                    label="Da"
                    value={dateFrom}
                    onChange={newValue => onChangeValue(newValue,'from')}
                    renderInput={(params) => <TextField size="small" {...params} />}
                />
                <DateTimePicker
                    ampm={false}
                    format="DD-MM-YYYY hh:mm:ss"
                    label="a"
                    value={dateTo}
                    onChange={newValue => onChangeValue(newValue,'to')}
                    renderInput={(params) => <TextField size="small" {...params} />}
                />
            </Stack>
        </LocalizationProvider>
    );

}


export default FromToPicker;