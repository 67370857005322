
import { ApiClient } from '../api';

class ClientsService extends ApiClient {


    
    async listClients(){
        return await this.do_get('/clients');
    }
    async getClient({clientId}){
        return await this.do_get('/clients/'+clientId);
    }
    async createClient({name,displayName}){
        if (!name || !displayName) return null;
        return await this.do_post('/clients',{name:name,display_name:displayName});
    
    }
    async listServices({clientId}){
        if (!clientId) return [];
        return await this.do_get(`/clients/${clientId}/services`)
    }
    async getService({clientId, serviceId}){
        if (!clientId) return null;
        if (!serviceId) return null;
        return await this.do_get(`/clients/${clientId}/services/${serviceId}`)
    }
    async createService({clientId, name,displayName}){
        if (!clientId) return null;
        if (!name || !displayName) return null;
        return await this.do_post(`/clients/${clientId}/services`,{name:name,display_name:displayName});
        
    }
    async toggleServiceActive({clientId, serviceId, newStatus}){
        if (!clientId) return null;
        if (!serviceId) return null;
        if (![true,false].includes(newStatus)) return null;
        return await this.do_put(`/clients/${clientId}/services/${serviceId}/active`,{target_active_status: newStatus});
    }
    async listProcesses({clientId,serviceId}){
        if (!clientId) return [];
        if (!serviceId) return [];
        return await this.do_get(`/clients/${clientId}/services/${serviceId}/processes`)
    }
    


}







export default ClientsService