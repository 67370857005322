import { Box, Paper, Stack, Typography } from "@mui/material";
import React from "react";
import { useSchedData } from "../../hooks/carrefour/carrefour";
import { hourMinuteDateStr } from "../../services/common/common";


export function DatiGenerici({
    data
}) {

    return (
        <Box style={{ overflowY: 'auto' }}>
            {JSON.stringify(data, null, 2)}
        </Box>
    )

}
function Item({ts,metadata,data,datacomp}) {
    let comps = { datacomp: datacomp ? datacomp : DatiGenerici };
    let at = new Date(ts);
    console.log("Item",ts,new Date(ts));
    return (
        <>
            <Typography>Lettura delle {hourMinuteDateStr(at)}</Typography>
            <comps.datacomp data={data}></comps.datacomp>
        </>
    )
}

export function LapideDati({
    schedName,
    datacomp
}) {

    
    const schedDataQuery = useSchedData({ schedName });


    return (
        <>
            { schedDataQuery.isFetching ? ("Loading...") : (
                (schedDataQuery && schedDataQuery.data?.length) ? (
                    <Box>
                    {/* <Typography>{schedDataQuery?.data? schedDataQuery?.data?.length+" letture" : ""}</Typography> */}
                        {
                            schedDataQuery.data?.map((d, dI) => <Item key={dI} {...d} datacomp={datacomp}></Item>)
                        }
                    </Box>
                )
                    : (
                        <Typography>Non ci sono dati</Typography>
                    )
            )}
        </>
    );
}