import { Auth } from 'aws-amplify';
import moment from 'moment';

export async function getJwt(){
    try {
        var r = await Auth.currentSession();
        var aa = r.getIdToken();
        return aa.getJwtToken();
    } catch(e){
        console.error(e);
        return null;
    }
}

export class APIServiceError extends Error {
    constructor(status,text) {
        super(text);
        this.status = status;
        this.text = text;
    }
    
}

export const elapsed = (date) => {
    const e1 = moment().diff(moment(date), 'minutes');
    if (e1 < 60 && e1 === 0) return '< 1 minute ago';
    if (e1 < 60 && e1 === 1) return e1 + ' minute ago';
    if (e1 < 60) return e1 + ' minutes ago';
    const e2 = moment().diff(moment(date), 'hours');
    if (e2 < 24 && e2 === 1) return '1 hour ago';
    if (e2 < 24) return e2 + ' hours ago';
    const e3 = moment().diff(moment(date), 'days');
    if (e3 < 30 && e3 === 1) return e3 + ' day ago';
    if (e3 <30) return e3 + ' days ago';
    else return 'more than a month ago';
}

export const elapsedSince = (toDate,sinceDate) => {
    sinceDate = moment(sinceDate);
    toDate = moment(toDate);
    const e1 = toDate.diff(sinceDate, 'minutes');
    if (e1 < 60 && e1 === 0) return '< 1 minute after';
    if (e1 < 60 && e1 === 1) return e1 + ' minute after';
    if (e1 < 60) return e1 + ' minutes after';
    const e2 = toDate.diff(sinceDate, 'hours');
    if (e2 < 24 && e2 === 1) return '1 hour after';
    if (e2 < 24) return e2 + ' hours after';
    const e3 = toDate.diff(sinceDate, 'days');
    if (e3 < 30 && e3 === 1) return e3 + ' day after';
    if (e3 <30) return e3 + ' days after';
    else return 'more than a month after';
}
export const expected = (date, sayExpired=false) => {
    const e1 = moment(date).diff(moment(), 'minutes');
    if (e1 <0 && sayExpired) return "expired";
    if (e1 < 60 && e1 === 0) return 'in few seconds';
    if (e1 < 60 && e1 === 1) return 'in 1 minute';
    if (e1 < 60) return 'in '+ e1 + ' minutes';
    const e2 = moment(date).diff(moment(), 'hours');
    if (e2 < 24 && e2 === 1) return 'in 1 hour';
    if (e2 < 24) return 'in '+ e2 + ' hours';
    const e3 = moment(date).diff(moment(), 'days');
    if (e3 < 30 && e3 === 1) return 'in 1 day';
    if (e3 <30) return 'in '+ e3 + ' days';
    else return 'in one month or more';
}


export const fullDateStr = (date) => {
    return moment(new Date(date)).format('DD/MM/YYYY HH:mm')
}
export const dayMonthDateStr = (date) => {
    return moment(new Date(date)).format('DD/MM')
}
export const hourMinuteDateStr = (date) => {
    return moment(new Date(date)).format('HH:mm')
}


export function extractCustomerService(inputArray){
    const outputArray = [];
    const customerSet = new Set();

    inputArray.forEach(item => {
        const customer = item.customer;
        const service = item.service;

        if (!customerSet.has(`${customer}-${service}`)) {
            outputArray.push({ customer, service });
            customerSet.add(`${customer}-${service}`);
        }

        if (!customerSet.has(`${customer}-${null}`)) {
            outputArray.push({ customer, service: null });
            customerSet.add(`${customer}-${null}`);
        }
    });

    return outputArray;
}

export function splitCamelCase(text) {
    var regex = /([a-z])([A-Z])/g;
    var result = text.replace(regex, '$1 $2');

    // Trasformiamo tutte le parole in minuscolo
    result = result.toLowerCase();

    // Capitalizziamo la prima lettera di ogni parola
    result = result.replace(/(?:^|\s)\S/g, function (a) {
        return a.toUpperCase();
    });

    return result;
}
export function splitDotted(text) {
    var regex = /([a-z])([A-Z])/g;
    var result = text.replace(regex, '$1 $2');

    // Trasformiamo tutte le parole in minuscolo
    result = result.toLowerCase();

    // Capitalizziamo la prima lettera di ogni parola
    result = result.replace(/(?:^|\s)\S/g, function (a) {
        return a.toUpperCase();
    });

    return result;
}

export function capitalizeEachWord(text){
    var split = null;
    if (!Array.isArray(text))
        split = text.split(" ");
    else split = text;
    for(var i = 0; i < split.length; i++){
        split[i] = split[i].charAt(0).toUpperCase() + split[i].substring(1);
    }
    return split.join(" ");
}

export const safeParseJson = function(data){
    try {
        return JSON.parse(data);
    } catch {
        return data;
    }
}

export const queryAwaiter =({query, transformData = x=>x, trasformError = x=>x})=>{
    return async function(){
        return new Promise((resolve, reject) => {
            var interval = setInterval(() => {
                if (query.isSuccess) {
                    clearInterval(interval);
                    resolve(transformData(query.data));
                } else if (query.isError) {
                    clearInterval(interval);
                    reject(trasformError(query.error));
                }
            }
                , 1000);
        });
    }
}

export const renderLongString = (longString, maxChars)=>{
    /**
     * Da una stringa troppo lunga restituisce una stringa di maxChars caratteri, sostituendo la parte centrale con ...
     */
    if (longString.length <= maxChars)
        return longString;
    else
        return longString.substring(0, maxChars/2) + "..." + longString.substring(longString.length - maxChars/2);

}