import { Grid } from '@mui/material';
import cronstrue from 'cronstrue/i18n';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import PageMainStack from '../../../components/page/pageMainStack';
import PagePaper from '../../../components/page/pagePaper';
import Specchietto from '../../../components/presenting/schecchietto';
import TopDeck from '../../../components/queries/topdeck';
import './list.scss';





function CronToString(cron_expression) {
    try {
        return cronstrue.toString(cron_expression, { locale: "it", verbose: true });
    } catch (e) {
        return "Invalid: " + e.toString();
    }
}

const AdminSchedulesDetails = () => {
    const navigate = useNavigate();

    // replace with service calls
    const [groupsQuery] = React.useState({ data: ['TEST', 'STELLANTIS', 'CARREFOUR'] })
    const [recurrencesQuery] = React.useState({data:[
        {
            cron:"5 * * * *",target_arn:"arn:aws:lambda:eu-west-1:745905534821:function:ItpaMonitoringServiceCollaudo",
            target_input:"{\"direct\":{\"operation\":\"start_flow_execution\",\"payload\":{\"flow_name\":\"CanaliMq\"}}}",
            target_role:"arn:aws:iam::745905534821:role/stellantis-flows-ScheduleCheckRole-13I1FVQXR82E7",
            name:"canalimq",group:"TEST",expiration_seconds:2592000,
        }
    ]});
    const [targetsQuery] = React.useState({data:[
        {
            target_arn:"arn:aws:lambda:eu-west-1:745905534821:function:ItpaMonitoringServiceCollaudo",
            role_arn:"arn:aws:iam::745905534821:role/stellantis-flows-ScheduleCheckRole-13I1FVQXR82E7",
            name:"moniserv"
        }
    ]});
    


    const [selectedGroup] = React.useState(null);
    const [selectedRule] = React.useState(null);
    
    
   
    return (
        <PageMainStack>
            <TopDeck
                loading={groupsQuery.isFetching || recurrencesQuery.isFetching || targetsQuery.isFetching}
                info={selectedRule === null ? false : ()=>navigate('./'+selectedGroup+"___"+selectedRule)}
                // newItem={isCreating? false : ()=>setIsCreating(true)}
                removeItem={false}
            ></TopDeck>
            <PagePaper height="15vh">
                <Specchietto
                    entity={{name:"CanaliMq",group:"TEST",cron:"5 * * * *",crontxt:CronToString("5 * * * *"),target:"Avvio flusso"}}
                    fields={["name","group","crontxt","target"]}
                ></Specchietto>
            </PagePaper>
            <PagePaper height="70vh">
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                        
                    </Grid>
                </Grid>
            </PagePaper>
        </PageMainStack>
    );



}


export default AdminSchedulesDetails;