import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import ArrowRightOutlinedIcon from '@mui/icons-material/ArrowRightOutlined';
import DoDisturbAltOutlinedIcon from '@mui/icons-material/DoDisturbAltOutlined';
import SearchIcon from '@mui/icons-material/Search';
import { Box, Drawer, Grid, IconButton, InputBase, Paper, Stack, Switch, Tab, Tabs, Typography } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import cronstrue from 'cronstrue/i18n';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import PageMainStack from '../../../components/page/pageMainStack';
import PagePaper from '../../../components/page/pagePaper';
import CreateRule from '../../../components/schedules/createRule';
import RuleDetails from '../../../components/schedules/ruleDetails';
import RuleExceptions from '../../../components/schedules/ruleExceptions';
import RuleStatus from '../../../components/schedules/ruleStatus';
import InDeck from '../../../components/queries/indeck';
import { useSchedulesGroups, useSchedulesRules, useSchedulesTargets } from '../../../hooks/schedules';
import { alertService } from '../../../services/alerting/alerting';
import ItpaApi from '../../../services/api';
import './list.scss';

import { useCabledQuery } from '../../../hooks/useCabledQuery';
import Deck from '../../../components/queries/deck';

function CronToString(cron_expression) {
    try {
        return cronstrue.toString(cron_expression, { locale: "it", verbose: true });
    } catch (e) {
        return "Invalid: " + e.toString();
    }
}

const AdminSchedulesList = () => {
    const navigate = useNavigate();


    function target2Name(target) {
        let found = targetsQuery?.data?.find(x => ((x.target_arn === target.target_arn || x.role_arn === target.role_arn)));
        if (found) return found.name; else return "custom";
    }


    const [tabIndex, setTabIndex] = React.useState(0);
    const [selectedGroup, setSelectedGroup] = React.useState(null);
    const [selectedRule, setSelectedRule] = React.useState(null);
    const [sideContent, setSideContent] = React.useState(null);
    const [searchFilter, setSearchFilter] = React.useState("");
    const handleChangeTab = (event, newValue) => {
        setTabIndex(newValue);
        setSelectedGroup(groupsQuery?.data[newValue]);
    };
    const ruleEquals = (rule1, rule2) => rule1?.name === rule2?.name && rule1?.group === rule2?.group
    const selectRule = (rule) => {
        if (selectedGroup === null) setSelectedGroup(groupsQuery?.data[tabIndex]);
        if (ruleEquals(rule, null)) return setSelectedRule(null);
        if (ruleEquals(selectedRule, rule)) return setSelectedRule(null);
        setSelectedRule(rule);
    }
    const toggleRuleDetailsEditor = (rule) => {
        selectRule(rule);
        setTimeout(() => setSideContent("rule_details"));
    }
    const toggleRuleExceptionsEditor = (rule) => {
        selectRule(rule);
        setTimeout(() => setSideContent("edit_exceptions"));
    }
    const toggleRuleOccurrencesDetails = (rule) => {
        selectRule(rule);
        setTimeout(() => setSideContent("rule_status"));
    }
    const clearSideContent = () => { setSideContent(null); setSelectedRule(null); }

    const groupsQuery = useSchedulesGroups();
    const {query:recurrencesQuery, queryKey: recurrencesQueryKey} = useCabledQuery({
        apiService: 'monitoring',
        method: 'listScheduleRules',
        filters: {group: selectedGroup},
        baseQueryKey: ['schedules_rules',selectedGroup],
        refetchInterval: 10 * 60 * 1000,
        enabled: true
    });
    
    
    // useSchedulesRules({ enabled: !!selectedGroup, group: selectedGroup });
    const targetsQuery = useSchedulesTargets();
    const queryClient = useQueryClient();
    const moniServ = ItpaApi.getService("monitoring");
    
    const toggleDisableRuleHandler = async (rule) => {
        // console.log("!!!", rule)
        try {
            let x = await moniServ.toggleEnableScheduleRule({ group: selectedGroup, name: rule.name, enabled: !rule.enabled });
            queryClient.invalidateQueries(recurrencesQuery.key);
            return x;
        } catch (e) {

        }
        setTimeout(() => toggleDisableRuleMutation.reset());
    };
    const toggleDisableRuleMutation = useMutation({ mutationFn: toggleDisableRuleHandler });

    const updateExceptionsHandler = async ({ name, dates }) => {
        console.log("!  1  ", name, dates);
        try {
            let x = await moniServ.updateScheduleExceptions({
                group: selectedGroup, name,
                dates
            });
            alertService.success("Eccezioni aggiornate");
            // clearSideContent();
            queryClient.invalidateQueries(recurrencesQuery.key);
            return x;
        } catch (e) {
            alertService.error("Errore nella creazione dell'eccezione'");
        }
        setTimeout(() => updateExceptionsMutation.reset());
    };
    const updateExceptionsMutation = useMutation({ mutationFn: updateExceptionsHandler });


    return (
        <>
            <Drawer
                anchor="right"
                open={sideContent !== null}
                onClose={clearSideContent}
            >
                {sideContent === "create_rule" ? (
                    <CreateRule 
                        onChange={() => queryClient.invalidateQueries(recurrencesQuery.key)}
                        onDismiss={clearSideContent}    
                        targets={targetsQuery?.data} 
                        group={selectedGroup} 
                        ></CreateRule>) : (<></>)}
                {sideContent === "rule_details" && !!selectedRule ? (
                    <RuleDetails
                        onChange={() => queryClient.invalidateQueries(recurrencesQuery.key)}
                        onDismiss={clearSideContent}
                        targets={targetsQuery?.data}
                        group={selectedGroup}
                        name={selectedRule.name}
                    ></RuleDetails>
                ) : (<></>)}
                {sideContent === "edit_exceptions" && !!selectedRule ? (
                    <RuleExceptions
                        onChange={() => queryClient.invalidateQueries(recurrencesQuery.key)}
                        onDismiss={clearSideContent}
                        initialExceptions={selectedRule.exceptions}
                        group={selectedGroup}
                        name={selectedRule.name}
                    ></RuleExceptions>
                ) : (<></>)}
                {sideContent === "rule_status" && !!selectedRule ? (
                    <RuleStatus
                        onChange={() => queryClient.invalidateQueries(recurrencesQuery.key)}
                        onDismiss={clearSideContent}
                        group={selectedGroup}
                        name={selectedRule.name}
                    ></RuleStatus>
                ) : (<></>)}
            </Drawer>
           
            
            <Deck 
                queryKey={recurrencesQueryKey}
                tabs={{
                    index: tabIndex,
                    setIndex: handleChangeTab,
                    tabsList: groupsQuery?.data?.map((groupName,groupId)=>({name:groupName,id:groupId}))
                }}
                spaceBelow={0}
            />
            <Paper sx={{height: '82vh', mt:0}}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={4} md={3} justifyContent="center">
                        <Paper
                            sx={{ minHeight: 200, p: 1 }}
                            className={"rule"}
                        >
                            <IconButton onClick={() => setSideContent("create_rule")}>
                                <AddOutlinedIcon sx={{ width: "50%", height: "50%" }}></AddOutlinedIcon>
                            </IconButton>
                        </Paper>
                    </Grid>


                    {
                        (!!selectedGroup) ? ((recurrencesQuery?.data  || [])
                            //?.filter(rule => (searchFilter === "" || rule.name.includes(searchFilter)))
                            .map((rule, ruleI) => (
                                <Grid item xs={12} sm={4} md={3} justifyContent="center" key={ruleI} >
                                    <Paper
                                        sx={{ minHeight: 200, p: 1, pt: 2 }}
                                        style={{ position: "relative" }}
                                        elevation={ruleEquals(selectedRule, rule) ? 20 : 0}
                                        className={"rule" + (rule.enabled === true ? "" : " disabled")}
                                        onDoubleClick={() => { toggleRuleDetailsEditor(rule) }}
                                    >
                                        <Box style={{ position: "absolute", top: 5, left: 10 }}>
                                            <Switch size="small" checked={rule.enabled} onChange={() => toggleDisableRuleMutation.mutate(rule)}></Switch>
                                        </Box>
                                        <Box style={{ position: "absolute", top: 1, right: 10 }}>
                                            <InDeck info={() => { toggleRuleDetailsEditor(rule) }}></InDeck>
                                        </Box>
                                        <Stack justifyContent="space-evenly" spacing={2}>
                                            <Typography variant="h5" component="div" color="success">
                                                {rule.name}
                                            </Typography>
                                            <Typography sx={{ fontSize: 12 }} variant="subtitle1" gutterBottom>
                                                {target2Name(rule.target)}
                                            </Typography>
                                            <Typography sx={{ fontSize: 12 }} variant="subtitle2" gutterBottom>
                                                {CronToString(rule.cron)}
                                            </Typography>
                                            <Stack direction="row" width="100%" height="35" justifyContent="space-evenly" >
                                                {/* <Box width="33%" height="35" sx={{ display: "flex", flexDirection: "column" }}>
                                                    <ModeStandbyOutlinedIcon ></ModeStandbyOutlinedIcon>
                                                    <Box sx={{ maxWidth: 150 }}>
                                                        {target2Name(rule.target)}
                                                    </Box>
                                                </Box> */}
                                                <Box width="50%" height="35" sx={{ display: "flex", flexDirection: "column" }} >
                                                    <DoDisturbAltOutlinedIcon></DoDisturbAltOutlinedIcon>
                                                    <Box sx={{ maxWidth: 150 }} onClick={() => toggleRuleExceptionsEditor(rule)}>
                                                        {rule.exceptions_count} eccezioni
                                                    </Box>
                                                </Box>
                                                <Box width="50%" height="35" sx={{ display: "flex", flexDirection: "column" }} >
                                                    <ArrowRightOutlinedIcon></ArrowRightOutlinedIcon>
                                                    <Box sx={{ maxWidth: 150 }} onClick={() => toggleRuleOccurrencesDetails(rule)}>
                                                        Occorrenze
                                                    </Box>
                                                </Box>
                                            </Stack>

                                        </Stack>


                                    </Paper>


                                </Grid>
                            ))) : (<></>)
                    }





                </Grid>
            </Paper>
        </>
    );



}


export default AdminSchedulesList;