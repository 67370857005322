
import { SigV4ApiClient } from "../../services/aws/sigV4api"

export default class AgentService extends SigV4ApiClient {
   
    async testAuthentication() {
        return await this.doPostWithCaller({
            path: "/pools/pool/agents/uid/test_autenticazione",
            body:{name: "ciccio"}}
        )
    }

    async listPools({envName}) {
        return await this.doGet({
            path: "/pools",
            queryParams: {
                env: envName
            }
        }
        )
    }

    async testGetPoolConfig({pool}) {
        return await this.doGet({
            path: `/pools/${pool}/config`}
        )
    }

    async testSetPoolConfig() {
        return await this.doPost({
            path: "/pools/{pool}/config"}
        )
    }

    async testGetPoolAssignment() {
        return await this.doGet({
            path: "/pools/{pool}/assignment"}
        )
    }

    async testSetPoolAssignment() {
        return await this.doPost({
            path: "/pools/{pool}/assignment"}
        )
    }

    async testCreatePool() {
        return await this.doPost({
            path: "/pools"}
        )
    }

    async testListAgentsInPoolsHandler() {
        return await this.doGet({
            path: "/pools/{pool}/agents"}
        )
    }

    async testToggleRunningFlagHandler() {
        return await this.doPost({
            path: "/pools/{pool}/agents/{uid}/runningFlag"}
        )
    }

    async testListActivitiesHandler() {
        return await this.doGet({
            path: "/activities"}
        )
    }


}