import { Box, Stack, Typography } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';



import OpinionatedForm from '../../forms/opinionatedForm';
import useCabledMutation from '../../../hooks/serviceRegistry/useCabledMutation';
import { useCabledQuery } from '../../../hooks/serviceRegistry/useCabledQuery';
import { ServiceContext } from '../../../hooks/serviceRegistry/serviceContext';
import { useQueryClient } from '@tanstack/react-query';


class AgentAssignment {
    constructor(
        { strategy = "local", details = {} } = {}
    ) {
        this.strategy = strategy || 'local';
        this.detailsQueue = details?.queue || {};
        this.detailsActivities = details?.activities || {};
    }
    static produce(fv){
        if (fv.strategy === 'local')
            return {
                strategy: fv.strategy,
                details: {}
            };
        else if (fv.strategy === 'queue')
            return {
                strategy: fv.strategy,
                details: {
                    queue: fv.detailsQueue
                }
            };
        else if (fv.strategy === 'activities')
            return {
                strategy: fv.strategy,
                details: {
                    activities: fv.detailsActivities
                }
            };
        else 
            throw new Error('Invalid agent assignment');
    }

}

const AdminPoolAssignmentEditor = ({
    poolName,
    onCancel = () => { },
    onSuccess = () => { },
    onError = () => { }
}) => {


    const queryClient = useQueryClient();
    const {getSvc} = useContext(ServiceContext);
    const [formValues, setFormValues] = useState(new AgentAssignment());
    const [retrieved, setRetrieved] = useState(false);
    const sqsSvc = getSvc('sqs');
    const agentsSvc = getSvc('agents');
    const existingAgentAssignmentQuery = useCabledQuery({
        svcName: 'agents',
        method: 'doGet',
        args: {
            path: `/pools/${poolName}/assignment`
        },
        refetchInterval: 100 * 60 * 1000,
        baseQueryKey: ['agents', 'pools', poolName, 'assignment'],
        enabled: !!poolName
    });
    useEffect(() => {
        if (existingAgentAssignmentQuery?.query?.data) {
            setFormValues(new AgentAssignment(existingAgentAssignmentQuery.query.data));
            setRetrieved(true);
        }

    }, [existingAgentAssignmentQuery?.query?.data]);


    const updateAssignmentMutation = useCabledMutation({
        svcName: 'agents',
        method: 'doPost',
        onSuccess,
        onFailure: onError,
        resetOnFailure: 10,
        resetOnSuccess: 10
    })

    const fields = [
        {
            name: "strategy", label: "Strategy", type: "select", required: true,
            getOptions: () => ['local','queue','activities']
        },
        {
            name: "detailsQueue", label: "Queue", type: "select", required: true,
            displayIf: (fv)=>fv.strategy === "queue",
            getOptions: async ()=>{
                const qs = sqsSvc.listQueues();
                return qs;
            }
        },
        {
            name: "detailsActivities", label: "Activities", type: "select", required: true,
            displayIf: (fv)=>fv.strategy === "activities",
            getOptions: async ()=>{
                const qs = await agentsSvc.doGet({path:"/activities"})
                return qs;
            }
        },
    ];
    const buttons = [
        { name: "Cancel", onClick: () => {queryClient.invalidateQueries(existingAgentAssignmentQuery.queryKey); onCancel()}, color: "error" },
        { name: "Update config", onClick: () => { queryClient.invalidateQueries(existingAgentAssignmentQuery.queryKey); updateAssignmentMutation.mutate({ path: `/pools/${poolName}/assignment`, body: AgentAssignment.produce(formValues)  }) }, color: "success", disabled: (formIsValid) => !formIsValid }
    ];

    return (

        <Stack spacing={2} sx={{ minWidth: "25vw" }} alignItems="center">
            <Box sx={{ height: "10vh" }} />
            <Typography variant="h5" component="div" gutterBottom>{poolName}</Typography>
            <Typography variant="h6" component="div" gutterBottom>Edit pool assignment</Typography>
            {existingAgentAssignmentQuery.query.isLoading && <Typography variant="h6" component="div" gutterBottom>Loading...</Typography>}
            {existingAgentAssignmentQuery.query.isError && <Typography variant="h6" component="div" gutterBottom>Error</Typography>}
            {retrieved && (
                <OpinionatedForm fields={fields} buttons={buttons} formValues={formValues} setFormValues={setFormValues} displayJson={true} validateOnChange={true} />
            )}
        </Stack>



    )
}



export default AdminPoolAssignmentEditor