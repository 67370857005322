import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import { useContext } from 'react';
import ItpaApi from '../services/api';
import { AppContext } from './appContext';



export function useProblemExecutions({problemId, enabled=true}) {  
    
    const monServ = ItpaApi.getService("monitoring");
    const { currentEnvironment } = useContext(AppContext);
    const fetchExecutions = async ()=>{
        return await monServ.listProblemExecutions.bind(monServ)({environment:currentEnvironment, problemId});
    }
    
    const executionsQuery = useQuery({
        queryKey: ['executions', currentEnvironment, 'problem', problemId],
        queryFn: fetchExecutions,
        enabled: enabled
    });
    return executionsQuery;
}
export function useExecutions({flowName,enabled=true}) {  
    
    const monServ = ItpaApi.getService("monitoring");
    const {currentEnvironment} = useContext(AppContext);
    console.log("USE EXECUTIONS");

    const fetchExecution = async () => {
        let data = await monServ.listExecutionsForFlow.bind(monServ)({flowName, environment:currentEnvironment});
        return data;
    }
    
    const executionsQuery = useInfiniteQuery({
        queryKey: ['execution',currentEnvironment, flowName],
        queryFn: () => fetchExecution(),
        enabled: enabled,
        getNextPageParam: (lastPage, pages) => lastPage.data ? lastPage.next_token : null
    });

    return executionsQuery;
}
export function useExecution({flowName, environment, executionName,enabled}) {  
    
    const monServ = ItpaApi.getService("monitoring");
    console.log("USE EXECUTION");

    const fetchExecution = async () => {
        let data = await monServ.getExecution.bind(monServ)({flowName, executionName,environment});
        return data;
    }
    
    const executionQuery = useQuery({
        queryKey: ['execution',environment, flowName, executionName],
        queryFn: fetchExecution,
        enabled: enabled,
        initialData: [],
    });

    return executionQuery;
}

