import { Avatar, Box, List, ListItem, ListItemAvatar, ListItemText, Paper, Typography, styled } from "@mui/material";
import { fullDateStr } from "../../services/common/common";
import NewTaskComment from "./newTaskComment";


const CommentAuthor = styled(Typography)`
  font-weight: bold;
`;

const CommentDate = styled(Typography)`
  font-size: 12px;
  color: gray;
`;

const CommentContent = styled(Typography)`
  margin-top: 8px;
`;

const CommentItem = ({ author, date, content }) => {
    return (

        <ListItem alignItems="flex-start">
            <ListItemAvatar>
                <Avatar>{author.charAt(0)}</Avatar>
            </ListItemAvatar>
            <ListItemText
                primary={<CommentAuthor>{author}</CommentAuthor>}
                secondary={
                    <>
                        <CommentDate>{fullDateStr(date)}</CommentDate>
                        <CommentContent>{content}</CommentContent>
                    </>
                }
            />
        </ListItem>

    );
};

export default function TaskComments({ notes }) {
    let nnotes = notes.sort((n1,n2)=>new Date(n1.dt) >= new Date(n2.dt) ? -1 : 1);
    return (
        <Box sx={{ height: "300px", overflowY: "scroll" }}>
            <List>
                {nnotes.map((note, index) => (
                    <CommentItem
                        key={index}
                        author={note.author}
                        date={new Date(note.dt)}
                        content={note.content}
                    />
                ))}
            </List>
        </Box>
    );
};