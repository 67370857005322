class Sizer {
    constructor({xs=null,sm=null,md=null,lg=null,xl=null}){
        this.xs = xs || 12;
        this.sm = sm || xs;
        this.md = md || sm;
        this.lg = lg || md;
        this.xl = xl || lg;
    }
}

export default Sizer