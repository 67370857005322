import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import { elapsed } from "../../services/common/common";
import CallMadeOutlinedIcon from '@mui/icons-material/CallMadeOutlined';
import PlayArrowOutlinedIcon from '@mui/icons-material/PlayArrowOutlined';
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import QuestionMarkOutlinedIcon from '@mui/icons-material/QuestionMarkOutlined';
const ItemMap = {
    'ExecStart': {'label': <p>Esecuzione<br />START</p>,'dotColor': 'primary','icon': CallMadeOutlinedIcon},
    'ExecRunning': {'label':ev=><p>Esecuzione<br />{ev.details.status}</p>,'dotColor':(ev)=>{
        switch(ev.details.status){
            case 'RUNNING':
                return 'primary'
            case 'SUCCEDED':
                return 'success'
            default:
                return 'danger'
        }},
        'icon': (ev)=>{
            switch(ev.details.status){
                case 'RUNNING':
                    return PlayArrowOutlinedIcon
                case 'SUCCEDED':
                    return DoneOutlinedIcon
                default:
                    return CloseOutlinedIcon
            }},
    },
    // 'ExecEnd': {
    //     'label':'Termine esecuzione',
    //     'dotColor':(ev)=>{
    //         switch(ev.details.status){
    //             case 'RUNNING':
    //                 return 'primary'
    //             case 'SUCCEDED':
    //                 return 'success'
    //             default:
    //                 return 'danger'
    //         }},
    //     'icon': StopOutlinedIcon
    // }
}






export function ProblemTimelineItem({
    ev
}) {
    let DotColor,DotIcon, Label;
    const mapValue = ItemMap[ev.evtType];
    if (mapValue){        
        Label = typeof mapValue.label === 'function' ? mapValue.label(ev) : mapValue.label;
        DotIcon = typeof mapValue.icon === 'function' ? mapValue.icon(ev) : mapValue.icon;
        DotColor = typeof mapValue.dotColor === 'function' ? mapValue.dotColor(ev) : mapValue.dotColor;
        // let DotIcon = typeof mapValue.icon === 'function' ? mapValue.icon(ev) : mapValue.icon;
    } else {
        Label = <p>{ev.evtType}</p>;
        DotIcon = QuestionMarkOutlinedIcon;
        DotColor = 'neutral';
    }

    return <TimelineItem>
        <TimelineOppositeContent>
            {/* {ex.start_date.format('DD/MM/YYYY HH:mm')} ({elapsed(ex.start_date)}) */}
            {ev.dt.format('DD/MM/YYYY HH:mm')}<br />({elapsed(ev.dt)})
        </TimelineOppositeContent>
        <TimelineSeparator>
            <TimelineDot color={DotColor} variant="outlined">
                <DotIcon />
            </TimelineDot>
            <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent sx={{mt: -2}}>
            {Label}
            {/* <Link to={'/admin/flows/'+ex.flow_name+'/'+ex.alias}>{ex.alias}</Link> */}
        </TimelineContent>
    </TimelineItem>
}