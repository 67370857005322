import { AWSCredentialsHelper } from "../../hooks/aws/credentials";
export class AWSIamBasedServiceClient {

    constructor({
        region, role
    }){
        this.region = region;
        this.role = role;
        this.ready = false;
        this.client = null;
        this.credentials = null;
    }
    areCredentialsExpired (){
        if (!!this.credentials){
            if (new Date(this.credentials.expiration) < new Date()){
                return true;
            }
        }
        return false;
    }
    async ensureCredentials(){
        if (!!this.credentials){
            if (new Date(this.credentials.expiration) < new Date()){
                await this.refreshClient();
            }
        }
    }
    async refreshCredentials (){
        AWSCredentialsHelper.init();
        try {
            
            this.credentials = await AWSCredentialsHelper.getCredentialsForRole(this.role);
        } catch(e){
            throw new Error(`Cannot refresh credentials for role ${this.role} due to ${e?.toString()}`);
        }
    }
    async init(){
        this.refreshClient = async ()=>{
            throw new Error("Not implemented");
        }
        await this.refreshClient();

        return this;
    }
}