import MonitorOutlinedIcon from '@mui/icons-material/MonitorOutlined';
import PauseOutlinedIcon from '@mui/icons-material/PauseOutlined';
import PlayArrowOutlinedIcon from '@mui/icons-material/PlayArrowOutlined';
import SensorsOffOutlinedIcon from '@mui/icons-material/SensorsOffOutlined';
import SensorsOutlinedIcon from '@mui/icons-material/SensorsOutlined';
import { Box } from '@mui/material';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import { useQueryClient } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import AdminEnvironmentSelector from '../../../components/admin/environmentSelector';
import Deck from '../../../components/queries/deck';
import useCabledMutation from '../../../hooks/serviceRegistry/useCabledMutation';
import { useCabledQuery } from '../../../hooks/serviceRegistry/useCabledQuery';
import { alertService } from '../../../services/alerting/alerting';
import AgentsInPool from '../../../components/agents/agentsInPool';
class Agent {

    constructor({ pool_name, uid, agent_version, processStatus, runnerConnectionStatus, runnerWorkingStatus, runningFlag, sessionStatus, state, updatedAt }) {
        this.poolName = pool_name;
        this.uid = uid;
        this.agentVersion = agent_version;
        this.id = uid;
        this.processStatus = processStatus;
        this.runnerConnectionStatus = runnerConnectionStatus;
        this.runnerWorkingStatus = runnerWorkingStatus;
        this.runningFlag = runningFlag;
        this.sessionStatus = sessionStatus;
        this.state = state;
        this.updatedAt = new Date(1000*parseInt(updatedAt,10));

    }
}
const CONFIG = {
    queryRefetchInterval: 1 * 60 * 1000
};




export function AdminAgentsDashboard() {
    const [agents, setAgents] = useState([]);
    const params = useParams();
    const poolName = params.pool;

    


    const agentsQuery = useCabledQuery({
        svcName: 'agents',
        method: 'doGet',
        args: {
            path: `/pools/${poolName}/agents`
        },
        enabled: true,
        refetchInterval: CONFIG.queryRefetchInterval,
        baseQueryKey: ['agents', poolName]
    });
    useEffect(() => {
        let aa = (agentsQuery?.query?.data || []).map(d => new Agent(d))
        setAgents(aa);
    }, [agentsQuery?.query?.data])


    return <>
        <Deck
            queryKey={agentsQuery.queryKey}
            onRefresh={() => { console.log("REFRESHED!") }}
            title={<AdminEnvironmentSelector />}
        />
        <AgentsInPool poolName={poolName} queryData={agentsQuery?.query?.data || []} adminView={true}/>
    </>
}