import { Box, Grid, Paper, Stack, Typography } from "@mui/material"

import './checkWidget.scss';
import styled from "@emotion/styled";
import { elapsed, expected, splitCamelCase } from "../../services/common/common";

import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import CheckIcon from '@mui/icons-material/Check';
import WarningIcon from '@mui/icons-material/Warning';
import ErrorIcon from '@mui/icons-material/Error';
import { useNavigate } from "react-router-dom";


const WidgetCard = styled(Paper, {
    shouldForwardProp: p => p !== "statusValue"
})(({ theme, statusValue }) => ({
    minHeight: '100px',
    minWidth: '150px',
    position: 'relative',
    display: 'block',
    background: theme.palette.rngLight[statusValue],
    boxShadow: theme.shadows[3],
    backdropFilter: 'blur(15px)',
    cursor: 'pointer',
    // borderColor: theme.palette.rngLight[statusValue],
    // borderWidth: "1px",
    // borderStyle: "solid",
    ":hover": {
        boxShadow: theme.shadows[6]
    }
}));

const NullIndicator = () => (
    <Box>
        <QuestionMarkIcon sx={{ color: 'orange' }} fontSize="large" />
    </Box>
)
const OKIndicator = () => (
    <Box>
        <CheckIcon sx={{ color: 'green' }} fontSize="large" />
    </Box>
)
const WarnIndicator = () => (
    <Box>
        <WarningIcon sx={{ color: 'orange' }} fontSize="large" />
    </Box>
)
const KOIndicator = () => (
    <Box>
        <ErrorIcon sx={{ color: 'red' }} fontSize="large" />
    </Box>
)


const CheckMiniWidget = function ({
    display_name = "",
    is_expired = false,
    status = null,
    last_str = null,
    onClick = ()=>{}
}) {
    const navigate = useNavigate();
    const statusValues = [false, null, true];
    return (
        <>
            <WidgetCard statusValue={statusValues.indexOf(status)} onClick={onClick}>
                <Stack justifyContent="space-between" sx={{ p: 1 }}>
                    <Typography sx={{ fontSize: '12px' }}>{last_str}</Typography>
                    <Typography noWrap sx={{ fontSize: '14px', fontWeight: 'bolder', maxWidth: "100%"}}>{display_name}</Typography>
                    <Box sx={{ flexGrow: 1 }} />
                    { is_expired && <WarnIndicator />}
                    { !is_expired && status === null && <NullIndicator />}
                    { !is_expired && status === true && <OKIndicator />}
                    { !is_expired && status === false && <KOIndicator />}
                </Stack>
            </WidgetCard>
        </>
    )
}


export default CheckMiniWidget;