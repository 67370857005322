import Timeline from "@mui/lab/Timeline/Timeline";
import TimelineConnector from "@mui/lab/TimelineConnector/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot/TimelineDot";
import TimelineItem from "@mui/lab/TimelineItem/TimelineItem";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent/TimelineOppositeContent";
import TimelineSeparator from "@mui/lab/TimelineSeparator/TimelineSeparator";
import { Box, Typography } from "@mui/material";
import { fullDateStr } from "../../services/common/common";
import UserMini from "../users/userMini";


const AssignmentEvent = ({ details }) => (
    <>
        {details?.assignee === null ? (
            <Typography variant="h6" component="h1">
                RILASCIATO
            </Typography>
        ) : (
            <>
                <Typography variant="h6" component="h1">
                    ASSEGNATO
                </Typography>
                <UserMini user={details.assignee} />
            </>
        )
        }
    </>
)
const PutNoteEvent = ({ details }) => (
    <>
        <Typography variant="h6" component="h1">
            AGGIUNTA NOTA
        </Typography>
        <UserMini user={details.author} />

    </>

)
const CreatedEvent = ({ details }) => (
    <>
        <Typography variant="h6" component="h1">
            CREATO
        </Typography>
    </>

)
const OtherEvent = ({ evt, details }) => (
    <>
        <Typography variant="h6" component="h1">
            {evt}
        </Typography>
        <Typography variant="body1">{JSON.stringify(details)}</Typography>
    </>
)



const TaskHistoryTimeline = ({ history }) => {


    // Ordina gli eventi in ordine decrescente in base alla data
    let sortedEvents = history.sort((a, b) => new Date(b.dt) - new Date(a.dt));
    let sortedEvents2 = sortedEvents.map(e => {
        let f = { ...e };
        if (f.evt === "CREATED") f.comp = <CreatedEvent {...f} />;
        if (f.evt === "ASSIGNMENT") f.comp = <AssignmentEvent {...f} />;
        else if (f.evt === "PUTNOTE") f.comp = <PutNoteEvent {...f} />;
        else f.comp = <OtherEvent {...f} />;
        return f;
    })
    console.log(sortedEvents2);

    return (
        <Box sx={{ height: "300px", overflowY: "scroll", p:1 }}>
            <Timeline>
                {sortedEvents2.map((event, eventI) => (
                    <TimelineItem key={eventI}>
                        <TimelineOppositeContent>
                            <Typography variant="body2" color="textSecondary">
                                {fullDateStr(event.dt)}
                            </Typography>
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                            <TimelineDot />
                            <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent>
                            {event.comp}
                        </TimelineContent>
                    </TimelineItem>
                ))}
            </Timeline>
        </Box>
    );
};

export default TaskHistoryTimeline;