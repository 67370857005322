import { useQuery, useQueryClient } from '@tanstack/react-query';
import ItpaApi from '../services/api';

export function useSchedulesTargets() {  
    
    const monServ = ItpaApi.getService("monitoring");

    const fetchTargets = async (filtersDict) => {
        const data = await monServ.listScheduleTargets.bind(monServ)();
        return data;
    };
    
    const targetsQuery = useQuery({
        queryKey: ['all_schedules_targets'],
        queryFn: fetchTargets,
        refetchInterval: 10 * 60 * 1000,
        refetchOnWindowFocus: false,
    });


    return targetsQuery;
}
export function useSchedulesGroups() {  
    
    const monServ = ItpaApi.getService("monitoring");

    const fetchGroups = async (filtersDict) => {
        const data = await monServ.listScheduleGroups.bind(monServ)();
        return data;
    };
    
    const groupsQuery = useQuery({
        queryKey: ['all_schedules_groups'],
        queryFn: fetchGroups,
        refetchInterval: 10 * 60 * 1000,
        refetchOnWindowFocus: false,
    });


    return groupsQuery;
}
export function useSchedulesRules({group, enabled}) {  
    
    const monServ = ItpaApi.getService("monitoring");

    const fetchRules = async (filtersDict) => {
        console.log("FEtCH RULES")
        const data = await monServ.listScheduleRules.bind(monServ)({group});
        console.log("FEtCH RULES",data);
        return data;
    };
    
    const rulesQuery = useQuery({
        queryKey: ['all_schedules_rules',group],
        queryFn: fetchRules,
        refetchInterval: 10 * 60 * 1000,
        refetchOnWindowFocus: false,
        enabled
    });


    return rulesQuery;
}
export function useSchedulesRule({group, name, enabled}) {  
    
    const monServ = ItpaApi.getService("monitoring");

    const fetchRules = async (filtersDict) => {
        const data = await monServ.getScheduleRule.bind(monServ)({group,name});
        return data;
    };
    
    const ruleQuery = useQuery({
        queryKey: ['schedule_rule',group, name],
        queryFn: fetchRules,
        refetchInterval: 10 * 60 * 1000,
        refetchOnWindowFocus: false,
        enabled
    });


    return ruleQuery;
}
export function useOccurrencesDemo({group, name, date_from,date_to,enabled}) {  
    
    const monServ = ItpaApi.getService("monitoring");

    const fetchOccsDemo = async (filtersDict) => {
        let data = await monServ.listDemoOccurrences.bind(monServ)({group,name,date_from,date_to});
        // data = data.map(x=>Object.assign(x,{at:new Date(x.at)}))
        return data;
    };
    
    const occsDemoQuery = useQuery({
        queryKey: ['occurrences_demo',group, name,date_from,date_to],
        queryFn: fetchOccsDemo,
        refetchInterval: 10 * 60 * 1000,
        refetchOnWindowFocus: false,
        enabled
    });


    return occsDemoQuery;
}
export function useOccurrences({group, name, date_from,date_to,enabled}) {  
    
    const monServ = ItpaApi.getService("monitoring");

    const fetchOccs = async (filtersDict) => {
        let data = await monServ.listOccurrences.bind(monServ)({group,name,date_from,date_to});
        // data = data.map(x=>Object.assign(x,{at:new Date(x.at)}))
        return data;
    };
    
    const occQuery = useQuery({
        queryKey: ['occurrences',group, name,date_from,date_to],
        queryFn: fetchOccs,
        refetchInterval: 10 * 60 * 1000,
        refetchOnWindowFocus: false,
        enabled
    });


    return occQuery;
}

