import { Button, FormControl, MenuItem, Select, Stack, Table, TableBody, TableCell, TableHead, TableRow, TextField, Typography } from "@mui/material";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import PageMainStack from "../../../components/page/pageMainStack";
import PagePaper from "../../../components/page/pagePaper";
import { useFlows } from "../../../hooks/flows";
import { useProblemsType, useProblemType } from "../../../hooks/problemtypes";
import { alertService } from "../../../services/alerting/alerting";
import ItpaApi from "../../../services/api";



const AdminInstallProblemType = () => {
    const params = useParams();
    const problemType = params.problem_type === 'new' ? null : params.problem_type;
    const environment = params.environment
    const editing = (problemType != null);
    const navigate = useNavigate();
    const [newProblemTypeName, setNewProblemTypeName] = React.useState(editing ? problemType : "");
    const [newProblemTypeNameError, setNewProblemTypeNameError] = React.useState("");
    const [flowAssociationRules, setFlowAssociationRules] = React.useState([
        { severity_min: 1, flow_name: "" },
        { severity_min: 2, flow_name: "" },
        { severity_min: 3, flow_name: "" },
        { severity_min: 4, flow_name: "" }
    ]);
    const severities = ['', 'WARNING', 'MINOR', 'MAJOR', 'CRITICAL'];

    const moniServ = ItpaApi.getService('monitoring');
    const flowsQuery = useFlows();

    const problemTypesQuery = useProblemsType({ enabled: false });
    const problemTypeQuery = useProblemType({ environment, problemType });
    const buildOutput = () => {
        let rules = [];
        if (!newProblemTypeName || newProblemTypeName.length < 5) {
            setNewProblemTypeNameError("Nome non valido, minimo 5 caratteri.")
            return false;
        }
        setNewProblemTypeNameError("");
        for (let r of flowAssociationRules) {
            if (r.flow_name.length) rules.push(r);
        }
        let out = {
            problemType: newProblemTypeName,
            environment: environment,
            flowRules: rules
        };
        return out;

    };
    const queryClient = useQueryClient();
    const problemTypeUpsert = async () => {
        let output = buildOutput();
        if (!output) {
            setTimeout(problemTypeUpsertMutation.reset);
        }
        try {
            await moniServ.createProblemType(output);
            alertService.success("Problem type creato");
            queryClient.invalidateQueries(problemTypesQuery.queryKey);
            navigate("..");
        } catch (e) {
            if (e.status === 409) alertService.error("Problem Type duplicato");
            else alertService.error("Impossibile creare problem type");

        }

    }
    const problemTypeUpsertMutation = useMutation({ mutationFn: problemTypeUpsert });




    React.useEffect(() => {
        if (problemTypeQuery?.data?.flow_rules)
            for (let r of problemTypeQuery.data.flow_rules) {
                setFlowName(r.severity_min - 1, r.flow_name);
            }
    }, [problemTypeQuery?.data?.flow_rules]);

    if (editing) {

        if (problemTypeQuery.isLoading) return <div>Loading...</div>;
        if (problemTypeQuery.isError) return <div>Error: {problemTypeQuery.error.message}</div>;

    }

    const setFlowName = (associationRuleIndex, flowName) => {
        let flowAssociationRulesTmp = [...flowAssociationRules];
        flowAssociationRulesTmp[associationRuleIndex].flow_name = flowName;
        setFlowAssociationRules(flowAssociationRulesTmp);
    }
    if (flowsQuery.isLoading)
        return <div>Loading...</div>
    if (flowsQuery.isError)
        return <div>Impossibile caricare l'elenco dei flussi installati.</div>

    return <PageMainStack>


        <PagePaper title={editing ? "Aggiorna Problem Type " + problemType : "Nuovo Problem Type"} height="20vh">
            <FormControl sx={{ width: "80%" }}>
                <TextField
                    label="Nome"
                    defaultValue={newProblemTypeName}
                    onChange={e => setNewProblemTypeName(e.target.value)}
                    disabled={editing}
                    error={!!newProblemTypeNameError}
                    helperText={newProblemTypeNameError}
                ></TextField>
            </FormControl>
        </PagePaper>
        <PagePaper height="52vh" subtitle="Regole flussi">
            <Stack spacing={2} justifyContent="space-evenly" >


                <Typography variant="h6"></Typography>

                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{ width: '30%' }}>Se la severity è almeno...</TableCell>
                            <TableCell>... usa il flusso:</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            flowAssociationRules.map((rule, ruleI) => <TableRow key={ruleI}>
                                <TableCell>{severities[rule.severity_min]}</TableCell>
                                <TableCell>
                                    <FormControl size="small" sx={{ width: "100%" }}>
                                        <Select
                                            value={rule.flow_name}
                                            onChange={e => setFlowName(ruleI, e.target.value)}
                                        >
                                            <MenuItem value={""}>---</MenuItem>
                                            {
                                                flowsQuery.data.map(({ name, executor }, flowI) => <MenuItem key={flowI} value={name}>{name}</MenuItem>)
                                            }
                                        </Select>
                                    </FormControl>
                                </TableCell>
                            </TableRow>)
                        }
                    </TableBody>
                </Table>
            </Stack>
        </PagePaper>
        <PagePaper height="8vh">
                <Stack direction="row" justifyContent="space-evenly" sx={{ width: '100%' }}>

                    <Button size="small" color="primary" onClick={() => problemTypeUpsertMutation.mutate()}>Conferma</Button>
                    <Button size="small" color="secondary" onClick={() => navigate("..")}>Annulla</Button>
                </Stack>
            </PagePaper>
    </PageMainStack>


}

export default AdminInstallProblemType;