import { Box, CircularProgress, IconButton, Stack, Switch } from "@mui/material";
import LoopOutlinedIcon from '@mui/icons-material/LoopOutlined';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import RemoveOutlinedIcon from '@mui/icons-material/RemoveOutlined';
import InfoIcon from '@mui/icons-material/Info';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import InstallDesktopIcon from '@mui/icons-material/InstallDesktop';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import FormatLineSpacingOutlinedIcon from '@mui/icons-material/FormatLineSpacingOutlined';
const InDeck = ({
    loading,
    newItem,
    removeItem,
    info,
    setup,
    copy,
    refresh,
    close,
    install,
    confirm,
    edit,
    format,
    withStyle={}
}) => {




    return <Box style={{display: 'inline-block',...withStyle}}>
        {loading ? <CircularProgress></CircularProgress> :
            <Stack direction="row" justifyContent="center">
                {refresh ? <IconButton color="neutral" onClick={() => refresh()}><LoopOutlinedIcon></LoopOutlinedIcon></IconButton>: <></>}
                {newItem ? <IconButton color="success" onClick={() => newItem()}><AddOutlinedIcon></AddOutlinedIcon></IconButton>: <></>}
                {removeItem ? <IconButton color="danger" onClick={() => removeItem()}><RemoveOutlinedIcon></RemoveOutlinedIcon></IconButton>: <></>}
                {info ? <IconButton color="neutral" onClick={() => info()}><InfoIcon></InfoIcon></IconButton>: <></>}
                {setup ? <IconButton color="neutral" onClick={() => setup()}><SettingsOutlinedIcon></SettingsOutlinedIcon></IconButton>: <></>}
                {copy ? <IconButton color="neutral" onClick={() => copy()}><ContentCopyOutlinedIcon></ContentCopyOutlinedIcon></IconButton>: <></>}
                {close ? <IconButton color="neutral" onClick={() => close()}><CloseOutlinedIcon></CloseOutlinedIcon></IconButton>: <></>}
                {install ? <IconButton color="neutral" onClick={() => install()}><InstallDesktopIcon></InstallDesktopIcon></IconButton>: <></>}
                {confirm ? <IconButton color="neutral" onClick={() => confirm()}><CheckOutlinedIcon></CheckOutlinedIcon></IconButton>: <></>}
                {edit ? <IconButton color="neutral" onClick={() => edit()}><EditOutlinedIcon></EditOutlinedIcon></IconButton>: <></>}
                {format ? <IconButton color="neutral" onClick={() => format()}><FormatLineSpacingOutlinedIcon></FormatLineSpacingOutlinedIcon></IconButton>: <></>}
            </Stack>}
    </Box>
    
}


export default InDeck;