import PagePaper from "../../components/page/pagePaper";

import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import React, { useContext } from "react";
import { alertService } from '../../services/alerting/alerting';
import Loader from "../../components/page/loader";
import { getCredentialsForRole, getRoleArnForCredentials, listAvailableRoles, obtainBaseRoleCredentials } from "../../services/aws/grants";
import { ServiceContext } from "../../hooks/serviceRegistry/serviceContext";

// import {CognitoIdentity} from 'aws-sdk';
export default function Dev1() {
    const {getSvc} = useContext(ServiceContext)
    let agentService = getSvc("agentservice")
    async function click_handler(){
        try {
            const test = await agentService.testAuthentication();
            console.log(test)
            if (test)
                alertService.info("Click eseguito dall'utente: " +test);
            else
                alertService.error("Permesso negato");

            return test
        } catch(e){
            console.error(e)
            alertService.error("Non è stato possibile completare l'operazione: "+e.message.toString());
        }
    } 
    return (<>
        <Button onClick={click_handler}>
            Click
        </Button>
    </>)
    
}