import React, { useState } from 'react';

import styled from '@emotion/styled';
import Timeline from '@mui/lab/Timeline/Timeline';
import TimelineConnector from '@mui/lab/TimelineConnector/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot/TimelineDot';
import TimelineItem from '@mui/lab/TimelineItem/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator/TimelineSeparator';
import { Typography } from '@mui/material';

import { dayMonthDateStr, hourMinuteDateStr } from '../../services/common/common';
import './checkTimeline.scss';

const RedDot = styled(TimelineDot)(({ theme }) => ({
    backgroundColor: theme.palette.error.main,
}));

const GreenDot = styled(TimelineDot)(({ theme }) => ({
    backgroundColor: theme.palette.success.main,
}));
const GrayDot = styled(TimelineDot)(({ theme }) => ({
    backgroundColor: 'grey',
}));

const CheckTimeline = ({ events, onSelect=()=>{} }) => {

    const [selected,setSelected] = useState(null);
    const onClick = function(index){
        let newSelected = selected === index ? null : index;
        setSelected(newSelected);
        if (onSelect){
            try { onSelect(newSelected !== null ? events[newSelected] : null)} catch (e){ console.error(e)}
        }
    }
    return (
        <Timeline>
            {events.map((event, index) => (
                <TimelineItem key={event.key} onClick={()=>onClick(index)} className='timeline-item'>
                    <TimelineSeparator>
                        {event.outcome === true && <GreenDot />}
                        {event.outcome === false && <RedDot />}
                        {event.outcome === null && <GrayDot />}
                         
                        {index < events.length - 1 && <TimelineConnector />}
                    </TimelineSeparator>
                    <TimelineContent>
                        <Typography className={(selected === index ? 'timeline-item-selected' : '')}>{dayMonthDateStr(event.dt)} {hourMinuteDateStr(event.dt)}</Typography>
                    </TimelineContent>
                </TimelineItem>
            ))}
        </Timeline>
    );
};

export default CheckTimeline;
