import { Box } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { usePdc, useSchedData } from "../../hooks/carrefour/carrefour";
import { fullDateStr } from "../../services/common/common";
import dayjs from "dayjs";
import { TabellaDollaro } from "./tabellaDollaro";



export default function PassaggioDiConsegne() {

    const queryClient = useQueryClient();
    const pdcQuery = usePdc();
    let astraUrl = null;
    let fgdepeUrl = null;
    let dicitura = "";
    let saluto = "";
    let sched_last_char = null;
    let aborted = {};
    let last_check_aborted = null;
    if (pdcQuery?.data) {
        console.log(pdcQuery.data);
        let sched = pdcQuery.data.metadata.sched;
        sched_last_char = sched[sched.length - 1];
        if (sched_last_char === "1") {
            dicitura = "mattutini";
            saluto = "Buon giorno";
        } else if (sched_last_char === "2") {
            dicitura = "serali";
            saluto = "Buona sera";
        } else { 
            dicitura = "notturni"; 
            saluto = "Buona sera";
        }
        let d = pdcQuery.data.data;
        astraUrl = d.find(x => x.data_name === "flussiastra" && x.data_type === "url");
        fgdepeUrl = d.find(x => x.data_name === "fgdepe" && x.data_type === "url");

        for (let x of pdcQuery?.data?.data){
            if (x.data_name.startsWith("aborted")){
                if (x.data_name === "aborted_check_date"){
                    last_check_aborted = dayjs(x.data_content).toDate();
                }
                let node = x.data_name.replace("aborted_","");
                try{
                    aborted[node] = JSON.parse(x.data_content);
                } catch(e){}
            }
        }
    }
    const fields = ['node','session','uproc','uproclabel','status','startdate','enddate','ticket'];
    return (
        <>
            {/* <Box>{
                pdcQuery?.data ? <pre>{JSON.stringify(aborted, null, 2)}</pre> : <p></p>
            }</Box>
            <Box>{
                pdcQuery?.data ? <pre>{JSON.stringify(pdcQuery.data, null, 2)}</pre> : <p></p>
            }</Box> */}
            <Box sx={{ textAlign: "left" }}>
                <p>Ultimo controllo aborted: {!!last_check_aborted && fullDateStr(last_check_aborted)}</p>
                <p></p>
                <p>{saluto},</p>
                <p>di seguito quanto riscontrato durante i controlli {dicitura}:</p>
                <p></p>
                <p><strong>AS400:</strong></p>
                <p></p>
                <p></p>
                {sched_last_char === '2' ? (<>
                    <p><strong>Flussi astra:</strong></p>
                    <p>
                        {astraUrl ? (
                            <img width="350" src={astraUrl.data_content}></img>

                        ) : (<p>Non ancora disponibile.</p>)}
                    </p>
                    <p></p>
                    <p><strong>Fine giornata DEPE:</strong></p>
                    <p>
                        {fgdepeUrl ? (
                            <img width="800" src={fgdepeUrl.data_content}></img>
                        ) : (<p>Non ancora disponibile.</p>)}

                    </p>
                    <p></p>
                </>) : (<></>)}
                {sched_last_char === '3' ? (<>
                    <p><strong>Incassi:</strong></p>
                    <p>Aborted del processo verifica incassi</p>
                    <p></p>
                </>) : (<></>)}
                <p><strong>ASTRA:</strong></p>
                {(aborted.itmicz83 && aborted.itmicz83.length) ? <TabellaDollaro fields={fields} bordered={true} data={aborted.itmicz83} /> : <p>Nessun problema riscontrato.</p>}
                <p></p>
                <p><strong>VEFA:</strong></p>
                {(aborted.itmicz27 && aborted.itmicz27.length) ? <TabellaDollaro fields={fields} bordered={true} data={aborted.itmicz27}/> : <p>Nessun problema riscontrato.</p>}
                <p></p>
                <p><strong>FAAT:</strong></p>
                {(aborted.itmicz40 && aborted.itmicz40.length) ? <TabellaDollaro fields={fields} bordered={true} data={aborted.itmicz40} /> : <p>Nessun problema riscontrato.</p>}
                <p></p>
                <p><strong>ITGREZLPAP030:</strong></p>
                {(aborted.itgrezlpap030 && aborted.itgrezlpap030.length) ? <TabellaDollaro fields={fields} bordered={true} data={aborted.itgrezlpap030} /> : <p>Nessun problema riscontrato.</p>}
                <p></p>
                <p><strong>ITGREZLPAP041:</strong></p>
                {(aborted.itgrezlpap041 && aborted.itgrezlpap041.length) ? <TabellaDollaro fields={fields} bordered={true} data={aborted.itgrezlpap041} /> : <p>Nessun problema riscontrato.</p>}
                <p></p>
                <p><strong>ITGREZLPAP046:</strong></p>
                {(aborted.itgrezlpap046 && aborted.itgrezlpap046.length) ? <TabellaDollaro fields={fields} bordered={true} data={aborted.itgrezlpap046} /> : <p>Nessun problema riscontrato.</p>}
                
                <p></p>
                <p><strong>Nodi critici:</strong></p>
                <p><strong>itmicz5051:</strong></p>
                {(aborted.itmicz5051 && aborted.itmicz5051.length) ? <TabellaDollaro fields={fields} bordered={true} data={aborted.itmicz5051} /> : <p>Nessun problema riscontrato.</p>}
                <p><strong>itmicz134:</strong></p>
                {(aborted.itmiz134 && aborted.itmiz134.length) ? <TabellaDollaro fields={fields} bordered={true} data={aborted.itmiz134} /> : <p>Nessun problema riscontrato.</p>}
                <p><strong>itgrezlpbs000:</strong></p>
                {(aborted.itgrezlpbs000 && aborted.itgrezlpbs000.length) ? <TabellaDollaro fields={fields} bordered={true} data={aborted.itgrezlpbs000} /> : <p>Nessun problema riscontrato.</p>}
                <p><strong>QLICK_PROD:</strong></p>
                {(aborted.QLICK_PROD && aborted.itgrezlpbs000.length) ? <TabellaDollaro fields={fields} bordered={true} data={aborted.itgrezlpbs000} /> : <p>Nessun problema riscontrato.</p>}
                <p></p>
                <p><strong>SC4 Food:</strong></p>
                {(aborted.SC4_PRO && aborted.SC4_PRO.length) ? <TabellaDollaro fields={fields} bordered={true} data={aborted.SC4_PRO} /> : <p>Nessun problema riscontrato.</p>}
                <p></p>
                <p><strong>SC4 Freschi:</strong></p>
                {(aborted.SC4FR_PRO && aborted.SC4FR_PRO.length) ? <TabellaDollaro fields={fields} bordered={true} data={aborted.SC4FR_PRO} /> : <p>Nessun problema riscontrato.</p>}
                <p></p>
                <p><strong>SC4 No Food:</strong></p>
                {(aborted.SC4NF_PRO && aborted.SC4NF_PRO.length) ? <TabellaDollaro fields={fields} bordered={true} data={aborted.SC4NF_PRO} /> : <p>Nessun problema riscontrato.</p>}
                <p></p>
                <p><strong>DEPE:</strong></p>
                {(aborted.DEPE_PRO && aborted.DEPE_PRO.length) ? <TabellaDollaro fields={fields} bordered={true} data={aborted.DEPE_PRO} /> : <p>Nessun problema riscontrato.</p>}
                <p></p>
            </Box>
        </>
    )

}