import { Box, Button, Paper, Stack, Typography, styled } from '@mui/material';
import { useContext } from 'react';
import { AppContext } from '../../hooks/appContext';
import './widget.scss';




const WidgetCard = styled(Paper, { shouldForwardProp: p => p !== "selected" || p !== "disabled" })(({ theme, selected, disabled }) => {
    const style = {
        backgroundColor: disabled ? 'gray' : 'white',
        minHeight: '100px',
        position: 'relative',
        display: 'block',
        margin: theme.spacing(1),
        boxShadow: theme.shadows[2],
        ":hover": {
            boxShadow: theme.shadows[5]
        }
    };
    if (selected) {
        style.border = "2px solid black";
    }
    return style;
});

const EnvWidget = ({
    
    name,
    disabled, 
    onDoubleClick = () =>  { }, 
    onChange = ()=>{},
    onDisablingEnvironment = ()=>{},
    onEnablingEnvironment = ()=>{}
}) => {
    const {currentEnvironment, setCurrentEnvironment} = useContext(AppContext);

   
    const selected = currentEnvironment === name;

    return (
        <WidgetCard onDoubleClick={onDoubleClick} {...{ selected, disabled }}>
            <Stack justifyContent="space-between" sx={{ minHeight: "100px", p: 1, }}>
                <Box sx={{ flexGrow: 1 }} />
                <Typography variant="h6" sx={{ wordWrap: true }}>{name}</Typography>
                <Box sx={{ flexGrow: 1 }}></Box>
                <Stack direction="row" justifyContent="space-between">
                    {disabled ? <Button onClick={onEnablingEnvironment}>ENABLE</Button> : <Button onClick={onDisablingEnvironment}>DISABLE</Button>}
                    {selected ? <Typography sx={{ fontSize: '12px' }}>SELECTED</Typography> : <Button onClick={()=>setCurrentEnvironment(name)}>SELECT</Button>}
                </Stack>
            </Stack>
        </WidgetCard>
    )
}

export default EnvWidget