import { CloudWatch, events } from 'aws-sdk';
import { AWSIamBasedServiceClient } from './awsIamBasedServiceClient';

events.on('retry', function(resp) {
    if (resp.error && resp.error.code === 'Throttling') {
      resp.error.retryable = false;
    }
  });




export class CloudWatchClient extends AWSIamBasedServiceClient {
    static lastGetAlarmsState = new Date();
    constructor({region, role}) {
        super({region, role})
        this.client = null;
        this.ready = false;
    }

    async init(){
        this.refreshClient = async () => {
            await this.refreshCredentials();
            try {
                this.client = new CloudWatch({credentials:this.credentials,region: this.region, maxRetries: 1});
                this.ready = true;
            } catch(e){
                throw new Error("Cannot init CW Client due to " + e.toString());
            }
        }
        await this.refreshClient();
        return this;
    }


    async getAlarmsState({alarmNames=null}) {
        // console.log("GETALARMSSTATE")
        await this.ensureCredentials();
        const baseParams = {
            
        };
        if (!!alarmNames){
            baseParams["AlarmNames"] = alarmNames;
        }
        if ((new Date()-this.lastGetAlarmsState).valueOf < 1000){
            await new Promise((a,b)=>setTimeout(() => a, 1000));
        }
        try {
            this.lastGetAlarmsState = new Date();
            const result = await this.client.describeAlarms(baseParams).promise();

            let out = [];
            for (let res of result.MetricAlarms){
                out.push({
                    name: res.AlarmName,
                    status: res.StateValue
                })
            }
            return out;
        } catch (e) {
            console.log(e)
            throw new Error("Cannot retrieve alarm status for " + alarmNames + "due to" + e.toString());

        }

    }

}



