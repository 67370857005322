import React, { useEffect, useState } from 'react';
import { Auth, Storage } from 'aws-amplify';
import { S3 } from 'aws-sdk';
export function SetS3Config(bucket, level, environ) {


    Storage.configure({
        bucket: bucket,
        level: level,
        region: 'eu-west-1',
        identityPoolId: environ === "production" ? 'eu-west-1:7cb5716d-0d7a-4966-b4ce-4d4a6823fa8c' : 'eu-west-1:0b8241e8-dccf-4cd7-9fa3-6d75cef6c6fb',
    });
}



export async function headS3File(bucket, level, environ, key) {
    const s3 = new S3({ maxRetries: 0, region: 'eu-west-1', credentials: await Auth.currentCredentials() });
    try {
        const result = await s3.headObject({
            Bucket: bucket,
            Key: level + '/' + key
        }).promise();
        return result;
    } catch {
        return null;
    }
}

export async function listS3ObjectsWithPrefix(bucketName, prefix, credentials = null, maxKeys = null, continuationToken = null) {

    const s3 = new S3({ maxRetries: 0, region: 'eu-west-1', credentials: credentials || (await Auth.currentCredentials()) });
    const params = {
        Bucket: bucketName,
        Prefix: prefix,
        MaxKeys: maxKeys || 10,
        ContinuationToken: continuationToken
    };

    try {
        const response = await s3.listObjectsV2(params).promise();
        const objects = response.Contents;

        if (objects.length > 0) {
            return { data: objects.map(o => o.Key), ct: response.NextContinuationToken };
        } else {
            return { data: [], ct: null };
        }
    } catch (error) {
        console.error('Errore durante il recupero degli oggetti:', error);
        return { data: [], ct: null };
    }
}

export async function readJsonFromS3(bucketName, key, credentials = null) {
    const s3 = new S3({ maxRetries: 0, region: 'eu-west-1', credentials: credentials || (await Auth.currentCredentials()) });

    const params = {
        Bucket: bucketName,
        Key: key
    };

    try {
        const response = await s3.getObject(params).promise();
        const jsonContent = response.Body.toString('utf-8');
        const data = JSON.parse(jsonContent);
        return data;
    } catch (error) {
        console.error('Error reading JSON file from S3:', error);
        return null;
    }
}

export async function getS3File(bucket, level, environ, key) {
    SetS3Config(bucket, level, environ);
    const result = await Storage.get(key, { download: true, cacheControl: 'no-cache' });
    return await result.Body.text();
}
export async function getS3Json(bucket, level, environ, key) {
    SetS3Config(bucket, level, environ);
    const result = await Storage.get(key, { download: true, cacheControl: 'no-cache' });
    const txt = await result.Body.text();
    return JSON.parse(txt);
}

// function s2ab(s) {
//     var buf = new ArrayBuffer(s.length);
//     var view = new Uint8Array(buf);
//     for (var i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
//     return buf;
// }

export async function downloadFileFromS3(bucket, level, environ, key, mime, encoding, filename) {
    SetS3Config(bucket, level, environ);
    const result = await Storage.get(key, { download: true, cacheControl: 'no-cache' });
    const blob = await result.Body;
    try {
        var element = document.createElement('a');
        let href = URL.createObjectURL(blob);
        element.setAttribute('href', href);
        element.setAttribute('download', filename);
        element.style.display = 'none';
        document.body.appendChild(element);
        element.click();
        setTimeout(() => document.body.removeChild(element));
    } catch (e) {
        console.error(e);
    }
}

export function initS3Client({ credentials }) {
    return new S3({ maxRetries: 0, region: 'eu-west-1', credentials });
}



const S3Image = ({ bucketName, objectKey, s3Client = null, ...props }) => {
    const [imageData, setImageData] = useState(null);

    useEffect(() => {

        

        const params = {
            Bucket: bucketName,
            Key: objectKey,
        };

        s3Client.getObject(params, (err, data) => {
            if (err) {
                setImageData('NOTPRESENT');
            } else {
                setImageData(data.Body);
            }
        });
    }, [bucketName, objectKey]);

    if (!imageData) {
        return <div>Caricamento in corso...</div>;
    }
    else if (imageData === 'NOTPRESENT') {
        return <div>Immagine non presente.</div>;
    }

    const imageSrc = `data:image/jpeg;base64,${imageData.toString('base64')}`;

    return (
        <div>
            <img {...props} src={imageSrc} alt="Per questo passaggio non è stata raccolta un'immagine." />
        </div>
    );
};

export default S3Image;

export const S3Image2 = ({ getter, ...props }) => {
    const [imageData, setImageData] = useState(null);

    useEffect(() => {

        getter().then((data)=>{
            setImageData(data);
        },(err)=>{
            setImageData('NOTPRESENT');
        }).catch((err)=>{
            setImageData('NOTPRESENT');
        })

    }, [getter]);

    if (!imageData) {
        return <div>Caricamento in corso...</div>;
    }
    else if (imageData === 'NOTPRESENT') {
        return <div>Immagine non presente.</div>;
    }
    function encode(data) {
        var str = data.reduce(function(a,b){ return a+String.fromCharCode(b) },'');
        return btoa(str).replace(/.{76}(?=.)/g,'$&\n');
    }
    
    const imageSrc = `data:image/png;base64,${encode(imageData)}`;

    return (
        <div>
            <img {...props} src={imageSrc} alt="Per questo passaggio non è stata raccolta un'immagine." />
        </div>
    );
};

