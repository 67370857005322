import { useQuery, useQueryClient } from '@tanstack/react-query';
import ItpaApi from '../services/api';
export function useProblemsType({enabled=true}={}) {  
    
    const moniServ = ItpaApi.getService("monitoring");
    const queryClient = useQueryClient();

    const fetchProblemTypes = async () => {
        const data = await moniServ.listAllProblemTypes.bind(moniServ)();
        return data;
    };
    
    const problemTypesQuery = useQuery({
        queryKey: ['all_problem_types'],
        queryFn: fetchProblemTypes,
        refetchInterval: 10 * 60 * 1000,
        refetchOnWindowFocus: false,
        enabled
    });

    problemTypesQuery.key = ['all_problem_types'];

    return problemTypesQuery;
}

export function useProblemType({problemType,environment,enabled=true}){
    const moniServ = ItpaApi.getService("monitoring");
    const queryClient = useQueryClient();
    const fetchProblemType = async ()=>{
        return await moniServ.describeProblemType.bind(moniServ)({problemType,environment});
    }
    
    const problemTypeQuery = useQuery({
        queryKey: ['problemType',environment,problemType],
        queryFn: fetchProblemType,
        refetchInterval: 10 * 60 * 1000,
        refetchOnWindowFocus: false,
        enabled
    });

    problemTypeQuery.key = ['problemType',environment,problemType];

    return problemTypeQuery;
}