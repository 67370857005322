import { Box, Button, FormControl, FormHelperText, InputLabel, MenuItem, Select, Stack, TextField, Typography } from "@mui/material";
import { useState } from "react";
import { useStepFunctions } from "../../hooks/stepfunctions";
import PropTypes from 'prop-types';
import ClientServiceFilter from "../filters/clientServiceFilter";
import { useClientServices } from "../../hooks/clients";
import StepFunctionFilter from "../filters/stepFunctionFilter";



const environments = [
    "Bper",
    "Stellantis",
    "SalaMacchine",
    "Sys"
];

export default function FlowInstallationForm({
    onOk,
    onCancel
}) {


    const [newFlow, setNewFlow] = useState({ meta: {}, name: "", executor: "" });
    const [errors, setErrors] = useState({});
    const [valid, setValid] = useState(false);
    const stepFunctionsQuery = useStepFunctions({ enabled: newFlow.executor === 'stepfunctions' });
    const customerServiceOptions = useClientServices();
    const validateForm = (valueToValidate) => {
        if (!valueToValidate) {
            valueToValidate = newFlow
        }
        console.log("validate", valueToValidate)
        let err = errors;
        let out = true;
        if (!valueToValidate.name) {
            err = { ...err, name: null };
            out = false;
        } else if (valueToValidate.name.length < 5) {
            err = { ...err, name: "Nome non valido, minimo 5 caratteri" };
            out = false;
        }
        else err = { ...err, name: null };
        if (!valueToValidate.executor) {
            out = false;
        }

        if (!valueToValidate.extFlowIdentity) {
            err = { ...err, extFlowIdentity: "Esecutore non valido" };
            out = false;
        }
        else err = { ...err, extFlowIdentity: null };
        setErrors(err)
        return out;
    }
    const changeNewFlow = (field, newValue) => {
        setNewFlow({ ...newFlow, [field]: newValue });
        setValid(validateForm({ ...newFlow, [field]: newValue }));
    }

    return (
        <Stack spacing={5} justifyContent="space-between" sx={{ minWidth: '40vh', minHeight: '50%', p: 2, pt: 10 }}>

            <Typography variant="h5" sx={{ mb: 3 }}>Installa nuovo flusso</Typography>
            <FormControl required>
                <InputLabel id="new-flow-environment-label">Ambiente</InputLabel>
                <Select
                    required
                    id="new-flow-environment"
                    labelId="new-flow-environment-label"
                    name="new-flow-environment"
                    label="Ambiente"
                    fullWidth={true}
                    value={newFlow.environment}
                    onChange={e => changeNewFlow("environment", e.target.value)}
                >
                    {environments.map((e, eI) => <MenuItem key={eI} value={e}>{e}</MenuItem>)}
                </Select>
            </FormControl>
            <TextField
                required
                id="new-flow-name"
                name="new-flow-name"
                label="Nome flusso"
                placeholder='DoSomething::1'
                error={!!errors.name}
                helperText={errors.name}
                onChange={e => changeNewFlow("name", e.target.value)}
                fullWidth={true}
            />
            <ClientServiceFilter labelForNull="nessuno" multiple={false} customerServiceOptions={customerServiceOptions.data || []} selection={newFlow.meta} onChange={x => changeNewFlow("meta", x )} />
            <FormControl
                required
                error={!!errors.executor}
            >
                <InputLabel id="new-flow-executor-label">Engine di esecuzione</InputLabel>
                <Select
                    required
                    id="new-flow-executor"
                    labelId="new-flow-executor-label"
                    name="new-flow-executor"
                    label="Engine di esecuzione"
                    placeholder='stepfunctions'
                    fullWidth={true}
                    value={newFlow.executor}
                    onChange={e => changeNewFlow("executor", e.target.value)}
                >
                    <MenuItem value="stepfunctions">StepFunctions</MenuItem>
                </Select>
                {!!errors.executor ? <FormHelperText>{errors.executor}</FormHelperText> : <></>}
            </FormControl>
            {newFlow.executor === 'stepfunctions' && <StepFunctionFilter onChange={e => changeNewFlow("extFlowIdentity", e)} selection={newFlow.extFlowIdentity} />}


            {/* <Box sx={{ flexGrow: 1 }} />
            <Box><pre>{JSON.stringify(newFlow, null, 2)}</pre></Box> */}
            <Box sx={{ flexGrow: 1 }} />
            <Stack direction="row" spacing={2} justifyContent="center">
                <Button
                    disabled={!valid}
                    type="button"
                    onClick={() => onOk(newFlow)}
                >Installa</Button>
                <Button
                    color="secondary"
                    type="button"
                    onClick={() => onCancel()}
                >Annulla</Button>
            </Stack>
        </Stack>
    )
}
FlowInstallationForm.propTypes = {
    onOk: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
};