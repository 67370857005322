import { Tooltip } from "@mui/material";
import PersonIcon from '@mui/icons-material/Person';
import { useContext } from "react";
import { UserContext } from "../../hooks/userContext";

export default function UserMini({ user }) {
    
    const {sub,user:currentUser} = useContext(UserContext);

    let title = "";
    if (user === sub || user === "user::"+sub || user === currentUser.username || user === "user::"+currentUser.username)
        title = "IO";
    else
        title = user;
    
    return (
        <Tooltip title={title}><PersonIcon></PersonIcon></Tooltip>
    );
}