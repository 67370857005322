import { Box, Typography } from '@mui/material';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js';
  import { Bar } from 'react-chartjs-2';

const customCanvasBackgroundColorPlugin = {
    id: 'customCanvasBackgroundColor',
    beforeDraw: (chart, args, options) => {
        const {ctx} = chart;
        ctx.save();
        ctx.globalCompositeOperation = 'destination-over';
        ctx.fillStyle = options.color || '#99ffff';
        ctx.fillRect(0, 0, chart.width, chart.height);
        ctx.restore();
    }
};
ChartJS.register(
    CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
    customCanvasBackgroundColorPlugin
);

const WidgetBarChart = function ({
    title = "",
    sx = {}
}) {

    const options = {
        responsive: true,
        plugins: {
            legend: false,
            title: false,
            customCanvasBackgroundColor: {
                color: 'rgba(255,255,255,.1)',
              }
        },
        scales: {
            x: {
                display: false
            },
            y: {
                display: false
            }
        },
        grid: {
            display: false
        },
        backgroundColor: "rgb(0,0,0)"
    };
    const data = {
        labels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
        datasets: [
            {
                label: 'Dataset1',
                data: [1, 2, 3, 4, 3, 5, 2, 6, 7, 1, 3],
                borderColor: "rgb(0,0,255)",
                backgroundColor: "rgba(0,0,255,1)"
            }
        ]
    }

    return (
        <Box sx={{...sx, maxHeight: '10vh'}} >
            <Typography>{title}</Typography>
            <Bar
                options={options}
                data={data}
                redraw={true}
                style={{ borderRadius: '5px', boxShadow: '1px 1px 1px 1px'}}
            />
        </Box>
    );
}

export default WidgetBarChart;