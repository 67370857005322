
import { ApiClient } from '../api';

class OperatorMonitoringService extends ApiClient {


    // TASKS

    async listMyTasks({nextToken=null}={}){
        let paginationQuery = {};
        if (nextToken) paginationQuery.next_token = nextToken;
        return await this.do_get(`/*/tasks`,{status:'OPEN'},paginationQuery);
    }
    async listAllMyTasks(){
        
        return await this.do_get_all_pages(`/*/tasks`,{status:'OPEN'});
    }
    async describeTask({environment,id}={}){
        return await this.do_get(`/${environment}/tasks/${id}`);
    }
    async taskTakeCharge({environment,id}={}){
        return await this.do_put(`/${environment}/tasks/${id}`,{assignee:'ME'})
    }
    async releaseTaskAssignment({environment,id}={}){
        return await this.do_put(`/${environment}/tasks/${id}`,{assignee:null})
    }
    async putTaskNote({environment,id,content}={}){
        return await this.do_put(`/${environment}/tasks/${id}`,{content})
    }
    async putTaskFeedback({environment,id,solutionId,formFeedback={}}={}){
        return await this.do_put(`/${environment}/tasks/${id}`,{
            solution_id: solutionId, form_feedback: formFeedback
        })
    }
    async listTaskSolutions({environment,id}={}){
        return await this.do_get(`/${environment}/tasks/${id}/solutions`)
    }


    // PROBLEMS

    async listProblems({environment, includeArchived, filtersDict = {}}) {
        filtersDict.include_archived = includeArchived;
        
        return await this.do_get_all_pages(`/${environment}/problems`,filtersDict)
    }

    async getProblem({environment, problemId}) {
        return await this.do_get(`/${environment}/problems/` + problemId);
    }

    async closeProblem({ environment, problemId, solution }) {
        return await this.do_post(`/${environment}/problems/${problemId}/close`, solution || "");
    }

    // CHECKS
    async listChecks({environment}){
        return await this.do_get_all_pages(`/${environment}/checks`);
    }
}







export default OperatorMonitoringService