import { SvgIcon } from "@mui/material";



export default function LogsIcon({w=144,h=144}){
    return <SvgIcon xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
    width={w} height={h}
    viewBox="0 0 512 512">

    <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
        fill="#000000" stroke="none">
        <path d="M1540 4950 l0 -130 937 0 938 0 297 -297 298 -298 0 428 0 427 -1235
0 -1235 0 0 -130z"/>
        <path d="M4100 4605 l0 -471 43 -42 43 -42 462 0 c254 0 462 3 462 8 0 4 -227
234 -505 512 l-505 505 0 -470z"/>
        <path d="M770 4610 l0 -130 937 0 938 0 297 -297 298 -298 0 428 0 427 -1235
0 -1235 0 0 -130z"/>
        <path d="M3330 4265 l0 -471 43 -42 43 -42 467 2 467 3 -510 510 -510 510 0
-470z"/>
        <path d="M0 2215 l0 -2175 1790 0 1790 0 0 1625 0 1625 -555 0 -555 0 0 550 0
550 -1235 0 -1235 0 0 -2175z m2050 1455 l0 -130 -855 0 -855 0 0 130 0 130
855 0 855 0 0 -130z m-2 -597 l-3 -128 -852 -3 -853 -2 0 130 0 130 855 0 856
0 -3 -127z m1192 -598 l0 -125 -1450 0 -1450 0 0 125 0 125 1450 0 1450 0 0
-125z m0 -595 l0 -130 -1450 0 -1450 0 0 123 c0 68 3 127 7 130 3 4 656 7
1450 7 l1443 0 0 -130z m0 -600 l0 -130 -1450 0 -1450 0 0 130 0 130 1450 0
1450 0 0 -130z m0 -600 l0 -130 -1448 2 -1447 3 -3 128 -3 127 1451 0 1450 0
0 -130z"/>
        <path d="M2560 3880 l0 -510 512 0 513 0 -510 510 c-280 281 -511 510 -512
510 -2 0 -3 -229 -3 -510z"/>
        <path d="M4353 3882 l87 -87 0 -252 0 -253 168 -2 167 -3 3 -127 3 -128 -171
0 -170 0 0 -170 0 -170 170 0 170 0 0 -130 0 -130 -170 0 -170 0 0 -170 0
-170 170 0 171 0 -3 -127 -3 -128 -167 -3 -168 -2 0 -170 0 -170 170 0 170 0
0 -125 0 -125 -170 0 -170 0 0 -255 0 -255 340 0 340 0 0 1620 0 1620 -427 0
-428 0 88 -88z"/>
        <path d="M3583 3542 l87 -87 0 -258 0 -257 170 0 170 0 0 -125 0 -125 -170 0
-170 0 0 -170 0 -170 170 0 170 0 0 -130 0 -130 -170 0 -170 0 0 -170 0 -170
170 0 170 0 0 -130 0 -130 -170 0 -170 0 0 -170 0 -170 170 0 170 0 0 -125 0
-125 -170 0 -170 0 0 -260 0 -260 340 0 340 0 0 1625 0 1625 -427 0 -428 0 88
-88z"/>
    </g>
</SvgIcon>
}