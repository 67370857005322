import { Grid } from '@mui/material';
import React from 'react';
import ActivityDetails from '../../../components/activities/details';
import ActivitiesList from '../../../components/activities/list';
import PagePaper from '../../../components/page/pagePaper';
const OperatorAlerts = () => {

    const [detailActivity, setDetailAlert] = React.useState(null);
    const toggleDisplayActivityDetails = (alert) => {
        if (alert === null) { setDetailAlert(null); }
        else setDetailAlert(alert);
    }


    return <PagePaper transparent={true}>
        <Grid container spacing={2} sx={{ height: '80vh' }}>

            <Grid item xs={12} xl={detailActivity === null ? 12 : 6} sx={{ height: '100%' }}>
                <ActivitiesList onDoubleClick={(row) => toggleDisplayActivityDetails(row)} />
            </Grid>
            {detailActivity && (
                <Grid item xs={12} xl={detailActivity === null ? 12 : 6} sx={{ height: '100%' }}>
                    <ActivityDetails
                        activity={detailActivity}
                        toggleDetailsDisplay={toggleDisplayActivityDetails} />
                </Grid>)}

        </Grid>
    </PagePaper>
};
export default OperatorAlerts