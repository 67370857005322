import { Route } from "react-router-dom";
import { ServiceRegistryWrapper } from "../components/serviceRegistry/serviceRegistryWrapper";
import StellantisDashboard from "../pages/stellantis/dashboard";
import AuthorizedSection from "./authorizedSection";
import { TextB } from "./breadcrumbs";
import TicketAssignmentDashboard from "../pages/stellantis/ticketAssignment";
import StellantisAgents from "../pages/salamacchine/stellantis/agents";

const routes = [
    <Route path="stellantis" element={<AuthorizedSection env="Stellantis" />}>
        <Route element={<ServiceRegistryWrapper key="srw_stellantis" defaultRole={"ItpaEnvStellantis"}
            requiredServices={[
                { service: 'ddb' },
                { service: 'cw' },
                { service: 's3' },
                { service: 'loginctrl', role: "Operator"},
                {service: 'agents', label: 'agentsInStellantis'}
            ]}
        />}>
            <Route index element={<StellantisDashboard />} handle={{ crumb: (data) => TextB(0, "Stellantis splunk: Dashboard") }} />

            <Route path="splunk">
                <Route index element={<StellantisDashboard />} handle={{ crumb: (data) => TextB(0, "Stellantis splunk: Dashboard") }} />
            </Route>
            <Route path="ticketassignment">
                <Route index element={<TicketAssignmentDashboard />} handle={{ crumb: (data)=> TextB(0, "Stellantis Ticket Assignment Dashboard")}} />
            </Route>
            <Route path="agents">
                <Route index element={<StellantisAgents />} handle={{ crumb: (data)=> TextB(0, "Stellantis Agenti RPA")}} />
            </Route>
        </Route>
    </Route>
];

export default routes;