import { Grid } from '@mui/material';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import PagePaper from "../../components/page/pagePaper";
import Deck from "../../components/queries/deck";
import { QueryLinearProgress } from '../../components/queries/queryLinearProgress';
import { SimpleStatus } from '../../components/simpleStatus/simpleStatus';
import { useCabledQuery } from '../../hooks/serviceRegistry/useCabledQuery';
import { fullDateStr } from "../../services/common/common";
const SITES = [
    { key: "pubblico", label: "Sito pubblico" },
    { key: "privati", label: "Italia privati" },
    { key: "corporate", label: "Italia corporate" },
    { key: "austria", label: "Austria" },
    { key: "unico", label: "Unico" },
]


class UnicreditReport {
    constructor(completedPages, expiration, reportName, reportOutcome, reportStatus, site, totalPages) {
        this.status = reportStatus;
        this.outcome = reportOutcome;
        this.expiration = new Date(expiration * 1000);
        this.fullname = reportName;
        this.site = site;
        this.timestamp = parseInt(reportName.replace(site, ""));
        this.date = new Date(this.timestamp * 1000);
        this.completedPages = completedPages;
        this.totalPages = totalPages;
        this.id = this.fullname;
    }
    static from_dynamo_record(record) {
        return new UnicreditReport(
            record.completedPages.N,
            record.expiration.N,
            record.reportName.S,
            record.reportOutcome?.BOOL,
            record.reportStatus.S,
            record.site.S,
            record.totalPages.N

        )
    }
}





export default function UnicreditHome() {


    const [siteReports, setSiteReports] = useState([]);
    const [currentTab, setCurrentTab] = useState(0);

    const { query: oQuery, queryKey: oqueryKey } = useCabledQuery({
        svcName: 'ddb',
        method: 'scanTable',
        baseQueryKey: 'ucrreports',
        args: {
            tableName: 'ucr.reports',
            filters: {
                FilterExpression: "site = :site",
                ExpressionAttributeValues: { ":site": { S: SITES[currentTab].key } }
            }
        },
        refetchInterval: 10 * 60 * 1000,
        enabled: true,
    });
    const alarmNames = {
        'unicredit-state/PubblicoReads': "Pubblico",
        'unicredit-state/PrivatiReads': "Privati",
        'unicredit-state/CorporateReads': "Corporate",
        'unicredit-state/AustriaReads': "Austria",
        'unicredit-state/UnicoReads': "Unico",
    };
    const alarmsQuery = useCabledQuery({
        svcName: 'cw',
        method: 'getAlarmsState',
        refetchInterval: 2 * 60 * 1000,
        baseQueryKey: 'ucralarms',
        enabled: true,
        args: {
            alarmNames: Object.keys(alarmNames)
        }
    });
   
    function onChangeTab(newSelectedTab) {
        setCurrentTab(newSelectedTab);
    }

    function renderStatus(p) {
        if (p.row.status === "COMPLETED") return "Completata";
        if (p.row.status === "COMPLETING") return "In completamento";
        if (p.row.status === "FAILED") return "Fallita";
        if (p.row.status === "CANCELLED") return "Annullata";
        if (p.row.status === "TODO") return "In corso";
    }
    
    const statusMap = {
        true: true,
        'OK': true,
        false: false,
        'ALARM': false,
    };
    useEffect(() => {
        var reports = oQuery?.data?.map((d) => UnicreditReport.from_dynamo_record(d)) || [];
        reports.sort((a, b) => a.date < b.date ? 1 : -1);
        setSiteReports(reports);
    }, [oQuery.data]);

    const columns = [
        { field: 'date', headerName: 'Data e Ora', type: 'date', flex: 1, valueGetter: p => fullDateStr(p.row.date) },
        { field: 'status', headerName: 'Stato scansione', flex: 1, valueGetter: renderStatus },
        { field: 'pagine', headerName: 'Pagine scansionate', flex: 1, valueGetter: p => `${p.row.completedPages} / ${p.row.totalPages}` },
        { field: 'fullname', headerName: 'Dettagli', flex: 1, type: 'actions', renderCell: p => p.row.status === "COMPLETED" ? <Link to={"/unicredit/siti/" + p.value}>Dettagli </Link> : <></> }
    ];

    return (
        <>
            <Grid container spacing={2} columns={20} sx={{ mb: 2 }}>
                {(alarmsQuery && alarmsQuery.query && alarmsQuery.query.data) && Object.entries(alarmNames).map(([k, v]) => (
                    <Grid item xs={20} sm={10} md={4} key={v}>
                        <SimpleStatus name={"Bot Sito " + v} width="100%" height="100%" tooltip="Indica se il robottino che legge il sito sta funzionando o meno."
                            top={<QueryLinearProgress query={alarmsQuery.query} queryKey={alarmsQuery.queryKey} />}
                            status={statusMap[alarmsQuery.query.data.find(x => x.name === k)?.status]}
                        />
                    </Grid>

                ))}

            </Grid>
            <Deck
                queryKey={oqueryKey}
                title="Unicredit Monitoraggio Siti"
                tabs={{
                    tabsList: SITES.map((S, i) => ({ name: S.label, id: S.key })),
                    index: currentTab,
                    setIndex: (x, y) => onChangeTab(y)
                }}
                spaceBelow={1}
            />

            <PagePaper height="73vh">
                {siteReports && (<DataGrid
                    slots={{ toolbar: GridToolbar }}
                    rows={siteReports}
                    columns={columns}></DataGrid>)}


            </PagePaper>
        </>
    )
}