import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@mui/material";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import GucciNightProcessReport from "../../../components/gucci/nightProcessReport";
import PagePaper from "../../../components/page/pagePaper";
import { useCabledQuery } from "../../../hooks/serviceRegistry/useCabledQuery";
import { dayMonthDateStr } from "../../../services/common/common";
import { SimpleStatus } from "../../../components/simpleStatus/simpleStatus";
import { QueryLinearProgress } from "../../../components/queries/queryLinearProgress";

const CONFIG = {
    bucketName: 'itpa-customer-gucci',
    queryRefetchInterval: 10 * 60 * 1000,
    reportsPrefix: 'public/reports/'
};

export default function GucciHome() {

    const [descriptors, setDescriptors] = useState([]);

    const navsQuery = useCabledQuery({
        svcName: 's3',
        method: 'listFiles',
        refetchInterval: 10 * 60 * 1000,
        args: {
            bucket: CONFIG.bucketName,
            prefix: CONFIG.reportsPrefix
        },
        enabled: true
    });
    useEffect(() => {
        if (navsQuery?.query?.data) {
            let d = navsQuery.query.data.filter(x => x.Key.endsWith("teams.json")).map(x => {
                let split = x.Key.split("/");
                let dt = split[2];
                let date = dayjs(dt).toDate()
                return { date, dt, key: x.Key, ...x };
            });
            d = d.sort((a, b) => (a.date >= b.date) ? -1 : 1);
            setDescriptors(d);
        }
    }, [navsQuery?.query?.data]);

    const statusMap = {
        true: true,
        'OK': true,
        false: false,
        'ALARM': false,
    };
    const alarmNames = {
        'gucci-state/health-gucci-rpa-alarm': "Bot Gucci"
    };
    const alarmsQuery = useCabledQuery({
        svcName: 'cw',
        method: 'getAlarmsState',
        refetchInterval: 2 * 60 * 1000,
        baseQueryKey: 'stlalarms',
        enabled: true,
        args: {
            alarmNames: Object.keys(alarmNames)
        }
    });

    const botIndicator = <SimpleStatus name={"Bot Gucci"} width="100%" height="50px" tooltip="Indica se il robottino che legge il sito sta funzionando o meno."
        top={< QueryLinearProgress query={alarmsQuery.query} queryKey={alarmsQuery.queryKey} />}
        status={statusMap[alarmsQuery?.query?.data?.find(x => x.name === "gucci-state/health-gucci-rpa-alarm")?.status]} />;

    return (
        <PagePaper title={botIndicator} transparent={true} >
            {descriptors && descriptors.length ? (
                descriptors.map((d, dI) => <Accordion key={dI} TransitionProps={{ unmountOnExit: true }}>
                    <AccordionSummary><Typography variant="h5">{dayMonthDateStr(d.date)}</Typography></AccordionSummary>
                    <AccordionDetails>
                        <GucciNightProcessReport s3_pointer={d}></GucciNightProcessReport>
                    </AccordionDetails>
                </Accordion>)
            ) : (<></>)}

        </PagePaper >
    )
}