import { Grid, Paper, Typography, styled } from "@mui/material";
const TodoItem = styled(Paper, {
    shouldForwardProp: p => p != "severity"
})(({ theme, severity }) => {
    return {
        backgroundColor: theme.palette.severities[parseInt(severity)] || '#fff',
        ...theme.typography.body2,
        paddingLeft: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        backdropFilter: 'blur(10px)',
        ':hover': {
            boxShadow: theme.shadows[5]
        },
        maxHeight: "30px",
        lineHeight: "30px",
    }
});












export function TaskMini({
    id, display_name, name, severity, iat, eat, customer, service, onClick = () => { }
}) {


    return (
        <>
            <TodoItem severity={severity} onClick={onClick}>
                <Grid container spacing={1}>

                    <Grid item xs={6}>
                        <Typography noWrap>
                            {customer}  {service}
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography noWrap>
                            {display_name}
                        </Typography>
                    </Grid>
                    
                </Grid>
            </TodoItem>
            
        </>
    );
}
