import React from 'react';
// import './style.scss';
import { Box, Button, Checkbox, CircularProgress, FormControl, FormControlLabel, FormGroup, FormHelperText, Grid, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import PropTypes from 'prop-types';
import { alertService } from '../../services/alerting/alerting';
import ItpaApi from '../../services/api';

const ActivityDetailsFeedback = (props) => {

    const [feedbacks, setFeedbacks] = React.useState({});
    const [feedbackValues,setFeedbackValues] = React.useState({});
    const [feedbackActivityLoading, setFeedbackActivityLoading] = React.useState(false);
    const [feedbackErrors, setFeedbackErrors] = React.useState({});
    function TabPanel(props) {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box sx={{ p: 3 }}>
                        <Typography>{children}</Typography>
                    </Box>
                )}
            </div>
        );
    }

    TabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.number.isRequired,
        value: PropTypes.number.isRequired,
    };


    const notiServ = ItpaApi.getService('notification');
    const queryClient = useQueryClient();


    const fetchFeedback = async () => {
        const data = await notiServ.getActivityFeedback.bind(notiServ)({ activityId: props.activity.activity_id });
        setFeedbacks(Object.assign(feedbacks, { [data.activity_id]: true }));
        return data.fields || [];
    }

    const feedbackQuery = useQuery({
        queryKey: ['activity', props.activity.activity_id, 'feedback'],
        queryFn: fetchFeedback,
        staleTime: 1000 * 60 * 10
    });

    const sendFeedback = async () => {
        const changeId = "FB" + (new Date().valueOf().toString());
        setFeedbackActivityLoading(true);
        let is_fulfilled = false;
        let attempts = 0
        let values = [];
        for (let [field_name,field_value] of Object.entries(feedbackValues)){
            values.push({field_name,field_value});
        }
        while (!is_fulfilled && attempts++<3) {
            await notiServ.requireActivityFeedbackChange({
                activityId: props.activity.activity_id,
                changeId,
                values: values
            }, {
                onSuccess: ({ fulfilled }) => {
                    is_fulfilled = fulfilled;
                    if (fulfilled) {
                        queryClient.invalidateQueries(['all_alerts']);
                        if (props.onClose) props.onClose();
                    }
                },
                onError: () => attempts >=3 && alertService.error("Impossibile inviare il feedback")
            });
        }
        setFeedbackActivityLoading(false);
        if (is_fulfilled) setTimeout(() => queryClient.invalidateQueries("all_alerts"));
    }
    const sendFeedbackMutation = useMutation({
        mutationKey: ['send_feedback'],
        mutationFn: sendFeedback
    })

    const applyFeedbackFieldValue = (fieldname,fieldvalue)=>{
        console.log(fieldname,fieldvalue);
        setFeedbackValues(Object.assign(feedbackValues,{[fieldname]:fieldvalue}));
    }
    const validateFeedback = ()=>{
        let valid = true;
        let fbe = {}
        for (let f of feedbackQuery.data){
            console.log(f);
            console.log(feedbackValues[f.fieldname]);
            if (f.required){ 
                if (!feedbackValues[f.fieldname] || feedbackValues[f.fieldname] === ""){
                    console.log("X")
                    fbe[f.fieldname] = 'Campo richiesto'
                    valid = false;
                }
                else fbe[f.fieldname] = '';
            }
            else fbe[f.fieldname] = '';
        }
        setFeedbackErrors(fbe);
        
        return valid;
    }
    return (
        <Box className="alertDetailsFeedback" sx={{ pt: 2 }}>

            <Grid container spacing={2} justifyItems="center" justifyContent="center">
                {feedbackQuery.isFetching || feedbackActivityLoading ? (<div><CircularProgress /></div>) : (
                    feedbackQuery.isError ? (<div>Errore: Non riesco a scaricare le definizioni del feedback.</div>) : (
                        
                        feedbackQuery?.data?.length ? (
                            <>
                            {
                                feedbackQuery?.data?.map(({ fieldname, fieldtype, label, required, options, placeholder }, i) => <Grid item xs={12} key={i} sx={{mb:2}}>
                                {
                                    (fieldtype === 'input') ? (
                                        <TextField 
                                            id={"feedback-"+fieldname} 
                                            fullWidth={true} 
                                            value={feedbackValues[fieldname]} 
                                            onChange={(e)=>applyFeedbackFieldValue(fieldname,e.target.value)} 
                                            label={label} 
                                            required={required} 
                                            placeholder={placeholder}
                                            error={!!feedbackErrors[fieldname]}
                                            helperText={feedbackErrors[fieldname]}
                                            ></TextField>
                                    ) : (
                                        fieldtype === 'select' ? (
                                            <FormControl fullWidth error={!!feedbackErrors[fieldname]}>
                                                <InputLabel>{label}</InputLabel>
                                                <Select
                                                    id={"feedback-"+fieldname}
                                                    defaultValue="" 
                                                    value={feedbackValues[fieldname]} 
                                                    onChange={(e)=>applyFeedbackFieldValue(fieldname,e.target.value)}
                                                    label={label}
                                                >
                                                    {
                                                        options.map((o,j)=><MenuItem key={j} value={o}>{o}</MenuItem>)
                                                    }
                                                    
                                                </Select>
                                                <FormHelperText>{feedbackErrors[fieldname]}</FormHelperText>
                                                </FormControl>
                                        ) : (
                                            fieldtype === 'checkbox' ? (
                                                <FormGroup>
                                                    <FormControlLabel  control={<Checkbox  id={"feedback-"+fieldname} onChange={(e)=>applyFeedbackFieldValue(fieldname,e.target.value)} />} label={label} required={required} />
                                                </FormGroup>
                                            ) : (
                                                <></>
                                            )
                                        )
                                    )

                                }
                                </Grid>
                                )
                            }
                            <Grid item xs={12}><Button color="primary" onClick={()=>validateFeedback() && sendFeedbackMutation.mutate()}>Invia</Button></Grid>
                            </>
                        ) : (
                            <p>Per questa attività non è previsto un feedback.</p>
                        )
                            
                                )
                            
                               
                    
                )
                }
            </Grid>
        </Box>
    )
}


export default ActivityDetailsFeedback;