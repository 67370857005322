import { Box, Table, TableBody, TableCell, TableHead, TableRow, styled } from "@mui/material";
import React from "react";
import './style.scss';




export function TabellaDollaro({data,bordered=false,fields=null}) {
    if (!data || !data.map){
        return <Box>DATI NON VALIDI: {JSON.stringify(data,null,2)}</Box>
    }
    let TC
    if (bordered){
        TC = styled(TableCell)`
        &.MuiTableCell-root {
            border: 1px solid #000;
          }
        `;
    } else TC = TableCell;


    return <Table size="small" className="borderTable">
        <TableHead>
            <TableRow>
                {(fields === null || fields.includes("uproc")) && <TC>Uproc</TC>}
                {(fields === null || fields.includes("status")) && <TC>Status</TC>}
                {(fields === null || fields.includes("startdate")) && <TC>Start Date</TC>}
                {(fields === null || fields.includes("enddate")) && <TC>End Date</TC>}
                {(fields === null || fields.includes("processingdate")) && <TC>Processing Date</TC>}
                {(fields === null || fields.includes("session")) && <TC>Session</TC>}
                {(fields === null || fields.includes("task")) && <TC>Task</TC>}
                {(fields === null || fields.includes("uproclabel")) && <TC>Uproc label</TC>}
                {(fields === null || fields.includes("node")) && <TC>Node</TC>}
                {(fields === null || fields.includes("launchno")) && <TC>Launch number</TC>}
                {(fields === null || fields.includes("sessionno")) && <TC>Session number</TC>}
                {(fields != null && fields.includes("ticket")) && <TC>Ticket</TC>}
            </TableRow>
        </TableHead>
        <TableBody>
            {data?.map((x, xI) => <TableRow key={xI}>
                {(fields === null || fields.includes("uproc")) && <TC>{x.uproc}</TC>}
                {(fields === null || fields.includes("status")) && <TC>{x.status}</TC>}
                {(fields === null || fields.includes("startdate")) && <TC>{x.startdate}</TC>}
                {(fields === null || fields.includes("enddate")) && <TC>{x.enddate}</TC>}
                {(fields === null || fields.includes("processingdate")) && <TC>{x.processingdate}</TC>}
                {(fields === null || fields.includes("session")) && <TC>{x.session}</TC>}
                {(fields === null || fields.includes("task")) && <TC>{x.task}</TC>}
                {(fields === null || fields.includes("uproclabel")) && <TC>{x.uproclabel}</TC>}
                {(fields === null || fields.includes("node")) && <TC>{x.node}</TC>}
                {(fields === null || fields.includes("launchno")) && <TC>{x.launchno}</TC>}
                {(fields === null || fields.includes("sessionno")) && <TC>{x.sessionno}</TC>}
                {(fields != null && fields.includes("ticket")) && <TC>&nbsp;</TC>}
            </TableRow>)}
        </TableBody>
    </Table>;

}
