import { Route } from "react-router-dom";
import { ServiceRegistryWrapper } from "../components/serviceRegistry/serviceRegistryWrapper";
import ChecksDashboard from "../pages/hub/checks";
import CarrefourReport from "../pages/salamacchine/carrefour/Report";
import GucciHome from "../pages/salamacchine/gucci/home";
import OperatorAlerts from "../pages/salamacchine/operatoralerts/operatoralerts";
import StellantisReport from "../pages/salamacchine/stellantis/report/Report";
import AuthorizedSection from "./authorizedSection";
import { TextB } from "./breadcrumbs";

const routes = [
    <Route path="salamacchine" element={<AuthorizedSection env="ItpaEnvSalaMacchine" />}>
        <Route index element={<OperatorAlerts />} handle={{ crumb: (data) => TextB(0, "Attività operatore") }} />
        
        <Route path="checks">
            <Route index element={<ChecksDashboard environment="SalaMacchine" />} handle={{ crumb: (data) => TextB(0, "Controlli") }} />
        </Route>
        <Route path="carrefour" element={<ServiceRegistryWrapper key="srw_carrefour" defaultRole="ItpaEnvSalaMacchine" requiredServices={[
            {service: 'carrefourstate'},
            {service: 'agents', label: 'agentsInSalaMacchine'},
            {service: 'lambda', label: 'client_pulsantone_crf'}
        ]} /> } >
            <Route index element={<CarrefourReport />} handle={{ crumb: (data) => TextB(0, "Monitoraggio carrefour") }} />
            <Route path=":what" element={<CarrefourReport />} handle={{ crumb: (data) => TextB(0, "Monitoraggio carrefour") }} />
        </Route>
        <Route path="stellantis">
            <Route index element={<StellantisReport />} handle={{ crumb: (data) => TextB(0, "Stellantis :: Stato Monitoraggio") }} />
            <Route path="splunk-monitoring" element={<StellantisReport />} handle={{ crumb: (data) => TextB(0, "Stellantis :: Stato Monitoraggio") }} />
        </Route>
        <Route path="gucci" element={<ServiceRegistryWrapper key="srw_gucci" defaultRole="ItpaEnvSalaMacchine_Role" requiredServices={[
            {service: 's3'},
            {service: 'lambda'},
            {service: 'cw'},
        ]}/>} >
            <Route index element={<GucciHome />} handle={{ crumb: (data) => TextB(1, "Gucci") }} />
        </Route>
    </Route>
];

export default routes;