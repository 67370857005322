import { Route } from "react-router-dom";
import { ServiceRegistryWrapper } from "../components/serviceRegistry/serviceRegistryWrapper";
import UnicreditHome from "../pages/unicredit/home";
import UnicreditLogins from "../pages/unicredit/logins";
import UnicreditReportDetails from "../pages/unicredit/reportDetails";
import AuthorizedSection from "./authorizedSection";
import { LinkB, TextB } from "./breadcrumbs";
import Pulsantone from "../pages/unicredit/pulsantone";
import UnicreditAgents from "../pages/unicredit/agents";




const routes = [
    <Route path="unicredit" element={<AuthorizedSection env="ItpaEnvUnicredit" />}>
        <Route element={<ServiceRegistryWrapper key="srw_unicredit" defaultRole={"ItpaEnvUnicredit"} 
            requiredServices={[
                {service: 'ddb'},
                {service: 's3'},
                {service: 'cw'},
                {service: 'lambda', label: 'client_pulsantone'},
                {service: 'agents', label: 'agentsInUnicredit'}
            ]}
        />}>
            <Route index element={<UnicreditHome />} handle={{ crumb: (data) => TextB(0, "Unicredit siti: controlli effettuati") }} />
            <Route path="siti">
                <Route index element={<UnicreditHome />} handle={{ crumb: (data) => TextB(0, "Unicredit siti: controlli effettuati") }} />
                <Route path=":reportName" element={<UnicreditReportDetails />} handle={{ crumb: (data) => [LinkB(0, 'Unicredit siti: controlli effettuati', '/unicredit/siti'), TextB(0, "Dettagli report")] }} />
            </Route>
            <Route path="logins">
                <Route index element={<UnicreditLogins />} handle={{ crumb: (data) => TextB(0, "Unicredit siti: login effettuati") }} />
            </Route>
            <Route path="pulsantone">
                <Route index element={<Pulsantone />} handle={{ crumb: (data) => TextB(0, "Unicredit siti: stop robottino") }} />
            </Route>
            <Route path="agents">
                <Route index element={<UnicreditAgents />} handle={{ crumb: (data)=> TextB(0, "Unicredit Agenti RPA")}} />
            </Route>
        </Route>
    </Route>
];

export default routes;