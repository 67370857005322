import { Drawer, Grid } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import React, { useContext, useState } from "react";
import AdminEnvironmentSelector from "../../../components/admin/environmentSelector";
import AdminCreateIncidentType from "../../../components/incidents/AdminCreateIncidentType";
import AdminEditIncidentType from "../../../components/incidents/AdminEditIncidentType";
import AdminRemoveIncidentType from "../../../components/incidents/AdminRemoveIncidentType";
import IncidentTypeWidget from "../../../components/incidents/IncidentTypeWidget";
import Deck from "../../../components/queries/deck";
import { AppContext } from "../../../hooks/appContext";
import { useCabledQuery } from "../../../hooks/useCabledQuery";




const AdminIncidentTypes = () => {

    const [newProblemType, setNewProblemType] = useState({ problemType: "", environment: "---" });
    const queryClient = useQueryClient();
    const { currentEnvironment } = useContext(AppContext);
    const [search, setSearch] = useState([]);
    const [selected, setSelected] = useState(null);
    const [sideContent, setSideContent] = useState(null);
    const { query: problemTypesQuery, queryKey: problemTypesQueryKey } = useCabledQuery({
        apiService: 'monitoring',
        method: 'listAllProblemTypes',
        baseQueryKey: 'problemTypes',
        filters: { environment: currentEnvironment },
        refetchInterval: 60 * 1000
    });

    const clearSideContent = () => { setSideContent(null); }
    const toggleSelected = (problemType) => {
        if (selected === problemType) { setSelected(null); }
        else { setSelected(problemType); }

    }


    return (
        <>
            <Deck
                queryKey={problemTypesQueryKey}
                hasOmniBox={true}
                searchHandler={{ search, setSearch, categories: [] }}
                hasRefresh={true}
                onRefresh={() => console.log("REFRESHED!")}
                title={<AdminEnvironmentSelector />}
                hasNewItem={true}
                onNewItem={() => setSideContent("create")}
                hasDeleteItem={selected !== null}
                onDeleteItem={() => setSideContent("delete")}
                hasOptions={selected !== null}
                onOptions={() => setSideContent("edit")}
            />
            <Grid container spacing={2} sx={{ mt: 1 }}>
                {problemTypesQuery.data?.map((pT, pTI) => <Grid item xs={12} sm={6} md={4} justifyContent="center" key={pT.problem_type}>
                    <IncidentTypeWidget
                        problemType={pT.problem_type}
                        environment={pT.environment}
                        highlighted={selected === pT.problem_type}
                        onClick={() => toggleSelected(pT.problem_type)} />

                </Grid>)}
            </Grid>
            <Drawer
                anchor="right"
                open={sideContent !== null}
                onClose={clearSideContent}>

                {sideContent === "create" && (
                
                    <AdminCreateIncidentType 
                        onCancel={() => clearSideContent()} 
                        onSuccess={() => { queryClient.invalidateQueries(problemTypesQueryKey); clearSideContent() }} 
                        />
                )}
                {sideContent === "edit" && (
                    <AdminEditIncidentType
                    problemType={selected} 
                    onCancel={() => clearSideContent()} 
                    onSuccess={() => { queryClient.invalidateQueries(problemTypesQueryKey); clearSideContent() }} 
                    />
                )}
                {sideContent === "delete" && (
                    <AdminRemoveIncidentType 
                    problemType={selected} 
                    onCancel={() => clearSideContent()} 
                    onSuccess={() => { queryClient.invalidateQueries(problemTypesQueryKey); clearSideContent() }} />
                )}

            </Drawer>

        </>
    );



};

export default AdminIncidentTypes;