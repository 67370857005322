import { Box, Card, CardContent, Stack, Typography } from "@mui/material";
import { elapsed } from "../../services/common/common";
// import './problemCard.scss';
import SeverityWidgetContainer from "../containers/severityWidgetContainer";


const IncidentWidgetMini = ({
    status,
    problemId,
    problemType,
    severity,
    startDate,
    endDate,
    onClick = () => { },
    onDoubleClick = () => { }
}) => {

    return (
        <SeverityWidgetContainer severity={severity} lightness={status === "CLOSED" ? 'light' : 'normal'} sx={{minHeight: 'fit-content'}} onClick={onClick} onDoubleClick={onDoubleClick}>
            <Stack direction="row" justifyContent="space-between" sx={{ minHeight: "100%",p:1 }}>
                <Typography sx={{fontSize: "14px", fontWeight: 'bold', lineHeight: '16px'}}>{problemType.toUpperCase()}</Typography>
                <Box sx={{ flexGrow: 1 }}></Box>
                <Typography sx={{fontSize: "10px", lineHeight: '16px'}}>{elapsed(startDate)}</Typography>
            </Stack>
        </SeverityWidgetContainer>
    );
};


export default IncidentWidgetMini;