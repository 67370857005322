import { Box, Button, Card, CardContent, Modal, Table, TableBody, TableCell, TableContainer, TableRow, Typography, styled } from "@mui/material";
import { elapsedSince, fullDateStr, safeParseJson } from "../../services/common/common";
import { useState } from "react";


const Title = ({ text }) => <Typography sx={{ fontWeight: 'bold' }}>{text}</Typography>
const Value = ({ text }) => <Typography sx={{ fontStyle: 'italic' }}>{text}</Typography>
const MyLink = ({ text, target }) => <Typography sx={{ fontStyle: 'italic' }}>
    <a href={target} target="_blank">{text}</a>
</Typography>


const RenderedDataBox = styled(Box)(({ theme }) => ({
    maxWidth: "400px",
    maxHeight: "200px",
    overflowY: 'scroll'
}));


function RenderedData({ data, onClick }) {

    return <RenderedDataBox onClick={onClick}>
        <pre>
            {JSON.stringify(data)}
        </pre>
    </RenderedDataBox>

}

const ExecutionDetails = ({ executionData, onStopExecution }) => {

    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [modalContent, setModalContent] = useState("");

    const handleModalClose = () => { setModalContent(""); setModalIsOpen(false); }
    const handleModalOpen = ({ content }) => { setModalContent(content); setModalIsOpen(true); }

    const inputData = !!executionData.input ? safeParseJson(executionData.input) : null;
    const outputData = !!executionData.output ? safeParseJson(executionData.output) : null;

    return <>

        <Modal open={modalIsOpen} onClose={handleModalClose}>
            <Box sx={{width: '70vw', height: '70vh', position: 'absolute', top: '15vh',left:'15vw', bgcolor: 'background.paper', overflowY:'scroll'}}>
                <pre>{
                    JSON.stringify(modalContent, null, 2)
                }</pre>
            </Box>

        </Modal>
        <TableContainer>
            <Table size="small">
                <TableBody>
                    <TableRow>
                        <TableCell>
                            <Title text="Flusso" />
                        </TableCell>
                        <TableCell>
                            <Value text={executionData.flow_name} />
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <Title text="Nome" />
                        </TableCell>
                        <TableCell>
                            <Value text={executionData.name} />
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <Title text="Stato" />
                        </TableCell>
                        <TableCell>
                            <Value text={executionData.status} />
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <Title text="Start" />
                        </TableCell>
                        <TableCell>
                            <Value text={executionData.start_date ? fullDateStr(executionData.start_date) : ""} />
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <Title text="End" />
                        </TableCell>
                        <TableCell>
                            <Value text={executionData.end_date ? (fullDateStr(executionData.end_date) + " ("+elapsedSince(executionData.end_date,executionData.start_date)+")") : ""} />
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <Title text="Esecuzione" />
                        </TableCell>
                        <TableCell>
                            <MyLink
                                text="Vai all'esecuzione"
                                target={`https://eu-west-1.console.aws.amazon.com/states/home?region=eu-west-1#/v2/executions/details/${executionData.name}`}
                            />
                        </TableCell>
                    </TableRow>
                    {!!inputData && (
                        <TableRow>
                            <TableCell>
                                <Title text="Input" />
                            </TableCell>
                            <TableCell>
                                <RenderedData data={inputData} onClick={() => handleModalOpen({content:inputData})} />
                            </TableCell>
                        </TableRow>
                    )}
                    {!!outputData && (
                        <TableRow>
                            <TableCell>
                                <Title text="Output" />
                            </TableCell>
                            <TableCell>
                                <RenderedData data={outputData} onClick={() => handleModalOpen({content:outputData})} />
                            </TableCell>
                        </TableRow>
                    )}
                    {executionData.status === "RUNNING" && (
                        <TableRow>
                            <TableCell colSpan={2}>
                                <Button variant="outlined" onClick={() => onStopExecution()}>FERMA ESECUZIONE</Button>
                            </TableCell>
                        </TableRow>

                    )}

                </TableBody>
            </Table>
        </TableContainer></>

};

export default ExecutionDetails;