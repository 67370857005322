import ArrowBack from "@mui/icons-material/ArrowBack";
import { Box, Button, ButtonGroup, Card, CardContent, Grid, IconButton, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from "@mui/material";
import React from "react";
import { dayMonthDateStr, expected, hourMinuteDateStr } from "../../services/common/common";
import { LapideBottom, LapideTop, stepSemaphore } from "./lapide";
import './style.scss';
import dayjs from 'dayjs';




export default function DatiListini({
    data
}) {
    console.log(data);
    let dd = null;
    let d = data.find(x => x.data_name === "listini_check_data");
    console.log(d);
    if (d) {
        dd = JSON.parse(d.data_content);
    }
    console.log(dd);

    return (
        <>
            {(dd === null || dd.dep_data === null) ? ("La procedura non ha raccolto dati.") : (
                <>
                <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>Deposito</TableCell>
                        <TableCell>Inizio</TableCell>
                        <TableCell>Fine</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {dd?.dep_data?.map((x, xI) => <TableRow key={xI}>
                        <TableCell>{x.deposito}</TableCell>
                        <TableCell>{x.inizio}</TableCell>
                        <TableCell>{x.fine}</TableCell>
                    </TableRow>)}
                </TableBody>
            </Table>
            </>
            )}

        </>
    )

}
