import { Button, FormControl, FormHelperText, Stack, TextField, TextareaAutosize, Typography } from "@mui/material";
import PropTypes from 'prop-types';
import { useState } from "react";
import { useStepFunctions } from "../../hooks/stepfunctions";


export default function FlowExecutionStartForm({
    onOk,
    onCancel
}) {


    const [newExecution, setNewExecution] = useState({});
    const [errors, setErrors] = useState({});
    const [valid, setValid] = useState(false);
    const stepFunctionsQuery = useStepFunctions({ enabled: newExecution.executor === 'stepfunctions' });

    const validateForm = (valueToValidate) => {
        if (!valueToValidate) {
            valueToValidate = newExecution
        }
        console.log("validate", valueToValidate)
        let err = errors;
        let out = true;
        if (!valueToValidate.name) {
            err = { ...err, name: null };
            out = false;
        } else if (valueToValidate.name.length < 5) {
            err = { ...err, name: "Nome non valido, minimo 5 caratteri" };
            out = false;
        }
        else err = { ...err, name: null };
        if (!valueToValidate.executor) {
            out = false;
        }

        if (valueToValidate.inputData) {
            try {
                JSON.parse(valueToValidate.inputData);
                err = { ...err, inputData: null };
            } catch {
                err = { ...err, inputData: "Payload non valido" };
                out = false;
            }
        }
        else err = { ...err, inputData: null };
        setErrors(err)
        return out;
    }
    const changeNewExecution = (field, newValue) => {
        setNewExecution({ ...newExecution, [field]: newValue });
        setValid(validateForm({ ...newExecution, [field]: newValue }));
    }

    return (
        <Stack spacing={5} justifyContent="space-between" sx={{ minWidth: '40vh', minHeight: '50%', p: 2, pt: 10 }}>
            <Typography variant="h5" sx={{ mb: 3 }}>Nuova esecuzione</Typography>
            <TextField
                fullWidth={true}
                label="Nome esecuzione"
                value={newExecution.name}
                error={!!errors.name}
                helperText={errors.name}
                onChange={(e) => { e.preventDefault(); changeNewExecution("name",e.target.value); }}
            ></TextField>
            <FormControl error={!!errors.inputData}>
                <TextareaAutosize
                    placeholder="{}"
                    label="Input data"
                    minRows={8}
                    value={newExecution.inputData}
                    onChange={(e) => { e.preventDefault(); changeNewExecution('inputData',e.target.value); }}
                />
                <FormHelperText>{errors.inputData ? errors.inputData : ""}</FormHelperText>

            </FormControl>
            <Stack direction="row" justifyContent="space-evenly">
                <Button
                    color="primary"
                    onClick={() => onOk(newExecution)}
                >
                    VIA
                </Button>
                <Button
                    color="secondary"
                    onClick={onCancel}
                >
                    Annulla
                </Button>
            </Stack>
        </Stack>
    )
}
FlowExecutionStartForm.propTypes = {
    onOk: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
};