import React from 'react';
// import './style.scss';
import { Box, Button, CircularProgress, Grid } from '@mui/material';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { alertService } from '../../services/alerting/alerting';
import ItpaApi from '../../services/api';

const ActivityDetailsCommands = (props) => {

    const [commandss, setCommandss] = React.useState({});
    const [commandActivityLoading,setCommandActivityLoading] = React.useState(false);
    const notiServ = ItpaApi.getService('notification');
    const queryClient = useQueryClient();

    const fetchCommands = async () => {
        const data = await notiServ.getActivityCommands.bind(notiServ)({ activityId: props.activity.activity_id });
        setCommandss(Object.assign(commandss, { [data.activity_id]: true }));
        return data.specs || [];
    }
    const commandQuery = useQuery({
        queryKey: ['activity', props.activity.activity_id, 'commands'],
        queryFn: fetchCommands,
        staleTime: 1000 * 60 * 10
    });

    const sendCommand = async ({ commandId }) => {
        const changeId = "CO" + (new Date().valueOf().toString());
        setCommandActivityLoading(true);
        let is_fulfilled = false;
        let attempts = 0
        while (!is_fulfilled && attempts++<3) {
            const res = await notiServ.requireActivityCommandChange({
                activityId: props.activity.activity_id,
                changeId,
                commandId
            }, {
                onSuccess: ({ fulfilled }) => {
                    is_fulfilled = fulfilled;
                    if (fulfilled) {
                        queryClient.invalidateQueries(['all_alerts']);
                        if (props.onClose) props.onClose();
                    }
                },
                onError: () => attempts >=3 && alertService.error("Impossibile eseguire il comando")
            });
        }
        setCommandActivityLoading(false);
        if (is_fulfilled) setTimeout(() => queryClient.invalidateQueries("all_alerts"));
    }
    const sendCommandMutation = useMutation({
        mutationKey: ['send_command'],
        mutationFn: sendCommand
    })


    return (
        <Box className="alertDetailsCommands" sx={{ pt: 2 }}>

            <Grid container spacing={2} justifyItems="center" justifyContent="center">

                {commandQuery.isFetching || commandActivityLoading ? (<div><CircularProgress /></div>) : (
                    commandQuery.isError ? (<div>Errore: Non riesco a scaricare le definizioni dei comandi.</div>) : (
                        <>
                            {
                                
                                commandQuery?.data?.length ? (
                                    commandQuery.data.map(({ color, text, command_id }, i) => <Grid xs={10} sm={5} key={i} item>
                                        <Box>
                                            <Button onClick={() => sendCommandMutation.mutate({ commandId: command_id })} size="large" variant="outlined" color={color.toLowerCase()}>{text}
                                            </Button>
                                        </Box>
                                    </Grid>
                                    )
                                ) : (
                                    <p>Per questa attività non sono previsti comandi.</p>
                                )
                                
                            }
                        </>
                    )
                )
                }


            </Grid>
        </Box>
    );
}


export default ActivityDetailsCommands;