import React from 'react';
// import './sidebar.scss';

import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import { MyLoc, MyLocation } from '../../hooks/loc';
import MyAppBar from './AppBar';
import { DesktopDrawer, MobileDrawer } from './Drawer';

const drawerWidth = 240;










function Sidebar(props, { defaultActive }) {
    
   
    
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };
    
    

    

    return (
        <MyLoc.Provider value={new MyLocation()}>
            <React.Fragment>
                <CssBaseline />
                <MyAppBar onToggleDrawer={handleDrawerToggle}/>
                <Box>
                    
                    
                </Box>
                <div style={{ color: 'black', position: 'absolute', left: '5px', bottom: '10px', zIndex: 10000 }}>{(
                    process.env['REACT_APP_NAME'] && process.env['REACT_APP_VERSION']
                ) ? (
                    <span>{process.env['REACT_APP_NAME']}:{process.env['REACT_APP_VERSION']}</span>
                ) : (<></>)
                }</div>
                <Box
                    component="nav"
                    

                    sx={{ 
                        width: { lg: drawerWidth }, 
                        flexShrink: { lg: 0 },
                        backgroundColor: 'background.main'
                    }}
                >
                    <DesktopDrawer />
                    <MobileDrawer onToggleDrawer={handleDrawerToggle} open={mobileOpen} />

                </Box>
            </React.Fragment>
        </MyLoc.Provider>
    );


}

export default Sidebar
