import Timeline from '@mui/lab/Timeline';
import { Button, Stack, Table, TableBody, TableCell, TableContainer, TableRow, TextareaAutosize, Typography } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import PageMainStack from '../../../components/page/pageMainStack';
import PagePaper from '../../../components/page/pagePaper';
import { ProblemTimelineItem } from '../../../components/incidents/problemTimeLine';
import { useProblemExecutions } from '../../../hooks/executions';
import { useProblem } from '../../../hooks/problems';
import { alertService } from '../../../services/alerting/alerting';

import PageGrid from '../../../components/page/pageGrid';
import ItpaApi from '../../../services/api';
import { elapsed } from '../../../services/common/common';
import { AppContext } from '../../../hooks/appContext';
import AdminEnvironmentSelector from '../../../components/admin/environmentSelector';

const AdminIncidentDetails = () => {
    const params = useParams();

    const problemId = params.problem_id;

    const {currentEnvironment} = useContext(AppContext);

    const navigate = useNavigate();
    if (!problemId ) {
        navigate('..');
    }

    const moniServ = ItpaApi.getService("monitoring");
    const [closeProblemConfirmation, setCloseProblemConfirmation] = React.useState(0);
    const [closeProblemSolution, setCloseProblemSolution] = React.useState("");
    const askForCloseProblemConfirmation = () => setCloseProblemConfirmation(1);
    const cancelCloseProblemConfirmation = () => setCloseProblemConfirmation(0);
    const askForCloseProblemConfirmation2 = () => setCloseProblemConfirmation(2);
    const cancelCloseProblemConfirmation2 = () => setCloseProblemConfirmation(0);

    const queryClient = useQueryClient();

    const closeProblem = async () => {
        try {
            await moniServ.closeProblem.bind(moniServ)({ problemId, solution: closeProblemSolution, environment: currentEnvironment });
            alertService.success("Problema chiuso");
            setCloseProblemConfirmation(0);
            queryClient.invalidateQueries(problemQuery.key);
            
        } catch (e) {
            alertService.error("Impossibile chiudere il problema");
            setCloseProblemConfirmation(0);
        }
    }

    const closeProblemMutation = useMutation({
        mutationFn: closeProblem
    })

  
    const EVENTLABELS = {
        'ExecStart': 'Richiesta esecuzione'
    };
    const timelineItems = [];
    const severities = ['', 'warning', 'minor', 'major', 'critical']
    
    const {problemQuery,refetchInterval:problemRefetchInterval} = useProblem({ problemId })
    const executionsQuery = useProblemExecutions({ problemId, enabled: !!problemQuery.data });
    var detailsBlock = <></>;
    var solutionsBlock = <></>;
    if (problemQuery.isFetching) {
        detailsBlock = <div>Loading problem...</div>
    }
    else if (executionsQuery.isFetching) {
        solutionsBlock = <div>Loading execution...</div>
    }
    else if (problemQuery.isError) {
        detailsBlock = <div>Error: {problemQuery.error.message}</div>
    }
    else if (executionsQuery.isError) {
        solutionsBlock = <div>Error: {executionsQuery.error.message}</div>
    }
    else if (problemQuery.data && executionsQuery.data) {
        for (let ev of problemQuery.data.events || []) {
            timelineItems.push({
                dt: moment(new Date(ev.dt)),
                evtType: ev.evt_type,
                details: ev.evt_details
            });
        }
        for (let ev of executionsQuery.data) {
            timelineItems.push({
                dt: moment(new Date(ev.start_date)),
                evtType: 'ExecRunning',
                details: { status: ev.status }
            });
        }
        console.log(timelineItems);
        solutionsBlock = <Timeline>
            {
                timelineItems ? timelineItems.map((ev, i) => (<ProblemTimelineItem
                    key={'ev' + i}
                    ev={ev}
                ></ProblemTimelineItem>
                )) : <p>Dati esecuzione non disponibili</p>
            }
        </Timeline>;
        detailsBlock = <TableContainer>
            <Table>
                <TableBody>
                    <TableRow>
                        <TableCell>Env</TableCell>
                        <TableCell>{problemQuery.data.environment}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Type</TableCell>
                        <TableCell>{problemQuery.data.problem_type}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>ID</TableCell>
                        <TableCell>{problemQuery.data.problem_id}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Opened</TableCell>
                        <TableCell>{elapsed(problemQuery.data.start_date)}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Closed</TableCell>
                        <TableCell>{problemQuery.data.end_date ? elapsed(problemQuery.data.end_date) : 'Non risolto'}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Impacts:</TableCell>
                        <TableCell>{
                            problemQuery.data.impacted_components.concat(problemQuery.data.related_components).map((c, cI) => <p key={cI}>{c}</p>)
                        }</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Severity:</TableCell>
                        <TableCell>{severities[problemQuery.data.severity]}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>State:</TableCell>
                        <TableCell>{problemQuery.data.status}</TableCell>
                    </TableRow>
                    { problemQuery.data?.status === "CLOSED" && <>
                        <TableRow>
                            <TableCell>Closed by:</TableCell>
                            <TableCell>{problemQuery.data.closed_by}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Solution:</TableCell>
                            <TableCell>{problemQuery.data.solution}</TableCell>
                        </TableRow>
                        </> }
                </TableBody>
            </Table>
        </TableContainer>;
    }


    


    return (
        <PageMainStack height="98vh">
            <AdminEnvironmentSelector />
            <PageGrid targetColW={{xl:6, lg:6}}>
                <PagePaper title="Facts" height={problemQuery.data?.status === "OPEN" ? '64vh' : '82vh'} minWidth="40vh">
                    {detailsBlock}
                </PagePaper >
                <PagePaper title="Solution flows" height={problemQuery.data?.status === "OPEN" ? '64vh' : '82vh'} minWidth="40vh">

                    {solutionsBlock}
                </PagePaper >
            </PageGrid>
            { problemQuery.data?.status === "OPEN" && (<PagePaper subtitle="Chiudi problema" height="16vh">
                {
                    closeProblemConfirmation === 2 ? (
                        <>
                            <Typography>Solution</Typography>
                            <div><TextareaAutosize
                                value={closeProblemSolution}
                                onChange={(e) => setCloseProblemSolution(e.target.value)}
                                style={{ width: '80%' }}
                                minRows={4}
                            ></TextareaAutosize></div>
                            <Stack direction="row" justifyContent="space-evenly">
                                <Button color="primary" onClick={() => closeProblemMutation.mutate()}> Close </Button>
                                <Button color="secondary" onClick={() => cancelCloseProblemConfirmation()}>
                                    No, I changed my mind.
                                </Button>
                            </Stack>
                        </>
                    ) : (
                        (closeProblemConfirmation === 1) ? (
                            <>
                                <Typography>Confermi?</Typography>
                                {/* <Button color="primary" onClick={() => closeProblemMutation.mutate()}> */}
                                <Stack direction="row" justifyContent="space-evenly">
                                    <Button color="primary" onClick={() => askForCloseProblemConfirmation2()}>
                                        Yes, close incident!
                                    </Button>
                                    <Button color="secondary" onClick={() => cancelCloseProblemConfirmation()}>
                                        No, I changed my mind.
                                    </Button>
                                </Stack>
                            </>
                        ) : (
                            <Button onClick={() => askForCloseProblemConfirmation()}>
                                Close incident
                            </Button>

                        )
                    )
                }
            </PagePaper>)}
        </PageMainStack>
    )
}



export default AdminIncidentDetails