import { Grid, Typography } from "@mui/material";



import React from 'react';
import Widget from "../../components/widget/Widget";





import './systemStatusDashboard.scss';





export default function SystemStatusDashboard() {




    return <React.Fragment>
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Typography className="subtitle" variant="h6">Platform</Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
                <Widget type="alerts" title="DB Cluster" status={false} statusText="Problemi"></Widget>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
                <Widget type="alerts" title="Core services" status={true}></Widget>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
                <Widget type="alerts" title="RPA" status={false}></Widget>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
                <Widget type="alerts" title="Scheduler" status={false}></Widget>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
                <Widget type="alerts" title="Monitoring engine" status={true}></Widget>
            </Grid>

            <Grid item xs={12}>
                <Typography className="subtitle" variant="h6">Customer data</Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
                <Widget type="alerts" title="Carrefour" status={true}></Widget>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
                <Widget type="alerts" title="Stellantis" status={null}></Widget>
            </Grid>
        </Grid>
    </React.Fragment>

}