import { Grid, Paper, Typography } from '@mui/material';
import React from 'react';
import { MyLoc } from '../../hooks/loc';
import CWAlarmWidget from '../../components/cwalarms/cwAlarmWidget';
import { useNavigate } from 'react-router-dom';




function AdminHome() {

    const mongoAlarms = [
        {icon: 'CPU', alarmName: 'MongoCpuAlarm'},
        {icon: 'MEM', alarmName: 'MongoMemAlarm'},
        {icon: 'DSK', alarmName: 'MongoDiskAlarm'}
    ];

    const navigate = useNavigate();

    return <>



        <h1>ITPA AMMINISTRAZIONE</h1>

        <Grid container spacing={2} sx={{mt:5}}>
            <Grid item xs={12}>
                <h3>Stato del sistema</h3>
            </Grid>
            <Grid item xs={12}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={4}>
                        <CWAlarmWidget
                            height="20px"
                            name="Mongo Cluster"
                            alarms={mongoAlarms}
                            baseQueryKey="MongoClusterAlarms"
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <h3>Sezioni amministrazione</h3>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
                <Paper>
                    <Typography variant="h6" onClick={()=>navigate('/admin/envs')}>Ambienti</Typography>
                </Paper>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
                <Paper>
                    <Typography variant="h6">Clienti e servizi</Typography>
                </Paper>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
                <Paper>
                    <Typography variant="h6">Risorse</Typography>
                </Paper>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
                <Paper>
                    <Typography variant="h6">Componenti</Typography>
                </Paper>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
                <Paper>
                    <Typography variant="h6">Problemi</Typography>
                </Paper>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
                <Paper>
                    <Typography variant="h6">Flussi</Typography>
                </Paper>
            </Grid>
        </Grid>
    </>

}



export default AdminHome