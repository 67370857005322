import { Autocomplete, TextField, Typography } from "@mui/material";



export default function ClientServiceFilter({

    customerServiceOptions = [],
    onChange = ()=>{},
    selection = [],
    multiple = true,
    labelForNull = "ALL"
 }) {

    let cso = [{customer:null,service:null},...customerServiceOptions];

    return <Autocomplete
        multiple={multiple}
        size="small"
        options={cso}
        groupBy={(option) => option.customer}
        onChange={(e, v) => {
            onChange(v);
        }}
        value={selection}
        getOptionLabel={(option) => `${option.customer || labelForNull} - ${option.service || labelForNull}`}
        renderOption={(props, data) => (
            <Typography key={data.id} {...props} variant="body1">
                {data.customer || labelForNull} - {data.service || labelForNull}
            </Typography>
        )}
        style={{ width: 500 }}
        renderInput={(params) => (
            <TextField
                {...params}
                variant="outlined"
                label="Customer - Service"
                placeholder="Customer - Service"
            />
        )}
    />;


}