import EastOutlinedIcon from '@mui/icons-material/EastOutlined';
import { Box, IconButton, Stack, TextField } from '@mui/material';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import moment from 'moment';
import React from 'react';
import { alertService } from '../../services/alerting/alerting';
import ItpaApi from '../../services/api';
import Notes from '../notes/note';

const ActivityDetailsNotes = (props) => {

    const [notess, setNotess] = React.useState([]);
    const [newNoteText, setNewNoteText] = React.useState("");
    const [creatingNotes, setCreatingNotes] = React.useState([]);
   
 
    const notiServ = ItpaApi.getService('notification');
    const queryClient = useQueryClient();

    const fetchNotes = async () => {
        const data = await notiServ.listActivityNotes.bind(notiServ)({ activityId: props.activity.activity_id });
        setNotess(Object.assign(notess, { [data.activity_id]: true }));
        return data.map(x => Object.assign(x, { created_at: moment(new Date(x.created_at)) })) || [];
    }
    const notesQuery = useQuery({
        queryKey: ['activity', props.activity.activity_id, 'notes'],
        queryFn: fetchNotes,
        staleTime: 1000 * 60 * 10
        //enabled: !descriptions[props.activity.activity_id]
    });

    const createNote = async () => {
        const changeId = "NT"+(new Date().valueOf().toString());
        setCreatingNotes([...creatingNotes,{
            content: newNoteText,
            changeId
        }]);
        let is_fulfilled = false;
        while (!is_fulfilled){
            const res = await notiServ.requireActivityNoteChange({
                activityId: props.activity.activity_id,
                changeId,
                content:newNoteText
            },{
                onSuccess: ({changeId,fulfilled})=>{
                    is_fulfilled = fulfilled;
                    if (fulfilled){
                        setCreatingNotes(creatingNotes.filter(x=>x.changeId != changeId))
                        setNewNoteText("");
                        alertService.success("Nota creata")
                        queryClient.invalidateQueries(['activity', props.activity.activity_id, 'notes'])
                    }
                },
                onError: ()=>alertService.error("Impossibile creare la nota")
            });
        }
        
        
    }
    const createNoteMutation = useMutation({
        mutationKey: ['create_note'],
        mutationFn: createNote
    });
    


    return (
            <Box className="alertDetailsNotes" sx={{ pt: 2, height: parseInt(props.viewHeight * 0.7) }} style={{ overflowY: 'scroll' }}>
                <Stack direction="row" justifyContent="space-evenly">
                    <TextField
                        fullWidth={true}
                        value={newNoteText}
                        onChange={e => { e.preventDefault(); setNewNoteText(e.target.value) }}
                        id="new-note"
                        variant="outlined"
                        label="Nuova nota"
                    ></TextField>
                    
                    <IconButton onClick={() => createNoteMutation.mutate()}>
                        <EastOutlinedIcon></EastOutlinedIcon>
                    </IconButton>
                </Stack>
                
                {notesQuery.isFetching ? (<p>Loading...</p>) : (
                    notesQuery.isError ? (<p>Error: {notesQuery.error.message}</p>) : (
                        <Notes
                            creatingNotes={creatingNotes}
                            notesData={notesQuery.data.sort((a, b) => a.created_at < b.created_at ? 1 : -1)}
                        ></Notes>
                    )
                )
                }

            </Box>
    );
}


export default ActivityDetailsNotes;