// descrive quali check (cloudwatch alarms) sono disponibili per ambiente
// in futuro può essere sostituito da un metodo da agganciare (ad esempio) al servizio Envs


const ChecksDefinitions = {
    Sys: [],
    Bper: [],
    SalaMacchine: [
        {name: "Riordino Mercati", alarms:[
            {alarmName: "carrefour-state/supporto-rm-easy"},
            {alarmName: "carrefour-state/supporto-rm-dollaro"}
        ]},
        {name: "Bot Gucci", alarms:[
            {alarmName: "gucci-state/health-gucci-rpa-alarm"}
        ]}
    ],
    Unicredit: [
        {name: "Bot siti", alarms: [
            {alarmName: "unicredit-state/PubblicoReads"},
            {alarmName: "unicredit-state/PrivatiReads"},
            {alarmName: "unicredit-state/CorporateReads"},
            {alarmName: "unicredit-state/AustriaReads"},
            {alarmName: "unicredit-state/UnicoReads"}
    ]}
    ],
    Stellantis: [
        {name: "Bot splunk", alarms: [
            {alarmName: "stellantis-state/GsdpHealth-Reads"}
        ]},
        {name: "Bot tickets", alarms:[
            {alarmName: "stellantis-servicenow-state/NewTicketsHealth-Reads"}
        ]}
    ]
};

export default ChecksDefinitions;