import { Box, Stack, Typography } from '@mui/material';
import React, { useState } from 'react';

import OpinionatedForm from '../forms/opinionatedForm';
import useCabledMutation from '../../hooks/serviceRegistry/useCabledMutation';


const AdminEnvCreate = ({
    onCancel = () => { },
    onSuccess = () => { },
    onError = () => { }
}) => {




    const [formValues, setFormValues] = useState({});
    const createEnvMutation = useCabledMutation({
        svcName: 'environments',
        method: 'doPost',
        onSuccess,
        onFailure: onError
    })

    const fields = [
        { name: "name", label: "Name", type: "text", required: true, validator: v => { if (!v || v.trim().length < 5) return 'Required, min length 5'; return null; } }
    ];
    const buttons = [
        { name: "Cancel", onClick: () => onCancel(), color: "error" },
        { name: "Create", onClick: () => { createEnvMutation.mutate({ path: '/envs', body:{name: formValues.name} }) }, color: "success", disabled: (formIsValid) => !formIsValid }
    ];

    return (

        <Stack spacing={2} sx={{ minWidth: "25vw" }} alignItems="center">
            <Box sx={{ height: "10vh" }} />
            <Typography variant="h6" component="div" gutterBottom>New environment</Typography>
            <OpinionatedForm fields={fields} buttons={buttons} formValues={formValues} setFormValues={setFormValues} displayJson={true} validateOnChange={true} />
        </Stack>



    )
}



export default AdminEnvCreate