import { Box, CircularProgress, IconButton, Stack, Typography } from "@mui/material";
import LoopOutlinedIcon from '@mui/icons-material/LoopOutlined';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import RemoveOutlinedIcon from '@mui/icons-material/RemoveOutlined';
import InfoIcon from '@mui/icons-material/Info';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';

import PropTypes from 'prop-types';
import dayjs from "dayjs";
import { useEffect, useState } from "react";

const Loader = ({ query, refresh, refetchInterval }) => {

    const [p, setP] = useState(100);
    let refInterval = null;

    const resetInterval = ()=>{
        refInterval = setInterval(() => {
            var updatedAt = query.isError
                ? dayjs(query.errorUpdatedAt)
                : query.isSuccess
                    ? dayjs(query.dataUpdatedAt)
                    : dayjs();
            setP(10*parseInt(((-100 * (dayjs() - updatedAt) / refetchInterval) % 100)/10));
        }, 100);
    }

    useEffect(() => {
        setTimeout(resetInterval);
        return () => {
            clearInterval(refInterval);
        }
    }, [refetchInterval])

    if (query.isFetching) {
        // resetInterval();
        return <CircularProgress color="background"></CircularProgress>
    }

    return (
        <Box sx={{ position: 'relative', display: 'inline-flex' }}>
            <CircularProgress variant="determinate" value={p} color="background" />
            <Box
                sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                {refresh ? <IconButton color="neutral" onClick={() => refresh()}><LoopOutlinedIcon></LoopOutlinedIcon></IconButton> : <></>}
            </Box>
        </Box>

    )

}



const TopDeck = ({
    loading,
    newItem,
    removeItem,
    info,
    setup,
    refresh = null,
    query = null,
    refetchInterval = null
}) => {


    try {
        return (
            <Box style={{ height: '5vh' }}>
                <Stack direction="row" justifyContent="center">
                    {(!!query & !!refetchInterval) ? (
                        <Loader query={query} refetchInterval={refetchInterval} refresh={refresh} />
                    ) : (
                        <>
                            {loading && <CircularProgress></CircularProgress>}
                            {refresh && <IconButton color="neutral" onClick={() => refresh()}><LoopOutlinedIcon></LoopOutlinedIcon></IconButton>}
                        </>
                    )}
                    {newItem ? <IconButton color="primary" onClick={() => newItem()}><AddOutlinedIcon></AddOutlinedIcon></IconButton> : <></>}
                    {removeItem ? <IconButton color="danger" onClick={() => removeItem()}><RemoveOutlinedIcon></RemoveOutlinedIcon></IconButton> : <></>}
                    {info ? <IconButton color="neutral" onClick={() => info()}><InfoIcon></InfoIcon></IconButton> : <></>}
                    {setup ? <IconButton color="neutral" onClick={() => setup()}><SettingsOutlinedIcon></SettingsOutlinedIcon></IconButton> : <></>}
                </Stack>
            </Box>
        )
    } catch (e) {
        console.error("Topdeck", e);
        return <p>Errore:controllare console</p>
    }
}

TopDeck.propTypes = {
    loading: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
    newItem: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
    removeItem: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
    info: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
    setup: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
    refresh: PropTypes.oneOfType([PropTypes.bool, PropTypes.func])
}


export default TopDeck;