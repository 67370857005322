import { Lambda } from 'aws-sdk';
import { AWSIamBasedServiceClient } from './awsIamBasedServiceClient';






export class LambdaClient extends AWSIamBasedServiceClient {

    constructor({region, role}) {
        super({region, role})
        this.client = null;
        this.ready = false;
    }

    async init(){
        this.refreshClient = async () => {
            await this.refreshCredentials();
            try {
                this.client = new Lambda({credentials:this.credentials,region: this.region});
                this.ready = true;
            } catch(e){
                throw new Error("Cannot init Lambda Client due to " + e.toString());
            }
        }
        await this.refreshClient();
        return this;
    }


    async invoke({arnOrName, payload}) {

        await this.ensureCredentials();
        const baseParams = {
            FunctionName: arnOrName,
            Payload: JSON.stringify(payload)
        };
        try {
            const result = await this.client.invoke(baseParams).promise();
            if (parseInt(result.StatusCode) === 200){
                return result.Payload;
            } else {
                throw new Error(result.Payload.toString());
            }
        } catch (e) {
            throw new Error("Cannot invoke lambda" + arnOrName + "due to" + e.toString());

        }

    }

}



