import { Box, Drawer, Paper, Stack, styled } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import React, { useState } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import FlowExecutionList from '../../../components/flows/executionList';
import FlowDetails from '../../../components/flows/flowDetails';
import FlowExecutionStartForm from '../../../components/flows/flowExecutionStartForm';
import Deck from '../../../components/queries/deck';
import { useCabledQuery, useInfiniteCabledQuery } from '../../../hooks/useCabledQuery';
import { alertService } from '../../../services/alerting/alerting';
import ItpaApi from '../../../services/api';


const MyPaper = styled(Paper)(({theme})=>({
    marginTop: 0,
    padding: theme.spacing(1)
}));

const AdminFlowDetails = () => {

    const [newExecutionInputData, setNewExecutionInputData] = React.useState('');
    const [newExecutionInputDataError, setNewExecutionInputDataError] = React.useState('');
    const [newExecutionName, setNewExecutionName] = React.useState('');
    const [newExecutionNameError, setNewExecutionNameError] = React.useState('');
    const [sideContent, setSideContent] = useState(null);
    const params = useParams();
    const environment = params.environment;
    const flowName = params.flow_name;
    const navigate = useNavigate();
    if (!flowName) {
        navigate('..');
    }
    const moniServ = ItpaApi.getService("monitoring");

    const queryClient = useQueryClient();

    // const {flowQuery,refetchInterval} = useFlow({ environment, flowName });
    const { query: flowQuery, queryKey: flowQueryKey } = useCabledQuery({
        apiService: "monitoring",
        method: "getFlow",
        baseQueryKey: 'flow',
        refetchInterval: 10 * 60 * 1000,
        filters: { environment, flowName }
    });
    const { query: executionsQuery, queryKey: executionsQueryKey } = useInfiniteCabledQuery({
        apiService: "monitoring",
        method: "listExecutionsForFlow",
        baseQueryKey: 'execution',
        refetchInterval: 60 * 1000,
        filters: { environment, flowName }
    });

    const clearSideContent = () => { setSideContent(null); }
    const createNewExecution = async (newExecution) => {
        try {
            const res = await moniServ.startNewExecution({ ...newExecution, environment, flowName });
            
            setTimeout(() => queryClient.invalidateQueries(executionsQuery.key));
            setTimeout(() => createNewExecutionMutation.reset());
            setSideContent(null);
            alertService.success("Esecuzione avviata correttamente");
            if (res.alias){
                navigate("./"+res.alias)
            }
        } catch (e) {
            if (e.status === 409) alertService.error("Esecuzione duplicata");
            else alertService.error("Impossibile avviare l'esecuzione.")
        }



    }


    const uninstallFlowMutation = useMutation({
        mutationFn: async () => {
            try {
                await moniServ.unInstallFlow({flowName, environment});
                alertService.success("Flusso eliminato");
                queryClient.invalidateQueries("all_flows")
                setTimeout(()=>navigate(".."));
            } catch(e){
                alertService.success("Eliminazione fallita");
            } finally {
                uninstallFlowMutation.reset();
            }
        }
    })

    const createNewExecutionMutation = useMutation({
        mutationFn: createNewExecution
    })

    var detailsBlock = <></>;
    if (flowQuery.isFetching) {
        detailsBlock = <div>Loading...</div>
    }
    else if (flowQuery.isError) {
        detailsBlock = <div>Error: {flowQuery.error.message}</div>
    }
    else if (flowQuery.data) {
        detailsBlock = <FlowDetails flowData={flowQuery.data} />
    }

    return (
        <>
            <Stack justifyContent="space-between" sx={{height: "89vh"}}>
                <Deck
                    queryKey={flowQueryKey}
                    onRefresh={() => { console.log("REFRESHED!") }}
                    hasDeleteItem={true}
                    onDeleteItem={()=>uninstallFlowMutation.mutate()}
                    spaceBelow={0}
                />
                <MyPaper sx={{height:"23vh"}}>
                    {detailsBlock}
                </MyPaper>
                <Box sx={{flexGrow:2}} />
                <Deck
                    title="Esecuzioni"
                    queryKey={executionsQueryKey}
                    onRefresh={() => { console.log("REFRESHED!") }}
                    spaceBelow={0}
                    hasNewItem={true}
                    onNewItem={()=>setSideContent("start")}
                />
                <Paper sx={{height:"50vh"}}>
                    <FlowExecutionList executionsQuery={executionsQuery} flowName={flowName} onCellDoubleClick={(params) => { navigate('./' + params.row.alias) }} />

                </Paper>
            </Stack>
            <Drawer
                anchor="right"
                open={sideContent !== null}
                onClose={clearSideContent}>

                {sideContent === "start" && (
                    <FlowExecutionStartForm onOk={createNewExecutionMutation.mutate} onCancel={clearSideContent} />
                )}

            </Drawer>
        </>
    )
}



export default AdminFlowDetails