
export function updateEnvInEnvs(envsKnownData, envUpdate) {
    if (Array.isArray(envsKnownData)) {
        let found = false;
        for (let i = 0; i < envsKnownData.length; i++) {
            if (envsKnownData[i].name === envUpdate.name) {
                Object.assign(envsKnownData[i], envUpdate);
                found = true;
                break; // Termina il loop una volta trovato il match per "name"
            }
        }
        if (!found) {
            envsKnownData.push(envUpdate);
        }
    } else if (typeof envsKnownData === 'object') {
        if (envsKnownData.name === envUpdate.name) {
            Object.assign(envsKnownData, envUpdate);
        }
        
    }
}