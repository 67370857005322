
import { Avatar, Chip, Divider, Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { applyOmniboxFilter } from '../../components/filters/omnibox';
import IncidentWidget from '../../components/incidents/IncidentWidget';
import PageMainStack from '../../components/page/pageMainStack';
import Deck from '../../components/queries/deck';
import { useCabledQuery } from '../../hooks/useCabledQuery';
import { useLocalStorage } from '../../hooks/useLocalStorage';
import './problemsList.scss';
const OperatorIncidentsList = () => {

    const [openProblems, setOpenProblems] = useState([]);
    const [closedProblems, setClosedProblems] = useState([]);
    const [search, setSearch] = useState([]);

    const navigate = useNavigate();

    const [includeArchived, setIncludeArchived] = useLocalStorage('operator-problems-list-include-archived', false);

    const { query: problemsQuery, queryKey: problemsQueryKey } = useCabledQuery({
        apiService: 'operator-monitoring',
        method: 'listProblems',
        baseQueryKey: ['operator', 'all_problems'],
        filters: { environment: '*', includeArchived },
        enabled: true,
        refetchInterval: 2 * 60 * 1000
    });


    useEffect(() => {
        let op = [];
        let cp = [];
        let cso = [];
        if (problemsQuery && problemsQuery.data) {
            problemsQuery.data.forEach(
                problem => {
                    if (problem.status === "OPEN") {
                        op.push(problem);
                    } else {
                        cp.push(problem);
                    }
                    let cust = null;
                    let serv = null;
                    for (let comp of problem.impacted_components || []) {
                        if (comp.startsWith("c::")) {
                            cust = comp.replace("c::", "")
                        }
                        if (comp.startsWith("s::")) {
                            serv = comp.replace("s::", "")
                        }
                    }
                    for (let comp of problem.related_components || []) {
                        if (comp.startsWith("c::")) {
                            cust = comp.replace("c::", "")
                        }
                        if (comp.startsWith("s::")) {
                            serv = comp.replace("s::", "")
                        }
                    }
                    cso.push({ customer: cust, service: serv });

                }
            )
            op = applyOmniboxFilter(op, search, preprocess).sort((a, b) => new Date(b.start_date) - new Date(a.start_date));
            cp = applyOmniboxFilter(cp, search, preprocess).sort((a, b) => new Date(b.end_date) - new Date(a.end_date));
            setOpenProblems(op);
            setClosedProblems(cp);
        }
    }, [problemsQuery.data, search]);


    if (problemsQuery.isError) return <div>Error in retrieving problems: {problemsQuery.error.message}</div>;
    const SEVERITIES = ['', 'warning', 'minor', 'major', 'critical']
    const categories = [
        {
            title: 'Customer - Service',
            fields: { 'customer': 'customer', 'service': 'service' },
            render: (data) => `${data.customer} - ${data.service || "nessun servizio"}`
        },
        {
            title: 'Severity',
            fields: { 'severity': 'severity' },
            render: (data) => SEVERITIES[data.severity]
        }
    ];

    const preprocess = (problem)=>{
        let out = {...problem};
        let cust = "Nessuno";
        let serv = "Nessuno";
        for (let comp of problem.impacted_components || []) {
            if (comp.startsWith("c::")) {
                cust = comp.replace("c::", "")
            }
            if (comp.startsWith("s::")) {
                serv = comp.replace("s::", "")
            }
        }
        for (let comp of problem.related_components || []) {
            if (comp.startsWith("c::")) {
                cust = comp.replace("c::", "")
            }
            if (comp.startsWith("s::")) {
                serv = comp.replace("s::", "")
            }
        }
        out.customer = cust || "Nessuno";
        out.service = serv || "Nessuno";
        return out;
    };

    return (
        <PageMainStack>
            <Deck
                queryKey={problemsQueryKey}
                hasRefresh={true}
                hasOmniBox={true}
                searchHandler={{ search, setSearch, categories,preprocess }}
                switches={[
                    {switchState: includeArchived, setSwitchState: setIncludeArchived, switchLabel: 'Archive'}
                ]}
            />
         

            <Grid container>

                <Grid item xs={12} sm={4} md={2} xl={1} className="problemCountIndicator">
                    <Chip label="Aperti" avatar={<Avatar>{openProblems.length}</Avatar>} />
                </Grid>
                <Grid item xs={12} sm={8} md={10} xl={11}>
                    <Divider />
                </Grid>
                {
                    openProblems?.map(p => (
                        <Grid item xs={12} sm={6} md={4} justifyContent="center" key={p.problem_id} >
                            <IncidentWidget
                                status={p.status}
                                problemId={p.problem_id}
                                problemType={p.problem_type}
                                impactedComponents={p.impacted_components}
                                severity={p.severity}
                                startDate={p.start_date}
                                endDate={p.end_date}
                                onDoubleClick={() => navigate('./' + p.problem_id)}
                            />
                        </Grid>
                    ))
                }
                <Grid item xs={12} sx={{minHeight: '30px'}} />
                <Grid item xs={12} sm={4} md={2} xl={1} className="problemCountIndicator">
                    <Chip label="Chiusi" avatar={<Avatar>{closedProblems.length}</Avatar>} />
                </Grid>
                <Grid item xs={12} sm={8} md={10} xl={11}>
                    <Divider />
                </Grid>
                {
                    closedProblems?.map(p => (
                        <Grid item xs={12} sm={6} md={4} justifyContent="center" key={p.problem_id} >
                            <IncidentWidget
                                status={p.status}
                                problemId={p.problem_id}
                                problemType={p.problem_type}
                                impactedComponents={p.impacted_components}
                                severity={p.severity}
                                startDate={p.start_date}
                                endDate={p.end_date}
                                onDoubleClick={() => navigate('./' + p.problem_id)}
                            />



                        </Grid>
                    ))
                }





            </Grid>
        </PageMainStack>
    )
}



export default OperatorIncidentsList;