import { SQS } from 'aws-sdk';
import { AWSIamBasedServiceClient } from './awsIamBasedServiceClient';






export class SqsClient extends AWSIamBasedServiceClient {

    constructor({region, role}) {
        super({region, role})
        this.client = null;
        this.ready = false;
    }

    async init(){
        this.refreshClient = async () => {
            await this.refreshCredentials();
            try {
                this.client = new SQS({credentials:this.credentials,region: this.region});
                this.ready = true;
            } catch(e){
                throw new Error("Cannot init Sqs Client due to " + e.toString());
            }
        }
        await this.refreshClient();
        return this;
    }


    
    async listQueues(args) {
        const params = {
            MaxResults: 10
        };
        this.ensureCredentials();
        let allObjects = [];

        try {
            let isTruncated = true;
            let continuationToken = undefined;

            while (isTruncated) {
                if (continuationToken) {
                    params.NextToken = continuationToken;
                }

                const response = await this.client.listQueues(params).promise();
                allObjects = allObjects.concat(response.QueueUrls);

                isTruncated = !!response.NextToken;
                continuationToken = response.NextToken;
            }

            return allObjects;
        } catch (err) {
            console.error('Error listing objects:', err);
            return [];
        }
    }

}



