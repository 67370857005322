import { Card, CardContent, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from "@mui/material";


const Title = ({ text }) => <Typography sx={{ fontWeight: 'bold' }}>{text}</Typography>
const Value = ({ text }) => <Typography sx={{ fontStyle: 'italic' }}>{text}</Typography>
const MyLink = ({ text, target }) => <Typography sx={{ fontStyle: 'italic' }}>
    <a href={target} target="_blank">{text}</a>
</Typography>


const FlowDetails = ({ flowData }) => (
    
    <TableContainer>
        <Table sx={{ heigth: '20vh' }} size="small">
            <TableBody>
                <TableRow>
                    <TableCell>
                        <Title text="Nome" />
                    </TableCell>
                    <TableCell>
                        <Value text={flowData.name} />
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>
                        <Title text="Ambiente" />
                    </TableCell>
                    <TableCell>
                        <Value text={flowData.environment} />
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>
                        <Title text="Cliente - Servizio" />
                    </TableCell>
                    <TableCell>
                        <Value text={(flowData.meta?.customer || "")+" - "+(flowData.meta?.service || "")} />
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>
                        <Title text="Executor" />
                    </TableCell>
                    <TableCell>
                        <Value text={flowData.executor} />
                    </TableCell>
                </TableRow>
                {flowData.executor === 'stepfunctions' ?
                    <TableRow>
                        <TableCell>
                            <Title text="StepFunctions machine" />
                        </TableCell>
                        <TableCell>
                            <MyLink
                                text={flowData.external_flow_identity.machine_name}
                                target={"https://eu-west-1.console.aws.amazon.com/states/home?region="+flowData.external_flow_identity.region+"#/statemachines/view/arn:aws:states:eu-west-1:745905534821:stateMachine:" + flowData.external_flow_identity.machine_name}
                            />
                        </TableCell>
                    </TableRow>

                    : <></>}
            </TableBody>
        </Table>
    </TableContainer>

);

export default FlowDetails;