
import { ApiClient } from '../api';

class MonitoringService extends ApiClient {


    // sistema environment migrato a envservice
    // // ENVIRONMENTS

    // async listEnvironments() {
    //     return await this.do_get('/environments');
    // }
    // async createEnvironment({ name }) {
    //     return await this.do_post('/environments', { name });
    // }
    // async toggleDisabledEnvironment({ name, disabled }) {
    //     return await this.do_put('/environments/'+name, { disabled });
    // }

   
    // sistema componenti disattivato perchè non utilizzato
    // // COMPONENTS

    // async listComponents({environment}) {
    //     return await this.do_get_all_pages(`/dev/${environment}/components`);
    // }
    // async createComponent({environment,name, customer, service}){
    //     return await this.do_post(`/dev/${environment}/components`,{name,customer,service});
    // } 
    // async deleteComponent({environment,name}){
    //     return await this.do_delete(`/dev/${environment}/components/${name}`);
    // }
    // async getComponent({environment,name}){
    //     return await this.do_get(`/dev/${environment}/components/${name}`);
    // }


    // PROBLEMTYPES
    
    async listAllProblemTypes({environment}) {

        return await this.do_get_all_pages(`/dev/${environment}/pt`);
    }
    async describeProblemType({ problemType, environment }) {
        return await this.do_get(`/dev/${environment}/pt/${problemType}`);
    }
    async deleteProblemType({ problemType, environment }) {
        return await this.do_delete(`/dev/${environment}/pt/${problemType}`);
    }
    async createProblemType({ problemType, environment, flowRules }){
        return await this.do_post(`/dev/${environment}/pt`, { problem_type: problemType, flow_rules: flowRules });
    }


    // PROBLEMS
    async createProblem({environment, problemType, startDate, severity,impactedComponents,relatedComponents}) {
        
        return await this.do_post(`/dev/${environment}/problems`,{
            problem_type: problemType,
            start_date: startDate || new Date(),
            severity: severity,
            impacted_components: impactedComponents || [],
            related_components: relatedComponents || []
        })
    }
  
    async listProblems({environment, includeArchived, filtersDict = {}}) {
        filtersDict.include_archived = includeArchived;
        return await this.do_get_all_pages(`/dev/${environment}/problems`,filtersDict)
    }
  
    async getProblem({environment, problemId}) {
        return await this.do_get(`/dev/${environment}/problems/` + problemId);
    }
    async closeProblem({ environment, problemId, solution }) {
        return await this.do_post(`/dev/${environment}/problems/${problemId}/close`, solution || "");
    }
    async listProblemExecutions({environment, problemId}) {
        return await this.do_get(`/dev/${environment}/problems/${problemId}/executions`);
    }

    // PROBLEMS - operator
    async operatorListProblems({environment, includeArchived, filtersDict = {}}) {
        filtersDict.include_archived = includeArchived;
        
        return await this.do_get_all_pages(`/operator/${environment}/problems`,filtersDict)
    }
  
    async operatorGetProblem({environment, problemId}) {
        return await this.do_get(`/operator/${environment}/problems/` + problemId);
    }
    async operatorCloseProblem({ environment, problemId, solution }) {
        return await this.do_post(`/operator/${environment}/problems/${problemId}/close`, solution || "");
    }
    // async listProblemExecutions({environment, problemId}) {
    //     return await this.do_get(`/dev/${environment}/problems/${problemId}/executions`);
    // }


    // STEP FUNCTIONS

    async listSFRegions() {
        return await this.do_get('/sf/regions');
    }
    async listSFPerRegion({region}) {
        return await this.do_get_all_pages('/sf/'+region);
    }



    // FLOWS

    
    async listAllFlows({environment}) {
        console.log("---> listAllFlows")
        return this.do_get_all_pages(`/dev/${environment}/flows`)
    }
    async getFlow({flowName,environment}) {
        console.log("---> getFlow",{flowName,environment})
        return await this.do_get(`/dev/${environment}/flows/${flowName}`)

    }

    async installFlow({ flowName, executor, externalFlowIdentity,environment,meta }){
        
        return await this.do_post(`/dev/${environment}/flows`, { name: flowName, external_flow_identity: externalFlowIdentity, executor, environment, meta });
    }
    async unInstallFlow({ flowName, environment }){
        
        return await this.do_delete(`/dev/${environment}/flows/${flowName}`);
    }

   

    async startNewExecution({ flowName, environment, executionName, executionInputData, executionMeta={} }) {

        return await this.do_post(`/dev/${environment}/flows/${flowName}/executions`,
            {
                execution_name: executionName,
                execution_input_data: executionInputData,
                execution_meta: executionMeta
            })

    }

    async listExecutionsForFlow({ environment, flowName, filtersDict = {} }) {
        console.log("---> listExecutionsForFlow",{environment, flowName, filtersDict})
        return await this.do_get(`/dev/${environment}/flows/${flowName}/executions`,filtersDict);

    }


    async getExecution({ flowName, executionName, environment }) {

        return await this.do_get(`/dev/${environment}/flows/${flowName}/executions/${executionName}`);
    }
    async abortFlowExecution({ flowName, executionName, environment }) {

        return await  this.do_post(`/dev/${environment}/flows/${flowName}/executions/${executionName}/abort`,{});
        

    }

    // SCHEDULES

    async listScheduleTargets(){
        return await this.do_get_all_pages("/schedules/targets");
    }
    async listScheduleGroups(){
        return await this.do_get("/schedules/groups");
    }
    async listScheduleRules({group}){
        return this.do_get_all_pages(`/schedules/${group}/rules`);
    }
    async getScheduleRule({group,name}){
        return this.do_get(`/schedules/${group}/rules/${name}`);
    }
    async createScheduleRule({
        group, name, cron, target_arn,role_arn, target_input
    }){
        return this.do_post(`/schedules/${group}/rules`,{
            name, cron, target_input,target_arn,role_arn
        })
    }
    async updateScheduleRuleCron({
        group, name, cron
    }){
        return this.do_put(`/schedules/${group}/rules/${name}/cron`,{
            cron
        })
    }
    async updateScheduleRuleInput({
        group, name, target_input
    }){
        return this.do_put(`/schedules/${group}/rules/${name}/target_input`,{
            target_input
        })
    }
    async toggleEnableScheduleRule({
        group, name, enabled
    }){
        return this.do_put(`/schedules/${group}/rules/${name}/enabled?enabled=${enabled}`)
    }
    async enableScheduleRule({
        group, name
    }){
        return this.do_put(`/schedules/${group}/rules/${name}`,{
            enabled: true
        })
    }
    async deleteScheduleRule({
        group, name
    }){
        return this.do_delete(`/schedules/${group}/rules/${name}`);
    }
    async updateScheduleExceptions({
        group, name, exceptions
    }){
        return this.do_post(`/schedules/${group}/rules/${name}/exceptions`,{
            dates:exceptions
        })
    }
    async listDemoOccurrences({
        group,name,date_from,date_to
    }){
        let timespan_qs =  this.encode_qs({date_from,date_to})
        return this.do_get(`/schedules/${group}/rules/${name}/occurrencesdemo?timespan=${timespan_qs}`);
    }
    async listOccurrences({
        group,name,date_from,date_to
    }){
        let timespan_qs =  this.encode_qs({date_from,date_to})
        return this.do_get_all_pages(`/schedules/${group}/rules/${name}/occurrences?timespan=${timespan_qs}`);
    }


}







export default MonitoringService