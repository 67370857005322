import { useCabledQuery } from "../../hooks/serviceRegistry/useCabledQuery";
import { Button, Box } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";

import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { DataGrid } from '@mui/x-data-grid';

function printAsPercent(dotNumber){
  return (dotNumber * 100).toString() + " %";
}

export default function MonitoraggioRisorse() {

  let queryClient = useQueryClient();

  let acmeQuery = useCabledQuery({
    svcName: "acme",
    method: "doListResources",
    refetchInterval: 30 * 1000,
    baseQueryKey: ["acme", "resourcesList"],
    args: {},
    enabled: true
  })
  let loadingData = acmeQuery?.query?.isLoading || acmeQuery?.query?.isFetching;
  let renderingData = acmeQuery?.query?.data || [];
  let errorOccurred = acmeQuery?.query?.isError;
  let hasRenderingData = !errorOccurred && renderingData.length;

  let columns = [
    {field: 'serverName', headerName: 'Server Name', flex: 2},
    {field: 'cpu', headerName: 'CPU %', flex: 1, renderCell: (params)=> printAsPercent(params.row.cpu)},
    {field: 'ram', headerName: 'RAM %', flex: 1, renderCell: (params)=> printAsPercent(params.row.cpu)},
    {field: 'disk', headerName: 'DISK %', flex: 1, renderCell: (params)=> printAsPercent(params.row.cpu)}
  ];


  if (loadingData) {
    return (
      <>
        <p>CARICAMENTO IN CORSO...</p>
      </>
    )
  }
  else if (errorOccurred) {
    return (
      <>
        <p>ERRORE:{acmeQuery.query.error}</p>
      </>
    )
  }
  else return (
    <>
      <Box sx={{ height: 400, width: '100%' }}>
      <DataGrid 
        rows={renderingData}
        columns={columns}
        getRowId={(row)=>row.serverName}
      />
      </Box>



      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table" size="small">
          <TableHead>
            <TableRow>
              <TableCell>Server Name</TableCell>
              <TableCell align="right">CPU %</TableCell>
              <TableCell align="right">RAM %</TableCell>
              <TableCell align="right">DISK %</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {hasRenderingData ? (
              renderingData?.map((row, i) => (
                <TableRow
                  key={i}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">{row.serverName}</TableCell>
                  <TableCell align="right">{printAsPercent(row.cpu)}</TableCell>
                  <TableCell align="right">{printAsPercent(row.ram)}</TableCell>
                  <TableCell align="right">{printAsPercent(row.disk)}</TableCell>
                </TableRow>
              ))
            ) : 
            (
              <TableRow><TableCell align="center" colSpan={4}>Nessun record</TableCell></TableRow>
            ) }
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );

}

{/* <p>Pagina Monitoraggio risorse</p>
<pre>{JSON.stringify(acmeQuery?.query?.isError)}</pre>
<pre>{JSON.stringify(acmeQuery?.query?.isFetching)}</pre>
<pre>{JSON.stringify(acmeQuery?.query?.data)}</pre>
<pre>{JSON.stringify(acmeQuery?.queryKey)}</pre>
<Button onClick={() => queryClient.invalidateQueries(["acme", "resourcesList"] )}>Clicca</Button> */}