import { Box } from '@mui/material';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import MonitorOutlinedIcon from '@mui/icons-material/MonitorOutlined';
import PauseOutlinedIcon from '@mui/icons-material/PauseOutlined';
import PlayArrowOutlinedIcon from '@mui/icons-material/PlayArrowOutlined';
import SensorsOffOutlinedIcon from '@mui/icons-material/SensorsOffOutlined';
import SensorsOutlinedIcon from '@mui/icons-material/SensorsOutlined';
import { useQueryClient } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import useCabledMutation from '../../hooks/serviceRegistry/useCabledMutation';
import { useCabledQuery } from '../../hooks/serviceRegistry/useCabledQuery';
import { alertService } from '../../services/alerting/alerting';






const RenderSessionStatus = p => p.value === "connected" ? (
    <MonitorOutlinedIcon sx={{ color: "green" }} />
) : (
    <MonitorOutlinedIcon sx={{ color: "red" }} />
);

const RenderUpdatedAt = p => {

    if (dayjs(p.row.updatedAt * 1000).add(5, 'minute') < dayjs()) {
        return <SensorsOffOutlinedIcon sx={{ color: "red" }} />
    } else {
        return <SensorsOutlinedIcon sx={{ color: "green" }} />
    }
}

export default function AgentsInPool({
    poolName,
    queryData = [],
    adminView = false,
    svcName = 'agents'
}) {
    const queryClient = useQueryClient();

    const toggleRunningMutation = useCabledMutation({
        svcName,
        method: 'doPost',
        onSuccess: () => { },
        onFailure: () => {
            alertService.error("Cannot toggle running flag")
        }
    });
    const setRunning = async (id) => {
        toggleRunningMutation.mutate({
            path: `/pools/${poolName}/agents/${id}/runningFlag`,
            args: {
                body: {
                    desired: true
                }
            }
        });
        setTimeout(() => {
            queryClient.invalidateQueries(['agents', poolName]);
        }, 2000);
    };
    const setPaused = async (id) => {
        toggleRunningMutation.mutate({
            path: `/pools/${poolName}/agents/${id}/runningFlag`,
            args: {
                body: {
                    desired: false
                }
            }
        });
        setTimeout(() => {
            queryClient.invalidateQueries(['agents', poolName]);
        }, 2000);
    };

    const adminOnlyColumns = ['agentVersion', 'actions'];
    
    const stateComparator = function(s1,s2){
        const sortedStates = ['ready','warmed','resuming','warming','booting','terminating','terminated'];
        const i1 = sortedStates.indexOf(s1);
        const i2 = sortedStates.indexOf(s2);
        return i1-i2;
    }

    let columns = useMemo(() => [
        { field: "uid", headerName: "ID", flex: 2 },
        { field: "state", headerName: "State", flex: 1,
        sortComparator: stateComparator,
    },
        { field: "sessionStatus", headerName: "Desktop", flex: 1, renderCell: RenderSessionStatus },
        { field: "agentVersion", headerName: "V", flex: 1.5 },
        { field: "processStatus", headerName: "Process", flex: 1, valueGetter: p => p?.row?.processStatus?.replace("ProcessingState", "").toLowerCase() || "" },
        { field: "runnerConnectionStatus", headerName: "Link", flex: 1 },
        { field: "runnerWorkingStatus", headerName: "Exec", flex: 1 },
        { field: "updatedAt", headerName: "Updates", flex: 1, renderCell: RenderUpdatedAt },
        // { field: "updatedAt2", headerName: "Updates", flex: 1, renderCell: p=>new Date(p.row.updatedAt*1000).toLocaleString()},
        {
            field: "actions", type: "actions", headerName: "Actions", flex: 1, cellClassName: "actions",
            getActions: ({ id, row }) => {
                let out = [];
                if (row.runningFlag) {
                    out.push(
                        <GridActionsCellItem
                            icon={<PauseOutlinedIcon />}
                            label="Pause"
                            onClick={() => setRunning(id)}
                            color="inherit"
                        />
                    );
                } else {
                    out.push(
                        <GridActionsCellItem
                            icon={<PlayArrowOutlinedIcon />}
                            label="Pause"
                            onClick={() => setPaused(id)}
                            color="inherit"
                        />
                    );

                }
                return out;

            }
        }

    ]);
    if (!adminView) {
        columns = columns.filter(c => !adminOnlyColumns.includes(c.field));
    }
    let agents = queryData || [];


    return (
        <Box sx={{ height: "82vh" }}>
            <DataGrid
                rows={agents}
                columns={columns}
                getRowId={r => r.uid}
                initialState={{
                    sorting: {
                        sortModel: [{ field: 'state', sort: 'asc' }],
                    },
                }}
            />
        </Box>
    )
}