import Box from '@mui/material/Box';
import './report.scss';

import React from 'react';

import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import PagePaper from '../../../components/page/pagePaper';
import { useCredentials } from '../../../hooks/carrefour/carrefour';
import { fullDateStr } from '../../../services/common/common';

const CarrefourCredentials = () => {

    const credsQuery = useCredentials();

    if (credsQuery.isFetching) {
        return <Box>Loading...</Box>
    } else if (credsQuery.isError) {
        return <Box>Impossibile scaricare lo stato delle credenziali.</Box>
    }


    return (
        <PagePaper>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>ID</TableCell>
                            <TableCell>Credenziale bloccata?</TableCell>
                            <TableCell>Assegnata fino a </TableCell>
                            <TableCell>Assegnata all'agente</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {credsQuery.data ? (credsQuery.data.map((c, cI) => <TableRow key={c.id}>
                            <TableCell>{c.id}</TableCell>
                            <TableCell>{c.disabled ? "SI" : "NO"}</TableCell>
                            <TableCell>{c.expire_at ? fullDateStr(c.expire_at) : "---"}</TableCell>
                            <TableCell>{c.assigned_to ? c.assigned_to : "---"}</TableCell>
                        </TableRow>)) : (<></>)}
                    </TableBody>
                </Table>
            </TableContainer>
        </PagePaper>
    )
}

export default CarrefourCredentials