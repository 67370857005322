import ArrowBack from "@mui/icons-material/ArrowBack";
import { Box, Button, ButtonGroup, Card, CardContent, Grid, IconButton, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from "@mui/material";
import React from "react";
import { dayMonthDateStr, expected, hourMinuteDateStr } from "../../services/common/common";
import { LapideBottom, LapideTop, stepSemaphore } from "./lapide";
import './style.scss';
import dayjs from 'dayjs';




export default function DatiDollaro({
    data
}) {
    console.log(data);
    let dd = null;
    let d = data.find(x => x.data_type === "json");
    console.log(d);
    if (d) {
        dd = JSON.parse(d.data_content);
        dd.sort(function(a,b){ return (dayjs(a.startdate,"DD/MM/YYYY hh:mm:ss") < dayjs(b.startdate,"DD/MM/YYYY hh:mm:ss")) ? 1 : -1})
    }
    console.log(dd);


    return (
        <Grid container>
            {(dd === null) ? ("La procedura non ha raccolto dati.") : (
                <>
                    {/* {dd?.map((x, xI) => <p key={xI}>{JSON.stringify(x)}</p>)} */}
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell>Uproc</TableCell>
                                <TableCell>Status</TableCell>
                                <TableCell>Start Date</TableCell>
                                <TableCell>End Date</TableCell>
                                <TableCell>Processing Date</TableCell>
                                <TableCell>Session</TableCell>
                                <TableCell>Task</TableCell>
                                <TableCell>Uproc label</TableCell>
                                <TableCell>Node</TableCell>
                                <TableCell>Launch number</TableCell>
                                <TableCell>Session number</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {dd?.map((x, xI) => <TableRow key={xI}>
                                <TableCell>{x.uproc}</TableCell>
                                <TableCell>{x.status}</TableCell>
                                <TableCell>{x.startdate}</TableCell>
                                <TableCell>{x.enddate}</TableCell>
                                <TableCell>{x.processingdate}</TableCell>
                                <TableCell>{x.session}</TableCell>
                                <TableCell>{x.task}</TableCell>
                                <TableCell>{x.uproclabel}</TableCell>
                                <TableCell>{x.node}</TableCell>
                                <TableCell>{x.launchno}</TableCell>
                                <TableCell>{x.sessionno}</TableCell>
                            </TableRow>)}
                        </TableBody>
                    </Table>

                </>

            )}

        </Grid>
    )

}
