import { Box, Stack, Typography } from '@mui/material';
import React, { useContext, useState } from 'react';



import { AppContext } from '../../../hooks/appContext';
import useCabledMutation from '../../../hooks/serviceRegistry/useCabledMutation';
import OpinionatedForm from '../../forms/opinionatedForm';




const AdminCreatePoolEditor = ({
    onCancel = () => { },
    onSuccess = () => { },
    onError = () => { }
}) => {
    const {currentEnvironment} = useContext(AppContext);
    const [formValues, setFormValues] = useState({});
    const createPoolMutation = useCabledMutation({
        svcName: 'agents',
        method: 'doPost',
        onSuccess,
        onFailure: onError
    });

    const fields = [
        {
            name: "poolName", label: "Pool Name", type: "text", required: true },
        {
            name: "agentChannel", label: "Agent Channel", type: "select", required: true,
            getOptions: () => ['collaudo','production']
        },
        { name: "minAgentVersion", label: "Min Agent Version", type: "text", placeholder: " 3.1.8706.21280", required: true },
    ];
    const buttons = [
        { name: "Cancel", onClick: () => onCancel(), color: "error" },
        { name: "Create", onClick: () => { createPoolMutation.mutate({ path: `/pools`, body: { 
            pool_name: formValues.poolName,
            environment: currentEnvironment,
            agentChannel: formValues.agentChannel,
            minAgentVersion: formValues.minAgentVersion,
         } }) }, color: "success", disabled: (formIsValid) => !formIsValid }
    ];

    return (

        <Stack spacing={2} sx={{ minWidth: "25vw" }} alignItems="center">
            <Box sx={{ height: "10vh" }} />
            <Typography variant="h6" component="div" gutterBottom>Create pool</Typography>
            <OpinionatedForm fields={fields} buttons={buttons} formValues={formValues} setFormValues={setFormValues} displayJson={true} validateOnChange={true} />
        </Stack>
    )
}
export default AdminCreatePoolEditor