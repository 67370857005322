import { Autocomplete, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";



function createObj(fields,values){ return fields.reduce((p,c,i)=>Object.assign(p,{[c]:values[i]}),{})}

function compareObjs(filterObj,masterObj){
    return Object.keys(filterObj).reduce((p,c)=>p && filterObj[c] === getByDot(masterObj,c),true);
}

function getByDot(obj,dotField){
    let s = dotField.split(".");
    if (s.length === 0) return obj;
    if (s.length === 1) return obj[s[0]];
    let r = s.shift();
    return getByDot(obj[r],s.join("."));
}

function getCombinations(arr, fields) {
    if (!arr || !arr.length) return [];
    return arr.reduce((p,c)=>{
        let values = Object.values(fields).map(f=>getByDot(c,f));
        let ind = p.findIndex(pp=>pp.reduce((a,b,i)=>a && b === values[i],true));
        if (ind === -1) p.push(values);
        return p
    },[]).map(c=>createObj(Object.keys(fields),c));
  }


export function applyOmniboxFilter(arr,filters,preprocess=x=>x){
    if (!filters.length) return arr;
    console.log(filters);
    return arr.map(preprocess).filter(
        x=>{
            
            console.log("processing",x);
            for (let filter of filters){
                if (typeof filter === 'string'){
                    console.log("str filter",filter);
                    if (Object.values(x).some(y=>(y || "").toString().toLowerCase().includes(filter.toLowerCase())))
                        return true
                } else if (typeof filter === 'object'){
                    if (filter.filter){
                        console.log('obj filter',filter.filter,x,compareObjs(filter.filter,x))
                        if (compareObjs(filter.filter,x))
                            return true;
                    }
                }

            }
            return false;
        }
    )
}

/**
 * Le categorie sono fatte come segue:
 * const categories = [
 *       {
 *           title: 'Customer - Service', // titolo del gruppo di opzioni nella select
 *           fields: {'customer':'meta.customer','service':'meta.service'}, // campi da estrarre come valori dei filtri: le chiavi sono libere, i valori sono il percorso del campo nel dato, in dot notation
 *           render: (data) => `${data.customer} - ${data.service || "nessun servizio"}` // come viene renderizzata l'opzione nella select
 *       }
 *   ];
 */

export default function OmniBox({
    data, // i dati su cui generare i filtri
    preprocess = x=>x,
    filters, // qui vengono salvati i filtri
    setFilters, // setter dei filtri
    categories, // categorie su cui creare i filtri
    multiple=true, // permette selezione multipla
    freeSolo=true, // permette inserimento testo libero
}) {
    
    const [options,setOptions] = useState([]);
    useEffect(()=>{
        let opts = [];
        for (let category of categories){
            let combinations = getCombinations((data || []).map(preprocess),category.fields);
            combinations.forEach(x=>Object.assign(x,{
                title:category.title, 
                render: category.render ? category.render(x) : JSON.stringify(x),
                optionLabel: category.render ? category.title+"="+category.render(x) : category.title+"="+JSON.stringify(x),
                filter: Object.keys(category.fields).reduce((p,c)=>Object.assign(p,{[category.fields[c]]:x[c]}),{})
            }))
            
            opts.push(...combinations);
        }
        opts.forEach((x,i)=>Object.assign(x,{id:i}))
        setOptions(opts);
    },[data])


    return <>
    {/* <Box><pre>{JSON.stringify(options,null,2)}</pre></Box> */}
    <Autocomplete
        id="omnibox"
        sx={{width: "100%"}}
        multiple={multiple}
        freeSolo={freeSolo}
        size="small"
        options={options || []}
        groupBy={(option) => option.title}
        onChange={(e, v) => {
            setFilters(v);
        }}
        value={filters}
        getOptionLabel={(option) => option.optionLabel ? option.optionLabel : 'search:'+option}
        renderOption={(props, data) => (
            <Typography key={data.id} {...props} variant="body1">
                {data.render}
            </Typography>
        )}
        style={{ width: 500 }}
        loading={!options.length}
        renderInput={(params) => (
            <TextField
                {...params}
                variant="outlined"
                label="Search"
                placeholder="Search"
            />
        )}
    /></>;

}